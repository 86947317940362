import englishMessages from 'ra-language-english';

const customEnglishMessages = {
    ...englishMessages,
    traducciones: {
        visor : {
            anotaciones:'Anotations',
            saveAnotaciones:'Save anotations',
            archivo : 'Documents',
            archivoLocal : 'Open local document',
            archivoUrl : 'Open document from url',
            exportar : 'Export document',
            componer : 'Compose document',
            guardarCache : 'Save document on cache',
            imprimir : 'Print document',
            imprimirPdf : 'Print document in PDF format',
            cerrarEditor : 'Close editor',
            exportarTexto : 'Export text',
            propiedades : 'Properties',
            editar : 'Edit',
            deshacer : 'Undo',
            rehacer : 'Redo',
            borrarAnotacion: 'Delete anotation',
            seleccionarTodo: 'Select All',
            seleccionarTodasAnotaciones: 'Select all anotations',
            limpiarSeleccion: 'Clean selection',
            copiarTexto : 'Copy text',
            buscarTexto: 'Search text',
            ver : 'Show',
            rotarIzquierda: 'Left Rotate',
            rotarDerecha: 'Right Rotate',
            alejar : 'Zoom Out',
            acercar : 'Zoom In',
            tamañoActual : 'Actual size',
            ajustarApagina : 'Fit to page',
            ajustarAancho : 'Fit to width',
            comoSVG: 'Show as SVG',
            comoImagen: 'Show as Picture',
            pagina:'Page',
            primerPagina:'First page',
            paginaAnterior:'Previus page',
            proximaPagina:'Next page',
            ultimaPagina:'Last page',
            rotarPaginaDerecha:'Rotate page to right',
            rotarPaginaIzquierda:'Rotate page to left',
            rotarPaginas:'Rotate pages...',
            simple:'Simple',
            doble:'Double',
            vertical:'Vertical',
            horizontal:'Horizontal',
            interaccion:'Interaction',
            irPaginaPrevia:'Go to previus page',
            irPaginaNext:'Go to next page',
            numPageActual:'Actual page number',
            tamanioReal:'Show real size of the page',
            tamaniEncajar:'Fit image to window',
            tamanioAjustarAncho:'Fit image to window width',
            singlePageDisp:'Single page display',
            doublePageDisp:'Double page display',
            verticalDisplay:'Vertical display',
            horizontalDisplay:'Horizontal display',
            lupa:'Magnifying glass',
            propiedadesAnotacion:'Anotation properties',
            anotacionProps:'Properties',
            anotacionCont:'Content',
            anotacionInfo:'Information',
            cerrar:'Close',
            cargandoDocumento : 'Loading document',
            verificando : 'Verifying server comunication',
            abriendoDocumento : 'Opening document',
        },
        menu: {
            administracion : 'Administration',
            usuarios: 'Users',
            grupos: 'Groups',
            estructura: 'Estructure',
            nodos : 'Nodes',
            indices : 'Document Titles',
            consultas : 'Queries',
            campos: 'By Fields',
            general: 'General',
            contenido: 'By Content',
            digitalizar: 'Digitize',
            desdeArchivo : 'From files',
            indexar: 'Classify',
            navegar : 'Browse',
            escritorio: 'Dashboard',
            plantillas : 'Template',
            bastanteo: 'For enough',
            predefinida : "Predefined queries",
            masivo:'Bulk Load',
            cuenta:'My account',
            catalogos: 'Catalogs'
        },
        titulos:{
            nuevoUsuario : 'New User',
            nuevoGrupo : 'New Group',            
            editarUsuario : 'Edit User',
            editarGrupo : 'Edit Group',
            gestionDeGrupos : 'Group Managment',
            gestionDeUsuarios : 'Users Managment',
            addNodosHijos : 'Add child for this node',
            addNodosPrincipal : 'Add root node',
            deleteNodo : 'Delete this Node',
            editNodo : 'Rename this document type',
            nuevoNodo : 'New Node',
            nodoPadre : 'Root Node',
            gestionDeNodos : 'Node managment',
            gestionDeIndices : 'Index mangment',
            nuevoIndice: 'New index',
            busquedaCampos : 'Queries by fields',
            busquedaGeneral : 'General Queries',
            busquedaContenido : 'Queries by content',
            consultarCampos : "Browse this node",
            camposResultados: "Complete the fields to search",            
            resultadoBusqueda : 'Query results',
            archivoDigitalizar : "Files to digitize",
            archivoSeleccionar : "Drag and drop your files here, or click to import them from file",
            imagenesDigitalizar : "Images to digitize",
            imagenesSeleccionar : "Drag and drop your images here, or click to import them from file",
            previsualizacion : "Document preview",
            digitalizar : "Digitize documents",
            gestionIndexar : 'Management of Documents to Index',
            reasignarDocumento : "Reassign document to another node",
            reasignar : "Reassign to this node",
            reasignarUsuario : "Reassign node to another user",
            consultaNavegacion : "Search by Navigation",
            resultado : "Result",
            porcentaje : "of completeness",
            colapsar : "Collapse child nodes",
            mostrar : "Show child nodes",
            restablecerContraseña : "Restore password",
            cambioContraseña : "Password change / First login",
            plantillaSeleccionada : "Use selected template",
            agregarIndice : "Select index to add",
            ingresando : "Entering...",
            selectNodo : 'Select this type of document',
            cambiarNombreNodo : 'Edit document type name',
            reubicarNodo : 'Relocate document type',
            reubicar : 'Relocate within this document type',
            busquedaBastanteo : 'Consultations for enough',
            resultadosBastanteo : "Search results for enough",
            ubicacion : "Location",
            busquedaPredefinida : 'Predefined queries',
            ejecutarConsulta : "Run Query",
            archivosTmp:'Temporary files',
            indexarDocumento : "Index document",
            indexarArchivos: 'Index files',
            resultadoNodos: 'Results found',
            fullTextOn: 'Software For Intelligent Image Analysis activated',
            fullTextOff: 'Software For Intelligent Image Analysis disabled',
            configuracionCuenta: 'Account settings',
            editarCatalogo: 'Edit catalog',
            gestionCatalogos:'Catalog management',
            catalogo: 'Catalogue',
            nuevoCatalogo: 'New catalog',
            seleccionNodos: 'Select the nodes',
            vaciarNodo: 'Empty node files',
            subirTmp: 'Upload temporary files',
            restablecer: 'Reset values',
            general:'General',
            navegar:'Browse',
            porCampos:'By fields',
            porContenido:'By content',
            sinAcceso: 'Sorry, you do not have the required permissions for this view.'
        },
        permisos: {
            anotaciones : 'Anotations',
            exportar : 'Export',
            importar : 'Import',
            digitalizar : 'Digitize',
            imprimir : 'Print',
            enviar : 'Send',
            sinmarcaagua : 'No watermark',
            general:'General query',
            navegar:'Query browser',
            porCampos:'Query by fields',
            porContenido:'Query by content',
            permDocumentos: 'Document permissions',
            permConsultas: 'Query permissions'
        },
        campos: {
            nombre : 'Name',
            contrasena: 'Password',
            usuario : 'User',
            grupo : 'Group',
            nombreDeGrupo : 'Name of group',
            email : 'Email address',
            rol : 'User role',
            estadoPassword: 'Password status',
            estadoUsuario: 'User status',
            vigenciaPassword: 'Password validity (days)',
            nodoNombre : 'Name of Node',
            indice : 'Index',
            tipo : 'Type of index',
            mascara : 'Mask',
            minimo : 'Minimum',
            maximo : 'Maximum',
            longitud : 'Long',
            requerido : 'Required',
            multiple : 'Multiple',
            unico : 'Unique',
            verificar : 'Verify',
            repetido : 'Repeated',
            diccionario : 'Dictionary',
            biometrico : 'Biometric',
            indiceNombre : 'Name of index',
            numeroTemporal : "Nº Temporal Document",
            fechaDigitalizacion : "Fecha de digitalización",
            descripcion : "Description",
            tipoDocumento : "Type of Document",
            passwordOld : "Current password",
            passwordNew : "New password",
            passwordNewRepeat : "Repeat new password",
            plantillas: 'Select template',
            usuarioHabilitado : 'Active User',
            usuarioDesHabilitado : 'User blocked',
            si : 'YES',
            no : 'NO',
            usuarioDigitalizacion : 'Digitizer User',
            idQuery : 'Query identifier',
            nombreQuery : 'Query Name',
            catalogo : 'Select Catalog',
            gruposNotificacion : 'Notification Groups',
            usuariosNotificacion : 'Notification Users',
            nombreCatalogo : 'New catalog name',
            valores : 'Values',
            valor : 'Value',
            buscador : 'Search document type',
            checkMarcar : 'Check / Uncheck All',
            activar : 'Activate',
            vencimiento : 'Permit expiration',
            cancelar: 'Cancel',
            aceptar: 'Accept',
            vaciar: 'Empty',
            ingDesc: 'Enter description',
            statusOn: 'Enabled user',
            statusOff: 'Disabled user',
            notificar: 'Notify' 
        },
        notificaciones: {
            addUsuarioOk : 'User created successfully',
            editUsuarioOk : 'User saved successfully',
            addGrupoOk : 'Group created successfully',
            editGrupoOk : 'Group saved successfully',
            noHayCampos : 'No fields result for query',
            actualizaAnotacionesOK : 'Anotations saved successfully',
            documentosOK : 'Digitization was successful',
            catalogosOk: 'Catalog created',
            catalogosInfo: 'You must add at least one value to the new catalog',
            catalogosEditOk: 'Modified catalog',
            usuarioDuplicado: 'The user already exists: ',
            grupoDuplicado: 'The group already exists: ',
            datosDuplicados: 'Duplicate data',
            duplicadosText: 'Check the data please',
            vaciarNodos: 'Are you sure you want to empty the files selected so far?',
            vacios: 'Values have been reset',
            sinDesc: 'You must indicate your description to classify the documents',
            clasificacionOk: 'The files have been classified',
            indexadoOk: '¡Indexed file!',
            actualizadosOk: 'The data has been updated',
            cambiosOk: 'Changes have been saved',
            cambiosError: 'Sorry, changes could not be saved',
            limiteValores: 'You have reached the limit of 25 values',
            aviso: 'Important Notice!',
            avisoMsj: 'Upon downloading, the user is solely responsible for the handling and confidentiality of the information. Please handle the data with care and in a confidential manner.'
        },
        modal : {
            eliminarGrupo : 'Delete Grup',
            eliminarGrupoConfirm : 'Are you sure you want to delete this group?',
            eliminarUsuario : 'Delete User',
            eliminarUsuarioConfirm : 'Are you sure you want to delete this user?',
            eliminarNodo : 'Delete Nodr',
            eliminarNodoConfirm : 'Are you sure you want to delete this node?',
            eliminarIndice : 'Delete Index', ///////////////
            eliminarIndiceConfirm : 'Are you sure you want to delete this index?', ////////////////////
            editarUsuarios : 'Select user to access the node:',
            editarUsuariosDocumento : 'Select users with access to this document',
            noResults : 'Results not found',
            eliminarDocumentoTemporal : 'Delete temp document',
            eliminarDocumentoConfirmTemporal : 'Are you sure you want to delete this temp document?',
            eliminarDocumento : 'Delete Document',
            eliminarDocumentoConfirm : 'Are you sure you want to delete this document?',
            ediarDatosDocumento : 'Update document data',
            eliminarCatalogo: 'Delete catalog',
            eliminarCatalogoConfirm: 'Are you sure you want to delete the catalog?',
            sinNombre: 'Enter the name of the document type',
            seleccionNodo : 'Select the path of the document type you want',
            notificaciones : 'Notifications',
            asigUser: 'Assign files only to',
            confirmVaciar: 'Do you want to empty the node files ',
            ayuda: 'Need help? You can activate the interactive guides here.'
        },
        roles : {
            administrador : 'Administrator',
            seguridad : 'Security',
            supervisor : 'Supervisor',
            operador : 'Operator',
            usuario : 'User',
        },
        estadoPassword : {
            caducado : 'Expired',
            vigente : 'Valid' ,
            noCaduca : 'Not Expired' ,
            temporalCaduca : 'Temporary that does expire' ,
            temporalNoCaduca : 'Temporary that does not expire',
        },
        botones : {
            consultar: "Search",
            subir: "Digitize Documents",
            indiceIndividual: "Add single index",
            indicePlantilla: "Add index by template",
            descargarSeleccionados : "Download selected files",
            descargarCsv : "Download selected data in CSV",
            descargarSeleccionadosAll : "Download all",
            descargarCsvAll : "Download all results in CSV",
            volver: 'Return',
            opcionesDescarga : 'Download options',
            cambiarContraseña: 'Change Password',
            agregarCatalogo: 'Add catalog',
            login: 'Log in',
            crearPre: 'Create new query',
            elegirArch : 'Choose files',
            noArchivos: 'files',
            clasificar: 'Classify files',
            vaciar: 'Empty files',
            guiasQuerys: 'Interactive Query Guides',
            guiasDigit: 'Interactive Digitize Guides',
            guiasAdmin:'Interactive Administration Guides',
            guiasEstructura:'Interactive Structure Guides',
            documentosFaltantes: 'Hide missing documents (No display of completion percentage)'
        },
        error : {
            indiceRepetido: "The index you want to add is already in the list",
            indicePlantillaRepetido : "Some of the aggregate indexes are repeated",
            noHaySeleccion: "You must select at least one item"
        },
        login : {
            bienvenido : 'Welcome',
            contactenos : 'Contact Us',
            ayuda : 'Help',
            restablecer : 'Restore',
            olvidepass : 'Forgot your password?',
            mensaje : 'We cross borders through secure and reliable solutions and implementations',
            primera : "If you are entering for the first time, click here : "
        },
        dash : {
            indexadosXdia : 'Documents indexed per day',
            documentos : 'Documents',
            digitalizados : 'digitized'
        },
        generales : {
            gruposTexto1 : 'Groups can be used to assign the same permissions to a team that has the same functions.',
            gruposTexto2 : 'For example: Team 1 is in charge of only importing the information.',
            step1 : 'Step 1.-',
            step2 : 'Step 2.-',
            step3 : 'Step 3.-',
            grupostep1 : 'Give your team a name',
            grupostep2 : 'Assign team members',
            grupostep3 : 'Select the functions that this team will have.',
            dashstep1 : 'Create your structures (folder image)',
            dashstep2 : 'Create the search fields which are the labels with which you will identify your documents within the platform',
            dashstep3 : 'Invite your Team and set their permissions',
            dashtitle : 'Ready for you to start configuring it',
            errorTitulo : 'Ups! An error has occurred',
            errorSubtitulo : 'There was an error communicating with the server, please try again',
            noArchivos : 'No. of files selected',
            instruccionDig: 'To digitize documents',
            inst1: 'Upload the files to the folder you want to upload them to',
            inst2: 'Once your files are selected, select an option',
            opcion: 'Option'
        },
        introMasiva : {
            intro1: "🎉 Welcome to this introductory guide! You will learn how to digitize your files step by step.",
            title1: 'Welcome!',
            intro2: "📁 Here you'll identify the nodes, where you can upload and manage your documents.",
            title2: 'Nodes',
            intro3: "🚀 This is where the magic happens. Drag and drop your files or click to add them.",
            title3: 'Upload documents',
            intro4: "📋 It shows how many files you have uploaded to the selected node.",
            title4: 'Uploaded files',
            intro5: "❌ Wrong file? Use this button to clear the node and start over.",
            title5: 'Clear node',
            intro6: "⚙️ After uploading files, you will find useful options to manage your documents.",
            title6: 'Available options',
            intro7: "🗑️ Delete all uploaded files in the nodes if you need a fresh start.",
            title7: 'Clear nodes',
            intro8: "📑 Upload documents to categorize them at your own pace.",
            title8: 'Categorize documents',
            intro9: "📄 Save your documents in the corresponding nodes if you know their location.",
            title9: 'Index documents',        
        },
        introBotones : {
            doneLabel: 'Finish',
            nextLabel: 'Next',
            prevLabel: 'Previous',
            dontShow: "Don't show again"            
        },
        introIndexar : {
            intro1: "📝 Let's start by entering the required fields to index the file.",
            title1: 'Index Files',
            intro2: "🚀 With the provided data, press the button to initiate the indexing of your files.",
            title2: 'Index File',
        },
        introClasificar : {
            intro1: "📝 Let's begin by entering the description under which the document will be saved for later categorization.",
            title1: 'Categorize Files',
            intro2: "🚀 After you've entered the description, simply click the button to finalize the process.",
            title2: 'Finish Categorization',
        },
        introPredefinidas : {
            //Con consultas predefinidas
            cintro1: "🎉 Hello and welcome! Here you will find the available predefined queries. These searches allow us to visualize both existing documents and those that are missing, as well as identify in which node they are located.",
            ctitle1: 'Predefined Queries',
            cintro2: "🔍 In this section, you can locate the name that will help you identify the query.",
            ctitle2: 'Query Name',
            cintro3: "🗑️ Use this button to delete queries when you no longer need them.",
            ctitle3: 'Delete Queries',
            cintro4: "🔍 Use this button to perform queries and obtain specific results.",
            ctitle4: 'Perform Queries',
            //Sin consultas predefinidas
            sintro1: "🎉 Welcome! You don't have any queries available yet. Let's create a new one!",
            stitle1: 'Welcome!',
            sintro2: "🔧 Click the creation button to get started.",
            stitle2: 'Create Query',
        },
        introCreate : {
            intro1: "🎉 Welcome! Here you can create a new predefined query with the configuration you need.",
            title1: 'Query Creation',
            intro2: "📝 Assign a name to your query for later identification.",
            title2: 'Query Name',
            intro3: "🔍 Select the operator that the query will use to compare values.",
            title3: 'Query Operator',
            intro4: 'Activate this option if you want all fields to be mandatory to get results. If activated, all selected fields must match to yield results.',
            title4: 'Activate Matching',
            intro5: 'Activate this option if you only need a single field for all selected fields. Once activated, you can assign a name to this common field. If it remains deactivated, you can enter data in each selected field individually.',
            title5: 'Activate Common Field',
            intro6: 'Here, you will find the selected fields. You can assign them a fixed value or remove them if you made a mistake or no longer need them.',
            title6: 'Selected Fields',
            intro7: 'Here, you can add the fields in which you want to query your documents. Later, we will return to add your first fields.',
            title7: 'Add Fields',
            intro8: "It is important to assign permissions. Here, you can specify which users and/or groups will have access to this query. Once created, you won't be able to modify these permissions, so make sure to configure them correctly.",
            title8: 'Assign Permissions',
            intro9: 'Once you have correctly configured your new query, you can click here to create it and start using it. This is the final step to launch your query.',
            title9: 'Create Query',
        },
        introhandleOpen: {
            intro1: 'Do you need help adding your fields? These are the steps you should follow.',
            title1: 'Add Fields',
            intro2: 'The first thing you need to do is search through the entire structure for the node from which you want to extract fields.',
            title2: 'Identify the Node',
            intro3: "Once you've identified the node you need, click the button to display the available fields. Then, you can select the fields you need from the node and add them to the query.",
            title3: 'View Available Fields',
            intro4: 'You can add different fields from various nodes. Once you have selected your fields, you can come back here to finish configuring your query.',
            title4: 'Return',
        },
        introValorFijo: {
            intro1: 'Here you can observe the field name and the node to which it belongs.',
            title1: 'Field Details',
            intro2: 'You can assign a different operator than the one configured in the query if necessary.',
            title2: 'Field Operator',
            intro3: "Here you can assign a fixed value to the field. Keep in mind that once assigned, you won't be able to modify it when using the query.",
            title3: 'Field Details',
            intro4: 'If you need to delete the field, simply click here.',
            title4: 'Delete Field',
            intro5: 'To save the changes made to the field, just click here.',
            title5: 'Save Changes',
        },
        introHandleToBack : {
            intro1: 'Here you will find the added fields. You can click on a field to assign it a fixed value, assign a different operator only to that field, or remove it from the query.',
            title1: 'Field Details',
        },       
        introPreCampos : {
            intro1: "🔍 In this section, you will find the name of the search you have previously selected.",
            title1: 'Final Steps!',
            intro2: "📝 Enter the data you want to search for in their respective fields.",
            title2: 'Enter Values',
            intro3: "🚀 All set! Once you have entered the data, click this button to search for the information.",
            title3: 'Perform Search',
            intro4: "🔙 Want to go back to the list of searches? Just click here.",
            title4: 'Return to List',
        },
        introHelp: {
            intro1: 'Need help? You can enable or disable interactive guides at any time. Just click here to activate or deactivate them.',
            title1: 'Interactive Guides',
        },
        introCampos : {
            intro1: '🔍 Welcome to Queries by fields! Here you can generate specific reports for a document type using its corresponding fields.',
            title1: 'Queries by fields',
            intro2: "🌟 Before you begin, it's crucial to choose the node where you want to conduct your search.",
            title2: 'Select a Node',
            intro3: '🔽 To expand the node structure and find the type of document you want to locate, simply click here.',
            title3: 'Expand Structure',
            intro4: '💡 Once you have identified the node, simply click this button to display the available fields where you can perform your search.',
            title4: 'Show Available Fields',
            //Get field
            cintro1: "Take a look at the available fields for the node you selected! 📄🔍",
            ctitle1: "Available Fields",
            cintro2: "Now, simply enter the data you need. You don't need to fill in all the fields! 📝😊",
            ctitle2: "Enter Data",
            cintro3: "Great! With the entered data, click this button to search for your documents. 🚀🔎",
            ctitle3: "Search",
        },
        introContenido : {
            intro1: '🔍 Welcome to Queries by content! Here you can locate documents by simply entering words or content within the document, even if they do not exist as fields. <br/><b>Important:</b> These searches are limited to documents that have been previously processed with SOFIIA®.',
            title1: 'Queries by content',
            intro2: "🌟 Before you begin, it's crucial to choose the node where you want to conduct your search.",
            title2: 'Select a Node',
            intro3: '🔽 To expand the node structure and find the type of document you want to locate, simply click here.',
            title3: 'Expand Structure',
            intro4: '💡 Once you have identified the node, simply click this button to display the field where you can perform your search.',
            title4: 'Show Field',
            //Get field
            cintro1: "Now you can enter words or content present in the documents you are looking for. 📝😊 Explore using examples like names, dates, and more!",
            ctitle1: "Enter Data",
            cintro2: "Great! With the entered data, click this button to search for your documents. 🚀🔎",
            ctitle2: "Search",
        },
        introGeneral : {
            intro1: '🔍 Welcome to General Queries! Here you can search for your information using any data from your document to locate it.',
            title1: 'General Queries',
            intro2: "🌟 Before you start, it's essential to select the node in which you want to conduct your search. <br/><b>Note:</b> These searches give us the ability to explore throughout the entire structure we choose.",
            title2: 'Select a Node',
            intro3: '🔽 To expand the node structure and find the type of document you want to locate, simply click here.',
            title3: 'Expand Structure',
            intro4: '💡 Once you have identified the node, simply click this button to display the field where you can perform your search.',
            title4: 'Show Field',
            //View field 
            cintro1: "Start now! Simply enter the data you need. This search allows you to explore different types of documents to find one or more pieces of data. You can search for up to 25 pieces of data at the same time to speed up your search. 📝😊",
            ctitle1: "Enter Data",
            cintro2: "Great! With the entered data, click this button to search for your documents. 🚀🔎",
            ctitle2: "Search",
        },
        introNavegar : {
            intro1: "🔍 Welcome to Search by Navigation! Here you can search for and view the most recent documents of each type. This search allows you to get an overview of the contents of each node.",
            title1: "Search by Navigation",
            intro2: "🌟 Before you begin, it's crucial to choose the node where you want to conduct your search.",
            title2: "Select a Node",
            intro3: "🔽 To expand the node structure and find the type of document you want to locate, simply click here.",
            title3: "Expand Structure",
            intro4: "💡 Once you have identified the node, simply click this button to display the latest documents stored in it.",
            title4: "Search",
        },
        introTabla : {
            intro1: 'These are your query results. In this table, you can see each of your results and perform some actions with them.',
            title1: 'Query Results',
            intro2: 'This is a document. Here you will find all the data related to the document and its available actions.',
            title2: 'Explore Documents',
            intro3: 'You can select the document if you wish to export it. Choose one or more documents.',
            title3: 'Select Document',
            intro4: 'If you want to export all the results from a page, select all the documents from here.',
            title4: 'Select All Documents',
            intro5: 'Once you have selected the documents you need to export, click here to choose the option you require.',
            title5: 'Export Documents',
            intro6: 'Need to modify any data in the document fields? Here, you can make modifications.',
            title6: 'Edit Document',
            intro7: 'Want to move a document to another node? You can reassign the document to another node here.',
            title7: 'Reassign Document',
            intro8: 'Need to delete a document? Use this icon accompanying each document to delete it.',
            title8: 'Delete Document',
            intro9: 'Here, you can grant access to users and groups for the document.',
            title9: 'Grant Access to Users and Groups',
            intro10: 'Want to view the document? This option will open a document viewer where you can view and interact with your document if you have the necessary permissions.',
            title10: 'View Document',
        },
        introCatalogos: {
            //Con catalogos
            cintro1: 'Welcome to the catalogs section! Here, you can view existing catalogs, manage them, or delete them.',
            ctitle1: 'Catalogs',
            cintro2: 'In this section, you can explore a catalog and see its name along with available actions.',
            ctitle2: 'Exploring a Catalog',
            cintro3: 'Here, you can manage your catalog, change its name, or administer its values.',
            ctitle3: 'Edit Catalog',
            cintro4: 'Want to delete a catalog? You can do it here.',
            ctitle4: 'Delete Catalog',
            cintro5: 'Create a new catalog in the blink of an eye! Just click here.',
            ctitle5: 'Create New Catalog',
            //Sin catalogos
            sintro1: 'Welcome to the catalogs section! Currently, you do not have any catalogs available.',
            stitle1: 'Catalogs',
            sintro2: 'If you wish to create a new catalog, you can do so by clicking here.',
            stitle2: 'Create New Catalog',
        },
        introCatalogoCreate: {
            intro1: 'To create a new catalog, first assign a name to your catalog here.',
            title1: 'Assign Catalog Name',
            intro2: 'Here, you can add as many values as you want and assign them a value.',
            title2: 'Add Values to the Catalog',
            intro3: "Once you've assigned a name and added the necessary values, click here to create the new catalog.",
            title3: 'Create Catalog',
        },
        introCatalogoEdit: {
            intro1: 'In this section, you can edit the values of your catalog.',
            title1: 'Edit Catalog Values',
            intro2: 'If a value is no longer needed in your catalog, you can delete it here.',
            title2: 'Delete Catalog Values',
            intro3: 'Do you want to save the changes to the catalog? Click here to confirm them.',
            title3: 'Save Changes to Catalog',
        },
        introDashboard: {
            intro1: "Take a look at the platform's structure to get familiar with it. This will make your experience much simpler and effective. 🌟",
            title1: "Welcome to Aiidara®",
            intro2: "This is your main dashboard, where you'll find graphs and statistics about platform usage, allowing you to see everything at a glance. Keep an eye on your important data here! 👀",
            title2: "Dashboard",
            intro3: "Here's the options menu. It's like your map for navigating the platform, taking you to different areas and important functions. Explore it! 🗺️",
            title3: "Options Menu",
            intro4: "These are the different queries you can use. Each one will help you search for specific information. 📖",
            title4: "Queries",
            intro5: "In this area, you can manage your documents. Upload or categorize your documents in an organized way. 🗂️",
            title5: "Digitization",
            intro6: "Here, you can manage your users and groups. Keep everything organized and under control. 👥",
            title6: "Administration",
            intro7: "In this space, you can manage the structure of your nodes, their fields, and catalogs. It's like building and maintaining your custom folders and labels. Do it your way and keep everything organized as you wish!",
            title7: "Structure",
            intro8: "Here you can log out and/or configure your password. Make sure to keep your data secure! 🔒",
            title8: "Log Out",
        },
        introDigit: {
            intro1: 'Here you can temporarily digitize your files for later classification according to your needs.',
            title1: 'Temporary Digitization',
            intro2: "🌟 Before you begin, it's crucial to choose which node you want to upload your documents to.",
            title2: 'Select a Node',
            intro3: '🔽 To expand the node structure and find the node where you want to upload your documents, simply click here.',
            title3: 'Expand Structure',
            intro4: "💡 Once you've identified the node, simply click this button to display the fields where you can upload your documents.",
            title4: 'Select Node',  
            //ToUpload           
            intro5: 'In this space, you can drag and drop or add the images you want to upload to the selected node. 🖼️',
            title5: 'Add Images',
            intro6: 'In this space, you can drag and drop or add the files you want to upload to the selected node. 📁',
            title6: 'Add Files',
            intro7: 'Here, you should add a description related to the documents you are uploading. This will help you identify them more easily when categorizing them. 📄',
            title7: 'Add Description',
            intro8: "In this place, you can assign the documents to different users. It's like specifying who is responsible. 💼",
            title8: 'Assign Users',
            intro9: "Well done! Once you've added your documents and the required data, click this button to upload the documents. 📤",
            title9: 'Upload Documents',
        },
        introCreateUser: {
            intro1: 'As a first step, assign a unique name to the group for identification.',
            title1: 'Assign Group Name',
            intro2: "Add the users who will be part of this group and will have the configured permissions for the group.<br/><b>Important:</b> When you add a user to a group, they inherit the group's permissions, which combine with their existing individual permissions.",
            title2: 'Add Users',
            intro3: 'Activate this permission to allow group users to add annotations to documents they have access to.',
            title3: 'Annotation Permission',
            intro4: 'Activate this permission to enable the document or CSV report download feature in searches.',
            title4: 'Export Permission',
            intro5: 'Activate this permission to allow group users to upload documents to nodes they have access to.',
            title5: 'Import Permission',
            intro6: 'Activate this permission to allow group users to upload documents to nodes they have access to.',
            title6: 'Digitization Permission',
            intro7: 'Activate this permission to allow group users to use the print function for documents they have access to within the viewer.',
            title7: 'Print Permission',
            intro8: 'Activate this permission if you want to allow group users to send a document from the document viewer.',
            title8: 'Send Permission',
            intro9: 'Activate this permission if group users should view documents without watermarks.',
            title9: 'Without Watermark',
            intro10: "Configure permissions for group users' access to different types of queries.",
            title10: 'Query Permissions',
            intro11: 'After assigning a name, adding your users, and configuring their permissions, simply click the button.',
            title11: 'Create New Group',
        },
        introGrupoList: {
            // With Groups
            cintro1: 'Welcome to the groups section! Here, you can view and manage the groups in your platform. 👥',
            ctitle1: 'Groups',
            cintro2: "This is a group, here you can find the group's name and observe the active permissions it has.",
            ctitle2: 'Exploring a Group',
            cintro3: "Here you can manage the group, change its name, or administer the group's permissions. 🛠️",
            ctitle3: 'Edit Group',
            cintro4: 'You can delete a group here if you no longer need it. 🗑️',
            ctitle4: 'Delete Group',
            cintro5: 'Create a new group in the blink of an eye! Just click here. ➕',
            ctitle5: 'Create New Group',
            // Without Groups
            sintro1: "Welcome to the groups section! At this moment, you haven't created any groups, but we're here to help you get started. 😊",
            stitle1: 'Groups',
            sintro2: '🚀 Creating a new group is simple and quick. Just follow these easy steps:',
            stitle2: 'Create New Group',
            sintro3: 'Now, all you need to do is click the button right here. Go ahead and give it a click to start creating your first group.',
            stitle3: 'Create Button',
        },
        introIndiceList: {
            intro1: "Welcome! In this section, you'll have full control over managing your fields. We'll guide you through the key steps so you can edit and manage your fields effectively.",
            title1: 'Fields',
            intro2: 'Here, you can quickly and easily visualize your nodes.',
            title2: 'Explore Nodes',
            intro3: 'If you need a broader view of your structure to find a specific node, use this option.',
            title3: 'Expand Structure',
            intro4: 'Here, you can see the fields of the node and manage them according to your needs. Use this option located within the node you need to access field information.',
            title4: 'View Node Fields',              
        },
        introCreateCampo: {
            intro1: 'Welcome! In this section, you will configure a new field according to your needs.',
            title1: 'New Field Configuration',
            intro2: 'Enter a name to identify this field.',
            title2: 'Assign Field Name',
            intro3: 'Select the data type you want this field to store.',
            title3: 'Select Field Type',
            intro4: 'Optionally, specify a specific format that the field should follow (leave blank if not needed).',
            title4: 'Field Format',
            intro5: 'Assign a minimum value for the field (you can leave it at 0 if not needed).',
            title5: 'Minimum Value',
            intro6: 'Assign a maximum value for the field (you can leave it at 0 if not needed).',
            title6: 'Maximum Value',
            intro7: "Set the maximum character length the field can contain (leave it at 0 if you don't want to limit it).",
            title7: 'Maximum Length',
            intro8: 'Do you want the field to be mandatory? Activate this option if necessary.',
            title8: 'Make Field Mandatory',
            intro9: 'Do you want the field to accept multiple values? Activate this option if necessary.',
            title9: 'Allow Multiple Values',
            intro10: 'Do you want the field to contain unique values that cannot be repeated? Activate this option if necessary.',
            title10: 'Make Field Unique',
            intro11: 'Activate this option to validate the data entered in the field.',
            title11: 'Field Validation',
            intro12: 'Activate this option if you want to allow repeated data in the field.',
            title12: 'Allow Repeated Data',
            intro13: 'Activate this option to notify users when changes are made to this field.',
            title13: 'Notify Changes to Field',
            intro14: 'Once you have configured all the details of your new field, click here to create it.',
            title14: 'Create New Field',               
        },        
        introViewCampos: {
            intro1: 'In this section, you can view all the fields of the node. In the table, you can modify data, delete fields, or create new fields.',
            title1: 'Node Fields',
            intro2: 'To add an additional field to the selected node, simply click here.',
            title2: 'Create New Field',
            intro3: 'If you have made modifications to the field data and want to save the changes, be sure to click here to save them.',
            title3: 'Save Changes',               
        },
        introSelectCampo: {
            intro1: 'Before creating a new field, choose one of the following options.',
            title1: 'Field Creation',
            intro2: 'If you want to create a field related to an existing template, select this option.',
            title2: 'Fields with Template',
            intro3: 'To create a custom field from scratch, choose this option.',
            title3: 'Individual Field',              
        },
        introNodosList: {
            intro1: 'Welcome! In this section, you will have complete control over the management of your node structure. We will guide you through the key steps so you can organize, edit, and manage your nodes effectively.',
            title1: 'Document Types',
            intro2: 'Here, you can view your main nodes. These nodes are the solid foundation on which you will build your organization and create your own node structures.',
            title2: 'Main Nodes',
            intro3: 'Here, you can create a new main node in your structure, which helps you better organize your documents.',
            title3: 'Create Main Node',
            intro4: 'Adding additional nodes allows you to create sublevels within a main node. This makes organization and navigation within your structure easier.',
            title4: 'Add Node',
            intro5: 'Node editing is useful when you need to change the name of an existing node to suit your needs.',
            title5: 'Edit Node',
            intro6: "Sometimes, it's necessary to get rid of nodes that are no longer relevant. Keep in mind that if a node has sublevels, they will also be deleted.",
            title6: 'Delete Node',
            intro7: 'Controlling who can access a node and its content is essential for the security of your structure. Use this option located within the node to configure permissions.',
            title7: 'Manage Access',
            intro8: 'Node reassignment allows you to move them to other parts of the structure as needed.',
            title8: 'Reassign Node',
            intro9: "Sofiia® is an important technology in some nodes. You can check if it's enabled or disabled by observing the color indicator in the node. It will be green if it's enabled and black and white if it's disabled. 🟢⚪⚫",
            title9: 'Node with Sofiia®',
            intro10: 'If you need a broader view of your structure to find a specific node, use this option.',
            title10: 'Expand Structure',
        },
        introDocumentsTmp: {
            //Con documentos
            intro1: 'Welcome! In this section, you can view your documents pending classification. 😊',
            title1: 'Classify Documents',
            intro2: "In this section, you will find the name of the user to whom the document has been assigned. It's like knowing who is responsible for this document. 👤",
            title2: 'Assigned User',
            intro3: "Here you will find the date on which the document was digitized. It's useful for identifying when the document was entered into the system. 📅",
            title3: 'Digitization Date',
            intro4: 'In this section, you will find the description with which the document was digitized. This will help you identify what the document is about. 📝',
            title4: 'Description',
            intro5: 'Here you can see the document type. This will indicate the category to which the document belongs. 📄',
            title5: 'Document Type',
            intro6: 'This button allows you to enter the document fields for indexing it. 🖊️',
            title6: 'Index Document',
            intro7: 'Have you assigned the document to the wrong node during digitization? This button allows you to reassign the document to the node you want.',
            title7: 'Reassign Node',
            intro8: "Have you assigned the document to the wrong user during digitization? This button allows you to reassign the document to another user. It's like changing the document's responsibility.",
            title8: 'Reassign User',
            intro9: 'If you no longer need the document, this button allows you to delete it. 🗑️',
            title9: 'Delete Document',
            //Sin documentos
            intro10: "Welcome! Currently, you don't have any documents pending classification. Here you can view your documents to be indexed. 😊",
            title10: 'Classify Documents',
            intro11: 'Great! You can start by uploading your documents here. 📤',
            title11: 'Upload Documents',
        },
        introUsuarioCreate: {
            intro1: 'Here, you must assign a username to identify the user.',
            title1: 'Assign Username',
            intro2: "Enter the email address of the new user. Make sure it's valid because their username and password will be sent there for platform access.",
            title2: 'Email Address',
            intro3: 'Assign a role depending on the functions you want the new user to perform.',
            title3: 'User Role',
            intro4: "Select the type of password the user will have. It is recommended to select 'Temporary, doesn't expire' to allow the user to change their password upon first login.",
            title4: 'Password State',
            intro5: 'Specify the number of days for which the password will be valid. Leave it as 0 days for no expiration.',
            title5: 'Password Expiry',
            intro6: 'Activate this permission to allow the user to add annotations to the documents they have access to.',
            title6: 'Annotations Permission',
            intro7: 'Activate this permission to enable the download function for documents or reports in CSV format in searches.',
            title7: 'Export Permission',
            intro8: 'Activate this permission to allow the user to upload documents to the nodes they have access to.',
            title8: 'Import Permission',
            intro9: 'Activate this permission to allow the user to scan documents to the nodes they have access to.',
            title9: 'Scanning Permission',
            intro10: 'Activate this permission to allow the user to use the document printing function for documents they have access to within the viewer.',
            title10: 'Printing Permission',
            intro11: 'Activate this permission if you want to allow the user to send a document from the document viewer.',
            title11: 'Send Permission',
            intro12: 'Activate this permission if the user should view documents without watermarks.',
            title12: 'Without Watermarks',
            intro13: "Configure permissions for the user's access to different types of queries, and finally, complete this guide and click the add button.",
            title13: 'Query Permissions',
        },
        introUsuariosList: {
            intro1: 'Welcome to the Users section! Here, you can view and manage the members of your platform. 👥',
            title1: 'Users',
            intro2: 'In this section, you will find the username. 👤',
            title2: 'Username',
            intro3: 'In this section, you will find the role the user has. This defines their permissions and functions. 👤',
            title3: 'User Role',
            intro4: "In this section, you will find the user's status, indicating whether the account is active or disabled. 🟢🔴",
            title4: 'User Status',
            intro5: 'In this section, you will find the password status, showing the current password configuration.',
            title5: 'Password Status',
            intro6: "Password validity tells you how long the user's password is valid, or if it's 0 days, it means it has no expiration! ⏳",
            title6: 'Password Validity',
            intro7: "In this section, you will find the user's email address. 📧",
            title7: 'Email Address',
            intro8: 'Discover which groups the user belongs to in this section. 👥🔍',
            title8: 'View Groups',
            intro9: 'Here, you can modify user data, change their status, and adjust their permissions with the edit option ✏️.',
            title9: 'Edit User',
            intro10: 'If you no longer need a user, you can delete them from this option. 🗑️',
            title10: 'Delete User',
            intro11: 'Create a new user in the blink of an eye! Just click here to enter the details. ➕',
            title11: 'Create New User',
        },
        introVisor: {
            title1: 'Document Viewer',
            intro1: 'Welcome to the Document Viewer! We will explore its structure together.',
            title2: 'Document 📄',
            intro2: 'Here you can view your document and perform actions on it.',
            title3: 'Toolbar 🛠️',
            intro3: 'This toolbar contains all the tools you need to work with the viewer.',
            title4: 'Document Options 📤',
            intro4: 'In this menu, you can export or print your document.',
            title5: 'Annotation Options ✍️',
            intro5: 'Access here to save all the annotations you add to the document.',
            title6: 'View Options 👀',
            intro6: 'Change the way you view the document, rotate, and adjust its size from here.',
            title7: 'Page Options 📃',
            intro7: 'In this menu, you can change pages or adjust the page display settings.',
            title8: 'Interaction Options 🤝',
            intro8: 'Find tools to interact with your document in this section.',
            title9: 'Close Document 🚪',
            intro9: 'Close the document or this tab from here.',
            title10: 'Pages 📚',
            intro10: 'View all the pages contained in the document in this section.',
            title11: 'Annotation Tools ✍️',
            intro11: 'Here, you will find the toolbar to add annotations to the document. <br/>Remember to save your annotations for the changes to take effect! 📌',
        },
        loader: {
            texto1: 'We are collecting your data.',
            texto2: 'Please be aware that this process may take a few minutes.',
            texto3: 'We appreciate your patience as we work on it.'
        },
        stepsDownCsv: {
            title1: 'Would you like to download all results in CSV format?',
            text1: 'A total of ',
            text2: ' results have been found.',
            text3: 'You can configure the number of results you want to download in CSV format. \nThe system will notify you when the data is available.',
            final: 'Data downloaded successfully!',
            btnConfig: 'Configure',
            resultError: 'The requested number of results is not valid; please review the provided data.',
            title: 'Configure Download',
            inputNumber: 'Enter the number of results you want to download:'
        }        
    }
}

export default customEnglishMessages;