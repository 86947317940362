import * as React from 'react';
import Button from '@material-ui/core/Button';
import ErrorIcon from '@material-ui/icons/Report';
import History from '@material-ui/icons/History';
import { Title, useTranslate } from 'react-admin';

const MyError = ({
    error,
    errorInfo,
    ...rest
}) => {
    const translate = useTranslate();
    return (
        <div style={{textAlign: 'center', padding : '50px'}}>
            <Title title="Error" />
            <h1>
            { translate("traducciones.generales.errorTitulo")}
            </h1>
            <div>{ translate("traducciones.generales.errorSubtitulo")}</div>
            {
            /* process.env.NODE_ENV !== 'production' && (
                <details>
                    <h2>{translate(error.toString())}</h2>
                    {errorInfo.componentStack}
                </details>
            ) */}
            <div style={{padding: '30px'}}>
                <Button
                    variant="contained"
                    startIcon={<History />}
                    onClick={() => 
                        window.history.back()
                    }>
                    {translate("traducciones.botones.volver")}
                </Button>
            </div>
        </div>
    );
};

export default MyError;