import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { useTranslate } from 'react-admin';

const LimitedTextInput = ({ value, onChange, ...rest }) => {
  const [inputValue, setInputValue] = useState(value);
  const [hasReachedLimit, setHasReachedLimit] = useState(false);
  const translate = useTranslate();

  const handleInputChange = (event) => {
    const newValue = event.target.value;

    const lines = newValue.split('\n');
    let limitedValue
    
    if(lines.length>25){
      const result = lines.filter(elemento => elemento != '')
      limitedValue = result.slice(0,25).join('\n');
      setHasReachedLimit(lines.length >= 25);
    }else if(lines.length<=25){
      if(lines[lines.length-1] == ''){
        const result = lines.filter(element => element !== '');
        result.push('');
        limitedValue = result.join('\n');
        setHasReachedLimit(result.length >= 25);
      }else{
        const result = lines.filter(element => element !== '');
        limitedValue = result.join('\n');
        setHasReachedLimit(result.length >= 25);
      }
    }

    setInputValue(limitedValue);

    if (onChange) {
      onChange(limitedValue);
    }
  };

  return (
    <>
      <TextField
        {...rest}
        multiline
        value={inputValue}
        onChange={handleInputChange}
      />
      {hasReachedLimit && (
        <p style={{ color: 'red', marginLeft: '-10px' }}>
          {translate('traducciones.notificaciones.limiteValores')}
        </p>
      )}
    </>
  );
};

LimitedTextInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default LimitedTextInput;