import * as React from 'react';
import { useState  } from 'react';
import {
    FormWithRedirect,
    TextInput ,
    useDelete,
    useTranslate,
    SimpleForm,  
    Loading, 
    Error, 
    Create,
    useRefresh,
    Query, 
    NumberInput ,
    SaveButton,
    DateInput,
    useDataProvider,
    Pagination,
    useNotify,
    SelectInput
} from 'react-admin';
import Button from '@material-ui/core/Button';
import FolderIcon from '@material-ui/icons/Folder';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SearchIcon from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/Warning';
import withWidth from '@material-ui/core/withWidth';
import CardResultado from './Responsive/CardResultado';
import ExternalViewer from '../Libs/ExternalViewer/ExternalViewer';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TableResultado from '../Components/TableResultado';
import MyLoading from '../Components/MyLoading';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import swal from 'sweetalert';
import NoAccess from '../Components/NoAccess';
import DownloadButton from '../Components/DownloadButton';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import { useEffect } from 'react';
import Loader from '../Components/Loader';

/*
Componente dedicado a la busqueda por campos 
*/

//Objeto principal para creacion de classes css del objeto de manera Dinamica.
const useStyles = makeStyles({
    nodo : {
        padding: '6px',
        border: '1px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        width: 'max-content'
    },
    nodoSeleccionado :  {
        padding: '6px',
        border: '1px solid black',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        backgroundColor: "#319b4257",
        color: '#277233'
    }
});

/*
Funcion recursiva que crear la visualizacion y opciones de los nodos hijos
Esta funcion recibe como parametros:
nodo : nodo padre,
clases : objeto de clases css dinamico,
addnode : funcion para seleccionar cada nodo,
translate : funcion de react admin declarada en la clase principal para las traducciones
nodoPadreelemento: nodo seleccionado principal,
colapsar: funcion para colapsar o no el nodo
checknodoabierto: funcion para verificar el estado de colapso del nodo que se esta dibujando
*/
function dibujaNodosHijos(nodo,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto){
    if(nodo.hijos != undefined){
        return (
            <div>
                {nodo.hijos.map(
                (item) => {
                    checknodoabierto(item);
                    return (
                    <div  style={{ marginLeft: '40px', marginTop : '-5px'}}>
                        <div style={{ float: 'left' }}>
                            <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                        </div>
                        <div className={ nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > <FolderIcon style={{ marginRight: '5px'}} />
                            { item.TextoNodo }
                            <Button title={ translate('traducciones.titulos.consultarCampos')} onClick={() => addNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                <SearchIcon style={{ fontSize: '16px'}}  />
                            </Button>
                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                <ExpandLessIcon />
                            </Button>
                            }
                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                <ExpandMoreIcon />
                            </Button>
                            }
                        </div>
                        { !item.colapsado &&
                        <div>
                            { dibujaNodosHijos(item,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                        </div>
                        }
                    </div>
                )})
                }
            </div>
        )
    }
}

const BusquedaCampos = ({ width, ...props }) => { 
    
    /*
    Props. permisos es un arreglo de permisos que devuelve React Admin, en cada vista (funcion dentro del Auth Provider)
    */
    const permisos = props.permissions ? props.permissions.ConsultadeDocumentos : null;  
    const checkPermiso = (permiso) => {
        if(permisos){
            return permisos[permiso];
        }
        return false;
    }
    const access= JSON.parse(localStorage.getItem('permisos'))

    const classes = useStyles();
    const [nodoPadreElemento, setnodoPadreElemento] = useState(null);    
    const [loadinga, setLoadinga] = useState(false);
    const [openNoResult, setOpenNoResult] = useState(false);
    const [openResult, setOpenResult] = useState(false);
    const [openDelete, setopenDelete] = useState(false);
    const [openDocument, setopenDocument] = useState(false);    
    const [indiceSeleccionado, setindiceSeleccionado] = useState(null);
    const [documentt, setDocument] = useState(null);
    const [deleteOne] = useDelete();
    const translate = useTranslate();
    const refresh = useRefresh();
    const [dataIndices, setDataIndices] = useState([]);
    const [dataSearch, setDataSearch] = useState([]);
    const [metas, setMetas] = useState(null);
    const [visor, setVisor] = useState(null); 
    const notify = useNotify();

    const [randomUpdate, setRandomUpdate] = useState(null);
    const [datanodosAbiertos, setDatanodosAbiertos] = useState([]);
    const [openDeleteDocumento, setOpenDeleteDocumento] = useState(false);
    const [cargando,setCargando] = useState (false);
    const [allDocs,setAllDocs] = useState(null);
    const [nodoBuscado,setNodoBuscado] = useState(null);
    const [nodos,setNodos] = useState(null);
    const [openNodosEncontrados,setOpenNodosEncontrados] = useState (false)
    const [resultadoNodos,setResultadoNodos] = useState([])
    const [isCheck, setIsCheck] = useState([]);
    const [todosSeleccionados, setTodosSeleccionados] = useState(false);
    const inTour = JSON.parse(localStorage.getItem('activeTour'))
    const [allReady, setAllReady] = useState(1);
    const [openEspera, setOpenEspera] = useState(false);

    let introHelp;
    introHelp = introJs('#root');
    introHelp.setOptions({
        disableInteraction: false,
        doneLabel: translate('traducciones.introBotones.doneLabel'),
        nextLabel: translate('traducciones.introBotones.nextLabel'),
        prevLabel: translate('traducciones.introBotones.prevLabel'),
        tooltipPosition:'top',
        steps: [
                {
                intro: translate('traducciones.introHelp.intro1'),
                title: translate('traducciones.introHelp.title1'),
                element: '#help'
                },
        ]                
    });

    let introNodos = introJs();
    useEffect(() => {
        if(inTour.querys){
            setTimeout(()=>{
                introNodos = introJs('#search-box');
                introNodos.setOptions({
                    disableInteraction: true,
                    doneLabel: translate('traducciones.introBotones.doneLabel'),
                    nextLabel: translate('traducciones.introBotones.nextLabel'),
                    prevLabel: translate('traducciones.introBotones.prevLabel'),
                    tooltipPosition:'top',
                    steps: [
                        {
                            intro: translate('traducciones.introCampos.intro1'),
                            title: translate('traducciones.introCampos.title1')
                        },
                        {
                            intro: translate('traducciones.introCampos.intro2'),
                            title: translate('traducciones.introCampos.title2'),
                            element: '#nodo'
                        },
                        {
                            intro: translate('traducciones.introCampos.intro3'),
                            title: translate('traducciones.introCampos.title3'),
                            element: '#more'
                        },
                        {
                            intro: translate('traducciones.introCampos.intro4'),
                            title: translate('traducciones.introCampos.title4'),
                            element: '#btnSearch'
                        },
                        
                    ]                
                  });
        
                introNodos.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                    showHelp()
                }).onexit(()=>{
                    showHelp()
                }).start();
            },1000)
        }
      }, []);

    const showHelp = () =>{
        const offPermisos = {
            querys: false,
            carga: false,
            admin: false,
            estructura: false,
        }
        if(document.cookie == 'introjs-dontShowAgain=true'){
            document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
                setTimeout(()=>{
                    introHelp.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).onexit(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).start();                         
                },500)
        }
    }

    function crearCookie(nombre, valor, diasParaExpirar) {
    var fechaExpiracion = new Date();
    fechaExpiracion.setDate(fechaExpiracion.getDate() + diasParaExpirar);
    var cookie = nombre + '=' + valor + '; expires=' + fechaExpiracion.toUTCString() + '; path=/';
    document.cookie = cookie;
    }      
      
    const onToggleItem = (e) => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    }

    const handleSelectAll = () => {
        setTodosSeleccionados(!todosSeleccionados);
        if(dataSearch && dataSearch.data){
            let marcas = dataSearch.data.map(li => li.IdDocument + "");
            setIsCheck(marcas);
        }

        if (todosSeleccionados) {
          setIsCheck([]);
        }
    };

    const dataProvider = useDataProvider();

    //Paginación///////////////////////////////

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [order, setOrder] = useState("");
    const [sort, setSort] = useState(true);

    const changePage = (nPage) => {
        if(allReady != 2){
            setDataSearch([])
            setPage(nPage);
            reloadPages(nPage,perPage);
        }else{
            setOpenEspera(true)
        }
    }
    const ChangePerPage = (rows) => {
        // setPage(1);
        setPerPage(rows);   
        reloadPages(page,rows);
    }

    const cambiaOrden = (column) => {
        setOrder(column);
        reloadPages(null,null,!sort,column);
        setSort(!sort);
    }

    const reloadPages = (npage,nperpage,nsort,norder) => {
        const startIndex = (npage - 1) * nperpage;
        const endIndex = startIndex + nperpage;
        if(endIndex > allDocs.data.length){
            setDataSearch([])
            setCargando(true)
            dataProvider.getList('queryCampos', { 
                IdNode: nodoPadreElemento != null ? nodoPadreElemento.RutaNodo : "/", 
                PageNumber : 1,
                DocumentsNumber : endIndex + 100,
                OrderField : norder ? norder : order,	
                AscendingOrder : nsort != null ? nsort : sort,
                Metadata: metas,
            })
                .then((data) => {
                    if(data != null && data.data.length == 0){
                        setCargando(false)
                        setOpenNoResult(true);
                    }else{
                        if(data != null && data.data.length > 0){
                            const dataSlice = {
                                data: data.data.slice(startIndex, endIndex),
                                porcentaje: data.porcentaje,
                                total: data.total
                            }
                            setAllDocs(data)
                            setDataSearch(dataSlice);
                            setCargando(false)
                            setOpenResult(true);
                        }
                    }
                })
                .catch(error => {
            });   
        }else{
            const dataSlice = {
                data: allDocs.data.slice(startIndex, endIndex),
                porcentaje: allDocs.porcentaje,
                total: allDocs.total
            }
            setDataSearch(dataSlice)
        }
    }

    let introQueryByCampos = introJs();
    const viewNode = (nodoPadre) => {
        dataProvider.getList('indices', { IdNode: nodoPadre != null ? nodoPadre.RutaNodo : "/"})
            .then(({ data }) => {
                setPage(1);
                setnodoPadreElemento(nodoPadre);
                setDataIndices(data);
                if(data.length > 0){
                    if(inTour.querys){
                        setTimeout(()=>{
                            introQueryByCampos = introJs('#search-box')
                            introQueryByCampos.setOptions({
                                disableInteraction: false,
                                doneLabel: translate('traducciones.introBotones.doneLabel'),
                                nextLabel: translate('traducciones.introBotones.nextLabel'),
                                prevLabel: translate('traducciones.introBotones.prevLabel'),
                                tooltipPosition:'top',
                                steps: [
                                        {
                                            intro: translate('traducciones.introCampos.cintro1'),
                                            title: translate('traducciones.introCampos.ctitle1'),
                                            element: "#campos",
                                            scrollTo: "off",
                                            disableInteraction: true
                                        },
                                        {
                                            intro: translate('traducciones.introCampos.cintro2'),
                                            title: translate('traducciones.introCampos.ctitle2'),
                                            element: "#formu",
                                            scrollTo: "off"
                                        },
                                        {
                                            intro: translate('traducciones.introCampos.cintro3'),
                                            title: translate('traducciones.introCampos.ctitle3'),
                                            element: "#btnSave",
                                            position: 'right',
                                            scrollTo: 'off'
                                        }
                                ]                
                              });
                    
                            introQueryByCampos.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).onexit(()=>{showHelp()}).oncomplete(()=>{showHelp()}).start();
                        },800)
                    }
                }
            })
            .catch(error => {
        });   
    }

    const handleCloseClick = () => {
        setOpenNoResult(false);
        setOpenResult(false);
        setopenDelete(false);
        setOpenNodosEncontrados(false);
    }

    const handleCloseClickTable = () => {
        if(allReady != 2){
            setOpenNoResult(false);
            setOpenResult(false);
            setopenDelete(false);
            setOpenNodosEncontrados(false);
        }else{
            setOpenEspera(true)
        }
    }

    if(loadinga){
        return <Loading />
    }

    const handleSubmitDelete = async values => {
        setLoadinga(true);        
        deleteOne('indices', indiceSeleccionado.id, indiceSeleccionado,{
            onSuccess: ({ data }) => {
                setDataIndices(data.data);
                setopenDelete(false);
                setLoadinga(false);
                refresh();
            },
            onFailure: ({ error }) => {
                setLoadinga(false);
            }
        });
        setLoadinga(false);  
        
    };


    const handleSubmitSearch = async values => {
        introQueryByCampos.exit();
        var campos = Object.entries(values);
        var meta = [];
        campos.forEach(function (record, index) { 
            if(record[1] != undefined && record[1] != null && record[1] != ""){
                meta.push(
                    {
                        "IdDocumento": "",
                        "IdIndice": record[0].replace('campo-', ''),
                        "ValorLlave": typeof record[1] === 'string' ? record[1].trim() : record[1]
                    }
                );
            }
        });
        if(meta.length > 0){
            meta = meta.filter((elem)=> elem.ValorLlave != '');
        }

        setMetas(meta);
        if(meta.length > 0){
            setPage(1)
            setPerPage(10)
            setIsCheck([])
            setTodosSeleccionados(false)
            setAllDocs(null)
            setCargando(true)
            dataProvider.getList('queryCampos', { 
                IdNode: nodoPadreElemento != null ? nodoPadreElemento.RutaNodo : "/", 
                PageNumber : 1,
                DocumentsNumber : 100,
                OrderField : "",	
                AscendingOrder : true,
                Metadata: meta,
            })
                .then((data) => {
                    setCargando(false)
                    if(data != null && data.data.length == 0){
                        
                        setOpenNoResult(true);
                    }else{
                        if(data != null && data.data.length > 0){
                            const dataSlice = {
                                data: data.data.slice(0,10),
                                porcentaje: data.porcentaje,
                                total: data.total
                            }
                            setAllDocs(data)
                            setDataSearch(dataSlice);
                            setOpenResult(true);
                        }
                    }
                })
                .catch(error => {
                    setCargando(false)
            });   
        }else{
            swal({
                icon: 'info',
                text: 'Por favor, debe llenar los campos solicitados.'
            })
        }
    }

    const getAllDocuments = (total) => {
        setAllReady(2)
        dataProvider.getList('queryCampos', { 
            IdNode: nodoPadreElemento != null ? nodoPadreElemento.RutaNodo : "/", 
            PageNumber : 1,
            DocumentsNumber : total,
            OrderField : "",	
            AscendingOrder : true,
            Metadata: metas,
        })
            .then((data) => {
                    if(data != null && data.data.length > 0){
                        setAllDocs(data)
                        setAllReady(3)
                        setOpenEspera(false)
                    }
            })
            .catch(error => {
        });
    }

    const resetAllReady = () =>{
        setAllReady(1)
    }

    const consultaDocumento = (elem) => {
         //Obtenemos la url completa y sustituimos el pathName por el pathname del visor con el parametro del id del documento
         const urlFull = window.location.href;
         const pathName = props.location.pathname;
         let urlHost = urlFull.replace(pathName,`/visor?id_document=${elem.IdDocument}`)
         
         /*
         La funcion consulta documento se cambio  por esta linea para abrir una nueva ventana  y mostrar el visor, se obtine el nombre del host y se pasa 
         el id del documento como parametro en la URL para su posterior consulta.
          */
         window.open(urlHost, '_blank', 'fullscreen=yes');
    } 

    /*
    Esta funcion sera la encargada de obtener las anotaciones desde el visor y guardarlas mediante las apis de MAxImage
    */
    const saveAnotations = ()=>{
        /*
        Ejecutaremos la funcion getAnnotations(), funcion desarrollada para obtener las antoaciones del visor en 
        formato XML, para poder fuardarlas en la api. Esta funcion se encuentra en public/Main/Main.js en la linea 2266
        */
        var anotations = visor.getAnnotations();
        if(anotations.length > 0){
            dataProvider.update('anotaciones', { DocumentId: documentt.IdDocument,Annotations:anotations})
                .then(({ data }) => {
                    swal({
                        icon:'success',
                        text: translate('traducciones.notificaciones.actualizaAnotacionesOK')
                    })
                })
                .catch(error => {
            });   
        }
    }

    const closeExternal = () =>{
        setOpenResult(true);
        setopenDocument(false);
    }

    const colapsar = (nodo) => {
        setRandomUpdate(Math.random());
        if(nodo.colapsado){
            datanodosAbiertos.push(nodo.RutaNodo)
            setDatanodosAbiertos(datanodosAbiertos);
        }else{
            setDatanodosAbiertos(datanodosAbiertos.filter(function(nodoguardado) { 
                return nodoguardado !== nodo.RutaNodo;
            }));
        }
        nodo.colapsado = !nodo.colapsado;
    }

    const checknodoabierto = (nodo) => {
        if(datanodosAbiertos.includes(nodo.RutaNodo)){
            nodo.colapsado = false;
        }
    }


    const deleteDocument = (doc) =>{
        setDocument(doc);
        setOpenDeleteDocumento(true);
    }


    const handleSubmitDeleteDocument = async values => {
        deleteOne('documento', documentt.IdDocument, documentt,{
            onSuccess: ({ data }) => {
                changePage(page);
                setOpenDeleteDocumento(false);
                setLoadinga(false);
            },
            onFailure: ({ error }) => {
                setLoadinga(false);
            }
        });        
    };

    const handleCloseClick2 = () => {
        setOpenDeleteDocumento(false);
    }

    var count = 0;

    const buscarNodo = (nodo,e) => {
        e.preventDefault();
        if(nodo==null || nodo.trim()==''){
            swal({
                icon: "info",
                text: translate('traducciones.modal.sinNombre'),
                timer: 1000,
                buttons:false
              });
        }else{
        let nodosEncontrados=[];
        nodos.map((nPadre) => {
          if (nPadre.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
            nodosEncontrados.push({
                'ruta':"/"+nPadre.TextoNodo,
                'n1':nPadre,
                'lvl':1
            })
          } else if (nPadre.hijos.length > 0) {
            nPadre.hijos.map((n2) => {
              if (n2.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                nodosEncontrados.push({
                    'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo,
                    'n1':nPadre,
                    'n2':n2,
                    'lvl':2
                })
              } else if (n2.hijos.length > 0) {
                n2.hijos.map((n3) => {
                  if (n3.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                    nodosEncontrados.push({
                        'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo,
                        'n1':nPadre,
                        'n2':n2,
                        'n3':n3,
                        'lvl':3
                    })
                  } else if (n3.hijos.length > 0) {
                    n3.hijos.map((n4) => {
                      if (n4.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                        nodosEncontrados.push({
                            'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo + "/" + n4.TextoNodo,
                            'n1':nPadre,
                            'n2':n2,
                            'n3':n3,
                            'n4':n4,
                            'lvl':4
                        })
                      } else if (n4.hijos.length > 0) {
                        n4.hijos.map((n5) => {
                          if (n5.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                            nodosEncontrados.push({
                                'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo + "/" + n4.TextoNodo + "/" + n5.TextoNodo,
                                'n1':nPadre,
                                'n2':n2,
                                'n3':n3,
                                'n4':n4,
                                'n5':n5,
                                'lvl':5
                            })
                          }else if(n5.hijos.length > 0){
                            n5.hijos.map(n6=>{
                                if (n6.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                                    nodosEncontrados.push({
                                        'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo + "/" + n4.TextoNodo + "/" + n5.TextoNodo + "/" + n6.TextoNodo,
                                        'n1':nPadre,
                                        'n2':n2,
                                        'n3':n3,
                                        'n4':n4,
                                        'n5':n5,
                                        'n6':n6,
                                        'lvl':6
                                    })
                                }
                            })
                          }
                        });
                      }else{
                        //Si no hay hijos en nivel 5
                      }
                    });
                  } else {
                    //Si no hay hijos en nivel 4
                  }
                });
              } else {
                //Si no hay hijos en nivel 3
              }
            });
          } else {
            //Si no hay hijos en nivel 2
          }
        });
        if(nodosEncontrados.length==1){
            desplegarNodos(nodosEncontrados[0])
        }else{
            setResultadoNodos(nodosEncontrados);
            setOpenNodosEncontrados(true)
        }
    }
    };

    const desplegarNodos = (nodo) =>{
        switch(nodo.lvl){
            case 1: 
                viewNode(nodo.n1)
                setOpenNodosEncontrados(false)
            break;
            case 2:
                colapsar(nodo.n1);
                viewNode(nodo.n2)
                setOpenNodosEncontrados(false) 
            break;
            case 3:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                viewNode(nodo.n3)
                setOpenNodosEncontrados(false) 
            break;
            case 4:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                colapsar(nodo.n3);
                viewNode(nodo.n4)
                setOpenNodosEncontrados(false) 
            break;
            case 5:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                colapsar(nodo.n3);
                colapsar(nodo.n4);
                viewNode(nodo.n5)
                setOpenNodosEncontrados(false)
            break;
            case 6:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                colapsar(nodo.n3);
                colapsar(nodo.n4);
                colapsar(nodo.n5);
                viewNode(nodo.n6)
                setOpenNodosEncontrados(false)
            break;
            default: ;
        }
    }

    let startIndex = (page - 1) * perPage;
    return (
        <Create {...props} title={ translate('traducciones.titulos.busquedaCampos')} >
            <SimpleForm {...props} toolbar={ null }  >

            {/* 
            External Viewer es el componente desarrollado para poder respresentar el html del visor de LeadTools en React.
            Este componente contiene el html codificado para react, obtenido desde el archivo Libs/ExternalViewer/index.html
            en este caso enviaremos al componente dos funciones locales:
            closeExternal, que cerrara el visor
            saveAnotations, que guardara las anotaciones cuando el usuario lo indique.
            */}
            
            { openDocument ? (     
            <ExternalViewer closeExternal = { closeExternal } saveAnotations= {saveAnotations} />
            ): access.CONSULTAPORCAMPOS ? (

            <div fullWidth style={{minHeight:'600px'}}>
                
                <div style={{ padding: '10px'}}>
                <div id='search-box'>
                        <div id='search-form' >
                        <input id='search-text' placeholder={translate('traducciones.campos.buscador')} type='text' onChange={(e) => setNodoBuscado(e.target.value)}/>
                        <button id='search-button' onClick={(e)=>buscarNodo(nodoBuscado,e)}><span><ManageSearchIcon style={{fontSize:'25px'}}/></span></button>
                        </div>
                    </div>       
                    <Query type="getList" resource="nodos" payload={ nodoPadreElemento }>
                        {({ data, loading, error }) => {
                            setNodos(data)
                            if (loading) { return <Loading />; }
                            if (error) { return <Error />; }
                            return (
                                <div  className="row">
                                    <div  className="col-md-5 col-md-custom" style={{ overflowX: "auto", padding: "10px"}}>
                                    {   data.map(
                                        (item) => {
                                            checknodoabierto(item);
                                            return (
                                            <div>
                                                <div id='nodo' className= { nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > 
                                                    <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                                    { item.TextoNodo } 
                                                    <Button id='btnSearch' title={ translate('traducciones.titulos.consultarCampos')}  onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                                        <SearchIcon style={{ fontSize: '16px'}}/>
                                                    </Button>
                                                    { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                        <ExpandLessIcon />
                                                    </Button>
                                                    }
                                                    { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                                    <Button id='more' style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                        <ExpandMoreIcon />
                                                    </Button>
                                                    }
                                                </div>
                                                { !item.colapsado &&
                                                <div style={{ marginLeft: '-20px'}}>
                                                    { dibujaNodosHijos(item,classes,viewNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                                                </div>
                                                }
                                            </div>
                                        )})
                                    }
                                    </div>
                                    {
                                        nodoPadreElemento != null ? (
                                    <div  className="col-md-7 col-md-custom" id='campos'>
                                        { dataIndices.length > 0 ? (
                                        <div>
                                            <div>
                                                {translate('traducciones.titulos.camposResultados')}
                                            </div>
                                            <SimpleForm id='formu' save={ handleSubmitSearch } toolbar= { <SaveButton id='btnSave' label={translate('traducciones.botones.consultar')} icon= { <SearchIcon /> } disabled={cargando}/> } >
                                                <div className="row" fullWidth>
                                                {   dataIndices.map(
                                                        (item) => (
                                                            <div className="col-md-6">
                                                                { item.Tipo == 1 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="text" name={ "campo-" + item.IdIndice + "" } resettable={ true }  label={ "Campo " + item.NombreIndice } source={item.IdIndice  }  />) : null }

                                                                { item.Tipo == 7 && item.ValoresTabla ? (
                                                                    <div>
                                                                        <SelectInput style={{ width: "100%" }} name={ "campo-" + item.IdIndice + "" } resettable={ true }  label={ "Campo " + item.NombreIndice } source={item.IdIndice }  choices={ item.ValoresTabla.map(value => ({id: value, name: value})) } />
                                                                    </div>
                                                                ) : null }

                                                                { item.Tipo == 2 ? (<NumberInput style={{ width: "100%" }} name={ "campo-" + item.IdIndice + "" } resettable={ true } label={ "Campo " + item.NombreIndice } source={item.IdIndice } value="" defaultValue="" />) : null }
                                                                { item.Tipo == 3 ? (<TextInput style={{ width: "100%" }} type="text" name={ "campo-" + item.IdIndice + "" } resettable={ true } label={ "Campo " + item.NombreIndice } source={item.IdIndice } value="" defaultValue="" />) : null }
                                                                { item.Tipo == 4 ? (<DateInput style={{ width: "100%" }} name={ "campo-" + item.IdIndice + "]" } resettable={ true } label={ "Campo " + item.NombreIndice } source={item.IdIndice } value="" defaultValue="" />) : null }
                                                                { item.Tipo == 5 ? (<TextInput style={{ width: "100%" }} type="time" name={ "campo-" + item.IdIndice + "" } resettable={ true } label={ "Campo " + item.NombreIndice } source={item.IdIndice } value="" defaultValue="" />) : null }
                                                                { item.Tipo == 6 ? (<TextInput style={{ width: "100%" }} type="email" name={ "campo-" + item.IdIndice + "" } resettable={ true } label={ "Campo " + item.NombreIndice } source={item.IdIndice } value="" defaultValue="" />) : null }
                                                            </div>
                                                        ))
                                                    }    
                                                </div>   
                                            </SimpleForm>  
                                        </div>                      
                                        ) : (
                                            <div style={{marginTop:'20%'}}><WarningIcon style={{ color: '#c18a2d', fontSize: '60px'}} /> { translate('traducciones.notificaciones.noHayCampos')}</div>
                                        )  
                                        }       
                                    </div>
                                    ) : null }
                                </div>
                            );
                        }}
                    </Query>
                </div> 
            </div>   
            
            ):(<NoAccess/>)}

            <Dialog
                fullWidth
                open={openNoResult}>
                <DialogTitle>{ translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                <DialogContent>
                    <WarningIcon style={{ color: '#c18a2d', fontSize: '60px'}} /> { translate('traducciones.modal.noResults')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClick} >
                        { translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog>   

            <Dialog
                    fullWidth
                    open={openDeleteDocumento}
                    >
                    <DialogTitle>{ translate('traducciones.modal.eliminarDocumento')}</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmitDeleteDocument}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            { translate('traducciones.modal.eliminarDocumentoConfirm')}
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.confirm"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                                icon = { <DeleteIcon /> }
                            />
                            <Button onClick={handleCloseClick2} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>  


            <Dialog
                fullWidth
                open={openResult}>
                <DialogTitle>{ translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                <DialogContent>
                    {cargando ? <MyLoading style={{marginTop:'-20px'}}/> : width != "xs" ? 
                    (
                      <TableResultado 
                      dataSearch= { dataSearch } 
                      consultaDocumento = { consultaDocumento } 
                      deleteDocument = { deleteDocument} 
                      cambiaOrden = { cambiaOrden } 
                      order = {order}
                      sort = { sort }
                      checkPermiso = { checkPermiso }
                      perPage= {perPage} 
                      setPage= {changePage} 
                      setPerPage = {ChangePerPage} 
                      page={page}
                      allDocuments={allDocs}
                      getAllDocuments={getAllDocuments}
                      allReady={allReady}
                      resetAllReady = {resetAllReady}
                  />  
                    ) : ( 
                        <div>
                        {
                        dataSearch && dataSearch.data && dataSearch.data.map(elem => {
                                count++;
                                startIndex++;
                                return (  
                                    <CardResultado dataSearch= {dataSearch} i = { startIndex } cambiaOrden= {cambiaOrden} order= {order} sort={sort} elem = {elem} onToggleItem={onToggleItem} todosSeleccionados={todosSeleccionados} isCheck={isCheck}/>
                                )                
                            })
                         }
                        <input type='checkbox'
                            style={{padding: "0px", marginBottom:'10px'}}
                            id='checkall'
                            name='checkall'
                                checked={todosSeleccionados}
                                onClick={handleSelectAll}
                        />
                        <span style={{marginLeft:'10px', color: 'rgb(92, 157, 102)'}}><b>{translate('traducciones.campos.checkMarcar')}</b></span>
                        <DownloadButton isCheck={isCheck} dataSearch={dataSearch} allDocuments={allDocs} todosSeleccionados={todosSeleccionados}/>
                        <Pagination perPage= {perPage} setPage= {changePage} setPerPage = {ChangePerPage} page={page} total= {dataSearch.total} />
                        </div>)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClickTable} >
                        { translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog>     

            <Dialog
                    fullWidth
                    open={openDelete}
                    >
                    <DialogTitle>{ translate('traducciones.modal.eliminarNodo')}</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmitDelete}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            { translate('traducciones.modal.eliminarNodoConfirm')}
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.confirm"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                                icon = { <DeleteIcon /> }
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>
                <Dialog fullWidth open={openNodosEncontrados}>
                    <DialogTitle>{ translate('traducciones.titulos.resultadoNodos')}</DialogTitle>
                        <FormWithRedirect
                            resource="catalogos"
                            save={buscarNodo}
                            render={({
                                handleSubmitWithRedirect,
                                pristine,
                                saving
                            }) => (
                                <>
                                <DialogContent>
                                <div  className="row">
                                    <div  className="col-md-12 col-md-custom"  style={{ overflowX: "auto", padding: "10px"}}>
                                        <span style={{fontSize:'15px'}} hidden={ resultadoNodos.length > 0 ? false : true }>{translate('traducciones.modal.seleccionNodo')}: </span><br/><br/>
                                    {resultadoNodos.length>0 ? resultadoNodos.map(item=>{
                                        return(
                                            <div>
                                               <center><input className='resultNodos' type='text' value={item.ruta} onClick={()=>desplegarNodos(item)} readOnly/></center>     
                                            </div>
                                        )
                                    }): 
                                        <div>
                                            <span style={{marginLeft:'42%',fontSize:'2vh'}}><b>{translate('traducciones.modal.noResults')}</b></span>    
                                        </div>
                                    }    
                                    </div>
                                </div>
                                </DialogContent>
                                <DialogActions>
                                    <SaveButton
                                        label="ra.action.add"
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        hidden
                                    />
                                    <Button onClick={handleCloseClick} >
                                        { translate('ra.action.cancel')}
                                    </Button>
                                </DialogActions>
                                </>
                            )}
                        />
                </Dialog>
                <Dialog open={openEspera}>
                    <DialogContent>
                        <div>
                        <Loader/>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setOpenEspera(false)}>
                            { translate('ra.action.close')}
                        </Button>
                    </DialogActions>
                </Dialog>          

            </SimpleForm>
        </Create>
)};

export default withWidth()(BusquedaCampos);
