import React from 'react';
import './Resources/Styles/DocumentViewerDemo.css';
import './Resources/Styles/Toolbars.css';
import './Resources/Styles/ImageViewerStyles.css';
import './Resources/Styles/ImageViewerThumbnailStyles.css';
import './Resources/Styles/Sidebar.css';
import './Resources/Styles/Table.css';
import './Resources/Styles/Dialogs.css';
import './Resources/Styles/Icons.css';
import './Resources/Styles/AutomationObjectsList.css';
import {
  useTranslate
} from 'react-admin';
import { useEffect } from 'react';
import introJs from 'intro.js';

/*

*/

const ExternalViewer = (props) => {

  const permisos = JSON.parse(localStorage.getItem("permisos"));
  const inTour = JSON.parse(localStorage.getItem('activeTour'))

  useEffect(()=>{
    setTimeout(()=>{
      if(inTour.querys){
        introJs('#headerToolbarContainer').setOptions({
          disableInteraction: false,
          doneLabel: translate('traducciones.introBotones.doneLabel'),
          nextLabel: translate('traducciones.introBotones.nextLabel'),
          prevLabel: translate('traducciones.introBotones.prevLabel'),
            steps: [
              {
                title: translate('traducciones.introVisor.title1'),
                intro: translate('traducciones.introVisor.intro1'),
              },
              {
                title: translate('traducciones.introVisor.title2'),
                intro: translate('traducciones.introVisor.intro2'),
                element: '.imageViewerContainer',
                position: 'right'
              },
              {
                title: translate('traducciones.introVisor.title3'),
                intro: translate('traducciones.introVisor.intro3'),
                element: '#headerToolbarContainer',
                position: 'right'
              },
              {
                title: translate('traducciones.introVisor.title9'),
                intro: translate('traducciones.introVisor.intro9'),
                element: '.btnClose',
                position: 'right'
              },
              {
                title: translate('traducciones.introVisor.title4'),
                intro: translate('traducciones.introVisor.intro4'),
                element: '#fileMenuItem',
                position: 'right'
              },
              {
                title: translate('traducciones.introVisor.title5'),
                intro: translate('traducciones.introVisor.intro5'),
                element: '.btnAnotaciones',
                position: 'right'
              },
              {
                title: translate('traducciones.introVisor.title6'),
                intro: translate('traducciones.introVisor.intro6'),
                element: '.btnVer',
                position: 'right'
              },
              {
                title: translate('traducciones.introVisor.title7'),
                intro: translate('traducciones.introVisor.intro7'),
                element: '.btnPagina',
                position: 'right'
              },
              {
                title: translate('traducciones.introVisor.title8'),
                intro: translate('traducciones.introVisor.intro8'),
                element: '.btnInteraccion',
                position: 'right'
              },
              {
                title: translate('traducciones.introVisor.title10'),
                intro: translate('traducciones.introVisor.intro10'),
                element: '#thumbnailsControl',
                position: 'right'
              },
              {
                title: translate('traducciones.introVisor.title11'),
                intro: translate('traducciones.introVisor.intro11'),
                element: '#annotationObjectBtnsContainer',
                position: 'right'
              }
          ]
          }).setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).start();
      }
    },1200)
  },[])

  const translate = useTranslate();

        return (
            <div className='maximageVisor' style={{position : 'absolute', left : '0', top: '0px', width: '100%', height: '100%', marginTop: '0px', backgroundColor: 'white'}}>
              <meta charSet="utf-8" />
              <link rel="shortcut icon" type="image/x-icon" href="Libs/ExternalViewer/Resources/Images/favicon.ico" />
              <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
              <meta httpEquiv="X-UA-Compatible" content="IE=Edge,chrome=IE8" />
              <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0" />
              <meta name="apple-mobile-web-app-capable" content="yes" />
              <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
              <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" />   
              <div id="headerToolbarContainer" className="toolbar headerToolbarDiv hide-for-print">
                <div className="headerToolbarTabs">
                  <ul className="nav">
                    <li className="dropdown">
                      <a href="#" id="fileMenuItem" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false"> {translate("traducciones.visor.archivo")}</a>
                      <ul className="dropdown-menu" role="menu">
                        { permisos && permisos.EXPORTAR ? ( 
                        <li>
                          <button id="saveDocument" className="menuItem" title={ translate("traducciones.visor.exportar")}>
                            <span className="icon icon-sm saveIcon" />
                            <span className="text">{translate("traducciones.visor.exportar")}...</span>
                          </button>
                        </li>
                        ) : null }                        
                        <li className="divider fileMenuDivider" />
                        { permisos && permisos.IMPRIMIR ? ( 
                        <li>
                          <button id="print" className="menuItem lt-hide-video"  title={translate("traducciones.visor.imprimir")}>
                            <span className="icon icon-sm print" />
                            <span className="text">{translate("traducciones.visor.imprimir")}...</span>
                          </button>
                        </li>
                        ) : null }   
                        { permisos && permisos.IMPRIMIR ? (  
                        <li>
                          <button id="printPDF" className="menuItem lt-hide-video" title={translate("traducciones.visor.imprimirPdf")}>
                            <span className="icon icon-sm print" />
                            <span className="text">{translate("traducciones.visor.imprimirPdf")}</span>
                          </button>
                        </li>
                        ) : null }  
                        <li className="divider fileMenuDivider" />
                        <li>
                          <button className="menuItem  lt-hide-video" onClick={() =>  props.closeExternal()}>
                            <span className="icon icon-sm closeIcon" />
                            <span className="text">{translate("traducciones.visor.cerrarEditor")}</span>
                          </button>                 
                        </li>                        
                      </ul>
                    </li>
                    { permisos && permisos.ANOTACIONES ? ( 
                    <li>
                      <a href="#" id="fileMenuItem" className="dropdown-toggle btnAnotaciones" data-toggle="dropdown" role="button" aria-expanded="false"> {translate("traducciones.visor.anotaciones")}</a>
                      <ul className="dropdown-menu" role="menu">
                          <li>
                            <button className="menuItem" onClick={() =>  props.saveAnotations()}>
                              <span className="icon icon-sm saveIcon" />
                              <span className="text">{translate("traducciones.visor.saveAnotaciones")}</span>
                            </button>
                          </li>
                      </ul>
                    </li>
                    ) : null }  
                    {/* View Menu */}
                    <li className="dropdown lt-hide-video">
                      <a href="#" id="viewMenuItem" className="dropdown-toggle btnVer" data-toggle="dropdown" role="button" aria-expanded="false">{translate("traducciones.visor.ver")}</a>
                      <ul className="dropdown-menu" role="menu">
                        {/* Rotate Counter Clockwise */}
                        <li>
                          <button id="rotateCounterClockwise" className="menuItem">
                            <span className="icon icon-sm rotateCounterClockwiseIcon" />
                            <span className="text">{translate("traducciones.visor.rotarIzquierda")}</span>
                          </button>
                        </li>
                        {/* Rotate Clockwise */}
                        <li>
                          <button id="rotateClockwise" className="menuItem">
                            <span className="icon icon-sm rotateClockwiseIcon" />
                            <span className="text">{translate("traducciones.visor.rotarDerecha")}</span>
                          </button>
                        </li>
                        <li className="divider" />
                        {/* Zoom Out */}
                        <li>
                          <button id="zoomOut" className="menuItem">
                            <span className="icon icon-sm zoomOutIcon" />
                            <span className="text">{translate("traducciones.visor.alejar")}</span>
                          </button>
                        </li>
                        {/* Zoom In */}
                        <li>
                          <button id="zoomIn" className="menuItem">
                            <span className="icon icon-sm zoomInIcon" />
                            <span className="text">{translate("traducciones.visor.acercar")}</span>
                          </button>
                        </li>
                        <li className="divider" />
                        {/* Page size: actualSize, fit, or fit width */}
                        <li>
                          <button id="actualSize" className="menuItem">
                            <span className="icon icon-sm actualPageSizeIcon" />
                            <span className="text">{translate("traducciones.visor.tamañoActual")}</span>
                          </button>
                        </li>
                        <li>
                          <button id="fit" className="menuItem">
                            <span className="icon icon-sm page-FitIcon" />
                            <span className="text">{translate("traducciones.visor.ajustarApagina")}</span>
                          </button>
                        </li>
                        <li>
                          <button id="fitWidth" className="menuItem">
                            <span className="icon icon-sm fitWidthIcon" />
                            <span className="text">{translate("traducciones.visor.ajustarAancho")}</span>
                          </button>
                        </li>
                      </ul>
                    </li>
                    {/* Page Menu */}
                    <li className="dropdown lt-hide-video">
                      <a href="#" id="pageMenuItem" className="dropdown-toggle btnPagina" data-toggle="dropdown" role="button" aria-expanded="false">{translate("traducciones.visor.pagina")}</a>
                      <ul className="dropdown-menu" role="menu">
                        {/* First page */}
                        <li>
                          <button id="firstPage" className="menuItem">
                            <span className="icon icon-sm firstPageIcon" />
                            <span className="text">{translate("traducciones.visor.primerPagina")}</span>
                          </button>
                        </li>
                        {/* Previous page */}
                        <li>
                          <button id="previousPage" className="menuItem">
                            <span className="icon icon-sm previousIcon" />
                            <span className="text">{translate("traducciones.visor.paginaAnterior")}</span>
                          </button>
                        </li>
                        {/* Next page */}
                        <li>
                          <button id="nextPage" className="menuItem">
                            <span className="icon icon-sm nextIcon" />
                            <span className="text">{translate("traducciones.visor.proximaPagina")}</span>
                          </button>
                        </li>
                        {/* Last page */}
                        <li>
                          <button id="lastPage" className="menuItem">
                            <span className="icon icon-sm lastPageIcon" />
                            <span className="text">{translate("traducciones.visor.ultimaPagina")}</span>
                          </button>
                        </li>                      
                        <li className="divider" />
                        {/* Rotate pages */}
                        <li>
                          <button id="rotateCurrentPageClockwise" className="menuItem" title="Rotate the current page by 90 degrees clockwise">
                            <span className="icon icon-sm rotateClockwiseIcon" />
                            <span className="text">{translate("traducciones.visor.rotarDerecha")}</span>
                          </button>
                        </li>
                        <li>
                          <button id="rotateCurrentPageCounterClockwise" className="menuItem" title="Rotate the current page by 90 degrees counter-clockwise">
                            <span className="icon icon-sm rotateCounterClockwiseIcon" />
                            <span className="text">{translate("traducciones.visor.rotarIzquierda")}</span>
                          </button>
                        </li>
                        <li>
                          <button id="rotatePages" className="menuItem" title="Rotate multiple pages">
                            <span className="icon icon-sm multiPagesExportTextIcon" />
                            <span className="text">{translate("traducciones.visor.rotarPaginas")}</span>
                          </button>
                        </li>
                        <li className="divider" />
                        {/* Document pages display */}
                        <li>
                          <button id="singlePageDisplay" className="menuItem" title="Single page display">
                            <span className="icon icon-sm singleLayoutIcon" />
                            <span className="text">{translate("traducciones.visor.simple")}</span>
                          </button>
                        </li>
                        <li>
                          <button id="doublePagesDisplay" className="menuItem" title="Double page display">
                            <span className="icon icon-sm doubleLayoutIcon" />
                            <span className="text">{translate("traducciones.visor.doble")}</span>
                          </button>
                        </li>
                        <li>
                          <button id="verticalPagesDisplay" className="menuItem" title="Vertical page display">
                            <span className="icon icon-sm verticalLayoutIcon" />
                            <span className="text">{translate("traducciones.visor.vertical")}</span>
                          </button>
                        </li>
                        <li>
                          <button id="horizontalPagesDisplay" className="menuItem" title="Horizontal page display">
                            <span className="icon icon-sm horizontalLayoutIcon" />
                            <span className="text">{translate("traducciones.visor.horizontal")}</span>
                          </button>
                        </li>
                      </ul>
                    </li>
                    {/* Interactive Menu */}
                    <li className="dropdown lt-hide-video">
                      <a href="#" id="interactiveMenuItem" className="dropdown-toggle btnInteraccion" data-toggle="dropdown" role="button" aria-expanded="false">{translate("traducciones.visor.interaccion")}</a>
                      <ul className="dropdown-menu" role="menu">
                        <li>
                          <button id="panZoomMode" className="menuItem">
                            <span className="icon icon-sm panZoomIcon" />
                            <span className="text">Pan/Zoom</span>
                          </button>
                        </li>
                        <li>
                          <button id="panMode" className="menuItem">
                            <span className="icon icon-sm panIcon" />
                            <span className="text">Pan</span>
                          </button>
                        </li>
                        <li>
                          <button id="zoomMode" className="menuItem">
                            <span className="icon icon-sm zoomIcon" />
                            <span className="text">Zoom</span>
                          </button>
                        </li>
                        <li>
                          <button id="zoomToMode" className="menuItem">
                            <span className="icon icon-sm zoomToIcon" />
                            <span className="text">Zoom to</span>
                          </button>
                        </li>
                        <li>
                          <button id="magnifyGlassMode" className="menuItem">
                            <span className="icon icon-sm magnifyGlassIcon" />
                            <span className="text">Magnify glass</span>
                          </button>
                        </li>
                      </ul>
                    </li>
                    <li>
                          <button className="menuItem  lt-hide-video btnClose" onClick={() =>  props.closeExternal()}>
                            <span className="icon icon-sm closeIcon" />
                            <span className="text">{translate("traducciones.visor.cerrarEditor")}</span>
                          </button>                 
                        </li>
                  </ul>
                </div>
                <div className="shortcutsbar">
                  {/* Previous page */}
                  <button id="previousPage_shortcut" title={translate("traducciones.visor.irPaginaPrevia")} className="lt-hide-video">
                    <span className="icon icon-sm previousIcon" />
                  </button>
                  {/* Next page */}
                  <button id="nextPage_shortcut" title={translate("traducciones.visor.irPaginaNext")} className="lt-hide-video">
                    <span className="icon icon-sm nextIcon" />
                  </button>
                  <div className="inputGroupContainer lt-hide-video">
                    <div className="input-group">
                      <input id="pageNumber" type="text" className="form-control input-xs" title={translate("traducciones.visor.numPageActual")} style={{width: '50px', padding: 0, textAlign: 'center'}} />
                      <span id="pageCount" className="input-group-addon" />
                    </div>
                  </div>
                  <span className="verticalDivider lt-hide-video" />
                  {/* Zoom Out */}
                  <button id="zoomOut_shortcut" title="Zoom out" className="lt-hide-video">
                    <span className="icon icon-sm zoomOutIcon" />
                  </button>
                  <div className="inputGroupContainer lt-hide-video">
                    <div className="input-group">
                      <select id="zoomValues" className="form-control input-xs">
                        <option id="currentZoomValue" style={{display: 'none', visibility: 'hidden'}} disabled />
                        <option>10%</option>
                        <option>25%</option>
                        <option>50%</option>
                        <option>75%</option>
                        <option>100%</option>
                        <option>125%</option>
                        <option>200%</option>
                        <option>400%</option>
                        <option>800%</option>
                        <option>1600%</option>
                        <option>2400%</option>
                        <option>3200%</option>
                        <option>6400%</option>
                        <option>Actual Size</option>
                        <option>Fit Page</option>
                        <option>Fit Width</option>
                        <option>Fit Height</option>
                      </select>
                    </div>
                  </div>
                  {/* Zoom In */}
                  <button id="zoomIn_shortcut" title="Zoom in" className="lt-hide-video">
                    <span className="icon icon-sm zoomInIcon" />
                  </button>
                  <span className="verticalDivider lt-hide-video" />
                  {/* Page size: actualSize, fit, or fit width */}
                  <button id="actualSize_shortcut" title={translate("traducciones.visor.tamanioReal")} className="lt-hide-video">
                    <span className="icon icon-sm actualPageSizeIcon" />
                  </button>
                  <button id="fit_shortcut" title={translate("traducciones.visor.tamaniEncajar")} className="lt-hide-video">
                    <span className="icon icon-sm page-FitIcon" />
                  </button>
                  <button id="fitWidth_shortcut" title={translate("traducciones.visor.tamanioAjustarAncho")} className="lt-hide-video">
                    <span className="icon icon-sm fitWidthIcon" />
                  </button>
                  <span className="verticalDivider" />
                  {/* Document pages display */}
                  <button id="singlePageDisplay_shortcut" title={translate("traducciones.visor.singlePageDisp")} className="lt-hide-video">
                    <span className="icon icon-sm singleLayoutIcon" />
                  </button>
                  <button id="doublePagesDisplay_shortcut" title={translate("traducciones.visor.doublePageDisp")} className="lt-hide-video">
                    <span className="icon icon-sm doubleLayoutIcon" />
                  </button>
                  <button id="verticalPagesDisplay_shortcut" title={translate("traducciones.visor.verticalDisplay")} className="lt-hide-video">
                    <span className="icon icon-sm verticalLayoutIcon" />
                  </button>
                  <button id="horizontalPagesDisplay_shortcut" title={translate("traducciones.visor.horizontalDisplay")} className="lt-hide-video">
                    <span className="icon icon-sm horizontalLayoutIcon" />
                  </button>
                  <span className="verticalDivider lt-hide-video" />
                  {/* Interactive mode */}
                  <button id="panZoomMode_shortcut" title="Pan and zoom" className="lt-hide-video">
                    <span className="icon icon-sm panZoomIcon" />
                  </button>
                  <button id="panMode_shortcut" title="Pan" className="lt-hide-video">
                    <span className="icon icon-sm panIcon" />
                  </button>
                  <button id="zoomMode_shortcut" title="Zoom" className="lt-hide-video">
                    <span className="icon icon-sm zoomIcon" />
                  </button>
                  <button id="zoomToMode_shortcut" title="Zoom to" className="lt-hide-video">
                    <span className="icon icon-sm zoomToIcon" />
                  </button>
                  <button id="magnifyGlassMode_shortcut" title={translate("traducciones.visor.lupa")} className="lt-hide-video">
                    <span className="icon icon-sm magnifyGlassIcon" />
                  </button>
                </div>
              </div>
              <div id="navigationbar" className="navigationbar hide-for-print" style={{ top : '73px !important'}}>
                <button id="showThumbnails" className="navigationbarBtn">
                  <span className="icon thumbnailsIcon" />
                </button>
                <button id="showBookmarks" className="navigationbarBtn">
                  <span className="icon bookmarksIcon" />
                </button>
                <button id="showAttachments" className="navigationbarBtn">
                  <span className="icon attachmentsIcon" />
                </button>
                <button id="showAnnotationsListControls" className="navigationbarBtn annotations">
                  <span className="icon annotationsIcon" />
                </button>
              </div>
              <div id="thumbnailsControl" className="affectedContainers hide-for-print">
                <div className="controlHeader">
                  <label>Pages</label>
                </div>
                <div id="thumbnails">
                </div>
              </div>
              <div id="bookmarksControl" className="affectedContainers hide-for-print">
                <div className="controlHeader">
                  <label>Bookmarks</label>
                </div>
                <div id="bookmarks">
                  <ul id="bookmarksTree" className="tree" />
                </div>
              </div>
              <div id="attachmentsControl" className="affectedContainers hide-for-print">
                <div className="controlHeader">
                  <label>Attachments</label>
                </div>
                <div id="attachments">
                </div>
              </div>
              <div id="annotationsListControls" className="annotations hide-for-print">
                <div id="annotationslist" className="annotationsListContainer">
                </div>
              </div>
              <div id="annotationObjectBtnsContainer" className="annotations footerToolbar hide-for-print" style={{height: 'auto'}}>
                <button className="annotationObjectBtn" title="Select" value={-1}>
                  <span className="icon selectIcon" />
                </button>
                <button className="annotationObjectBtn" title="Line" value={-2}>
                  <span className="icon lineIcon" />
                </button>
                <button className="annotationObjectBtn" title="Rectangle" value={-3}>
                  <span className="icon rectangleIcon" />
                </button>
                <button className="annotationObjectBtn" title="Ellipse" value={-4}>
                  <span className="icon ellipseIcon" />
                </button>
                <button className="annotationObjectBtn" title="Polyline" value={-5}>
                  <span className="icon polylineIcon" />
                </button>
                <button className="annotationObjectBtn" title="Polygon" value={-6}>
                  <span className="icon polygonIcon" />
                </button>
                <button className="annotationObjectBtn" title="Curve" value={-7}>
                  <span className="icon curveIcon" />
                </button>
                <button className="annotationObjectBtn" title="Closed Curve" value={-8}>
                  <span className="icon closedCurveIcon" />
                </button>
                <button className="annotationObjectBtn" title="Pointer" value={-9}>
                  <span className="icon pointerIcon" />
                </button>
                <button className="annotationObjectBtn" title="Freehand" value={-10}>
                  <span className="icon freehandIcon" />
                </button>
                <button className="annotationObjectBtn" title="Hilite" value={-11}>
                  <span className="icon hiliteIcon" />
                </button>
                <button className="annotationObjectBtn" title="Text" value={-12}>
                  <span className="icon textIcon" />
                </button>
                <button className="annotationObjectBtn" title="Note" value={-15}>
                  <span className="icon noteIcon" />
                </button>
                <button className="annotationObjectBtn" title="Rubber Stamp" value={-17}>
                  <span className="icon rubberStampIcon" />
                </button>
                <button className="annotationObjectBtn" title="Redaction" value={-22}>
                  <span className="icon redactionIcon" />
                </button>
              </div>
              <div id="thumbnailsContextMenuParent" className="hide-for-print" tabIndex={-1}>
              </div>
              <div id="attachmentsContextMenuParent" className="hide-for-print" tabIndex={-1}>
              </div>
              <div id="viewContextMenuParent" className="hide-for-print" tabIndex={-1}>
              </div>
              {/* find text div */}
              <div id="findTextPanel" className="hide-for-print">
                <div className="inputGroupContainer">
                  <div className="btn-group">
                    {/* Find options button */}
                    <button className="btn btn-default dropdown-toggle" data-toggle="dropdown" title="Find Options">
                      <span className="icon icon-sm settingsIcon" />
                    </button>
                    {/* Menu Items */}
                    <ul className="dropdown-menu" role="menu">
                      <li>
                        <a href="#">
                          <label className="checkbox-inline" style={{width: '100%'}}>
                            <input id="matchCase" type="checkbox" />
                            <label htmlFor="matchCase">Match case</label>
                          </label>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <label className="checkbox-inline" style={{width: '100%'}}>
                            <input id="wholeWordsOnly" type="checkbox" />
                            <label htmlFor="wholeWordsOnly">Whole words only</label>
                          </label>
                        </a>
                      </li>
                      <li className="divider" />
                      <li>
                        <a href="#">
                          <label className="checkbox-inline" style={{width: '100%'}}>
                            <input id="findInCurrentPageOnly" type="checkbox" />
                            <label id="findInCurrentPageOnlyLabel" htmlFor="findInCurrentPageOnly">Restrict to current page only</label>
                          </label>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <label className="checkbox-inline" style={{width: '100%'}}>
                            <input id="getTextForFind" type="checkbox" defaultChecked />
                            <label htmlFor="getTextForFind">Get text for pages as needed</label>
                          </label>
                        </a>
                      </li>
                      <li className="divider" />
                      <li>
                        <a href="#">
                          <label className="checkbox-inline" style={{width: '100%'}}>
                            <input id="findAllMatches" type="checkbox" defaultChecked />
                            <label htmlFor="findAllMatches">Highlight all</label>
                          </label>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <label className="checkbox-inline" style={{width: '100%'}}>
                            <input id="findLoop" type="checkbox" defaultChecked />
                            <label htmlFor="findLoop">Loop back to start</label>
                          </label>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="input-group inputGroupClose">
                    {/* Text to find input */}
                    <input id="textToFind" type="text" className="form-control" placeholder="Find..." autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
                    <div className="input-group-btn">
                      {/* Live search */}
                      <button id="findLive" className="btn btn-default" title="Update results immediately while typing or changing options">
                        <span className="icon icon-sm rotateClockwiseIcon" />
                      </button>
                    </div>
                  </div>
                  <div className="btn-group">
                    {/* Find previous */}
                    <button id="findPrevious" className="btn btn-default" title="Find Previous">
                      <span className="icon icon-xs previous-xs-Icon" />
                    </button>
                    {/* Find next */}
                    <button id="findNext" className="btn btn-default" title="Find Next">
                      <span className="icon icon-xs next-xs-Icon" />
                    </button>
                  </div>
                  <div className="btn-group">
                    {/* Close find Panel */}
                    <button id="closeFindPanel" className="btn btn-default">Close</button>
                  </div>
                </div>
              </div>
              <div id="imageViewerContainer" className="affectedContainers hide-for-print">
                {/* The viewer will be dynamically created inside imageViewerDiv */}
                <div id="imageViewerDiv">
                </div>
                <div id="tooltip" className="link-tooltip hide-for-print" tabIndex={-1}>
                  <div id="tooltip_Title" className="link-tooltip-title">N/A</div>
                  <div id="tooltip_Tip" className="link-tooltip-tip"><code>CTRL</code> <strong>+</strong> <code>click</code> to follow link</div>
                </div>
              </div>
              <div data-comment="dialogs">
                {/* About dialog */}
                <div id="dlgAbout" className="dlg-container container">
                  <div className="dlg-box">
                    <header>
                      <h3 id="dlgAbout_Title">Leadtools Document Viewer Demo</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <div id="demoDescription" style={{display: 'none'}}>
                        <ul style={{listStyleType: 'square'}}>
                          <li>Upload and view any document, such as PDF, DOCX or TIFF</li>
                          <li>Convert and download to any supported document</li>
                          <li>View pages as SVG or raster image</li>
                          <li>Annotations support</li>
                          <li>Parse and select text using SVG and OCR technologies</li>
                          <li>Interactive modes including pan zoom, magnify glass, auto-pan and more</li>
                          <li>Multi-touch and gesture support for mobile devices and tablets</li>
                        </ul>
                      </div>
                      <div className="inline-center">
                        <div>Copyright © 1991-2021 All Rights Reserved.</div>
                        <div>LEAD Technologies, Inc.</div>
                        <img src="Resources/Images/LEAD.png" alt="LEADTOOLS" />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button className="btn btn-default dlg-close">Close</button>
                    </div>
                  </div>
                </div>
                {/* Text results dialog */}
                <div id="dlgTextResults" className="dlg-container container">
                  <div className="dlg-box dlg-desktop-l">
                    <header>
                      <h4 id="dlgTextResults_Title" />
                    </header>
                    <div className="dlg-body modal-body">
                      <textarea id="dlgTextResults_Results" readOnly="readonly" rows={7} defaultValue={""} />
                    </div>
                    <div className="modal-footer">
                      <button className="btn btn-default dlg-close">Close</button>
                    </div>
                  </div>
                </div>
                {/* Upload document dialog */}
                <div id="dlgUploadDoc" className="dlg-container container">
                  <div className="dlg-box">
                    <header>
                      <h3>Open Local File</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <div>
                        <h4 className="header-underline">Document</h4>
                        <div className="top-push">
                          <input id="dlgUploadDoc_DocumentFile" type="file" className="full-width" />
                        </div>
                        <div className="top-push">
                          <button id="dlgUploadDoc_PageRange" className="btn btn-default">Set Page Range</button>
                          <label className="offset-right-slight button-align" id="dlgUploadDoc_PageRangeDescription" />
                        </div>
                        <div className="top-push">
                          <label htmlFor="dlgUploadDoc_LoadAttachmentsMode">Load attachments</label>
                          <select id="dlgUploadDoc_LoadAttachmentsMode" className="form-control">
                            <option>None</option>
                            <option selected>As attachments</option>
                          </select>
                        </div>
                      </div>
                      <div className="top-push annotations">
                        <h4 className="header-underline">Annotations</h4>
                        <div className="form">
                          <div className="bottom-push">
                            <div className="radio">
                              <label><input type="radio" name="dlgUploadDoc_AnnotationsLoadOptions" defaultValue={0} defaultChecked />Do not load any annotations</label>
                            </div>
                            <div className="offset-right help-block">
                              <p>Annotations will not be loaded.</p>
                            </div>
                          </div>
                          <div className="bottom-push">
                            <div className="radio">
                              <label><input type="radio" name="dlgUploadDoc_AnnotationsLoadOptions" defaultValue={1} />Render annotations</label>
                            </div>
                            <div className="offset-right help-block">
                              <p>Render the annotations on each page. This is currently valid only for PDF embedded annotations.</p>
                            </div>
                          </div>
                          <div className="bottom-push">
                            <div className="radio">
                              <label><input type="radio" name="dlgUploadDoc_AnnotationsLoadOptions" defaultValue={2} />Load the annotations embedded in the document</label>
                            </div>
                            <div className="offset-right help-block">
                              <p>Some document formats such as PDF and TIFF can have embedded annotations inside the file itself. Select this option to load these annotations if they exist.</p>
                            </div>
                          </div>
                          <div>
                            <div className="radio">
                              <label><input type="radio" name="dlgUploadDoc_AnnotationsLoadOptions" defaultValue={3} />Load the annotations from a local file</label>
                            </div>
                            <div className="offset-right help-block">
                              <p>Select this option if the annotations are stored in a local file, such as LEADTOOLS annotations stored in an XML file.</p>
                              <div>
                                <input id="dlgUploadDoc_AnnotationsFile" className="input-xs" type="file" style={{width: '100%'}} disabled />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button id="dlgUploadDoc_Upload" className="btn btn-default">Upload</button>
                      <button className="btn btn-default dlg-close">Close</button>
                    </div>
                  </div>
                </div>
                {/* Open from url dialog */}
                <div id="dlgOpenUrl" className="dlg-container container">
                  <div className="dlg-box">
                    <header>
                      <h3>Open From URL</h3>
                    </header>
                    <div className="dlg-body modal-body openFromUrl">
                      <div className="form">
                        <div className="bottom-push">
                          <div className="radio">
                            <label><input type="radio" name="dlgOpenUrl_UrlOptions" defaultValue={0} defaultChecked />Select a sample document</label>
                          </div>
                          <div className="offset-right">
                            <select id="dlgOpenUrl_FileSelect" className="form-control">
                              {/* Filled dynamically */}
                            </select>
                          </div>
                        </div>
                        <div className="bottom-push">
                          <div className="radio">
                            <label><input type="radio" name="dlgOpenUrl_UrlOptions" defaultValue={1} />Enter a URL</label>
                          </div>
                          <div className="offset-right">
                            <div className="bottom-push">
                              <input id="dlgOpenUrl_FileUrl" type="text" className="form-control" defaultValue="https://sede.aemps.gob.es/docs/CIMA-REST-API_1_19.pdf" disabled />
                            </div>
                            <div className="top-push">
                              <button id="dlgOpenUrl_PageRange" className="btn btn-default">Set Page Range</button>
                              <label className="offset-right-slight button-align" id="dlgOpenUrl_PageRangeDescription" />
                            </div>
                            <div className="top-push">
                              <label htmlFor="dlgOpenUrl_LoadAttachmentsMode">Load attachments</label>
                              <select id="dlgOpenUrl_LoadAttachmentsMode" className="form-control">
                                <option>None</option>
                                <option selected>As attachments</option>
                              </select>
                            </div>
                            <div className="bottom-push annotations">
                              <h4 className="header-underline">Annotations</h4>
                              <div className="bottom-push">
                                <div className="radio">
                                  <label><input type="radio" name="dlgOpenUrl_AnnotationsLoadOptions" defaultValue={0} defaultChecked />Do not load any annotations</label>
                                </div>
                                <div className="offset-right">
                                  <p className="help-block">Annotations will not be loaded.</p>
                                </div>
                              </div>
                              <div className="bottom-push">
                                <div className="radio">
                                  <label><input type="radio" name="dlgOpenUrl_AnnotationsLoadOptions" defaultValue={1} />Render annotations</label>
                                </div>
                                <div className="offset-right">
                                  <p className="help-block">Render the annotations on each page. This is currently valid only for PDF embedded annotations.</p>
                                </div>
                              </div>
                              <div className="bottom-push">
                                <div className="radio">
                                  <label><input type="radio" name="dlgOpenUrl_AnnotationsLoadOptions" defaultValue={2} />Load the annotations embedded in the document</label>
                                </div>
                                <div className="offset-right">
                                  <p className="help-block">Some document formats such as PDF and TIFF can have embedded annotations inside the file itself. Select this option to load these annotations if they exist.</p>
                                </div>
                              </div>
                              <div className="bottom-push">
                                <div className="radio">
                                  <label><input type="radio" name="dlgOpenUrl_AnnotationsLoadOptions" defaultValue={3} />Load the annotations from an external address</label>
                                </div>
                                <div className="offset-right">
                                  <p className="help-block">Select this option if the annotations are stored in an external address, such as LEADTOOLS annotations stored in an XML file.</p>
                                  <label htmlFor="dlgOpenUrl_AnnotationsUrl">Annotations URL:</label>
                                  <input id="dlgOpenUrl_AnnotationsUrl" type="text" className="form-control" disabled />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button id="dlgOpenUrl_Load" className="btn btn-default">Load</button>
                      <button className="btn btn-default dlg-close">Close</button>
                    </div>
                  </div>
                </div>
                {/* Print dialog */}
                <div id="dlgPrint" className="dlg-container container">
                  <div className="dlg-box">
                    <header>
                      <h3>Print Document</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <div>
                        <h4 className="header-underline bottom-push">Pages</h4>
                        <p id="dlgPrint_MessageContainer" />
                        <div className="form">
                          <div className="radio">
                            <label><input id="dlgPrint_PageOption_0" type="radio" name="dlgPrint_PageOption" defaultValue={0} defaultChecked />Print All</label>
                          </div>
                          <div className="radio">
                            <label><input type="radio" name="dlgPrint_PageOption" defaultValue={1} />Print Current<span id="dlgPrint_CurrentPageLabel" /></label>
                          </div>
                          <div className="radio">
                            <div>
                              <label style={{width: '100%'}}>
                                <input type="radio" name="dlgPrint_PageOption" defaultValue={2} />
                                <input id="dlgPrint_Pages" type="text" placeholder="e.g. 1-3, 5" className="form-control input-xs" />
                              </label>
                            </div>
                          </div>
                          <div className="radio">
                            <label><input type="radio" name="dlgPrint_PageOption" defaultValue={3} />Print Visible Area<span id="dlgPrint_VisiblePagesLabel" /></label>
                          </div>
                        </div>
                      </div>
                      <div className="top-push">
                        <h4 className="header-underline">Sizing</h4>
                        <p className="help-block">
                          The size of first page is<span id="dlgPrint_DocumentSize" />.
                          Pages are scaled to fit and centered within the container.
                        </p>
                        <div className="input-group">
                          <span className="input-group-addon">Page Size:</span>
                          <select id="dlgPrint_PageSize" className="form-control" />
                        </div>
                      </div>
                      <div className="top-push">
                        <h4 className="header-underline">Orientation</h4>
                        <div className="input-group">
                          <span className="input-group-addon">Container Orientation:</span>
                          <select id="dlgPrint_Orientation" className="form-control">
                            <option selected="selected" value={0}>Portrait</option>
                            <option value={1}>Landscape</option>
                          </select>
                        </div>
                        <p className="help-block">
                          The browser's print dialog will need to have an orientation set to match the above value.
                        </p>
                        <div className="top-push">
                          <input id="dlgPrint_Orientation_AutoRotate" type="checkbox" defaultChecked />
                          <label htmlFor="dlgPrint_Orientation_AutoRotate">Rotate page for best fit within container</label>
                        </div>
                      </div>
                      <div className="top-push">
                        <h4 className="header-underline">Additional Options</h4>
                        <div className="input-group top-push">
                          <span className="input-group-addon">DPI:</span>
                          <input id="dlgPrint_PageDpi" type="number" className="form-control" />
                        </div>
                        <div id="dlgPrint_ShowAnnotationsContainer" className="top-push">
                          <input id="dlgPrint_ShowAnnotations" type="checkbox" defaultChecked />
                          <label htmlFor="dlgPrint_ShowAnnotations">Show Annotations</label>
                        </div>
                        <div id="dlgPrint_RemoveMarginsContainer" className="top-push">
                          <input id="dlgPrint_RemoveMargins" type="checkbox" defaultChecked />
                          <label htmlFor="dlgPrint_RemoveMargins">Remove Margins</label>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button id="dlgPrint_Print" className="btn btn-default">Print</button>
                      <button className="btn btn-default dlg-close">Close</button>
                    </div>
                  </div>
                </div>
                {/* Open from cloud storage dialog */}
                <div id="dlgOpenCloud" className="dlg-container container">
                  <div className="dlg-box">
                    <header>
                      <h3>Open From Cloud Storage</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <p id="dlgOpenCloud_InfoText" className="text-center alert alert-warning" />
                      <div className="top-push">
                        <h4 className="header-underline">Document</h4>
                        <div className="top-push">
                          <div className="btn-group btn-group-justified">
                            <div className="btn-group">
                              <button id="dlgOpenCloud_Document_OneDrive" type="button" className="btn btn-default">OneDrive</button>
                            </div>
                            <div className="btn-group">
                              <button id="dlgOpenCloud_Document_SharePoint" type="button" className="btn btn-default">SharePoint</button>
                            </div>
                            <div className="btn-group">
                              <button id="dlgOpenCloud_Document_GoogleDrive" type="button" className="btn btn-default">Google Drive</button>
                            </div>
                          </div>
                          <p id="dlgOpenCloud_Document_File" className="top-push inline-center" />
                        </div>
                        <div className="top-push">
                          <button id="dlgOpenCloud_Document_PageRange" className="btn btn-default">Set Page Range</button>
                          <label className="offset-right-slight button-align" id="dlgOpenCloud_Document_PageRangeDescription" />
                        </div>
                        <div className="top-push">
                          <label htmlFor="dlgOpenCloud_LoadAttachmentsMode">Load attachments</label>
                          <select id="dlgOpenCloud_LoadAttachmentsMode" className="form-control">
                            <option>None</option>
                            <option selected>As attachments</option>
                          </select>
                        </div>
                      </div>
                      <div className="top-push annotations">
                        <h4 className="header-underline">Annotations</h4>
                        <div className="form">
                          <div className="bottom-push">
                            <div className="radio">
                              <label><input type="radio" name="dlgOpenCloud_Annotations_LoadOptions" defaultValue={0} defaultChecked />Do not load any annotations</label>
                            </div>
                            <div className="offset-right help-block">
                              <p>Annotations will not be loaded.</p>
                            </div>
                          </div>
                          <div className="bottom-push">
                            <div className="radio">
                              <label><input type="radio" name="dlgOpenCloud_Annotations_LoadOptions" defaultValue={1} />Render annotations</label>
                            </div>
                            <div className="offset-right help-block">
                              <p>Render the annotations on each page. This is currently valid only for PDF embedded annotations.</p>
                            </div>
                          </div>
                          <div className="bottom-push">
                            <div className="radio">
                              <label><input type="radio" name="dlgOpenCloud_Annotations_LoadOptions" defaultValue={2} />Load the annotations embedded in the document</label>
                            </div>
                            <div className="offset-right help-block">
                              <p>Some document formats such as PDF and TIFF can have embedded annotations inside the file itself. Select this option to load these annotations if they exist.</p>
                            </div>
                          </div>
                          <div>
                            <div className="radio">
                              <label><input type="radio" name="dlgOpenCloud_Annotations_LoadOptions" defaultValue={3} />Load the annotations from a local file</label>
                            </div>
                            <div className="offset-right help-block">
                              <p>Select this option if the annotations are stored in a local file, such as LEADTOOLS annotations stored in an XML file.</p>
                              <div className="btn-group btn-group-justified">
                                <div className="btn-group">
                                  <button id="dlgOpenCloud_Annotations_OneDrive" type="button" className="btn btn-default" disabled>OneDrive</button>
                                </div>
                                <div className="btn-group">
                                  <button id="dlgOpenCloud_Annotations_SharePoint" type="button" className="btn btn-default" disabled>SharePoint</button>
                                </div>
                                <div className="btn-group">
                                  <button id="dlgOpenCloud_Annotations_GoogleDrive" type="button" className="btn btn-default" disabled>Google Drive</button>
                                </div>
                              </div>
                              <p id="dlgOpenCloud_Annotations_File" className="top-push inline-center" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button id="dlgOpenCloud_Load" className="btn btn-default">Load</button>
                      <button className="btn btn-default dlg-close">Close</button>
                    </div>
                  </div>
                </div>
                {/*  Document converter dialog */}
                <div id="dlgConvert" className="dlg-container container">
                  <div className="dlg-box">
                    <header>
                      <h3>Export Document</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <ul className="nav nav-tabs">
                        <li className="active"><a id="dlgConvert_TabBtn_Document" href="#dlgConvert_DocumentTab" data-toggle="tab">Document</a></li>
                        <li><a id="dlgConvert_TabBtn_Options" href="#dlgConvert_OptionsTab" data-toggle="tab">Options</a></li>
                        <li className="dlg-tab-btn"><a id="dlgConvert_TabBtn_Quick" href="#dlgConvert_QuickBtn" data-toggle="tab">Quick Export</a></li>
                      </ul>
                      <div className="tab-content top-push">
                        <div className="tab-pane active" id="dlgConvert_DocumentTab">
                          <h4 className="header-underline">Pages</h4>
                          <div>
                            <p className="help-block top-push inline-center">
                              This demo application has a conversion limit of 10 pages.
                              This limit can be removed via the service configuration file.
                              Disabled pages are not included in conversion.
                            </p>
                            <div className="input-group top-push">
                              <span className="input-group-addon">From Page:</span>
                              <input id="dlgConvert_Input_Start" type="number" className="form-control" defaultValue={1} />
                              <span className="input-group-btn">
                                <button id="dlgConvert_Input_MakeFirst" className="btn btn-default" type="button">Reset</button>
                              </span>
                            </div>
                            <div className="input-group top-push">
                              <span className="input-group-addon">To Page:</span>
                              <input id="dlgConvert_Input_End" type="number" className="form-control" />
                              <span className="input-group-btn">
                                <button id="dlgConvert_Input_MakeLast" className="btn btn-default" type="button">Reset</button>
                              </span>
                            </div>
                          </div>
                          <div className="top-push">
                            <h4 className="header-underline">Format</h4>
                            <div className="top-push">
                              <div className="input-group bottom-push-inner">
                                <span className="input-group-addon">Type:</span>
                                <select id="dlgConvert_Format_TypeSelect" className="form-control">
                                  <option selected="selected" value="document">Document</option>
                                  <option value="raster">Raster</option>
                                </select>
                              </div>
                              <div id="dlgConvert_Format_DocumentContainer" className="input-group dlg-convert-mobile-sm">
                                <select id="dlgConvert_Format_DocumentSelect" className="form-control" />
                                <span className="input-group-btn">
                                  <button className="btn btn-default" id="dlgConvert_Format_DocumentOptions">Options...</button>
                                </span>
                              </div>
                              <div id="dlgConvert_Format_RasterContainer">
                                <select id="dlgConvert_Format_RasterSelect" className="form-control" />
                              </div>
                            </div>
                          </div>
                          <div className="top-push">
                            <h4 className="header-underline">Annotations</h4>
                            <div className="top-push">
                              <select id="dlgConvert_AnnotationsSelect" className="form-control">
                                <option value={0}>Do not export</option>
                                <option value={1}>Export to an external file</option>
                                <option value={2}>Overlay (Raster formats only)</option>
                                <option selected value={3}>Embed into output (PDF and TIF only)</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="dlgConvert_OptionsTab">
                          <div>
                            <h4 className="header-underline">General</h4>
                            <div className="top-push">
                              <div className="input-group">
                                <span className="input-group-addon">Job Name:</span>
                                <input id="dlgConvert_Options_JobName" type="text" className="form-control" />
                              </div>
                              <div className="input-group top-push">
                                <span className="input-group-addon">Empty Page Mode:</span>
                                <select id="dlgConvert_Options_EmptyPageMode" className="form-control">
                                  <option value={0}>None</option>
                                  <option value={1}>Skip</option>
                                  <option value={2}>Skip Ignore Annotations</option>
                                </select>
                              </div>
                              <div className="top-push">
                                <input id="dlgConvert_Options_JobErrorMode" type="checkbox" />
                                <label htmlFor="dlgConvert_Options_JobErrorMode">Continue on recoverable errors</label>
                              </div>
                              <div className="input-group dlg-convert-mobile-sm top-push">
                                <span className="input-group-addon">Numbering:</span>
                                <input id="dlgConvert_Options_PageNumberingTemplate" type="text" className="form-control" />
                              </div>
                            </div>
                          </div>
                          <div className="top-push">
                            <h4 className="header-underline">SVG</h4>
                            <div>
                              <div className="top-push">
                                <input id="dlgConvert_Options_UseSvgConversion" type="checkbox" />
                                <label htmlFor="dlgConvert_Options_UseSvgConversion">Use SVG conversion if available</label>
                              </div>
                              <div className="input-group top-push">
                                <span className="input-group-addon">SVG Images Recognition:</span>
                                <select id="dlgConvert_Options_SvgImagesRecognitionMode" className="form-control">
                                  <option value={0}>Auto</option>
                                  <option value={1}>Disabled</option>
                                  <option value={2}>Always</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="top-push">
                            <h4 className="header-underline">Preprocessing</h4>
                            <div>
                              <input id="dlgConvert_Options_PreprocessorDeskew" type="checkbox" />
                              <label htmlFor="dlgConvert_Options_PreprocessorDeskew">Deskew</label>
                            </div>
                            <div>
                              <input id="dlgConvert_Options_PreprocessorInvert" type="checkbox" />
                              <label htmlFor="dlgConvert_Options_PreprocessorInvert">Invert</label>
                            </div>
                            <div>
                              <input id="dlgConvert_Options_PreprocessorOrient" type="checkbox" />
                              <label htmlFor="dlgConvert_Options_PreprocessorOrient">Orient</label>
                            </div>
                          </div>
                          <div>
                            <hr />
                            <button id="dlgConvert_Options_RevertToDefaults" type="button" className="btn btn-default">Revert to Defaults</button>
                          </div>
                        </div>
                        <div className="tab-pane" id="dlgConvert_QuickBtn">
                          <h4 className="header-underline">Quick Export</h4>
                          <p>Download the cached document and/or cached annotations from the service without conversion. Page operations such as rotation and mark for deletion are ignored.</p>
                          <p>Cache identifier: <strong id="dlgConvert_Quick_Id" /></p>
                          <div className="top-push">
                            <div className="content-space">
                              <input id="dlgConvert_Quick_IncludeDocument" type="checkbox" defaultChecked />
                              <label htmlFor="dlgConvert_Quick_IncludeDocument">Include Document</label>
                            </div>
                            <div className="content-space">
                              <input id="dlgConvert_Quick_IncludeAnnotations" type="checkbox" defaultChecked />
                              <label htmlFor="dlgConvert_Quick_IncludeAnnotations">Include Annotations</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button id="dlgConvert_Convert" className="btn btn-default">Export</button>
                      <a id="dlgConvert_Quick_LinkBtn" href="javascript:void(0);" target="_blank" rel="noopener noreferrer" className="btn btn-default">Quick Export</a>
                      <button className="btn btn-default dlg-close">Close</button>
                    </div>
                  </div>
                </div>
                {/* Document formats options dialog */}
                <div id="dlgDocFormatOpts" className="dlg-container container">
                  <div className="dlg-box">
                    <header>
                      <h3>Document Format Options</h3>
                      <h4 id="dlgDocFormatOpts_Title" />
                    </header>
                    <div className="dlg-body modal-body">
                      <div id="dlgDocFormatOpts_PDF" className="dlg-format-opts-container">
                        <div className="input-group">
                          <span className="input-group-addon">PDF Type:</span>
                          <select id="dlgDocFormatOpts_PDF_DocumentSelect" className="form-control">
                            <option value={0}>Pdf</option>
                            <option value={1}>PdfA</option>
                            <option value={2}>Pdf12</option>
                            <option value={3}>Pdf13</option>
                            <option value={4}>Pdf15</option>
                            <option value={5}>Pdf16</option>
                          </select>
                        </div>
                        <div className="top-push">
                          <input id="dlgDocFormatOpts_PDF_ImageOverText" type="checkbox" />
                          <label htmlFor="dlgDocFormatOpts_PDF_ImageOverText">Image Over Text</label>
                        </div>
                        <div>
                          <input id="dlgDocFormatOpts_PDF_Linearized" type="checkbox" />
                          <label htmlFor="dlgDocFormatOpts_PDF_Linearized">Fast web view (Linearized)</label>
                        </div>
                        <div className="top-push">
                          <button id="dlgDocFormatOpts_PDF_AdvancedOptions" type="button" className="btn btn-default">Advanced...</button>
                        </div>
                      </div>
                      <div id="dlgDocFormatOpts_DOCX" className="dlg-format-opts-container">
                        <div>
                          <input id="dlgDocFormatOpts_DOCX_FrameText" type="checkbox" />
                          <label htmlFor="dlgDocFormatOpts_DOCX_FrameText">Frame Text</label>
                        </div>
                        <p className="help-block inline-center">
                          This format uses text flow. Using frames helps achieve maximum resemblance between the input document and generated document,
                          since the document writer can place objects using absolute (X and Y) position parameters.
                        </p>
                      </div>
                      <div id="dlgDocFormatOpts_RTF" className="dlg-format-opts-container">
                        <div>
                          <input id="dlgDocFormatOpts_RTF_FrameText" type="checkbox" />
                          <label htmlFor="dlgDocFormatOpts_RTF_FrameText">Frame Text</label>
                        </div>
                        <p className="help-block inline-center">
                          This format uses text flow. Using frames helps achieve maximum resemblance between the input document and generated document,
                          since the document writer can place objects using absolute (X and Y) position parameters.
                        </p>
                      </div>
                      <div id="dlgDocFormatOpts_TXT" className="dlg-format-opts-container">
                        <div className="input-group">
                          <span className="input-group-addon">Encoding:</span>
                          <select id="dlgDocFormatOpts_TXT_DocumentSelect" className="form-control">
                            <option value={0}>Ansi</option>
                            <option value={1}>Unicode</option>
                            <option value={2}>Unicode Big Endian</option>
                            <option value={3}>UTF8</option>
                          </select>
                        </div>
                        <div className="top-push">
                          <input id="dlgDocFormatOpts_TXT_AddPageNumber" type="checkbox" />
                          <label htmlFor="dlgDocFormatOpts_TXT_AddPageNumber">Add Page Number</label>
                        </div>
                        <div className="top-push">
                          <input id="dlgDocFormatOpts_TXT_AddPageBreak" type="checkbox" />
                          <label htmlFor="dlgDocFormatOpts_TXT_AddPageBreak">Add Page Break</label>
                        </div>
                        <div className="top-push">
                          <input id="dlgDocFormatOpts_TXT_Formatted" type="checkbox" />
                          <label htmlFor="dlgDocFormatOpts_TXT_Formatted">Formatted</label>
                        </div>
                      </div>
                      <div id="dlgDocFormatOpts_DOC" className="dlg-format-opts-container">
                        <div>
                          <input id="dlgDocFormatOpts_DOC_FrameText" type="checkbox" />
                          <label htmlFor="dlgDocFormatOpts_DOC_FrameText">Frame Text</label>
                        </div>
                        <p className="help-block inline-center">
                          This format uses text flow. Using frames helps achieve maximum resemblance between the input document and generated document,
                          since the document writer can place objects using absolute (X and Y) position parameters.
                        </p>
                      </div>
                      <div id="dlgDocFormatOpts_HTM" className="dlg-format-opts-container">
                        <div className="input-group">
                          <span className="input-group-addon">Embed Font Mode:</span>
                          <select id="dlgDocFormatOpts_HTM_EmbedFontMode" className="form-control">
                            <option value={0}>None</option>
                            <option value={1}>Auto</option>
                            <option value={2}>Force</option>
                            <option value={3}>All</option>
                          </select>
                        </div>
                        <div className="top-push">
                          <input id="dlgDocFormatOpts_HTM_UseBackgroundColor" type="checkbox" />
                          <label htmlFor="dlgDocFormatOpts_HTM_UseBackgroundColor">Use Background Color</label>
                        </div>
                        <div className="top-push">
                          <div id="dlgDocFormatOpts_HTM_BackgroundColor" className="colorContainer well" />
                        </div>
                      </div>
                      <div id="dlgDocFormatOpts_EMF" className="dlg-format-opts-container">
                        <p className="help-block inline-center">
                          The EMF format does not support multiple pages. When saving a multi-page document to EMF, the result is the last page only.
                        </p>
                      </div>
                      <div id="dlgDocFormatOpts_ALTO" className="dlg-format-opts-container">
                        <div className="input-group">
                          <span className="input-group-addon">Measurement unit:</span>
                          <select id="dlgDocFormatOpts_ALTO_MeasurementUnit" className="form-control">
                            <option value={0}>MM10</option>
                            <option value={1}>Inch1200</option>
                            <option value={2}>Dpi</option>
                            <option value={3}>Pixel</option>
                          </select>
                        </div>
                        <div className="top-push">
                          <div className="input-group">
                            <span className="input-group-addon">File name:</span>
                            <input id="dlgDocFormatOpts_ALTO_FileName" type="text" className="form-control" />
                          </div>
                          <div className="input-group top-push">
                            <span className="input-group-addon">Software creator:</span>
                            <input id="dlgDocFormatOpts_ALTO_SoftwareCreator" type="text" className="form-control" />
                          </div>
                          <div className="input-group top-push">
                            <span className="input-group-addon">Software name:</span>
                            <input id="dlgDocFormatOpts_ALTO_SoftwareName" type="text" className="form-control" />
                          </div>
                          <div className="input-group top-push">
                            <span className="input-group-addon">Application description:</span>
                            <input id="dlgDocFormatOpts_ALTO_ApplicationDescription" type="text" className="form-control" />
                          </div>
                          <div className="input-group top-push">
                            <input id="dlgDocFormatOpts_ALTO_Formatted" type="checkbox" />
                            <label htmlFor="dlgDocFormatOpts_ALTO_Formatted">Formatted</label>
                          </div>
                          <div className="input-group top-push">
                            <span className="input-group-addon">Indentation:</span>
                            <input id="dlgDocFormatOpts_ALTO_Indentation" type="text" className="form-control" />
                          </div>
                          <div className="input-group top-push">
                            <input id="dlgDocFormatOpts_ALTO_Sort" type="checkbox" />
                            <label htmlFor="dlgDocFormatOpts_ALTO_Sort">Sort</label>
                          </div>
                          <div className="input-group top-push">
                            <input id="dlgDocFormatOpts_ALTO_PlainText" type="checkbox" />
                            <label htmlFor="dlgDocFormatOpts_ALTO_PlainText">Plain text</label>
                          </div>
                          <div className="input-group top-push">
                            <input id="dlgDocFormatOpts_ALTO_ShowGlyphInfo" type="checkbox" />
                            <label htmlFor="dlgDocFormatOpts_ALTO_ShowGlyphInfo">Show glyph info</label>
                          </div>
                          <div className="input-group top-push">
                            <input id="dlgDocFormatOpts_ALTO_ShowGlyphVariants" type="checkbox" />
                            <label htmlFor="dlgDocFormatOpts_ALTO_ShowGlyphVariants">Show glyph variants</label>
                          </div>
                        </div>
                      </div>
                      <div id="dlgDocFormatOpts_NONE" className="dlg-format-opts-container">
                        <p className="help-block inline-center">
                          This format has no additional options.
                        </p>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button id="dlgDocFormatOpts_Apply" className="btn btn-default">Apply</button>
                      <button className="btn btn-default dlg-close">Close</button>
                    </div>
                  </div>
                </div>
                {/*  Advanced PDF options dialog */}
                <div id="dlgAdvancedPDF" className="dlg-container container">
                  <div className="dlg-box">
                    <header>
                      <h3>Advanced PDF Options</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <ul id="dlgAdvancedPDF_Tabs" className="nav nav-tabs">
                        <li className="active"><a href="#dlgAdvancedPDF_DescriptionTab" data-toggle="tab">Description</a></li>
                        <li><a href="#dlgAdvancedPDF_FontTab" data-toggle="tab">Font</a></li>
                        <li><a href="#dlgAdvancedPDF_SecurityTab" data-toggle="tab">Security</a></li>
                        <li><a href="#dlgAdvancedPDF_CompressionTab" data-toggle="tab">Compression</a></li>
                        <li><a href="#dlgAdvancedPDF_InitialViewTab" data-toggle="tab">Initial View</a></li>
                      </ul>
                      <div className="tab-content top-push">
                        <div className="tab-pane active" id="dlgAdvancedPDF_DescriptionTab">
                          <h4 className="header-underline">Description</h4>
                          <div className="top-push">
                            <div className="input-group">
                              <span className="input-group-addon">Title</span>
                              <input id="dlgAdvancedPDF_Desc_Title" type="text" className="form-control" />
                            </div>
                            <div className="input-group top-push">
                              <span className="input-group-addon">Subject</span>
                              <input id="dlgAdvancedPDF_Desc_Subject" type="text" className="form-control" />
                            </div>
                            <div className="input-group top-push">
                              <span className="input-group-addon">Author</span>
                              <input id="dlgAdvancedPDF_Desc_Author" type="text" className="form-control" />
                            </div>
                            <div className="input-group top-push">
                              <span className="input-group-addon">Keywords</span>
                              <input id="dlgAdvancedPDF_Desc_Keywords" type="text" className="form-control" />
                            </div>
                            <p className="help-block">
                              Keywords may be separated by a comma or a dot.
                            </p>
                          </div>
                        </div>
                        <div className="tab-pane" id="dlgAdvancedPDF_FontTab">
                          <h4 className="header-underline">Font</h4>
                          <div className="top-push">
                            <div className="input-group">
                              <span className="input-group-addon">Embedding</span>
                              <select id="dlgAdvancedPDF_Font_FontEmbedding" className="form-control">
                                <option value={0}>None</option>
                                <option value={1}>Auto</option>
                                <option value={2}>Force</option>
                                <option value={3}>All</option>
                              </select>
                            </div>
                            <div className="top-push">
                              <input id="dlgAdvancedPDF_Font_Linearized" type="checkbox" />
                              <label htmlFor="dlgAdvancedPDF_Font_Linearized">Fast Web View (Linearized)</label>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="dlgAdvancedPDF_SecurityTab">
                          <h4 className="header-underline">Security</h4>
                          <div className="top-push">
                            <div>
                              <input id="dlgAdvancedPDF_Security_ProtectedDocument" type="checkbox" className="dlg-advanced-pdf-security" />
                              <label htmlFor="dlgAdvancedPDF_Security_ProtectedDocument">Protect Document</label>
                            </div>
                            <div className="input-group top-push">
                              <span className="input-group-addon">Encryption Mode</span>
                              <select id="dlgAdvancedPDF_Security_EncryptionMode" className="form-control dlg-advanced-pdf-security">
                                <option value={0}>RC40Bit</option>
                                <option value={1}>RC128Bit</option>
                              </select>
                            </div>
                            <div className="input-group top-push">
                              <span className="input-group-addon">User Password</span>
                              <input id="dlgAdvancedPDF_Security_UserPassword" type="password" className="form-control dlg-advanced-pdf-security" />
                            </div>
                            <div className="input-group top-push">
                              <span className="input-group-addon">Owner Password</span>
                              <input id="dlgAdvancedPDF_Security_OwnerPassword" type="password" className="form-control dlg-advanced-pdf-security" />
                            </div>
                            <div className="top-push">
                              <div>
                                <input id="dlgAdvancedPDF_Security_CopyEnabled" type="checkbox" className="dlg-advanced-pdf-security dlg-advanced-pdf-security-permissions" />
                                <label htmlFor="dlgAdvancedPDF_Security_CopyEnabled">Enable copy from document and text extraction</label>
                              </div>
                              <div>
                                <input id="dlgAdvancedPDF_Security_EditEnabled" type="checkbox" className="dlg-advanced-pdf-security dlg-advanced-pdf-security-permissions" />
                                <label htmlFor="dlgAdvancedPDF_Security_EditEnabled">Enable document editing</label>
                              </div>
                              <div>
                                <input id="dlgAdvancedPDF_Security_AnnotationsEnabled" type="checkbox" className="dlg-advanced-pdf-security dlg-advanced-pdf-security-permissions" />
                                <label htmlFor="dlgAdvancedPDF_Security_AnnotationsEnabled">Enable comment and annotations</label>
                              </div>
                              <div>
                                <input id="dlgAdvancedPDF_Security_PrintEnabled" type="checkbox" className="dlg-advanced-pdf-security dlg-advanced-pdf-security-permissions" />
                                <label htmlFor="dlgAdvancedPDF_Security_PrintEnabled">Enable printing</label>
                              </div>
                              <div>
                                <input id="dlgAdvancedPDF_Security_AssemblyEnabled" type="checkbox" className="dlg-advanced-pdf-security dlg-advanced-pdf-security-permissions" />
                                <label htmlFor="dlgAdvancedPDF_Security_AssemblyEnabled">Enable document assembly</label>
                              </div>
                              <div>
                                <input id="dlgAdvancedPDF_Security_HighQualityPrintEnabled" type="checkbox" className="dlg-advanced-pdf-security dlg-advanced-pdf-security-permissions" />
                                <label htmlFor="dlgAdvancedPDF_Security_HighQualityPrintEnabled">Enable high quality (faithful) printing</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="dlgAdvancedPDF_CompressionTab">
                          <div>
                            <h4 className="header-underline">Compression</h4>
                            <div className="top-push">
                              <div className="input-group">
                                <span className="input-group-addon">One-Bit Image:</span>
                                <select id="dlgAdvancedPDF_Compress_OneBitImageCompression" className="form-control">
                                  <option value={0}>Flate</option>
                                  <option value={1}>FaxG31D</option>
                                  <option value={2}>faxG32D</option>
                                  <option value={3}>faxG4</option>
                                  <option value={4}>Lzw</option>
                                  <option value={5}>Jbig2</option>
                                </select>
                              </div>
                              <div className="input-group top-push">
                                <span className="input-group-addon">Colored Image:</span>
                                <select id="dlgAdvancedPDF_Compress_ColoredImageCompression" className="form-control">
                                  <option value={0}>FlateJpeg</option>
                                  <option value={1}>LzwJpeg</option>
                                  <option value={2}>Flate</option>
                                  <option value={3}>Lzw</option>
                                  <option value={4}>Jpeg</option>
                                  <option value={5}>FlateJpx</option>
                                  <option value={6}>LzwJpx</option>
                                  <option value={7}>Jpx</option>
                                </select>
                              </div>
                              <div className="input-group top-push">
                                <span className="input-group-addon">Quality Factor:</span>
                                <input id="dlgAdvancedPDF_Compress_QualityFactor" type="text" className="form-control" />
                              </div>
                            </div>
                          </div>
                          <div className="top-push">
                            <h4 className="header-underline">Image Over Text Compression</h4>
                            <div className="top-push">
                              <div className="input-group">
                                <span className="input-group-addon">Size:</span>
                                <select id="dlgAdvancedPDF_Compress_ImageOverTextSize" className="form-control">
                                  <option value={0}>Original</option>
                                  <option value={1}>Half</option>
                                  <option value={2}>Quarter</option>
                                </select>
                              </div>
                              <div className="input-group top-push">
                                <span className="input-group-addon">Mode:</span>
                                <select id="dlgAdvancedPDF_Compress_ImageOverTextMode" className="form-control">
                                  <option value={0}>None</option>
                                  <option value={1}>Strict</option>
                                  <option value={2}>Relaxed</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="dlgAdvancedPDF_InitialViewTab">
                          <div>
                            <h4 className="header-underline">Layout and Page Mode</h4>
                            <div className="top-push">
                              <div className="input-group">
                                <span className="input-group-addon">Page Mode:</span>
                                <select id="dlgAdvancedPDF_Init_DocumentPageModeType" className="form-control">
                                  <option value={0}>Page Only</option>
                                  <option value={1}>Bookmarks And Page</option>
                                  <option value={2}>Thumbnail And Page</option>
                                  <option value={3}>Full Screen</option>
                                  <option value={4}>Layer And Page</option>
                                  <option value={5}>Attachments And Page</option>
                                </select>
                              </div>
                              <div className="input-group top-push">
                                <span className="input-group-addon">Page Layout:</span>
                                <select id="dlgAdvancedPDF_Init_DocumentPageLayoutType" className="form-control">
                                  <option value={0}>Single Page Display</option>
                                  <option value={1}>One Column Display</option>
                                  <option value={2}>Two Column Left Display</option>
                                  <option value={3}>Two Column Right Display</option>
                                  <option value={4}>Two Page Left</option>
                                  <option value={5}>Two Page Right</option>
                                </select>
                              </div>
                              <div className="input-group top-push">
                                <span className="input-group-addon">Page Fit:</span>
                                <select id="dlgAdvancedPDF_Init_DocumentPageFitType" className="form-control">
                                  <option value={0}>Default</option>
                                  <option value={1}>Fit Width</option>
                                  <option value={2}>Fit Height</option>
                                  <option value={3}>Fit Width Bounds</option>
                                  <option value={4}>Fit Height Bounds</option>
                                  <option value={5}>Fit Bounds</option>
                                  <option value={25}>25 %</option>
                                  <option value={50}>50 %</option>
                                  <option value={75}>75 %</option>
                                  <option value={100}>100 %</option>
                                  <option value={125}>125 %</option>
                                  <option value={150}>150 %</option>
                                  <option value={200}>200 %</option>
                                  <option value={400}>400 %</option>
                                  <option value={800}>800 %</option>
                                  <option value={1600}>1600 %</option>
                                  <option value={2400}>2400 %</option>
                                  <option value={3200}>3200 %</option>
                                  <option value={6400}>6400 %</option>
                                </select>
                              </div>
                              <div className="input-group top-push">
                                <span className="input-group-addon">Open To Page:</span>
                                <input id="dlgAdvancedPDF_Init_InitialPageNumber" type="number" className="form-control" />
                                <span id="dlgAdvancedPDF_Init_TotalPages" className="input-group-addon" />
                              </div>
                            </div>
                          </div>
                          <div className="top-push">
                            <h4 className="header-underline">Window</h4>
                            <div className="top-push">
                              <div>
                                <input id="dlgAdvancedPDF_Init_FitWindow" type="checkbox" />
                                <label htmlFor="dlgAdvancedPDF_Init_FitWindow">Resize Window to Initial Page</label>
                              </div>
                              <div>
                                <input id="dlgAdvancedPDF_Init_CenterWindow" type="checkbox" />
                                <label htmlFor="dlgAdvancedPDF_Init_CenterWindow">Center Window on Screen</label>
                              </div>
                              <div className="input-group top-push">
                                <span className="input-group-addon">Show:</span>
                                <select id="dlgAdvancedPDF_Init_DisplayDocTitle" className="form-control">
                                  <option value={0}>File Name</option>
                                  <option value={1}>Document Title</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="top-push">
                            <h4 className="header-underline">User Interface</h4>
                            <div className="top-push">
                              <div>
                                <input id="dlgAdvancedPDF_Init_HideMenubar" type="checkbox" />
                                <label htmlFor="dlgAdvancedPDF_Init_HideMenubar">Hide Menu Bar</label>
                              </div>
                              <div>
                                <input id="dlgAdvancedPDF_Init_HideToolbar" type="checkbox" />
                                <label htmlFor="dlgAdvancedPDF_Init_HideToolbar">Hide Toolbar</label>
                              </div>
                              <div>
                                <input id="dlgAdvancedPDF_Init_HideWindowUI" type="checkbox" />
                                <label htmlFor="dlgAdvancedPDF_Init_HideWindowUI">Hide Window Controls</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button id="dlgAdvancedPDF_Apply" className="btn btn-default">Apply</button>
                      <button className="btn btn-default dlg-close">Close</button>
                    </div>
                  </div>
                </div>
                {/*  Save to dialog */}
                <div id="dlgExportSave" className="dlg-container container">
                  <div className="dlg-box">
                    <header>
                      <h3>Export Items</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <h4 id="dlgExportSave_Cache_Header" className="header-underline">Cache</h4>
                      <div id="dlgExportSave_Cache">
                        <p className="help-block">This conversion result was added to the cache with the below identifier.</p>
                        <input id="dlgExportSave_Cache_Input" className="form-control" type="text" readOnly />
                      </div>
                      <div id="dlgExportSave_Export_Header" className="top-push"><h4 className="header-underline">Export</h4></div>
                      <div id="dlgExportSave_Export">
                        <div className="input-group">
                          <span className="input-group-addon">Name:</span>
                          <input id="dlgExportSave_FileName" type="text" className="form-control" />
                        </div>
                        <p className="help-block">This name will be used for all options except Local Save.</p>
                        <p id="dlgExportSave_Message" className="text-center alert alert-warning top-push" />
                        <div id="dlgExportSave_Group" className="btn-group btn-group-justified top-push">
                          <div className="btn-group">
                            <button type="button" id="dlgExportSave_LocalSave" className="btn btn-default">Local Save</button>
                          </div>
                          <div className="btn-group">
                            <button type="button" id="dlgExportSave_SaveToOneDrive" className="btn btn-default">OneDrive</button>
                          </div>
                          <div className="btn-group">
                            <button type="button" id="dlgExportSave_SaveToSharePoint" className="btn btn-default">SharePoint</button>
                          </div>
                          <div className="btn-group">
                            <button type="button" id="dlgExportSave_SaveToGoogleDrive" className="btn btn-default">Google Drive</button>
                          </div>
                        </div>
                        <div className="top-push">
                          <div className="inline-center">
                            <img id="dlgExportSave_FileSaving" src="Resources/Images/Loading.gif" />
                          </div>
                          <div id="dlgExportSave_GoogleSaveContainer" className="input-group">
                            <p className="help-block">
                              If you experience issues saving to Google Drive, you may need to disable ad blocker browser plugins or change your browser cookie restrictions.
                              Disabling browser plugins may cause the browser page to refresh, so save this document locally first or copy the document's cache ID.
                            </p>
                            <div id="googleSaveButtonContainer" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button id="dlgExportSave_CloseNext" className="btn btn-default">Close</button>
                    </div>
                  </div>
                </div>
                {/* Export Job Dialog */}
                <div id="dlgExportJob" className="dlg-container container">
                  <div className="dlg-box">
                    <header>
                      <h3>Export</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <div>
                        <h4 className="header-underline">URI</h4>
                        <p id="dlgExportJob_ConversionHelp" className="help-block">
                          This conversion result was added to the cache with the below identifier.
                          Click "Load" to unload the current document, close this dialog, and load this conversion result.
                        </p>
                        <p id="dlgExportJob_AttachmentHelp" className="help-block">
                          This attachment was added to the cache with the below identifier.
                          Click "Load" to unload the current document, close this dialog, and load this attachment.
                        </p>
                        <div className="input-group">
                          <input id="dlgExportJob_Uri_Input" className="form-control" type="text" readOnly />
                          <span className="input-group-btn">
                            <button id="dlgExportJob_Uri_Load" className="btn btn-default" type="button">Load</button>
                          </span>
                        </div>
                      </div>
                      <div className="top-push">
                        <h4 className="header-underline">Export</h4>
                        <a id="dlgExportJob_LocalSave" href="javascript:void(0);" target="_blank" rel="noopener noreferrer" className="btn btn-default">Local Save</a>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button className="btn btn-default dlg-close">Close</button>
                    </div>
                  </div>
                </div>
                {/* Pages Rotation dialog */}
                <div id="dlgPageRotation" className="dlg-container container">
                  <div className="dlg-box">
                    <header>
                      <h3>Page Rotation</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <div className="bottom-push">
                        <p className="help-block">
                          Page rotations will be saved to the cache and applied during document export.
                        </p>
                      </div>
                      <div>
                        <h4 className="header-underline">Direction</h4>
                        <div className="offset-right">
                          <select id="dlgPageRotation_DirectionSelect" className="form-control" />
                        </div>
                      </div>
                      <div className="top-push bottom-push">
                        <h4 className="header-underline bottom-push">Range</h4>
                        <div className="form offset-right">
                          <div className="radio">
                            <label><input type="radio" name="dlgPageRotation_RangeOption" defaultValue={0} defaultChecked />All Pages (<span id="dlgPageRotation_PageCount">N/A</span>)</label>
                          </div>
                          <div className="radio">
                            <label><input type="radio" name="dlgPageRotation_RangeOption" defaultValue={1} />Current (Page <span id="dlgPageRotation_CurrentPage">N/A</span>)</label>
                          </div>
                          <div className="radio">
                            <label style={{width: '100%'}}>
                              <input type="radio" name="dlgPageRotation_RangeOption" defaultValue={2} />
                              <input id="dlgPageRotation_CustomRange" type="text" placeholder="e.g. 1-3, 5" className="form-control input-xs" />
                            </label>
                          </div>
                          <div className="top-push">
                            <h5 className="header-underline">Range Conditions</h5>
                            <div className="bottom-push"><select id="dlgPageRotation_Condition_EvenOddSelect" className="form-control" /></div>
                            <div><select id="dlgPageRotation_Condition_OrientationSelect" className="form-control" /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button id="dlgPageRotation_Apply" className="btn btn-default">Apply</button>
                      <button className="btn btn-default dlg-close">Cancel</button>
                    </div>
                  </div>
                </div>
                {/*  Pages dialog */}
                <div id="dlgPages" className="dlg-container container">
                  <div className="dlg-box">
                    <header>
                      <h3 id="dlgPages_Title">Pages</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <div className="bottom-push">
                        <p id="dlgPages_Description" />
                      </div>
                      <div className="bottom-push">
                        <input id="dlgPages_AllPages" type="checkbox" />
                        <label htmlFor="dlgPages_AllPages">Run on all pages (<span id="dlgPages_PageCount">N/A</span>)</label>
                      </div>
                      <div className="input-group">
                        <label htmlFor="dlgPages_PageNumberInput" className="input-group-addon">Page Number (Current: <span id="dlgPages_CurrentPage">N/A</span>)</label>
                        <input id="dlgPages_PageNumberInput" type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button id="dlgPages_Apply" className="btn btn-default">Apply</button>
                      <button className="btn btn-default dlg-close">Cancel</button>
                    </div>
                  </div>
                </div>
                {/* Document properties dialog */}
                <div id="dlgDocumentProperties" className="dlg-container container">
                  <div className="dlg-box dlg-desktop-l">
                    <header>
                      <h3>Document Properties</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <ul className="nav nav-tabs">
                        <li className="active"><a href="#dlgDocumentProperties_PropertiesTab" data-toggle="tab">Properties</a></li>
                        <li><a href="#dlgDocumentProperties_MetadataTab" data-toggle="tab">Metadata</a></li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="dlgDocumentProperties_PropertiesTab">
                          <div className="table-responsive top-push">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <td>Key</td>
                                  <td>Value</td>
                                </tr>
                              </thead>
                              <tbody id="dlgDocumentProperties_Properties" />
                            </table>
                          </div>
                        </div>
                        <div className="tab-pane" id="dlgDocumentProperties_MetadataTab">
                          <div className="table-responsive top-push">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <td>Key</td>
                                  <td>Value</td>
                                </tr>
                              </thead>
                              <tbody id="dlgDocumentProperties_Metadata" />
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button className="btn btn-default dlg-close">Close</button>
                    </div>
                  </div>
                </div>
                {/* Attachment properties dialog */}
                <div id="dlgAttachmentProperties" className="dlg-container container">
                  <div className="dlg-box dlg-desktop-l">
                    <header>
                      <h3>Attachment Properties</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <ul className="nav nav-tabs">
                        <li className="active"><a href="#dlgAttachmentProperties_PropertiesTab" data-toggle="tab">Properties</a></li>
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="dlgAttachmentProperties_PropertiesTab">
                          <div className="table-responsive top-push">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <td>Key</td>
                                  <td>Value</td>
                                </tr>
                              </thead>
                              <tbody id="dlgAttachmentProperties_Properties" />
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button className="btn btn-default dlg-close">Close</button>
                    </div>
                  </div>
                </div>
                {/* Processing pages dialog (Barcode results) */}
                <div id="dlgProcessPages" className="dlg-container container dlg-process-pages">
                  <div className="dlg-box dlg-desktop-l">
                    <header>
                      <h3><strong className="process-name">[Process]</strong> for <strong className="process-pages">#</strong> page(s)</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <h4><span className="process-status" /><img id="documentLoading" className="process-loading" src="Resources/Images/Loading.gif" /></h4>
                      <h5 className="process-summary" />
                      <div className="process-content" />
                      <button className="process-cancel btn btn-default">Cancel</button>
                    </div>
                    <div className="modal-footer">
                      <button className="btn btn-default dlg-close">Close</button>
                    </div>
                  </div>
                </div>
                {/*  Custom Render Mode dialog */}
                <div id="dlgCustomRenderMode" className="dlg-container container">
                  <div className="dlg-box">
                    <header>
                      <h3>Customize Render Mode</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <div className="form-group">
                        Use the two lists below to customize which annotations objects should be visible in the render mode.
                        For example, you can modify the render mode to only show the redaction objects used to hide text from users without the correct security privileges.
                      </div>
                      <div className="row">
                        <div className="col-xs-6">
                          <label className="pull-left">Visible Objects</label>
                          <select id="dlgCustomRenderMode_VisibleObjectSelect" className="form-control listbox form-group" multiple="multiple" size={15} />
                          <button id="dlgCustomRenderMode_MoveToInvisible" type="button" className="btn btn-default pull-right">&gt;&gt;</button>
                        </div>
                        <div className="col-xs-6">
                          <label className="pull-right">Invisible Objects</label>
                          <select id="dlgCustomRenderMode_InvisibleObjectSelect" className="form-control listbox form-group" multiple="multiple" size={15} />
                          <button id="dlgCustomRenderMode_MoveToVisible" type="button" className="btn btn-default pull-left">&lt;&lt;</button>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button id="dlgCustomRenderMode_Apply" className="btn btn-default">Apply</button>
                      <button className="btn btn-default dlg-close">Cancel</button>
                    </div>
                  </div>
                </div>
                {/*  Automation update object dialog */}
                <div id="dlgAutomationUpdate" className="dlg-container container">
                  <div className="dlg-box dlg-desktop-l">
                    <header>
                      <h3>{translate("traducciones.visor.propiedadesAnotacion")}</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <ul className="dlg-tabs">
                        <li id="dlgAutomationUpdate_PropertiesTab">{translate("traducciones.visor.anotacionProps")}</li>
                        <li id="dlgAutomationUpdate_ContentTab">{translate("traducciones.visor.anotacionCont")}</li>
                        <li id="dlgAutomationUpdate_ReviewsTab">{translate("traducciones.visor.anotacionInfo")}</li>
                      </ul>
                      <div className="dlg-tabview">
                        <div id="dlgAutomationUpdate_PropertiesPage" />
                        <div id="dlgAutomationUpdate_ContentPage">
                          <div className="content-textarea-holder">
                            <textarea id="contentTextArea" className="content-textarea" rows={7} defaultValue={""} />
                          </div>
                        </div>
                        <div id="dlgAutomationUpdate_ReviewsPage" />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button className="btn btn-default dlg-close">{translate("traducciones.visor.cerrar")}</button>
                    </div>
                  </div>
                </div>
                {/* Brush dialog */}
                <div id="brushDialog" className="dlg-container container">
                  <div className="dlg-box">
                    <a href="#"><span className="dlg-close-x" /></a>
                    <header>
                      <h1>Brush</h1>
                    </header>
                    <div className="dlg-body">
                      <div id="auoDlg_Body">
                        <ul className="dlg-tabs">
                          <li id="brushDlg_solidBrushTab">Solid</li>
                          <li id="brushDlg_hatchBrushTab">Hatch</li>
                          <li id="brushDlg_linearGradientBrushTab">Linear Gradient</li>
                        </ul>
                        <div className="dlg-tabview">
                          <div id="brushDlg_solidBrushPage" />
                          <div id="brushDlg_hatchBrushPage" />
                          <div id="brushDlg_linearGradientBrushPage" />
                        </div>
                      </div>
                      <div className="row dlg-bottom-push dlg-top-push">
                        <div className="col-xs-12">
                          <button className="dlg-close fill-row">Close</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Input Dialog */}
                <div id="dlgInput" className="dlg-container container">
                  <div className="dlg-box">
                    <header>
                      <h3 id="dlgInput_Title">Input</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <p id="dlgInput_Description" />
                      <div>
                        <input id="dlgInput_Input" className="form-control" />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button id="dlgInput_Apply" className="btn btn-default">Apply</button>
                      <button id="dlgInput_Hide" className="btn btn-default">Cancel</button>
                    </div>
                  </div>
                </div>
                {/* Cache Dialog */}
                <div id="dlgCache" className="dlg-container container">
                  <div className="dlg-box">
                    <header>
                      <h3 id="dlgCache_Title">Cache</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <div className="bottom-push">
                        <p id="dlgCache_Description" />
                      </div>
                      <div className="bottom-push">
                        <button id="dlgCache_PasteCurrentForLoad" className="btn btn-default btn-sm">Paste current document cache identifier<strong id="dlgCache_PasteCurrentForLoad_Synced"> (Not Synced)</strong></button>
                      </div>
                      <div>
                        <input id="dlgCache_Input" className="form-control" />
                      </div>
                      <div className="top-push">
                        <button id="dlgCache_ReloadCurrentFromSave" className="btn btn-default">Reload this document from the cache</button>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button id="dlgInput_Load" className="btn btn-default">Load</button>
                      <button className="btn btn-default dlg-close">Close</button>
                    </div>
                  </div>
                </div>
                {/* Document viewer options dialog*/}
                <div id="dlgOptions" className="dlg-container container">
                  <div className="dlg-box dlg-desktop-xl">
                    <header>
                      <h3>Document Viewer Options</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-lg-6 dlg-option-section">
                            <h4 className="header-underline">Client-Side PDF</h4>
                            <div className="top-push">
                              <div>
                                <div>
                                  <input id="dlgOptions_UsePDFRendering" type="checkbox" />
                                  <label htmlFor="dlgOptions_UsePDFRendering">Use client-side PDF rendering <sup>1</sup></label>
                                </div>
                                <p>
                                  If the document is not a PDF, normal viewing preferences are used.
                                </p>
                              </div>
                              <div>
                                <div>
                                  <input id="dlgOptions_UsePDFText" type="checkbox" />
                                  <label htmlFor="dlgOptions_UsePDFText">Use client-side PDF text retrieval</label>
                                </div>
                                <p>
                                  If the document is not a PDF, text is retrieved from the DocumentService.
                                </p>
                              </div>
                              <div className="content-space">
                                <input id="dlgOptions_UsePDFThumbnailsInterpolation" type="checkbox" />
                                <label htmlFor="dlgOptions_UsePDFThumbnailsInterpolation">Use interpolation on client-side PDF thumbnails <sup>1</sup></label>
                              </div>
                              <div>
                                <div className="input-group">
                                  <label htmlFor="dlgOptions_MaximumPDFRenderingRasterPagePixelSizeTextInput" className="input-group-addon">Maximum raster page size (in pixels): <sup>1</sup></label>
                                  <input id="dlgOptions_MaximumPDFRenderingRasterPagePixelSizeTextInput" type="text" className="form-control" />
                                </div>
                                <p>
                                  Use the service to get raster pages that have a width or height greater than this value to speed up rendering.
                                  Only available if Document Load Type is <code>Service</code> or <code>LocalThenService</code>. A value of 0 means always use client rendering.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 dlg-option-section">
                            <h4 className="header-underline">AJAX</h4>
                            <div className="top-push">
                              <div>
                                <div>
                                  <input id="dlgOptions_HookPrepareAjax" type="checkbox" />
                                  <label htmlFor="dlgOptions_HookPrepareAjax">Hook DocumentViewer.prepareAjax</label>
                                </div>
                                <p>Use <code>DocumentViewer.prepareAjax</code> to output into the console information on every call made to the DocumentService.</p>
                              </div>
                              <div>
                                <div>
                                  <input id="dlgOptions_UseAjaxImageLoading" type="checkbox" />
                                  <label htmlFor="dlgOptions_UseAjaxImageLoading">Use AJAX image loading <sup>1</sup></label>
                                </div>
                                <p>Use AJAX to load all images in this instance of document viewer instead of typical <code>image.src</code>. Using AJAX supports sending custom headers with each request to the server through <code>DocumentFactory.prepareAjax</code>.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 dlg-option-section">
                            <h4 className="header-underline">Upload &amp; Export</h4>
                            <div className="top-push">
                              <div>
                                <div>
                                  <input id="dlgOptions_VerifyUploadedMimeTypes" type="checkbox" />
                                  <label htmlFor="dlgOptions_VerifyUploadedMimeTypes">Verify uploaded documents before loading</label>
                                </div>
                                <p>
                                  Before loading an uploaded document from the cache for the first time, verify that it is an accepted format.
                                  The service must be configured to support this feature.
                                </p>
                              </div>
                              <div>
                                <div>
                                  <input id="dlgOptions_Export_UseStatus" type="checkbox" defaultChecked />
                                  <label htmlFor="dlgOptions_Export_UseStatus">Use Status Query Requests</label>
                                </div>
                                <p>
                                  Make periodic requests to the service to query the status of the document export.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 dlg-option-section">
                            <h4 className="header-underline">Network</h4>
                            <div className="top-push">
                              <div>
                                <div>
                                  <input id="dlgOptions_Heartbeat_Enabled" type="checkbox" />
                                  <label htmlFor="dlgOptions_Heartbeat_Enabled">Check Service Heartbeat</label>
                                </div>
                                <p>
                                  Make periodic requests to the service to check that it is still up and running. Show a message
                                  when the service is down.
                                </p>
                                <ul style={{listStyleType: 'none'}}>
                                  <li>
                                    <div className="input-group input-group-sm content-space">
                                      <span htmlFor="dlgOptions_Heartbeat_Start" className="input-group-addon">Start Timer (in ms):</span>
                                      <input id="dlgOptions_Heartbeat_Start" type="text" className="form-control" />
                                    </div>
                                    <div className="input-group input-group-sm content-space">
                                      <span htmlFor="dlgOptions_Heartbeat_Interval" className="input-group-addon">Interval Timer (in ms):</span>
                                      <input id="dlgOptions_Heartbeat_Interval" type="text" className="form-control" />
                                    </div>
                                    <div className="input-group input-group-sm">
                                      <span htmlFor="dlgOptions_Heartbeat_AutoPause" className="input-group-addon">Inactivity Timer (in ms):</span>
                                      <input id="dlgOptions_Heartbeat_AutoPause" type="text" className="form-control" />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 dlg-option-section">
                            <h4 className="header-underline">View</h4>
                            <div className="top-push">
                              <div className="input-group">
                                <label htmlFor="dlgOptions_View_NumberOfWorkers" className="input-group-addon">Number of Workers: <sup>2</sup></label>
                                <input id="dlgOptions_View_NumberOfWorkers" type="text" className="form-control" />
                              </div>
                              <div className="content-space">
                                <input id="dlgOptions_View_LazyLoad" type="checkbox" />
                                <label htmlFor="dlgOptions_View_LazyLoad">Lazy Load <sup>2</sup></label>
                              </div>
                              <div className="content-space">
                                <input id="dlgOptions_View_CSSTransitions" type="checkbox" />
                                <label htmlFor="dlgOptions_View_CSSTransitions">CSS3 Transitions</label>
                              </div>
                              <div className="content-space">
                                <input id="dlgOptions_View_UseSvgBackImage" type="checkbox" />
                                <label htmlFor="dlgOptions_View_UseSvgBackImage">Use SVG Back Image <sup>1</sup></label>
                                <p>Try getting the image elements in an SVG document separately to preserve memory.</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 dlg-option-section">
                            <h4 className="header-underline">Thumbnails</h4>
                            <div className="top-push">
                              <div className="input-group">
                                <label htmlFor="dlgOptions_Thumbs_NumberOfWorkers" className="input-group-addon">Number of Workers: <sup>2</sup></label>
                                <input id="dlgOptions_Thumbs_NumberOfWorkers" type="text" className="form-control" />
                              </div>
                              <div className="content-space">
                                <input id="dlgOptions_Thumbs_LazyLoad" type="checkbox" />
                                <label htmlFor="dlgOptions_Thumbs_LazyLoad">Lazy Load <sup>2</sup></label>
                              </div>
                              <div className="content-space">
                                <input id="dlgOptions_Thumbs_UseGrids" type="checkbox" />
                                <label htmlFor="dlgOptions_Thumbs_UseGrids">Use Grids <sup>1, 2</sup></label>
                              </div>
                              <ul style={{listStyleType: 'none'}}>
                                <li>
                                  <div className="input-group input-group-sm">
                                    <span htmlFor="dlgOptions_Thumbs_PixelSize" className="input-group-addon">Pixel Size: </span>
                                    <input id="dlgOptions_Thumbs_PixelSize" type="text" className="form-control" />
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 dlg-option-section">
                            <h4 className="header-underline">Document Load Type <sup>1</sup></h4>
                            <div className="top-push">
                              <div>
                                <div>
                                  <select id="dlgOptions_LoadDocumentMode" className="form-control">
                                    <option selected="selected" value={0} description="Uses the document service.">Service</option>
                                    <option value={1} description="Will not use the service. Loading Pdf, Png, Jpeg and Gif is supported.">Local</option>
                                    <option value={2} description="Will use the service if needed, all formats are supported.">Local Then Service</option>
                                  </select>
                                </div>
                                <p id="dlgOptions_LoadDocumentModeDescription">
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 dlg-option-section">
                            <h4 className="header-underline">Timeouts <sup>1</sup></h4>
                            <div className="top-push">
                              <p>
                                Abort loading documents from the service if it takes more than this value. A value of 0
                                means wait indefinitely till document loading finishes.
                              </p>
                              <div className="input-group">
                                <label htmlFor="dlgOptions_LoadDocumentTimeout" className="input-group-addon">Load Document Timeout (in ms): </label>
                                <input id="dlgOptions_LoadDocumentTimeout" type="text" className="form-control" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <hr />
                        <div>
                          <strong><sup>1</sup></strong> Changes made to this option will take effect the next time you load a document. All other changes take effect immediately.
                        </div>
                        <div>
                          <strong><sup>2</sup></strong> This option may be automatically overwritten if client-side PDF rendering is enabled and available for the loaded document.
                          Lazy loading is enabled, view and thumbnails workers are disabled, and the thumbnail grids feature is disabled.
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button id="dlgOptions_Apply" className="btn btn-default">Apply</button>
                      <button className="btn btn-default dlg-close">Cancel</button>
                    </div>
                  </div>
                </div>
                {/* Link Value Dialog */}
                <div id="dlgLinkValue" className="dlg-container container">
                  <div className="dlg-box">
                    <header>
                      <h3>Open Link</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <p>
                        Standard security practices prevent this page from automatically opening this link.
                        Click the link manually below (automatically opens in a new tab):
                      </p>
                      <div className="content-space">
                        <a id="dlgLinkValue_Link" className="btn btn-default" />
                      </div>
                      <div>
                        <input id="dlgLinkValue_AlwaysOpenLinks" type="checkbox" />
                        <label htmlFor="dlgLinkValue_AlwaysOpenLinks">Always open external links (Do not show this message again)</label>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button className="btn btn-default dlg-close">Close</button>
                    </div>
                  </div>
                </div>
                {/* Link Message Dialog */}
                <div id="dlgLinkMessage" className="dlg-container container">
                  <div className="dlg-box">
                    <header>
                      <h3 id="dlgLinkMessage_Title" />
                    </header>
                    <div className="dlg-body modal-body">
                      <p id="dlgLinkMessage_Message" />
                      <div className="content-space">
                        <a id="dlgLinkMessage_Link" className="btn btn-default" />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button className="btn btn-default dlg-close">Close</button>
                    </div>
                  </div>
                </div>
                {/* SharePoint picker */}
                <div id="dlgSharePointPicker" className="dlg-container container dlg-sharepoint-picker">
                  <div className="dlg-box">
                    <header>
                      <h3 id="dlgSharePointPicker_Title" />
                      <h5 id="dlgSharePointPicker_Directory_CurrentPath" />
                    </header>
                    <div className="dlg-body modal-body">
                      <div id="dlgSharePointPicker_Properties">
                        <div>
                          <h4 className="header-underline">Server Address</h4>
                          <div>
                            <p className="top-push">Enter the site root. Only the "Shared Documents" folder will be accessible by default.</p>
                            <div>
                              <input id="dlgSharePointPicker_Properties_Server" type="text" className="form-control" placeholder="Example: http://myserver/mysite" spellCheck="false" />
                            </div>
                          </div>
                        </div>
                        <div className="top-push">
                          <h4 className="header-underline">Credentials</h4>
                          <div className="top-push">
                            <div>
                              <input id="dlgSharePointPicker_Credentials_Use" type="checkbox" />
                              <label htmlFor="dlgSharePointPicker_Credentials_Use">Use server credentials</label>
                            </div>
                            <div className="input-group top-push">
                              <label htmlFor="dlgSharePointPicker_Credentials_Username" className="input-group-addon">Username</label>
                              <input id="dlgSharePointPicker_Credentials_Username" type="text" className="form-control sharePointCredentials" spellCheck="false" />
                            </div>
                            <div className="input-group top-push">
                              <label htmlFor="dlgSharePointPicker_Credentials_Password" className="input-group-addon">Password</label>
                              <input id="dlgSharePointPicker_Credentials_Password" type="password" className="form-control sharePointCredentials" />
                            </div>
                            <div className="input-group top-push">
                              <label htmlFor="dlgSharePointPicker_Credentials_Domain" className="input-group-addon">Domain</label>
                              <input id="dlgSharePointPicker_Credentials_Domain" type="text" className="form-control sharePointCredentials" spellCheck="false" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="dlgSharePointPicker_Directory">
                        <div className="sharepoint-table-scroll">
                          <table className="table">
                            <tbody id="dlgSharePointPicker_Directory_Body" />
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <img id="dlgSharePointPicker_Loading" src="Resources/Images/Loading.gif" />
                      <button type="button" id="dlgSharePointPicker_SetProperties" className="btn btn-default pull-left">
                        <span className="icon icon-sm server" />
                        <span className="text">Disconnect</span>
                      </button>
                      <button id="dlgSharePointPicker_Go" className="btn btn-default">Connect</button>
                      <button className="btn btn-default dlg-close">Close</button>
                    </div>
                  </div>
                </div>
                {/* Load Page Range dialog */}
                <div id="dlgLoadPageRangeOptions" className="dlg-container container">
                  <div className="dlg-box">
                    <header>
                      <h3>Document Page Options</h3>
                    </header>
                    <div className="dlg-body modal-body">
                      <div>
                        <div className="input-group top-push">
                          <span className="input-group-addon">First Page:</span>
                          <input id="dlgLoadPageRangeOptions_FirstPage" type="number" className="form-control" />
                        </div>
                        <div className="input-group top-push">
                          <span className="input-group-addon">Last Page:</span>
                          <input id="dlgLoadPageRangeOptions_LastPage" type="number" className="form-control" />
                        </div>
                        <div className="help-block">
                          <p>A value of -1 for the ‘Last Page’ signifies to use the last page of the document, regardless of its length.</p>
                        </div>
                      </div>
                      <div className="top-push">
                        <button id="dlgLoadPageRangeOptions_AllPages" className="btn btn-default">Set to All Pages</button>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button id="dlgLoadPageRangeOptions_Set" className="btn btn-default">Set Options</button>
                      <button className="btn btn-default dlg-close">Close</button>
                    </div>
                  </div>
                </div>
                {/* Loading dialog */}
                <div id="dlgLoading" className="hide-for-print dlg-container container">
                  <div className="dlg-box loading-dialog">
                    <div className="dlg-body modal-body">
                      <header>
                        <h4 id="dlgLoading_ProcessText" />
                        <h5 id="dlgLoading_ProcessSubText" className="bottom-push-inner" />
                      </header>
                      <div className="progress">
                        <div id="dlgLoading_ProgressBar" className="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} style={{width: '0%'}}>
                          <span id="dlgLoading_ProgressPercentage" />
                        </div>
                      </div>
                    </div>
                    <div id="dlgLoading_CancelDiv" className="modal-footer">
                      <button id="dlgLoading_Cancel" className="btn btn-default">Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Document redaction dialog */}
              <div id="dlgRedactionOptions" className="dlg-container container">
                <div className="dlg-box">
                  <header>
                    <h3>Redaction Options</h3>
                  </header>
                  <div className="dlg-body modal-body">
                    <ul className="nav nav-tabs">
                      <li className="active"><a href="#dlgRedactionOptions_AutoOptionsTab" data-toggle="tab">Auto</a></li>
                      <li><a href="#dlgRedactionOptions_ViewOptionsTab" data-toggle="tab">View</a></li>
                      <li><a href="#dlgRedactionOptions_ConvertOptionsTab" data-toggle="tab">Convert</a></li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active" id="dlgRedactionOptions_AutoOptionsTab">
                        <div className="table-responsive top-push">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <td>Presets</td>
                                <td>
                                  <div id="dlgAutoRedaction_Presets">
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Annotation Type</td>
                                <td>
                                  <select id="dlgAutoRedaction_Type" className="form-control">
                                    <option value={1}>Redact</option>
                                    <option value={2}>Hilite</option>
                                  </select>
                                </td>
                              </tr>
                            </thead>
                          </table>
                          <button id="dlgAutoRedaction_Apply" className="btn btn-default top-push">Apply Preset</button>
                          <p>The annotation objects of the selected type will be placed over words matching the preset conditions</p>
                        </div>
                      </div>
                      <div className="tab-pane" id="dlgRedactionOptions_ViewOptionsTab">
                        <div className="table-responsive top-push">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <td>Redaction Mode</td>
                                <td>
                                  <select id="dlgViewRedaction_Mode" className="form-control">
                                    <option selected="selected" value={0}>None</option>
                                    <option value={1}>Apply</option>
                                    <option value={2}>Apply then rasterize</option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td>Replace Character</td>
                                <td>
                                  <input id="dlgViewRedaction_ReplaceCharacter" maxLength={1} />
                                </td>
                              </tr>
                            </thead>
                            <tbody id="dlgRedactionOptions_ViewOptions" />
                          </table>
                          <p>Changes made to this option will cause saving the document to cache then reload it.</p>
                        </div>
                      </div>
                      <div className="tab-pane" id="dlgRedactionOptions_ConvertOptionsTab">
                        <div className="table-responsive top-push">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <td>Redaction Mode</td>
                                <td>
                                  <select id="dlgConvertRedaction_Mode" className="form-control">
                                    <option selected="selected" value={0}>None</option>
                                    <option value={1}>Apply</option>
                                    <option value={2}>Apply then rasterize</option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td>Replace Character</td>
                                <td>
                                  <input id="dlgConvertRedaction_ReplaceCharacter" maxLength={1} />
                                </td>
                              </tr>
                            </thead>
                            <tbody id="dlgRedactionOptions_ConvertOptions" />
                          </table>
                          <p />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button id="dlgRedactionOptions_Apply" className="btn btn-default">Apply</button>
                    <button className="btn btn-default dlg-close">Cancel</button>
                  </div>
                </div>
              </div>
              {/* Auto Redaction Dlg*/}
              <div id="redactPresets-dlg" className="dlg-container container">
                <div className="dlg-box" style={{width: '725px'}}>
                  <header>
                    <h3>Auto Redaction</h3>
                  </header>
                  <div className="dlg-body modal-body">
                    <div className="row content">
                      <div className="rule-panel" style={{flex: '0 0 auto'}}>
                        <div id="redactPresets-rules" style={{display: 'flex', flexDirection: 'column', flex: '0 0 auto'}} />
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: 'auto', flex: '0 0 auto'}}>
                          <h4 style={{marginLeft: '10px'}}>Actions</h4>
                          <div id="redactPresets-actions" />
                        </div>
                        <div style={{marginTop: 'auto', borderTop: '5px solid #ddd', display: 'flex', flexDirection: 'row', flex: '0 0 auto'}}>
                          <button className="btn btn-default" id="redactPresets-apply" style={{width: '100%', borderRadius: 0}}>Apply </button>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', flex: '0 0 auto'}}>
                          <button className="btn btn-default" id="redactPresets-applyCommit" style={{width: '100%', borderRadius: 0}}>Apply and Commit</button>
                        </div>
                      </div>
                      <div className="modal-panel" style={{display: 'flex', flexDirection: 'column'}}>
                        <div id="data-container" style={{height: '80%', overflow: 'auto'}}>
                        </div>
                        <div style={{height: '20%', display: 'flex', flexDirection: 'row', borderTop: '5px solid #ddd'}}>
                          <label className="vcenter" style={{marginLeft: '5px'}} id="changes">Accepted Changes: </label>
                          <button className="btn btn-default" id="redactPresets-commit" style={{margin: 'auto', marginLeft: 'auto', marginRight: 0}}>
                            Commit
                          </button>
                          <button className="btn btn-default" id="redactPresets-close" style={{margin: 'auto', marginLeft: '5px', marginRight: '5px'}}>
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Video Player */}
              <div className="video-viewer-shell" id="videoPlayer-root">
                <div className="video-viewer-inner" id="videoPlayer-viewer">
                </div>
              </div>
              <div>
                {/* Print containers */}
                <div id="printDiv" className="lt-print print-div">
                </div>
                <iframe id="printFrame" className="lt-print print-frame" />
                <iframe id="pdfPrintFrame" className="lt-print print-frame" />
              </div>
            </div>
          );
        }


export default ExternalViewer;