import { useState, useEffect } from 'react';
import {
    useTranslate,
    useDataProvider,
    useNotify,
    Loading
} from 'react-admin';
import ExternalViewer from '../Libs/ExternalViewer/ExternalViewer';
import withWidth from '@material-ui/core/withWidth';
import swal from 'sweetalert';

const VisorLead = ({ ...props }) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const [loading, setLoading] = useState(true);
    const [visor, setVisor] = useState(null);
    const [openDocument, setopenDocument] = useState(false);
    const [document, setDocument] = useState(null);
    const [openResult, setOpenResult] = useState(false);

    useEffect(() => {

        //Obtenemos el id del documento de la URL
        let idDocument = props.location.search;
        idDocument = idDocument.replace("?id_document=", "");
        /*
        La funcion consulta documento es la encargada de llamar a la api de maximage para obtener la informacion del documento
        y con esa informacion en base 64, llamar al visor de LeadTools
        */
        //Llamado a la api de documentos
        //dataProvider.getOne('documento', { IdDocument: elem.IdDocument})
        dataProvider.getOne('documento', { IdDocument: idDocument })
            .then(({ data }) => {
                setLoading(false);
                data.data.IdDocument = idDocument;
                setDocument(data.data);
                setOpenResult(false);
                setopenDocument(true);

                /*
                Instancia del visor de LeadTools, en este caso el objeto del aplicativo se llama HTML5Demo, porque asi se llamaba
                en el ejemplo de la documentacion general.
                DocumentViewerDemo, es el objeto del visor en si.
                y la funcion DocumentViewerDemoApp() lo inicializa
                */
                var app = new window.HTML5Demos.DocumentViewerDemo.DocumentViewerDemoApp();

                //Luego de inicializarlo, guardamos ese objeto en react para manipularlo en el componente.
                setVisor(app);

                /*
                Luego de instanciar el objeto del visor enviaremos ciertos parametros hacia el visor
                para que pueda interpretar esta informacion, el objeto que creamos para enviar la informacion 
                lo llamaremos fileExt, haciendo referencia a que es un documento externo al applicativo.
                */

                //Antes de enviar el resultado del servicio al objeto fileExt, adjuntaremos las traducciones correspondientes.
                data.data.traducciones = {
                    'cargandoDocumento': translate('traducciones.visor.cargandoDocumento'),
                    'verificando': translate('traducciones.visor.verificando'),
                    'abriendoDocumento': translate('traducciones.visor.abriendoDocumento'),
                }

                data.data.userName = localStorage.getItem("userName");

                //Luego de cargar los parametros al objeto data.data (que es el objeto resultante del servicio), lo enviaremos al visor mediante el objeto fileExt
                app.fileExt = data.data;

                //Corremos el visor para que interprete los datos, esta interpretacion se puede seguir en el archivo public/Main/Main.js
                app.run();

            })
            .catch(error => {
                setLoading(false);
                swal({
                    title: 'Carga de documento',
                    icon:'error',
                    text:'Error: No fue posible mostrar el documento.'
                })
                .then((value) => {
                    closeExternal();
                });
            });
    }, []);

    const closeExternal = () => {
        window.close();
    }

    /*
    Esta funcion sera la encargada de obtener las anotaciones desde el visor y guardarlas mediante las apis de MAxImage
    */
    const saveAnotations = () => {
        /*
        Ejecutaremos la funcion getAnnotations(), funcion desarrollada para obtener las antoaciones del visor en 
        formato XML, para poder fuardarlas en la api. Esta funcion se encuentra en public/Main/Main.js en la linea 2266
        */
        var anotations = visor.getAnnotations();
        if (anotations.length > 0) {
            dataProvider.update('anotaciones', { DocumentId: document.IdDocument, Annotations: anotations })
                .then(({ data }) => {
                    swal({
                        icon:'success',
                        text: translate('traducciones.notificaciones.actualizaAnotacionesOK')
                    })
                })
                .catch(error => {
                });
        }
    }

    if (loading) {
        return <div style={{ marginTop: '10%' }} ><Loading loadingPrimary={translate('traducciones.visor.cargandoDocumento')} /></div>
    }

    return (
        <div>
            {openDocument ? (
                <ExternalViewer closeExternal={closeExternal} saveAnotations={saveAnotations} />
            ) : <div></div>}
        </div>)
}

export default withWidth()(VisorLead);
