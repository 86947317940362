import * as React from 'react';
import { useState ,useEffect } from 'react';
import {
    FormWithRedirect,
    useCreate,
    useDelete,
    useTranslate,
    SimpleForm,  
    Loading, 
    Error, 
    FileInput, 
    Create,
    useRefresh,
    Query, 
    FileField ,
    ImageField,
    SaveButton,
    ImageInput,
    useDataProvider,
    useNotify,
    ReferenceInput,
    AutocompleteArrayInput,
    TextInput,
    required
} from 'react-admin';
import Button from '@material-ui/core/Button';
import FolderIcon from '@material-ui/icons/Folder';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import WarningIcon from '@material-ui/icons/Warning';
import ImageIcon from '@material-ui/icons/Image';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PublishIcon from '@material-ui/icons/Publish';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Card,
  } from '@material-ui/core';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import swal from 'sweetalert';
import introJs from 'intro.js';
import 'intro.js/introjs.css';

const useStyles = makeStyles({
    nodo : {
        padding: '6px',
        border: '1px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        width : 'max-content'
    },
    nodoSeleccionado :  {
        padding: '6px',
        border: '1px solid black',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        backgroundColor: "#319b4257",
        color: '#277233'
    }
});

function dibujaNodosHijos(nodo,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto){
    if(nodo.hijos != undefined){
        return (
            <div>
                {nodo.hijos.map(
                (item) => {
                    checknodoabierto(item);
                    return (
                    <div  style={{ marginLeft: '40px', marginTop : '-5px'}}>
                        <div style={{ float: 'left' }}>
                            <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                        </div>
                        <div className={ nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > <FolderIcon style={{ marginRight: '5px'}} />
                            { item.TextoNodo }
                            <Button title={ translate('traducciones.titulos.consultarCampos')} onClick={() => addNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                <ExitToAppIcon style={{ fontSize: '16px'}}  />
                            </Button>
                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                <ExpandLessIcon />
                            </Button>
                            }
                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                <ExpandMoreIcon />
                            </Button>
                            }
                        </div>
                        { !item.colapsado &&
                        <div>
                            { dibujaNodosHijos(item,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                        </div>
                        }
                    </div>
                )})
                }
            </div>
        )
    }
}

const BusquedaCampos = ({ ...props }) => {   

    const classes = useStyles();
    const [nodoPadreElemento, setnodoPadreElemento] = useState(null);    
    const [loadinga, setLoadinga] = useState(false);
    const [openNoResult, setOpenNoResult] = useState(false);
    const [openResult, setOpenResult] = useState(false);
    const [openDelete, setopenDelete] = useState(false);
    const [openDocument, setopenDocument] = useState(false);    
    const [indiceSeleccionado, setindiceSeleccionado] = useState(null);
    const [create] = useCreate('indices');
    const [deleteOne] = useDelete();
    const translate = useTranslate();
    const refresh = useRefresh();
    const [dataIndices, setDataIndices] = useState([]);
    const [dataSearch, setDataSearch] = useState([]);
    const [dataUsers, setDataUsers] = useState(null);
    const [usuarioLocal, setUsuarioLocal] = useState(null);
    const notify = useNotify();

    const [randomUpdate, setRandomUpdate] = useState(null);
    const [datanodosAbiertos, setDatanodosAbiertos] = useState([]);

    const [procesando, setProcesando] = useState(false);

    const dataProvider = useDataProvider();
    const inTour = JSON.parse(localStorage.getItem('activeTour'))

    //Variables agregadas para el buscador de nodos
    const [nodoBuscado,setNodoBuscado] = useState(null);
    const [nodos,setNodos] = useState(null);
    const [openNodosEncontrados,setOpenNodosEncontrados] = useState (false)
    const [resultadoNodos,setResultadoNodos] = useState([])

    let introHelp;
    introHelp = introJs('#root');
    introHelp.setOptions({
        disableInteraction: false,
        doneLabel: translate('traducciones.introBotones.doneLabel'),
        nextLabel: translate('traducciones.introBotones.nextLabel'),
        prevLabel: translate('traducciones.introBotones.prevLabel'),
        tooltipPosition:'top',
        steps: [
                {
                intro: translate('traducciones.introHelp.intro1'),
                title: translate('traducciones.introHelp.title1'),
                element: '#help'
                },
        ]                
    });

    let introNodos;
    useEffect(() => {
        if(inTour.carga){
            setTimeout(()=>{
                introNodos = introJs('#search-box');
                introNodos.setOptions({
                    disableInteraction: true,
                    doneLabel: translate('traducciones.introBotones.doneLabel'),
                    nextLabel: translate('traducciones.introBotones.nextLabel'),
                    prevLabel: translate('traducciones.introBotones.prevLabel'),
                    tooltipPosition:'top',
                    steps: [
                        {
                            intro: translate('traducciones.introDigit.intro1'),
                            title: translate('traducciones.introDigit.title1')
                        },
                        {
                            intro: translate('traducciones.introDigit.intro2'),
                            title: translate('traducciones.introDigit.title2'),
                            element: '#nodo'
                        },
                        {
                            intro: translate('traducciones.introDigit.intro3'),
                            title: translate('traducciones.introDigit.title3'),
                            element: '#more'
                        },
                        {
                            intro: translate('traducciones.introDigit.intro4'),
                            title: translate('traducciones.introDigit.title4'),
                            element: '#btnSearch'
                        },
                        
                    ]                
                  });
        
                introNodos.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                    showHelp()
                }).onexit(()=>{
                    showHelp()
                }).start();
            },1000)
        }
      }, []);

    const showHelp = () =>{
        const offPermisos = {
            querys: false,
            carga: false,
            admin: false,
            estructura: false,
        }
        if(document.cookie == 'introjs-dontShowAgain=true'){
            document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
                setTimeout(()=>{
                    introHelp.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).onexit(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).start();                         
                },500)
        }
    }

    function crearCookie(nombre, valor, diasParaExpirar) {
    var fechaExpiracion = new Date();
    fechaExpiracion.setDate(fechaExpiracion.getDate() + diasParaExpirar);
    var cookie = nombre + '=' + valor + '; expires=' + fechaExpiracion.toUTCString() + '; path=/';
    document.cookie = cookie;
    }

    useEffect(() => {
        if(dataUsers == null){
            setDataUsers([]);
            dataProvider.getList('usuarios', { filter : {}})
            .then(({ data }) => {
                var datareturn = [];
                data.forEach(function (record) { 
                    var rd = {};
                    rd.id = record.IdUsuario;
                    rd.Nombre = record.Nombre;
                    datareturn.push(rd);
                });  
                setDataUsers(datareturn);
                var arr = [];
                arr.push(parseInt(localStorage.getItem("IdUsuario")));
                setUsuarioLocal(arr);
            });
        }
    });

    if(dataUsers == null){
        return <Loading />;
    }

    const viewNode = (nodoPadre) => {
        setnodoPadreElemento(nodoPadre);
            if(inTour.carga){
                setTimeout(()=>{
                    const introQueryByCampos = introJs('#search-box')
                    introQueryByCampos.setOptions({
                        disableInteraction: false,
                        doneLabel: translate('traducciones.introBotones.doneLabel'),
                        nextLabel: translate('traducciones.introBotones.nextLabel'),
                        prevLabel: translate('traducciones.introBotones.prevLabel'),
                        steps: [
                            {
                                intro: translate('traducciones.introDigit.intro5'),
                                title: translate('traducciones.introDigit.title5'),
                                element: "#inputImg",
                                scrollTo: "off",
                                position: 'left',
                            },
                            {
                                intro: translate('traducciones.introDigit.intro6'),
                                title: translate('traducciones.introDigit.title6'),
                                element: "#inputFile",
                                scrollTo: "off",
                                position: 'left'
                            },
                            {
                                intro: translate('traducciones.introDigit.intro7'),
                                title: translate('traducciones.introDigit.title7'),
                                element: "#inputDesc",
                                scrollTo: 'off',
                                position: 'left'
                            },
                            {
                                intro: translate('traducciones.introDigit.intro8'),
                                title: translate('traducciones.introDigit.title8'),
                                element: "#inputUsers",
                                scrollTo: 'off',
                                position: 'left'
                            },
                            {
                                intro: translate('traducciones.introDigit.intro9'),
                                title: translate('traducciones.introDigit.title9'),
                                element: "#btnUpload",
                                scrollTo: 'off',
                                position: 'left'
                            }
                        ]                
                        });
            
                    introQueryByCampos.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).onexit(()=>{showHelp()}).oncomplete(()=>{showHelp()}).start();
                },800)
            }   
    }

    const handleCloseClick = () => {
        setOpenNoResult(false);
        setOpenResult(false);
        setopenDelete(false);
        setOpenNodosEncontrados(false)
    }

    const colapsar = (nodo) => {
        setRandomUpdate(Math.random());
        if(nodo.colapsado){
            datanodosAbiertos.push(nodo.RutaNodo)
            setDatanodosAbiertos(datanodosAbiertos);
        }else{
            setDatanodosAbiertos(datanodosAbiertos.filter(function(nodoguardado) { 
                return nodoguardado !== nodo.RutaNodo;
            }));
        }
        nodo.colapsado = !nodo.colapsado;
    }

    const checknodoabierto = (nodo) => {
        if(datanodosAbiertos.includes(nodo.RutaNodo)){
            nodo.colapsado = false;
        }
    }

    if(loadinga){
        return <Loading />
    }

    const handleSubmitDelete = async values => {
        setLoadinga(true);        
        deleteOne('indices', indiceSeleccionado.id, indiceSeleccionado,{
            onSuccess: ({ data }) => {
                setDataIndices(data.data);
                setopenDelete(false);
                setLoadinga(false);
                refresh();
            },
            onFailure: ({ error }) => {
                setLoadinga(false);
            }
        });
        setLoadinga(false);  
        
    };

    const convertFileToBase64 = file => 
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });

    const handleSubmitSearch = async values => {

        if((values.imagenes == undefined || values.imagenes.length == 0) && (values.archivos == undefined || values.archivos.length == 0)){
            notify("Seleccione al menos un archivo o imagen", 'error');
            return Promise.resolve({ id:0, data: []});
        }
        setProcesando(true);
        var newPictures;
        if(values.imagenes){
            newPictures = values.imagenes.filter(
                p => p.rawFile instanceof File
            );
        }
        if(values.archivos){
            if(newPictures){
                newPictures.push.apply(newPictures,values.archivos.filter(
                    p => p.rawFile instanceof File
                ));
            }else{
                newPictures = values.archivos.filter(
                    p => p.rawFile instanceof File
                );
            }
        }

        return Promise.all(newPictures.map(convertFileToBase64))
        .then(base64Pictures => {
                var archivos = [];
                base64Pictures.map(picture64 => {

                    var extension = picture64.split(';')[0].split('/')[1];
                    if(extension.includes("officedocument.wordprocessingml")){
                        extension =  "docx";    
                    }
                    if(extension.includes("officedocument.spreadsheet")){
                        extension =  "xlsx";    
                    }
                    if(extension.includes("application/x-zip-compressed") || extension.includes("application/zip") || extension.includes("application/zip-compressed") || extension.includes("zip")){
                        extension = "zip";    
                    }
                    if(extension.includes('text') || extension.includes('plain')){
                        extension='txt';
                    }

                    archivos.push(
                    {
                        IdNode : nodoPadreElemento != null ? nodoPadreElemento.RutaNodo : "/",
                        OperationType : "DIGITALIZACION",
                        AssignedUserId : values.user? values.user[0] : null,
                        DocumentPages : 6,
                        Description : values.descripcion,
                        DocumentType : extension,
                        IdPlantilla : 0,
                        Document : picture64.split("base64,")[1]
                    })  
                });
                
                // console.log(archivos);
                dataProvider.create('documentos', { data: archivos })
                .then(({ data }) => {
                    refresh();
                    setProcesando(false);
                    swal({
                        icon:'success',
                        text : translate('traducciones.notificaciones.documentosOK')
                    })
                })
                .catch(error => {
                    setProcesando(false);
                    swal({
                        text: error.message,
                        icon: 'error'
                    })
                    return Promise.reject();
                });   

            }
        );

    }

    const consultaDocumento = (elem) => {

        dataProvider.getOne('documento', { IdDocumento: elem.IdDocumento})
            .then(({ data }) => {
            })
            .catch(error => {
        });
    } 

    const buscarNodo = (nodo,e) => {
        e.preventDefault();
        if(nodo==null || nodo.trim()==''){
            swal({
                icon: "info",
                text: translate('traducciones.modal.sinNombre'),
                timer: 1000,
                buttons:false
              });
        }else{
        let nodosEncontrados=[];
        nodos.map((nPadre) => {
          if (nPadre.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
            nodosEncontrados.push({
                'ruta':"/"+nPadre.TextoNodo,
                'n1':nPadre,
                'lvl':1
            })
          } else if (nPadre.hijos.length > 0) {
            nPadre.hijos.map((n2) => {
              if (n2.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                nodosEncontrados.push({
                    'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo,
                    'n1':nPadre,
                    'n2':n2,
                    'lvl':2
                })
              } else if (n2.hijos.length > 0) {
                n2.hijos.map((n3) => {
                  if (n3.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                    nodosEncontrados.push({
                        'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo,
                        'n1':nPadre,
                        'n2':n2,
                        'n3':n3,
                        'lvl':3
                    })
                  } else if (n3.hijos.length > 0) {
                    n3.hijos.map((n4) => {
                      if (n4.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                        nodosEncontrados.push({
                            'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo + "/" + n4.TextoNodo,
                            'n1':nPadre,
                            'n2':n2,
                            'n3':n3,
                            'n4':n4,
                            'lvl':4
                        })
                      } else if (n4.hijos.length > 0) {
                        n4.hijos.map((n5) => {
                          if (n5.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                            nodosEncontrados.push({
                                'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo + "/" + n4.TextoNodo + "/" + n5.TextoNodo,
                                'n1':nPadre,
                                'n2':n2,
                                'n3':n3,
                                'n4':n4,
                                'n5':n5,
                                'lvl':5
                            })
                          }else if(n5.hijos.length > 0){
                            n5.hijos.map(n6=>{
                                if (n6.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                                    nodosEncontrados.push({
                                        'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo + "/" + n4.TextoNodo + "/" + n5.TextoNodo + "/" + n6.TextoNodo,
                                        'n1':nPadre,
                                        'n2':n2,
                                        'n3':n3,
                                        'n4':n4,
                                        'n5':n5,
                                        'n6':n6,
                                        'lvl':6
                                    })
                                }
                            })
                          }
                        });
                      }else{
                        //Si no hay hijos en nivel 5
                      }
                    });
                  } else {
                    //Si no hay hijos en nivel 4
                  }
                });
              } else {
                //Si no hay hijos en nivel 3
              }
            });
          } else {
            //Si no hay hijos en nivel 2
          }
        });
        if(nodosEncontrados.length==1){
            desplegarNodos(nodosEncontrados[0])
        }else{
            setResultadoNodos(nodosEncontrados);
            setOpenNodosEncontrados(true)
        }
    }
    };

    const desplegarNodos = (nodo) =>{
        switch(nodo.lvl){
            case 1: 
                viewNode(nodo.n1)
                setOpenNodosEncontrados(false)
            break;
            case 2:
                colapsar(nodo.n1);
                viewNode(nodo.n2)
                setOpenNodosEncontrados(false) 
            break;
            case 3:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                viewNode(nodo.n3)
                setOpenNodosEncontrados(false) 
            break;
            case 4:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                colapsar(nodo.n3);
                viewNode(nodo.n4)
                setOpenNodosEncontrados(false) 
            break;
            case 5:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                colapsar(nodo.n3);
                colapsar(nodo.n4);
                viewNode(nodo.n5)
                setOpenNodosEncontrados(false)
            break;
            case 6:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                colapsar(nodo.n3);
                colapsar(nodo.n4);
                colapsar(nodo.n5);
                viewNode(nodo.n6)
                setOpenNodosEncontrados(false)
            break;
            default: ;
        }
    }
    

    return (
        <Create {...props} title={ translate('traducciones.titulos.digitalizar')} >
            <SimpleForm {...props} toolbar={ null }  >
            <div fullWidth>
                
                <div style={{ padding: '10px'}}>
                    <div id='search-box'>
                        <div id='search-form' >
                        <input id='search-text' placeholder={translate('traducciones.campos.buscador')} type='text' onChange={(e) => setNodoBuscado(e.target.value)}/>
                        <button id='search-button' onClick={(e)=>buscarNodo(nodoBuscado,e)}><span><ManageSearchIcon style={{fontSize:'25px'}}/></span></button>
                        </div>
                    </div>           
                    <Query type="getList" resource="nodos" payload={ nodoPadreElemento }>
                        {({ data, loading, error }) => {
                            setNodos(data)
                            if (loading) { return <Loading />; }
                            if (error) { return <Error />; }
                            return (
                                <div  className="row">
                                    <div  className="col-md-5 col-md-custom" style={{ overflowX: "auto", padding: "10px"}}>
                                    {   data.map(
                                        (item) => {
                                            checknodoabierto(item);
                                            return (
                                            <div>
                                                <div id='nodo' className= { nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > 
                                                    <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                                    { item.TextoNodo } 
                                                    <Button id='btnSearch' title={ translate('traducciones.titulos.consultarCampos')}  onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                                        <ExitToAppIcon style={{ fontSize: '16px'}}/>
                                                    </Button>
                                                    { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                        <ExpandLessIcon />
                                                    </Button>
                                                    }
                                                    { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                                    <Button id='more' style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                        <ExpandMoreIcon />
                                                    </Button>
                                                    }
                                                </div>
                                                { !item.colapsado &&
                                                <div style={{ marginLeft: '-20px'}}>
                                                    { dibujaNodosHijos(item,classes,viewNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                                                </div>
                                                }
                                            </div>
                                        )})
                                    }
                                    </div>
                                    {
                                        nodoPadreElemento != null ? (
                                    <div  className="col-md-7 col-md-custom">
                                        <div>
                                            <SimpleForm save={ handleSubmitSearch } toolbar= { <SaveButton id='btnUpload' disabled={procesando} label={translate('traducciones.botones.subir')} icon= { <PublishIcon /> } /> } >
                                                <div className="row">
                                                    <div id='inputImg'>
                                                        <ImageInput source="imagenes" multiple={true} label={translate('traducciones.titulos.imagenesDigitalizar')} accept="image/*,application/pdf" placeholder={
                                                            <div>
                                                                <div><ImageIcon style={{ fontSize: "50px", color:"#319b426e"}} /></div>
                                                                <p>{translate('traducciones.titulos.imagenesSeleccionar')}</p>
                                                            </div>
                                                        }>
                                                                <ImageField source="src" title="title" />
                                                        </ImageInput>
                                                    </div>
                                                    <div id='inputFile'>
                                                        <FileInput source="archivos" multiple={true} label={translate('traducciones.titulos.archivoDigitalizar')} accept="application/*,text/plain" placeholder={
                                                            <div>
                                                                <div><FileCopyIcon style={{ fontSize: "50px", color:"#319b426e"}} /></div>
                                                                <p>{translate('traducciones.titulos.archivoSeleccionar')}</p>
                                                            </div>
                                                            }>
                                                            <FileField source="src" title="title" />
                                                        </FileInput>
                                                    </div>
                                                    <div id='inputDesc'>
                                                        <TextInput validate={required()} multiline={true} rows="4"  style={{ width: "100%" }} type="text" name={ "descripcion" } resettable={ true } label={ "Descripción"} source="descripcion" value="" defaultValue="" />
                                                    </div>
                                                <div>Usuario al que se le asignaran estos documentos</div>
                                                <div id='inputUsers'>
                                                    { dataUsers != null ? (
                                                    <AutocompleteArrayInput validate={required()} defaultValue = { usuarioLocal } label="traducciones.campos.usuario"  choices= { dataUsers ? dataUsers : null }  optionText="Nombre" optionValue="id" source="user" /> 
                                                    ) : null }
                                                </div>
                                                </div>   
                                            </SimpleForm>  
                                        </div>    
                                    </div>
                                    ) : null }
                                </div>
                            );
                        }}
                    </Query>
                </div> 
            </div>     
            <Dialog
                open={openNoResult}>
                <DialogTitle>{ translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                <DialogContent>
                    <WarningIcon style={{ color: '#c18a2d', fontSize: '60px'}} /> { translate('traducciones.modal.noResults')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClick} >
                        { translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog>   


            <Dialog
                open={openResult}>
                <DialogTitle>{ translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                <DialogContent>
                    <Card >
                        <Table className={"customDataGrid"}>
                            <TableHead>
                            <TableRow>
                                <TableCell>

                                </TableCell>
                                {
                                dataSearch && dataSearch.length > 0 && dataSearch[0].DatosDocumento && dataSearch[0].DatosDocumento.map(({ NombreIndice }) => {
                                    return  (
                                            <TableCell>
                                                { NombreIndice}
                                            </TableCell>
                                        )
                                    }
                                )            
                                }
                                <TableCell>

                                </TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                   dataSearch && dataSearch.map(elem => {
                                        return (  
                                        <TableRow>
                                            <TableCell>
                                                <Button onClick={() => consultaDocumento(elem) } >
                                                <VisibilityIcon />
                                                </Button>
                                            </TableCell>  
                                            {
                                                elem && elem.DatosDocumento.map(({ ValorLlave},index) => {
                                                    
                                                return  (                                                    
                                                        <TableCell>
                                                            { ValorLlave}
                                                        </TableCell>
                                                    )
                                                }
                                                
                                                )
                                            }
                                             <TableCell>
                                                <DeleteIcon />
                                            </TableCell>
                                        </TableRow>
                                        )                
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClick} >
                        { translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog>     

            <Dialog
                open={openDocument}>
                <DialogTitle>{ translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                <DialogContent>
                    <WarningIcon style={{ color: '#c18a2d', fontSize: '60px'}} /> { translate('traducciones.modal.noResults')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClick} >
                        { translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog>      


            <Dialog
                    open={openDelete}
                    >
                    <DialogTitle>{ translate('traducciones.modal.eliminarNodo')}</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmitDelete}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            { translate('traducciones.modal.eliminarNodoConfirm')}
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.confirm"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                                icon = { <DeleteIcon /> }
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog> 
                <Dialog fullWidth open={openNodosEncontrados}>
                    <DialogTitle>{ translate('traducciones.titulos.resultadoNodos')}</DialogTitle>
                        <FormWithRedirect
                            resource="catalogos"
                            save={buscarNodo}
                            render={({
                                handleSubmitWithRedirect,
                                pristine,
                                saving
                            }) => (
                                <>
                                <DialogContent>
                                <div  className="row">
                                    <div  className="col-md-12 col-md-custom"  style={{ overflowX: "auto", padding: "10px"}}>
                                        <span style={{fontSize:'15px'}} hidden={ resultadoNodos.length > 0 ? false : true }>{translate('traducciones.modal.seleccionNodo')}: </span><br/><br/>
                                    {resultadoNodos.length>0 ? resultadoNodos.map(item=>{
                                        return(
                                            <div>
                                               <center><input className='resultNodos' type='text' value={item.ruta} onClick={()=>desplegarNodos(item)} readOnly/></center>     
                                            </div>
                                        )
                                    }): 
                                        <div>
                                            <span style={{marginLeft:'42%',fontSize:'2vh'}}><b>{translate('traducciones.modal.noResults')}</b></span>    
                                        </div>
                                    }    
                                    </div>
                                </div>
                                </DialogContent>
                                <DialogActions>
                                    <SaveButton
                                        label="ra.action.add"
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        hidden
                                    />
                                    <Button onClick={handleCloseClick} >
                                        { translate('ra.action.cancel')}
                                    </Button>
                                </DialogActions>
                                </>
                            )}
                        />
                </Dialog>        

            </SimpleForm>
        </Create>
)};

export default BusquedaCampos;
