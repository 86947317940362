import React from 'react';
import { useState , useEffect } from 'react';
import {
    Datagrid,
    List,
    TextField,
    EditButton,
    useTranslate,
    Pagination,
    SaveButton,
    FormWithRedirect,
    Error, 
    Loading, 
    Query,
    useDataProvider,
    TextInput,
    BooleanInput,
    useRefresh,
    DeleteButton,
    SelectInput,
    required,
    AutocompleteArrayInput
} from 'react-admin';
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import '../Libs/ExternalViewer/Resources/Styles/digitalizarStyles.css'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Dialog, DialogContent,Card,CardContent,Typography } from '@mui/material';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FolderIcon from '@material-ui/icons/Folder';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import '../Libs/ExternalViewer/Resources/Styles/predefinidasStyle.css'
import VisibilityIcon from '@material-ui/icons/Visibility';
import swal from 'sweetalert';
import { ComponentPAY } from '../Components/ComponentPay'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircleOutline';
import Container from '@mui/material/Container';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import introJs from 'intro.js';
import 'intro.js/introjs.css';

const useStyles = makeStyles({
    oculto : {
        visibility : "hidden"
    },
    nodo : {
        padding: '6px',
        border: '1px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        width: 'max-content'
    },
    nodoSeleccionado :  {
        padding: '6px',
        border: '1px solid black',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        backgroundColor: "#319b4257",
        color: '#277233'
    },
    noScroll: {
        "& .MuiDialog-paper": {
          overflow: "hidden",
        }
    },
    card: {
        width: '600px', 
        height: '300px', 
        margin: 'auto',
        marginTop: '50px',
    }
});

const ListPagination = props => <Pagination rowsPerPageOptions={[5,10, 25, 50, 100]} {...props} />;

const BusquedaPredefinida = ({ width,...props }) => {

    const [datanodosAbiertos, setDatanodosAbiertos] = useState([]);
    const [randomUpdate, setRandomUpdate] = useState(null);
    const [nodoPadreElemento, setnodoPadreElemento] = useState(null); // Almacena el nodo actual seleccionado  
    const [dataCampos, setDataCampos] = useState([]); // Almacena todos los campos agregados a la consulta
    const [campos,setCampos] = useState([]) // Almacena los campos seleccionados dentro de un nodo
    
    const [nombres, setNombres] = useState([]); // Nombres de nodos seleccionados
    const [dataIndices, setDataIndices] = useState([]); // Almacena los campos del nodo al hacer petición
    const [checkCampoComun,setCheckCampoComun] = useState (false)
    const [openValorFijo,setOpenValorFijo] = useState(false)
    const [campoValor,setCampoValor] = useState({})
    const [valor,setValor] = useState('')
    const [operador,setOperador] = useState('')
    const [valorInicio,setValorInicio] = useState('')
    const [valorFin,setValorFin] = useState('')
    
    //Variables para control de Dialogs
    const [openNombre, setOpenNombre] = useState(false);
    const [openNodos,setOpenNodos] = useState (false);
    const [dataUsers, setDataUsers] = useState(null);
    const [dataGrupos, setDataGrupos] = useState(null);

    const translate = useTranslate();
    const dataProvider=useDataProvider();
    const refresh = useRefresh();
    const classes = useStyles();
    const inTour = JSON.parse(localStorage.getItem('activeTour'))

    //Opciones para la creación de búsquedas
    const choicesOperator = [
        {idOperador:'=',operador:'Igual'},
        {idOperador:'>=',operador:'Mayor/Igual'},
        {idOperador:'<=',operador:'Menor/Igual'},
        {idOperador:'>= <=',operador:'Mayor/Igual y Menor/Igual'}
    ]

    const permisos = props.permissions ? props.permissions.ConsultaPredefinida : null; 
    const checkPermiso = (permiso) => {
        if(permisos){
            return permisos[permiso];
        }
        return false;
    }

    const getUsersAndGroups = () =>{
        if(props.permissions.Usuarios.AccesoaModuloUsuarios && props.permissions.Grupos.AccesoaModuloGrupos){
            if(dataUsers == null || dataGrupos == null){
                dataProvider.getList('usuarios', { filter:{} })
                .then(({ data }) => {
                    if(dataUsers == null){
                        setDataUsers(data);
                    }
                        dataProvider.getList('grupos', { filter:{} })
                        .then(({ data }) => {          
                            if(dataGrupos == null){
                                setDataGrupos(data);
                            }  
                        }).catch(error=>{
                        })
                })
                .catch(error => {
                });
            }
        }
    }

    useEffect((getUsersAndGroups),[])

    let introHelp;
    introHelp = introJs('#root');
    introHelp.setOptions({
        disableInteraction: false,
        doneLabel: translate('traducciones.introBotones.doneLabel'),
        nextLabel: translate('traducciones.introBotones.nextLabel'),
        prevLabel: translate('traducciones.introBotones.prevLabel'),
        tooltipPosition:'top',
        steps: [
                {
                intro: translate('traducciones.introHelp.intro1'),
                title: translate('traducciones.introHelp.title1'),
                element: '#help'
                },
        ]                
    });

    const showHelp = () =>{
        const offPermisos = {
            querys: false,
            carga: false,
            admin: false,
            estructura: false,
        }
        if(document.cookie == 'introjs-dontShowAgain=true'){
            document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
                setTimeout(()=>{
                    introHelp.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).onexit(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).start();                         
                },500)
        }
    }

    function crearCookie(nombre, valor, diasParaExpirar) {
    var fechaExpiracion = new Date();
    fechaExpiracion.setDate(fechaExpiracion.getDate() + diasParaExpirar);
    var cookie = nombre + '=' + valor + '; expires=' + fechaExpiracion.toUTCString() + '; path=/';
    document.cookie = cookie;
    }
    
    let introPredefinidas = introJs()
    useEffect(() => {
        if(inTour.querys){
            setTimeout(()=>{
                const showDelete = props.permissions.ConsultaPredefinida ? props.permissions.ConsultaPredefinida.EliminarConsultaPredefinida : null;
                const noQuerys = document.querySelectorAll('#play')
                if(noQuerys.length > 0){
                    if(showDelete){
                        introPredefinidas = introJs('#search-box');
                        introPredefinidas.setOptions({
                        disableInteraction: false,
                        doneLabel: translate('traducciones.introBotones.doneLabel'),
                        nextLabel: translate('traducciones.introBotones.nextLabel'),
                        prevLabel: translate('traducciones.introBotones.prevLabel'),
                        tooltipPosition:'top',
                        steps: [
                            {
                                intro: translate('traducciones.introPredefinidas.cintro1'),
                                title: translate('traducciones.introPredefinidas.ctitle1'),
                                element: '#container'
                            },
                            {
                                intro: translate('traducciones.introPredefinidas.cintro2'),
                                title: translate('traducciones.introPredefinidas.ctitle2'),
                                element: '#NameQuery'
                            },
                            {
                                intro: translate('traducciones.introPredefinidas.cintro3'),
                                title: translate('traducciones.introPredefinidas.ctitle3'),
                                element: '#delete'
                            },
                            {
                                intro: translate('traducciones.introPredefinidas.cintro4'),
                                title: translate('traducciones.introPredefinidas.ctitle4'),
                                element: '#play'
                            },
                        ]                                      
                      });        
                    }else{
                        introPredefinidas = introJs('#search-box');
                        introPredefinidas.setOptions({
                        disableInteraction: false,
                        doneLabel: translate('traducciones.introBotones.doneLabel'),
                        nextLabel: translate('traducciones.introBotones.nextLabel'),
                        prevLabel: translate('traducciones.introBotones.prevLabel'),
                        tooltipPosition:'top',
                        steps: [
                            {
                                intro: translate('traducciones.introPredefinidas.cintro1'),
                                title: translate('traducciones.introPredefinidas.ctitle1'),
                                element: '#container'
                            },
                            {
                                intro: translate('traducciones.introPredefinidas.cintro2'),
                                title: translate('traducciones.introPredefinidas.ctitle2'),
                                element: '#NameQuery'
                            },
                            {
                                intro: translate('traducciones.introPredefinidas.cintro4'),
                                title: translate('traducciones.introPredefinidas.ctitle4'),
                                element: '#play'
                            },
                        ]                                      
                      });
                    }
                }else{
                    introPredefinidas = introJs('#search-box');
                    introPredefinidas.setOptions({
                        disableInteraction: false,
                        doneLabel: translate('traducciones.introBotones.doneLabel'),
                        nextLabel: translate('traducciones.introBotones.nextLabel'),
                        prevLabel: translate('traducciones.introBotones.prevLabel'),
                        tooltipPosition:'top',
                        steps: [
                            {
                                intro: translate('traducciones.introPredefinidas.sintro1'),
                                title: translate('traducciones.introPredefinidas.stitle1'),
                                element: '#container'
                            },
                            {
                                intro: translate('traducciones.introPredefinidas.sintro2'),
                                title: translate('traducciones.introPredefinidas.stitle2'),
                                element: '.button-38'
                            },
                        ]                    
                      });
                }
        
                introPredefinidas.setOption("dontShowAgain", true).oncomplete(()=>{
                    showHelp()
                }).onexit(()=>{showHelp()}).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).start();
            },1000)
        }
      }, []);

    //Datos para agregar un campo de fecha de creación
    const fechaCreacion={
        IdIndice: 1,
        IdNodo: nodoPadreElemento ? nodoPadreElemento.RutaNodo : '/0/',
        NombreIndice: 'Fecha de creación',
        Tipo: 4,
        Formato: null,
        Mascara: null,
        Minimo: 0,
        Maximo: 0,
        IdTabla: 0,
        Longitud: 0,
        Requerido: false,
        Multiple: false,
        Unico: false,
        Verificar: false,
        Repetido: false,
        IdDiccionario: 0,
        Biometrico: 0,
        IdModel: 0,
        ValorLlave: null,
        ValoresTabla: null,
        Notificar: false,
        fechaCreacion:true
    }
    
    function dibujaNodosHijos(nodo,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto){
        if(nodo.hijos != undefined){
            return (
                <div>
                    {nodo.hijos.map(
                    (item) => {
                        checknodoabierto(item);
                        return (
                        <div  style={{ marginLeft: '40px', marginTop : '-5px', width: 'max-content'}}>
                        <div style={{ float: 'left' }}>
                            <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                        </div>
                        <div className={ nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > <FolderIcon style={{ marginRight: '5px'}} />
                            { item.TextoNodo }
                            <Button title={ translate('traducciones.titulos.selectNodo')} onClick={() => addNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                <VisibilityIcon style={{ fontSize: '16px'}}  />
                            </Button>
                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                <ExpandLessIcon />
                            </Button>
                            }
                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                <ExpandMoreIcon />
                            </Button>
                            }
                        </div>
                        { !item.colapsado &&
                        <div>
                            { dibujaNodosHijos(item,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                        </div>
                        }
                    </div>
                    )})
                    }
                </div>
            )
        }
    }

    const colapsar = (nodo) => {
        setRandomUpdate(Math.random());
        if(nodo.colapsado){
            datanodosAbiertos.push(nodo.RutaNodo)
            setDatanodosAbiertos(datanodosAbiertos);
        }else{
            setDatanodosAbiertos(datanodosAbiertos.filter(function(nodoguardado) { 
                return nodoguardado !== nodo.RutaNodo;
            }));
        }
        nodo.colapsado = !nodo.colapsado;
    }

    const checknodoabierto = (nodo) => {
        if(datanodosAbiertos.includes(nodo.RutaNodo)){
            nodo.colapsado = false;
        }
    }
    
    // Restablece los valores para abrir el modal para la creación de una nueva Búsqueda
    const handleOpenCreate = () =>{
        introPredefinidas.exit();
        setOpenNombre(true)
        setTimeout(()=>{
            if(inTour.querys){
                const introCreacion = introJs('.MuiDialog-container');
                introCreacion.setOptions({
                    disableInteraction: false,
                    doneLabel: translate('traducciones.introBotones.doneLabel'),
                    nextLabel: translate('traducciones.introBotones.nextLabel'),
                    prevLabel: translate('traducciones.introBotones.prevLabel'),
                    steps: [
                        {
                            element: '#dialogCreate',
                            intro: translate('traducciones.introCreate.intro1'),
                            title: translate('traducciones.introCreate.title1'),
                            position: 'left',
                            scrollTo: 'off'
                        },
                        {
                            element: '#NombreQuery',
                            intro: translate('traducciones.introCreate.intro2'),
                            title: translate('traducciones.introCreate.title2'),
                        },
                        {
                            element: '#operador',
                            intro: translate('traducciones.introCreate.intro3'),
                            title: translate('traducciones.introCreate.title3'),
                        },
                        {
                            element: '#CheckInterseccion',
                            intro: translate('traducciones.introCreate.intro4'),
                            title: translate('traducciones.introCreate.title4'),
                            position: 'left',
                            scrollTo: 'off'
                        },
                        {
                            element: '#CheckComun',
                            intro: translate('traducciones.introCreate.intro5'),
                            title: translate('traducciones.introCreate.title5'),
                        },
                        {
                            element: '#camposSeleccionados',
                            intro: translate('traducciones.introCreate.intro6'),
                            title: translate('traducciones.introCreate.title6'),
                        },
                        {
                            element: '#btnAddCampo',
                            intro: translate('traducciones.introCreate.intro7'),
                            title: translate('traducciones.introCreate.title7'),
                            disableInteraction: true
                        },
                        {
                            element: '#permisosQuery',
                            intro: translate('traducciones.introCreate.intro8'),
                            title: translate('traducciones.introCreate.title8'),
                        },
                        {
                            element: '#btnSave',
                            intro: translate('traducciones.introCreate.intro9'),
                            title: translate('traducciones.introCreate.title9'),
                            scrollTo: 'off'
                        }                        
                    ]                    
                })
                introCreacion.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')
                ).start()
            }
        },1000)
    }

    const handleOpenNodos = () =>{
        setCampos([])
        setDataIndices([])
        setnodoPadreElemento(null)
        setOpenNodos(true)
        
        //IntroAddCampos
        setTimeout(()=>{
            if(inTour.querys){
                const introCreacion = introJs('#dialogAddCampos');
                introCreacion.setOptions({
                    disableInteraction: true,
                    doneLabel: translate('traducciones.introBotones.doneLabel'),
                    nextLabel: translate('traducciones.introBotones.nextLabel'),
                    prevLabel: translate('traducciones.introBotones.prevLabel'),
                    steps: [
                        {
                            // element: '#dialogCreate',
                            intro: translate('traducciones.introhandleOpen.intro1'),
                            title: translate('traducciones.introhandleOpen.title1'),
                            position: 'left',
                            scrollTo: 'off'
                        },
                        {
                            element: '#nodo',
                            intro: translate('traducciones.introhandleOpen.intro2'),
                            title: translate('traducciones.introhandleOpen.title2'),
                            scrollTo: 'off'
                        },
                        {
                            element: '#verCampos',
                            intro: translate('traducciones.introhandleOpen.intro3'),
                            title: translate('traducciones.introhandleOpen.title3'),
                            scrollTo: 'off'
                        },
                        {
                            element: '#back',
                            intro: translate('traducciones.introhandleOpen.intro4'),
                            title: translate('traducciones.introhandleOpen.title4'),
                            scrollTo: 'off'
                        },
                    ]                    
                })
                introCreacion.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')
                ).start()
            }
        },1000)
    }

    // Hace la petición de los campos del nodo seleccionado para almacenarlos y mostrarlos.
    const viewNode = (nodoPadre) => {
        setCampos([])
        dataProvider.getList('indices', { IdNode: nodoPadre != null ? nodoPadre.RutaNodo : "/"})
            .then(({ data }) => {
                setnodoPadreElemento(nodoPadre);
                setDataIndices(data);
                // setTimeout(()=>{
                //     let tour = localStorage.getItem('inTourQuerys');
                //     if(tour == 'true'){
                //         const introCreacion = introJs('#dialogAddCampos');
                //         introCreacion.setOptions({
                //             disableInteraction: false,
                //             doneLabel: translate('traducciones.introBotones.doneLabel'),
                //             nextLabel: translate('traducciones.introBotones.nextLabel'),
                //             prevLabel: translate('traducciones.introBotones.prevLabel'),
                //             steps: [
                //                 {
                //                     element: '#listCampos',
                //                     intro: translate('traducciones.introCreate.intro1'),
                //                     title: translate('traducciones.introCreate.title1'),
                //                     position: 'left',
                //                     disableInteraction: true,
                //                     scrollTo: 'off'
                //                 },
                //                 {
                //                     element: '#btnAddCampos',
                //                     intro: translate('traducciones.introCreate.intro2'),
                //                     title: translate('traducciones.introCreate.title2'),
                //                     scrollTo:'off'
                //                 },
                //                 {
                //                     element: '#back',
                //                     intro: translate('traducciones.introCreate.intro2'),
                //                     title: translate('traducciones.introCreate.title2'),
                //                     scrollTo:'off'
                //                 },
                //             ]                    
                //         })
                //         introCreacion.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')
                //         ).start()
                //     }
                // },1000)
            })
            .catch(error => {
        });   
    }

    // Función que se ejecuta al evento OnChange de los campos mostrados para irlos almacenando
    // en un estado para una vez seleccionados los campos deseados puedan ser agregados a la búsqueda
    const handleChangueCampos = (e,campo) =>{
        if (e.target.checked) {
            if(campos.length>0){
                const existe = campos.some(item => item.IdIndice === campo.IdIndice)
                if (!existe) {
                setCampos((prevState) => [...prevState, campo]);
                }
            }else{
                setCampos((prevState) => [...prevState, campo]);
            }
        } else {
            const filtrada = campos.filter((n) => n.IdIndice !== campo.IdIndice);
            setCampos(filtrada);
        }
    }

    // Función que valida que el campo no haya sido agregado antes y almacena los campos seleccionados.
    const addCampos = () =>{
        if(campos.length<=0){
            swal({
                icon:'info',
                text:'No hay campos seleccionados'
            })
        }else{
            swal({
                text:'Se agregaran '+campos.length+' campos, esta seguro?',
                buttons:{
                    cancel: {
                    text: translate('traducciones.campos.cancelar'),
                    value: false,
                    visible: true,
                    className: "bg-danger text-white",
                    closeModal: true,
                    },
                    confirm: {
                    text: 'Confirmar',
                    value: true,
                    visible: true,
                    className: "bg-success text-white",
                    closeModal: true
                    }
                }
            }).then((res)=>{
                if(res){
                    campos.map((c)=>{
                        const existe = dataCampos.some(item => item.IdIndice === c.IdIndice && item.IdNodo === c.IdNodo)
                        if(existe){
                            swal({
                                icon:'info',
                                text:'Campo ya agregado'
                            })
                        }else{
                            c.NombreNodo=nodoPadreElemento.TextoNodo
                            setDataCampos((prevState)=>[...prevState,c])
                            swal({
                                text:'Se han agregado los campos',
                                icon:'success'
                            })
                        }
                    })
                    if(!nombres.includes(nodoPadreElemento.TextoNodo)){
                        setNombres((prevState)=>[...prevState,nodoPadreElemento.TextoNodo])
                    }
                setCampos([])
                setDataIndices([])
                setnodoPadreElemento(null)
                }   
            })
        }
    }

    // Función que crea los detalles de la consulta para mostrarlos al usuario y mostrarlos en los detalles.
    const NextStep = () =>{
        if(dataCampos.length>0){
            setCheckCampoComun(false)
            setOpenNombre(true)
        }else{
            swal({
                icon:'info',
                text:'Seleccione al menos un campo para continuar'
            })
        }
    }

    //Función que abre el Dialog para agregar detalles especificos a un campo si se necesita
    const verCampo = (campo) =>{
        setCampoValor(campo)
        if(campo.fijo){
            setValor(campo.fijo)
        }else{
            setValor('')
        }
        if(campo.operador){
            setOperador(campo.operador)
        }else{
            setOperador(0)
        }
        campo.inicio ? setValorInicio(campo.inicio) : setValorInicio('')
        campo.fin ? setValorFin(campo.fin) : setValorFin('')
        setOpenValorFijo(true)
        
        setTimeout(()=>{
            if(inTour.querys){
                const introCreacion = introJs('#dialogValorFijo');
                introCreacion.setOptions({
                    disableInteraction: true,
                    doneLabel: translate('traducciones.introBotones.doneLabel'),
                    nextLabel: translate('traducciones.introBotones.nextLabel'),
                    prevLabel: translate('traducciones.introBotones.prevLabel'),
                    steps: [
                        {
                            element: '#detallesCampo',
                            intro: translate('traducciones.introValorFijo.intro1'),
                            title: translate('traducciones.introValorFijo.title1'),
                            scrollTo: 'off'
                        },
                        {
                            element: '#operadorCampo',
                            intro: translate('traducciones.introValorFijo.intro2'),
                            title: translate('traducciones.introValorFijo.title2'),
                            scrollTo: 'off'
                        },
                        {
                            element: '#inputValor',
                            intro: translate('traducciones.introValorFijo.intro3'),
                            title: translate('traducciones.introValorFijo.title3'),
                            scrollTo: 'off'
                        },
                        {
                            element: '#btnDeleteCampo',
                            intro: translate('traducciones.introValorFijo.intro4'),
                            title: translate('traducciones.introValorFijo.title4'),
                            scrollTo: 'off'
                        },
                        {
                            element: '#btnSaveCampo',
                            intro: translate('traducciones.introValorFijo.intro5'),
                            title: translate('traducciones.introValorFijo.title5'),
                            scrollTo: 'off'
                        },                        
                    ]                    
                })
                introCreacion.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')
                ).start()
            }
        },1000)
    }

    //Función que se encarga de almacenar el valor fijo asignado a un campo especifico
    const handleChangeValor = (e) =>{
        if(e.target == undefined){
            setValor('')
        }else{
            setValor(e.target.value)
        }
    }

    const handleChangeInicio = (e) =>{
        setValorInicio(e.target.value)
    }

    const handleChangeFin = (e) =>{
        setValorFin(e.target.value)
    }

    //Función que se encarga de almacenar el operador asignado a un campo especifico
    const handleChangeOperator = (e) =>{
        setOperador(e.target.value)
    }

    //Función que se encarga de guardar los cambios hechos a un campo especifico
    const handleAddValor = () =>{
        if(valor.trim() == ''){
            delete campoValor.fijo
        }else{
            campoValor.fijo = valor;
        }

        if(operador == 0){
            delete campoValor.operador
        }else if(operador != '>= <='){
            campoValor.operador = operador
        }else{
            campoValor.operador = operador
            
            if(valorInicio.trim() == ''){
                delete campoValor.inicio
            }else{
                campoValor.inicio=valorInicio
            }

            if(valorFin.trim() == ''){
                delete campoValor.fin
            }else{
                campoValor.fin=valorFin
            }
        }
        setOpenValorFijo(false)
    }

    //Función que se encarga de eliminar un campo especifico
    const handleDelete = () =>{
        swal({
            text:'¿Seguro que desea quitar el campo '+campoValor.NombreIndice+ '?',
            buttons:{
                cancel: {
                  text: translate('traducciones.campos.cancelar'),
                  value: false,
                  visible: true,
                  className: "bg-danger text-white",
                  closeModal: true,
                },
                confirm: {
                  text: 'Aceptar',
                  value: true,
                  visible: true,
                  className: "bg-success text-white",
                  closeModal: true
                }
              }
        }).then(res=>{
            if(res){
                const newArray = dataCampos.filter(element => element != campoValor)
                setDataCampos(newArray)
                swal({
                    icon:'success',
                    text:'Cambios guardados',
                    buttons: false,
                    timer:700
                })
                setOpenValorFijo(false)
            }
        })   
    }
    
    // Función que valida los detalles de la consulta, crea la cadena logica y hace la petición al servicio para agregar la búsqueda
    const handleSubmit = (values) =>{
        let fields = [];
        let count=1;
        
        if(dataCampos.length > 0){
                if(values.NombreQuery.trim() != ''){
                    let cadena = '';
                    const operator = values.operador;
    
                    dataCampos.map((d)=>{
                        if(d.Tipo==4){
                            d.inicio ? d.inicio = d.inicio.replace(/-/g, "") : d.inicio = d.inicio 
                            d.fin ? d.fin = d.fin.replace(/-/g, "") : d.fin = d.fin
                            d.fijo ? d.fijo = d.fijo.replace(/-/g, "") : d.fijo = d.fijo
                        }
                        if(d.operador){
                            if(d.operador != '>= <='){
                                if(d.fechaCreacion){
                                    if(d.fijo){
                                        cadena = cadena + d.IdNodo + d.operador + ',' + 'Date' + count + ',' + d.fijo.trim() + '|'
                                        count++
                                    }else{
                                        cadena = cadena + d.IdNodo + d.operador + ',' + 'Date' + count + '|'
                                        count++
                                    }
                                }else{
                                    if(d.fijo){
                                        cadena = cadena + d.IdNodo + d.operador + ',' + d.IdIndice + ',' + d.fijo.trim()  + '|'
                                    }else{
                                        cadena = cadena + d.IdNodo + d.operador + ',' + d.IdIndice + '|'
                                    }
                                }
                                fields.push(d)
                            }else{
                                if(d.fechaCreacion){
                                    if(d.inicio){
                                        cadena = cadena + d.IdNodo + '>=,' + 'Date' + count + ',' + d.inicio + '|' 
                                        count++
                                    }else{
                                        cadena = cadena + d.IdNodo + '>=,' + 'Date' + count + '|' 
                                        count++
                                    }
                                    if(d.fin){
                                        cadena = cadena + d.IdNodo + '<=,' + 'Date' + count + ',' + d.fin + '|'
                                        count++
                                    }else{
                                        cadena = cadena + d.IdNodo + '<=,' + 'Date' + count + '|'
                                        count++
                                    }
                                }else{
                                    if(d.inicio){
                                        cadena = cadena + d.IdNodo + '>=,' + d.IdIndice + ',' + d.inicio + '|'
                                    }else{
                                        cadena = cadena + d.IdNodo + '>=,' + d.IdIndice + '|'
                                    }
                                    if(d.fin){
                                        cadena = cadena + d.IdNodo + '<=,' + d.IdIndice + ',' + d.fin + '|'
                                    }else{
                                        cadena = cadena + d.IdNodo + '<=,' + d.IdIndice + '|'
                                    }
                                }
                                fields.push(d)
                            }
                        }else if (operator != '>= <='){
                                if(d.fechaCreacion){
                                    if(d.fijo){
                                        cadena = cadena + d.IdNodo + operator + ',' + 'Date' + count + ',' + d.fijo.trim() + '|'
                                    }else{
                                        cadena = cadena + d.IdNodo + operator + ',' + 'Date' + count + '|'
                                        count++
                                    }
                                }else{
                                    if(d.fijo){
                                        cadena = cadena + d.IdNodo + operator + ',' + d.IdIndice + ',' + d.fijo.trim()  + '|'
                                    }else{
                                        cadena = cadena + d.IdNodo + operator + ',' + d.IdIndice + '|'
                                    }
                                }
                                fields.push(d)
                        }else{
                                if(d.fechaCreacion){
                                    cadena = cadena + d.IdNodo + '>=,' + 'Date'+ count + '|' 
                                    count++
                                    cadena = cadena + d.IdNodo + '<=,' + 'Date'+ count + '|'
                                    count++
                                }else{
                                    cadena = cadena + d.IdNodo + '>=,' + d.IdIndice + '|' + d.IdNodo + '<=,' + d.IdIndice + '|'
                                }
                                fields.push(d)
                        }
                    })
                        cadena = cadena.slice(0,-1)
    
                        let dataQuery={
                            IdQuery: 1,
                            NombreBusqueda: values.NombreQuery.trim(),
                            CadenaLogica: cadena,
                            Conector: values.CheckInterseccion,
                            CampoComun: values.CheckComun,
                            NombreCampoComun: values.CheckComun ? (values.NombreComun ? values.NombreComun : '' ) : '' ,
                            FieldList: fields,
                            IdUsuarios: values.usuarios ? values.usuarios : [],
                            IdGrupos: values.grupos ? values.grupos : []
                        }
                        // console.log(dataQuery)
                        dataProvider.create('predefinida',{ data:dataQuery })
                        .then(({ data }) => {
                            setOpenNodos(false)
                            setOpenNombre(false)
                            refresh();
                            swal({
                                text:'Nueva consulta creada',
                                timer: 1200,
                                icon: 'success'
                            })
                        }).catch((error)=>{
                            swal({
                                text: error.message,
                                timer: 1200,
                                icon: 'error'
                            })
                        })
            }else{
                swal({
                    text:'Debe ingresar el nombre de la consulta',
                    icon:'info'
                })
            }
        }else{
            swal({
                text: 'Debe agregar al menos un campo a la consulta',
                icon:'info'
            })
        }
    }

    const handleToBack = () =>{
        setOpenNodos(!openNodos)

        //IntroViewCampos
        if(dataCampos.length > 0){
            setTimeout(()=>{
                if(inTour.querys){
                    const introCreacion = introJs('.MuiDialog-container');
                    introCreacion.setOptions({
                        disableInteraction: true,
                        doneLabel: translate('traducciones.introBotones.doneLabel'),
                        nextLabel: translate('traducciones.introBotones.nextLabel'),
                        prevLabel: translate('traducciones.introBotones.prevLabel'),
                        steps: [
                            {
                                element: '#campo',
                                intro: translate('traducciones.introHandleToBack.intro1'),
                                title: translate('traducciones.introHandleToBack.title1'),
                                scrollTo: 'off'
                            }                        
                        ]                    
                    })
                    introCreacion.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')
                    ).start()
                }
            },1000)
        }
    }

    return(
        <>
            {checkPermiso('AgregarConsultaPredefinida') && props.permissions.Usuarios.AccesoaModuloUsuarios && props.permissions.Grupos.AccesoaModuloGrupos &&(
            <div>
            <button className="button-38" style={{width:'15em'}} onClick={handleOpenCreate}>
                <PlaylistAddIcon style={{marginRight:'5px'}}/>
                <span>{translate('traducciones.botones.crearPre')}</span>
            </button>            
            </div>
            )}
            {/* <SimpleForm save={handleValues}>
                 <DateInput style={{ width: "100%" }} name="campo" resettable={true} label="Campo" value="" defaultValue="2023-07-20" />   
            </SimpleForm> */}

            {/*
            <Container  maxWidth="md" sx={{ mb: 3 }}>
                <ComponentPAY />
            </Container>
             */}
        <div style={{marginTop:'10px'}} id='container'>
        <div id='search-box'></div>
        <List
                {...props}
                title={translate('traducciones.titulos.busquedaPredefinida')}
                registro={ props.record} 
                bulkActionButtons={false}            
                actions = {null}
                pagination={<ListPagination />} >
                   
                    <Datagrid isRowSelectable={record => record.id < 1} >
                        <TextField source="IdQuery" label= "traducciones.campos.idQuery" />
                        <TextField source="NombreBusqueda" id='NameQuery' label= "traducciones.campos.nombreQuery"/>
                        <EditButton label="" icon={ <PlayArrowIcon /> } className="actionButton" id='play' title= { translate("traducciones.titulos.ejecutarConsulta") } />
                        {checkPermiso('EliminarConsultaPredefinida') && <DeleteButton label="" className="actionButton" undoable={false} id='delete' confirmTitle={translate('Eliminar consulta')} confirmContent={translate('¿Esta seguro que desea eliminar la consulta?')}/>}
                    </Datagrid>                
        </List>
            </div>
            
        <Dialog id='dialogAddCampos' fullWidth open={openNodos} className={classes.noScroll} {...props} >
                    <DialogTitle>{translate('traducciones.titulos.seleccionNodos')}</DialogTitle>
                        <FormWithRedirect
                            resource="catalogos"
                            save={NextStep}
                            render={({
                                handleSubmitWithRedirect,
                                pristine,
                                saving
                            }) => (
                                <>
                                <DialogContent>
                                <div id='contenido' className="row">
                                    <div className="col-md-12 col-md-custom"  style={{ overflowX: "auto", padding: "10px"}}>
                                        <span style={{fontSize:'15px'}}>{translate('traducciones.modal.seleccionNodo')}: </span>
                                        <span id='noCampos' style={{float:'right',fontSize:'15px'}}>Campos agregados: <b>{dataCampos.length}</b> </span>
                                        <Query type="getList" resource="nodos">
                                            {({ data, loading, error }) => {
                                                if (loading) { return <Loading />; }
                                                if (error) { return <Error />; }
                                                return (
                                                    <div  className="row">
                                                        <div  className="col-md-7 col-md-custom"  style={{ overflowX: "auto", padding: "10px", width:'40%'}}>
                                                        {data.map(
                                                            (item) => {
                                                            checknodoabierto(item);
                                                            return (
                                                                <div>
                                                                    <div id='nodo' className= { nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > 
                                                                        <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                                                        { item.TextoNodo } 
                                                                        <Button id='verCampos' title={ translate('traducciones.titulos.selectNodo')}  onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                                                            <VisibilityIcon style={{ fontSize: '16px'}}/>
                                                                        </Button>
                                                                        { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                                                        <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                                            <ExpandLessIcon />
                                                                        </Button>
                                                                        }
                                                                        { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                                                        <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                                            <ExpandMoreIcon />
                                                                        </Button>
                                                                        }
                                                                    </div>
                                                                    { !item.colapsado &&
                                                                    <div style={{ marginLeft: '-20px'}}>
                                                                        { dibujaNodosHijos(item,classes,viewNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                                                                    </div>
                                                                    }
                                                                </div>
                                                            )})
                                                        }
                                                        </div>
                                                        { nodoPadreElemento != null ? (
                                                            <div className="col-md-4 col-md-custom">
                                                                <div id='listCampos' style={{maxWidth:'fit-content'}}>
                                                                <b><span>Campos de {nodoPadreElemento.TextoNodo}</span></b>
                                                                {dataIndices.length>0 ? 
                                                                    dataIndices.map(item=>{
                                                                    return(
                                                                        <div className="checkbox-wrapper-16">
                                                                            <label className="checkbox-wrapper">
                                                                                <input
                                                                                type="checkbox"
                                                                                className="checkbox-input"
                                                                                onChange={(e)=>handleChangueCampos(e,item)}
                                                                                defaultValue={false}
                                                                                />
                                                                                <span className="checkbox-tile">
                                                                                <span className="checkbox-icon"></span>
                                                                                    <span className="checkbox-label">{item.NombreIndice}</span>
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                }):(
                                                                    <div style={{fontSize:'15px', marginTop:'10px'}}>
                                                                        <span>No se encontraron campos</span>
                                                                    </div>
                                                                )}
                                                                {dataIndices.length >0 ?(
                                                                    <div className="checkbox-wrapper-16">
                                                                        <label className="checkbox-wrapper">
                                                                            <input
                                                                            type="checkbox"
                                                                            className="checkbox-input"
                                                                            onChange={(e)=>handleChangueCampos(e,fechaCreacion)}
                                                                            defaultValue={false}
                                                                            />
                                                                            <span className="checkbox-tile">
                                                                            <span className="checkbox-icon"></span>
                                                                                <span className="checkbox-label">Fecha creación</span>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                ):null}
                                                                </div>
                                                            </div>
                                                        ) : null }
                                                        {dataIndices.length >0 ? ( 
                                                        <div id='btnAddCampos' className="col-md-2 col-md-custom button-38" style={{height:'52px', marginTop:'20px'}} onClick={addCampos}>
                                                            <span>Agregar campo(s)</span>
                                                        </div>
                                                        ):(null)}
                                                    </div>
                                                );
                                            }}
                                        </Query>
                                    </div>
                                </div>
                                </DialogContent>
                                <DialogActions>
                                    {/* <SaveButton
                                        label="Continuar"
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                    /> */}
                                    <Button id='back' onClick={handleToBack} >
                                        { translate('ra.action.back')}
                                    </Button>
                                </DialogActions>
                                </>
                            )}
                        />
                </Dialog>
                
                <Dialog id='dialogCreate' fullWidth open={openNombre}>
                    <DialogTitle>Detalles de consulta</DialogTitle>
                        <FormWithRedirect 
                            resource="catalogos"
                            save={handleSubmit}
                            render={({
                                handleSubmitWithRedirect,
                                pristine,
                                saving
                            }) => (
                                <>
                                <DialogContent>                                    
                                  <div className='row' style={{marginTop:'15px', paddingLeft:'10px'}}>
                                    <div className='row'>
                                        <div className='col-md-6' style={{fontSize:'14px'}}>
                                            <span>Asigne un nombre y revise los detalles de la consulta</span>
                                        </div>
                                    </div>
                                    <div>
                                        <TextInput style={{ width: '40%',marginRight:'15px'}} name='NombreQuery' id='NombreQuery' label="Nombre de consulta" validate={required()}/>
                                        <SelectInput style={{marginRight:'15px'}} source="operador" id='operador' choices={choicesOperator} optionText="operador" optionValue="idOperador" validate={required()}/>
                                        <TextInput style={{ width: '20%',marginRight:'15px'}} name='NombreComun' id='NombreComun' label="Nombre del campo común" hidden={!checkCampoComun}/>
                                        <br/>
                                        <BooleanInput style={{display:'inline-flex', marginRight:'-15px'}} name='CheckInterseccion' id='CheckInterseccion' defaultValue={false}/><span style={{fontSize:'14px',marginRight:'20px'}}>Coincidencia en todos los campos seleccionados</span>
                                        <BooleanInput style={{display:'inline-flex', marginRight:'-15px'}} name='CheckComun' id='CheckComun' defaultValue={false} onChange={(e) =>{setCheckCampoComun(e)}}/><span style={{fontSize:'14px',marginRight:'20px'}}>Seleccionar campo comun para la consulta</span>
                                        {/* <hr className='hr-text' data-content={'Campos seleccionados'} style={{marginTop:'-3px'}}/> */}
                                        <hr style={{marginTop:'-3px'}}/>
                                        <div className='row'>
                                            <div className='col-md-10' id='camposSeleccionados'>
                                                <span>Campos seleccionados: </span>
                                                {dataCampos && dataCampos.length>0 ?
                                                    dataCampos.map((d)=>{
                                                        return(
                                                            <button id='campo' onClick={()=>verCampo(d)} class="button-48" role="button"><span className="text"><b>{d.NombreIndice}</b></span></button>
                                                        )
                                                }):<span><b>No hay campos seleccionados todavía</b></span>}
                                            </div>
                                            <div className='col-md-2'>
                                                 <button id='btnAddCampo' style={{color:'green',border:'0px solid', borderRadius:'5px', background:'#EAEAEA', marginTop:'auto', float:'right'}} onClick={handleOpenNodos}>Agregar <AddCircleOutlineIcon/></button>
                                            </div>
                                        </div>
                                        <hr className='hr-text' data-content={'Asignar permisos'}/>
                                        {/* <hr/> */}
                                        <div className='row' id='permisosQuery' style={{justifyContent:'space-between'}}> 
                                        {/* <span>Asignar permisos</span>   */}
                                            <div className='col-md-6'>  
                                            {dataUsers ? (
                                                <AutocompleteArrayInput options= { {fullWidth: true } } label="traducciones.campos.usuario"  optionText="Nombre" optionValue="IdUsuario" source="usuarios" choices= { dataUsers ? dataUsers : null } style={{width:'100%'}}/>
                                                ) : null }
                                            </div>
                                            <div className='col-md-6'>   
                                                {dataGrupos ? (
                                                <AutocompleteArrayInput options= { {fullWidth: true } } label="traducciones.campos.grupo"  optionText="GrupoNombre" optionValue="IdGrupo" source="grupos" choices= { dataGrupos ? dataGrupos : null } style={{width:'100%'}} />
                                                ) : null }
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                </DialogContent>
                                <DialogActions>
                                    <SaveButton
                                        id='btnSave'
                                        label="ra.action.confirm"
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                    />
                                    <Button onClick={()=>{
                                        setOpenNombre(false)
                                        }} >
                                        { translate('ra.action.back')}
                                    </Button>
                                </DialogActions>
                                </>
                            )}
                        />
                </Dialog>
                <Dialog id='dialogValorFijo' maxWidth='sm' open={openValorFijo}>
                    <DialogTitle>Asignar valor fijo</DialogTitle>
                        <FormWithRedirect 
                            resource="catalogos"
                            save={handleAddValor}
                            render={({
                                handleSubmitWithRedirect,
                                pristine,
                                saving
                            }) => (
                                <>
                                <DialogContent>
                                    <div style={{marginTop:'10px',marginLeft:'15px',marginRight:'15px', marginBottom:'10px'}}>
                                        <div id='detallesCampo'>
                                            <span>Campo: <b>{campoValor.NombreIndice}</b></span><br/>
                                            <span>Nodo:<b> {campoValor.NombreNodo} </b></span><br/><br/>    
                                        </div>
                                        <span>Asignar operador:</span>
                                        <select id='operadorCampo' defaultValue={ campoValor.operador ? campoValor.operador : '0'} className='form-control' name='operador' style={{width:'100%', marginBottom:'10px'}} onChange={handleChangeOperator}>
                                            <option value='0'>Seleccione...</option>
                                            <option value='='>Igual</option>
                                            <option value='>='>Mayor/Igual</option>
                                            <option value='<='>Menor/Igual</option>
                                            <option value='>= <='>Mayor/Igual y Menor/Igual</option>
                                        </select>
                                        {operador == '>= <=' || campoValor.operador == '>= <='?
                                        (
                                            <div id='inputValor'>
                                            Valor de inicio:
                                            <input className='form-control' defaultValue={ campoValor.inicio ? campoValor.inicio : '' } onChange={handleChangeInicio} style={{width:'100%',marginTop:'5px',marginBottom:'5px'}} type={campoValor.Tipo == 4 ? 'Date' : campoValor.Tipo == 2 ? 'number' : 'text'} placeholder='Valor inicio'/>
                                            Valor de fin:
                                            <input className='form-control' defaultValue={ campoValor.fin ? campoValor.fin : '' } onChange={handleChangeFin} style={{width:'100%',marginTop:'5px',marginBottom:'15px'}} type={campoValor.Tipo == 4 ? 'Date' : campoValor.Tipo == 2 ? 'number' : 'text'} placeholder='Valor fin'/>
                                            </div>
                                        ):(
                                            <div id='inputValor'>
                                                Valor fijo:
                                                <input className='form-control' defaultValue={ campoValor.fijo ? campoValor.fijo : '' } onChange={handleChangeValor} style={{width:'100%',marginTop:'10px',marginBottom:'10px'}} type={campoValor.Tipo == 4 ? 'Date' : campoValor.Tipo == 2 ? 'number' : 'text'} placeholder='Valor fijo'/>
                                            </div>
                                        )}
                                        <center><span id='btnDeleteCampo' className='form-control' style={{color:'red', cursor:'pointer'}} onClick={handleDelete}><RemoveCircleIcon style={{fontSize:'14px',marginTop:'-2px'}}/> Quitar campo</span></center>
                                    </div>                                 
                                </DialogContent>
                                <DialogActions>
                                    <SaveButton
                                        id='btnSaveCampo'
                                        label="ra.action.save"
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                    />
                                    <Button onClick={()=>{
                                        setOpenValorFijo(false)
                                        }} >
                                        { translate('ra.action.cancel')}
                                    </Button>
                                </DialogActions>
                                </>
                            )}
                        />
                </Dialog> 
        </>
    )
}

export default withWidth()(BusquedaPredefinida);