import * as React from 'react';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useEffect , useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { formatText } from '../helpers/helpers'
import { useDataProvider } from 'react-admin';
import swal from 'sweetalert';

const defaultTheme = createTheme();

export function Review({ data }) {
  const dataProvider = useDataProvider();

  const carpetas = ['Signature','Face','Ocr']
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (data.globalData.order.id) {
      setIsLoading(true)
      dataProvider.create('nodos', { data: {
            IdParentNode: data.globalData.node[0],
            NodeName: data.paymentDetails.metadata.template.IndustryName,
            Fulltext: false
          }}
      ).then((json) => {
        if(json.data.information){
          const nodoPlantilla = json.data.information.find((node) => node.TextoNodo == data.paymentDetails.metadata.template.IndustryName)
          dataProvider.create('plantilla',{data: {IndustryId: data.paymentDetails.metadata.template.IndustryId , ParentNode: nodoPlantilla.RutaNodo}}).then((data)=>{
            swal({
              icon: 'success',
              text: data.data.information ? data.data.information : 'La plantilla se generó correctamente.'
            })
            setIsLoading(false)
          }).catch((error)=>{
              console.log(error);
          })
        }
      }).catch((error) => {
        console.error(error)
        swal({
          icon: "error",
          text: 'Ocurrio un error al crear el nodo'
        });
      })
    }
  }, []);

  let count = 0;

  // function structureClient() {
  //   dataProvider
  //     .create('nodos', {
  //       data: {
  //         IdParentNode: data.globalData.node[0],
  //         NodeName: data.paymentDetails.metadata.node,
  //         Fulltext: false,
  //       },
  //     })
  //     .then((json) => {
  //       console.log(json);
  //       if (json.data.id !== null) {
  //         const ruteNode = json.data.information.find((o) => o.TextoNodo.includes(json.data.NodeName));
  //         console.log(ruteNode);
  //         createNodesRecursive(0, ruteNode);
  //       } else {
  //         showError('Ocurrió un error al crear el nodo');
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       showError('Ocurrió un error al crear el nodo');
  //     });
  // }

// function createNodesRecursive(index, ruteNode) {
//     if (index >= carpetas.length || count > 3) {
//       return;
//     }

//     const e = carpetas[index];
//     dataProvider
//       .create('nodos', {
//         data: {
//           IdParentNode: ruteNode.RutaNodo,
//           NodeName: e,
//           Fulltext: false,
//         },
//       })
//       .then((json) => {
//         if (json.data.id !== null) {
//           const ruteNodeVector = json.data.information.find((o) => o.TextoNodo.includes(json.data.NodeName));
//           console.log(ruteNodeVector);
//           createVectorNode(ruteNodeVector);
//           count += 1;
//         } else {
//           showError('Ocurrió un error al crear el nodo');
//         }
//         createNodesRecursive(index + 1, ruteNode);
//       })
//       .catch((error) => {
//         console.error(error);
//         showError('Ocurrió un error al crear el nodo');
//         createNodesRecursive(index + 1, ruteNode);
//       });
//   }

  // function createVectorNode(ruteNodeVector) {
  //   dataProvider
  //     .create('nodos', {
  //       data: {
  //         IdParentNode: ruteNodeVector.RutaNodo,
  //         NodeName: 'Vector',
  //         Fulltext: false,
  //       },
  //     })
  //     .then((json) => {
  //       console.log(json);
  //       if (json.data.id === null) {
  //         showError('Ocurrió un error al crear el nodo');
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       showError('Ocurrió un error al crear el nodo');
  //     });
  // }

  // function showError(message) {
  //   swal({
  //     icon: 'error',
  //     text: message,
  //   });
  // }

  const formatPriceFinal = (price) => {
    let numeroString = price.toString();
    let longitud = numeroString.length;
    let nuevaCadena = numeroString.slice(0, longitud - 2) + '.' + numeroString.slice(longitud - 2);
    return parseFloat(nuevaCadena);
  }

  if (!data.globalData.order.id) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <Card>
          <CardContent>
            <Typography variant="h6">Loading...</Typography>
          </CardContent>
        </Card>
      </ThemeProvider>
    );
  }

  if(isLoading){
    return(
      <div style={{textAlign:'center'}}>
        <span className='creating'>Creando plantilla, por favor espera un momento...</span>
      </div>
    )
  }
  
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Gracias por tu compra, a continuacion tu resumen:
      </Typography>
      {data.paymentDetails.line_items.map((payment) => (
        <ListItem key={payment.name} sx={{ py: 1, px: 0 }}>
          <ListItemText
            primary={'Producto: '.concat(payment.name)}
            secondary={(formatText(payment.description).slice(0,6))}/>
            <Typography variant="body2">${formatPriceFinal(payment.unit_price)}</Typography>
        </ListItem>
        ))}
    </React.Fragment>
  );
}