import * as React from 'react';
import { useState,useEffect } from 'react';
import { 
    useLogin, 
    useNotify, 
    Notification, 
    defaultTheme, 
    useTranslate,
    SimpleForm,
    SaveButton,
    Button,
    TextInput,
    required,
    useDataProvider,
    Toolbar,
    Loading,
    PasswordInput 
} from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import EmailIcon from '@material-ui/icons/Email';
import "../login.css"
import swal from 'sweetalert';

export const LoginPage = ({ theme }) => {
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [openRecupera, setOpenRecupera] = useState(false);
    const [openCambia, setOpenCambia] = useState(false);
    const login = useLogin();
    const notify = useNotify();
    
    const submit = e => {
        e.preventDefault();
        setLoading(true);
        const logoEmp = localStorage.getItem('logoEmpresa')
        localStorage.clear();
        localStorage.setItem('logoEmpresa',logoEmp)
        login({ user, password }).then((resp) => {
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            if (error.message == 'Suscripción Vencida, para tener acceso debe renovar la suscripción.') {
                swal({
                icon: "warning",
                text: error.message,
                buttons: {
                redirect: {
                    text: "Consultar planes",
                    value: true,
                    className: "my-custom-button-class"
                    },
                    cancel: "Cancelar"

                    },
              }).then((value) => {
                    if (value) {
                        window.location.href = "http://localhost/FuenteLanding8%20.1/";
                    }
                });
            } else {
                swal({
                    icon: "warning",
                    text: error.message
                  });
                error.message == 'Password temporal, renueve su password' || error.message== 'Usuario Nuevo, debe cambiar su password' || error.message == 'Password caducado, renueve su password' ? setOpenCambia(true) : setOpenCambia(false)
            }
        }
        );
    };

    const dataProvider = useDataProvider();

    const apiUrl = window.$parametros_generales.REACT_APP_BASE_URL;

    const getLogo = () => {
        fetch(apiUrl+'Login/getCustomerLogo',{
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json'}),
        }).then(( response ) => response.json())
        .then((data)=>{
            if (data.Information != null){
                const logo = 'data:image/svg+xml;base64,'+data.Information.Base64Logo
                const b64toSvg = atob(logo.split(",")[1]);

                let width = 125; // Ancho deseado en píxeles
                let height = 125; // Alto deseado en píxeles

                let modifiedSvg = b64toSvg.replace(/<svg[^>]*>/, function (match) {
                return match
                    .replace(/width="[^"]*"/, 'width="' + width + 'px"')
                    .replace(/height="[^"]*"/, 'height="' + height + 'px"');
                });

                //Se vuelve a pasar a formato b64
                let encodedSvg = btoa(modifiedSvg);
                localStorage.setItem('logoEmpresa',encodedSvg)
            }else{
                localStorage.setItem('logoEmpresa',null)
            }
        })
        .catch(error => {
            localStorage.setItem('logoEmpresa',null)
        });
    }

    useEffect(()=>{
        getLogo();
    },[])

    const handleRecupera = async values => {
        dataProvider.password('restore', { Email: values.emailRecupera})
        .then((json) => {
            notify(json.data.Message);
            setOpenRecupera(false);
            return;
        })
        .catch(error => {
        }); 
    }

    const handleCambiaPass = async values => {
        if(values.NewPassword !== values.pass2){
            notify("Las contraseñas no coinciden", "error");
            return;
        }
        dataProvider.password('change', { values })
        .then((json) => {
            if(json.data.Success){
                notify(json.data.Message);
                setOpenCambia(false);
            }else{
                notify(json.data.Message,'error',null,true,5000);
                return;
            }
        })
        .catch(error => {
        }); 
    };

    const translate = useTranslate();

    if(loading){
        return <div style={{ marginTop: '10%'}} ><Loading loadingPrimary={translate('traducciones.titulos.ingresando')} /></div>
    }

    const viewLogo = () =>{
        if(localStorage.getItem('logoEmpresa')==null || localStorage.getItem('logoEmpresa')=='null'){
           
        }else{
            return(
                <div style={{display:'contents'}}>
                    <hr style={{width:'5%',rotate:'90deg', border:'1px solid',display:'inline-flex', marginBottom:'3px'}}/>
                    <img src={"data:image/svg+xml;base64,"+localStorage.getItem('logoEmpresa')} width='130vh' style={{marginLeft:'-5px'}} alt=''/>
                </div>
            )
        }
    }

    return (
        <ThemeProvider theme={createTheme(defaultTheme)}>
            <div className="login_div" style={{  background: "url(background.png)", backgroundSize: '100% 100%'}}>
                <div className="row" style={{ background: "#ececee", height: '15vh'}}>
                    <div className="col-md-6 d-flex">
                        <img style={{ height: "13vh", padding: "15px 5px 5px 5px" ,marginRight:'-5px',marginLeft:'-20px'}} src={"7.png"} alt='' />
                        {localStorage.getItem('logoEmpresa')==null || localStorage.getItem('logoEmpresa')=='null' 
                        ? null
                        :(
                        // <div style={{display:'contents'}}>
                        //     <hr style={{width:'5%',rotate:'90deg', border:'1px solid',display:'inline-flex', marginBottom:'3px'}}/>
                        //     <iframe src={"data:image/svg+xml;base64,"+localStorage.getItem('logoEmpresa')} style={{width:'68%',height:'100%',border:'none',overflow:'hidden',float:'right',marginRight:'25px',marginTop:'-10px'}} alt=''/>
                        // </div>
                        <div style={{display:'contents'}}>
                            <hr style={{width:'0%',rotate:'180deg', border:'1px solid',display:'inline-flex', marginBottom:'3px', height:'25%', marginTop:'6%',marginRight:'22px'}}/>
                            <div style={{height:'100%',width:'20%',overflow:'hidden', display:'inline-grid',alignItems:'center', justifyContent:'center', marginTop:'-15px'}}>
                                <iframe src={"data:image/svg+xml;base64,"+localStorage.getItem('logoEmpresa')} style={{width:'90%',height:'100%',border:'none'}} alt=''/>
                            </div>
                        </div>
                        )}
                    </div>
                    
                    {/* <div class="col-md-4" style={{ paddingTop : '6vh', paddingRight: '30px', textAlign: 'right', fontSize: '0.9em !important;' }}>
                        <span style={{ marginRight: '15px', fontWeight: 'bold', color: 'black' }}>{translate('traducciones.login.primera')}</span>
                    </div>
                    <div class="col-md-2" style={{ textAlign: 'center', display: 'flex', alignItems: 'center'}}>
                        <input type='button' className='btn_pass' onClick={e => setOpenCambia(true)} value="Cambiar Contraseña" />
                    </div> */}
                </div>
                <div style={{ height: '70vh' }} >
                    <SimpleForm onSubmit={submit} className="formInicio" toolbar={null}>
                        <div className="row" fullWidth>
                                <div className="col-md-7">
                                    <div className="enunciado">
                                        <div className="enunciado_texto">
                                        "{translate('traducciones.login.mensaje')}"
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5" style={{position: 'relative'}}>
                                    <div className="content_login">
                                        <TextInput
                                            label={translate('traducciones.campos.usuario')}
                                            name="text"
                                            value={user}
                                            style={{width: '100%'}}
                                            onChange={e => setUser(e.target.value)}
                                        />
                                        <PasswordInput
                                            name="password"
                                            label={translate('traducciones.campos.contrasena')}
                                            value={password}
                                            style={{width: '100%'}}
                                            onChange={e => setPassword(e.target.value)}
                                        />
                                        <div style={{textAlign: 'center'}}>
                                            <div className="password_recovery" style={{cursor: "pointer"}}>
                                                <a onClick={e => setOpenRecupera(true)}>{translate('traducciones.login.olvidepass')}</a>
                                            </div>
                                            <input type="submit" value={translate('traducciones.botones.login')} />
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </SimpleForm>
                </div>
                <div style={{ height: '15vh', backgroundColor: "white"}}>
                    <div className="row">
                        <div className="col-md-5" style={{ position: 'relative', height: '15vh'}}>
                            <div className="copyright">
                                © {(new Date().getFullYear())} Maximage Data Systems
                            </div>
                        </div>
                        <div className="col-md-2" style={{ position: 'relative', height: '15vh', textAlign : 'center'}}>
                            <img src='ISO27001.png' style={{ height: "70%", marginTop: "5%"}} />
                        </div>
                        <div className="col-md-5" style={{ position: 'relative', height: '15vh'}}>
                            <div className="copyright" >
                                <a>{translate('traducciones.login.ayuda')}</a>
                                <a href="https://www.maximage-ds.com/contacto" target="_blank" style={{ marginLeft: '30px'}}>{translate('traducciones.login.contactenos')}</a>
                                <span style={{marginLeft:'15px'}}>V. 1 . 4 . 1 . 2 </span>
                            </div>
                        </div>
                    </div>
                </div>
                 
                <Notification />


            </div>
            <div>
                <Dialog open={openRecupera}>
                    <DialogTitle>{ translate('traducciones.titulos.restablecerContraseña')}</DialogTitle>
                    <DialogContent>
                        <SimpleForm save={handleRecupera} noValidate toolbar = { 
                            <Toolbar style={{ justifyContent: "space-between" }}>
                                <SaveButton  label ="traducciones.login.restablecer" icon={<EmailIcon />} /> 
                                <Button label ="ra.action.cancel"  onClick={e => setOpenRecupera(false)} />
                            </Toolbar> } >
                            <TextInput style={{ minWidth: "400px"}} source="emailRecupera" label="traducciones.campos.email" validate={required()} />
                        </SimpleForm>
                    </DialogContent>
                </Dialog> 
                <Dialog open={openCambia}>
                    <DialogTitle>{ translate('traducciones.titulos.cambioContraseña')}</DialogTitle>
                    <DialogContent>
                        <SimpleForm save={handleCambiaPass} noValidate toolbar = { 
                            <Toolbar style={{ justifyContent: "space-between" }}>
                                <SaveButton  label ="traducciones.login.restablecer" icon={<EmailIcon />} /> 
                                <Button label ="ra.action.cancel"  onClick={e => setOpenCambia(false)} />
                            </Toolbar> } >
                            <TextInput style={{ minWidth: "400px"}} source="UserName" label="traducciones.campos.usuario" validate={required()} />
                            <PasswordInput  style={{ minWidth: "400px"}} source="CurrentPassword" label="traducciones.campos.passwordOld" validate={required()} />
                            <PasswordInput  style={{ minWidth: "400px"}} source="NewPassword" label="traducciones.campos.passwordNew" validate={required()} />
                            <PasswordInput  style={{ minWidth: "400px"}} source="pass2" label="traducciones.campos.passwordNewRepeat" validate={required()} />
                        </SimpleForm>
                    </DialogContent>
                </Dialog> 
            </div>
        </ThemeProvider>
    );
};