import { 
    Edit, 
    TextInput, 
    SimpleForm, 
    Toolbar,
    SaveButton,
    ReferenceInput,
    BooleanInput,
    AutocompleteArrayInput,
    required, 
    useEditController,
    useTranslate,
} from 'react-admin';
import Button from '@material-ui/core/Button';

const EditToolbar = ({ onCancel, ...props }) => {
    
    const translate = useTranslate();

    return (
       <Toolbar {...props}>
           <div>
                <SaveButton label="ra.action.save" {...props} />
                <Button onClick={onCancel} style={{position: 'absolute', right: '26px'}}>{translate('ra.action.cancel')}</Button>
           </div>
       </Toolbar>
)};

const GrupoEdit = ({ onCancel, onSuccessOk, ...props }) =>
{
    
    const { record } = useEditController(props);
    const translate = useTranslate();
    const dataUsuarios=props.dataUsers
    
    return (
        <Edit {...props} undoable={false} onSuccess={ onSuccessOk }>
            <SimpleForm  toolbar={<EditToolbar onCancel={onCancel} />}>
                <div>
                    <div style={{ textAlign: 'center', width: '100%'}}>
                        <div>{translate('traducciones.titulos.editarGrupo')}</div>
                        <img src={"grupo.png"} style={{width: '60%'}} />
                    </div>
                    <TextInput source="GrupoNombre" label="traducciones.campos.nombreDeGrupo" validate={required()} />
                    {/* <ReferenceInput label="traducciones.menu.usuarios" source="Users" reference="usuarios" perPage={1000} filter={{}}> */}
                        <AutocompleteArrayInput source='Users' optionText="Nombre" optionValue="Nombre" key='IdUsuario' choices={dataUsuarios}/>
                    {/* </ReferenceInput> */}
                    { record.GrupoNombre != undefined ? (
                    <div className="row rowNoMessage" style={{ width: '100%'}}>
                        <hr className='hr-text' data-content={translate('traducciones.permisos.permDocumentos')}/>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.anotaciones" source="Permissions.Anotaciones" />
                        </div>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.exportar"  source="Permissions.Exportar" />
                        </div>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.importar"  source="Permissions.Importar" />
                        </div>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.digitalizar"  source="Permissions.Digitalizar" />
                        </div>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.imprimir"  source="Permissions.Imprimir" />
                        </div>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.enviar"  source="Permissions.Enviar" />
                        </div>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.sinmarcaagua" source="Permissions.Sin_marca_de_agua" />
                        </div>
                        <hr className='hr-text' data-content={translate('traducciones.permisos.permConsultas')}/>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.general" defaultValue={false} source="Permissions.Consulta_general" />
                        </div>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.navegar" defaultValue={false} source="Permissions.Consulta_navegar" />
                        </div>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.porCampos" defaultValue={false} source="Permissions.Consulta_por_campos" />
                        </div>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.porContenido" defaultValue={false} source="Permissions.Consulta_por_contenido" />
                        </div>
                    </div>
                    ) : null }
                </div>
            </SimpleForm>
        </Edit>
    )
};

export default GrupoEdit;