import React from 'react'
import { useEffect, useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { conektaFetch, dataCustomerMaxisky} from '../DataProviders/GlobalDataProvider'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Plans } from './Plans';
import { PaymentForm } from './PaymentForm';
import { Review } from './Review';
import { Typography } from '@mui/material';
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';
import { Loading } from 'react-admin';
import { clientData } from '../helpers/helpers';
import { Prices } from './Prices';

export const ComponentPAY = ({ node, item }) => {
    const steps = ['Concept', 'Payment details', 'Review your order'];
    const defaultTheme = createTheme();
    
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = async() => {
        if(activeStep == 0){
            try {
                const dataOrder = await conektaFetch('post_order', 'orders', paymentDetails);
                    setOrder(dataOrder)
               } catch (error) {
                    swal({
                    icon: 'error', text: `${error}`
                })
               }
        }
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const [paymentDetails, setPaymentDetails] = useState({
       line_items: [
            {
            name: '',
            description: '',
            unit_price: 0,
            quantity: 1,
            sku: ''
            }
        ],
        checkout: {
            allowed_payment_methods: ["cash", "card", "bank_transfer"],
            monthly_installments_enabled: true,
            monthly_installments_options: [3, 6, 9, 12]
        },
        customer_info: {
            customer_id: clientData.PlatformCustomerId
        },
        currency: 'MXN',
        metadata: {
            customerIdMaxisky: clientData.CustomerID,
            token: '',
            order: '',
            node : '',
            template: item
        }
    });

    const [order, setOrder] = useState({});
    const [flag, setFlag] = useState(false);
    const [dataReview, setDataReview] = useState({});
    const [ flagOrder , setFlagOrder] = useState(false)

    const handleInputChangue = (event) => {
        setFlagOrder(false)
        if (event.id) {
            setPaymentDetails({
                ...paymentDetails,
                metadata: {
                   ...paymentDetails.metadata,
                       token: event,
                       order : order,
                       template: item
               }
            })
        } else {
            swal({
                icon: 'error', text: 'Ocurrio un error al tokenizar la tarjeta'
            })
        }
    };

    useEffect(async () => {
        // if (paymentDetails.line_items[0].name && flagOrder) {
        //    try {
        //     const dataOrder = await conektaFetch('post_order', 'orders', paymentDetails);
        //         setOrder(dataOrder)
        //    } catch (error) {
        //         swal({
        //         icon: 'error', text: `${error}`
        //     })
        //    }
        // }
        if(paymentDetails.line_items[0].name && !flagOrder){
            dataCustomerMaxisky(paymentDetails)
            const response = await conektaFetch('payment_source','order/payment-source', paymentDetails);
            setDataReview(response)
            setActiveStep( activeStep + 1)
        }
    }, [paymentDetails])

    const handleClickChangue = (plan) => {
        setPaymentDetails({
             ...paymentDetails,
             line_items: [
                 {
                     name : plan.data.PlanName,
                     description: plan.data.Description,
                     unit_price: Math.round((plan.data.Price * plan.dollar) * 100),
                     quantity: 1,
                     sku : `sku-${plan.data.IdPlan}`
                 } 
             ],
             checkout: {
                allowed_payment_methods: ["cash", "card", "bank_transfer"],
                monthly_installments_enabled: true,
                monthly_installments_options: [3, 6, 9, 12],
             },
             customer_info: {
                customer_id: clientData.PlatformCustomerId
             },
             currency: 'MXN',
            metadata: {
                customerIdMaxisky: clientData.CustomerID,
                node : node
            }
        })
        setFlagOrder(true)
    };

    const activeButton = (active) => {
        setFlag(active)
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <Prices onClick={handleClickChangue} setFlag={activeButton} type={1} item={item}/>;
            case 1:
                return !flagOrder ? <Loading/> : <PaymentForm onSubmit={handleInputChangue} order={order} />;
            case 2:
                return <Review data={{paymentDetails : paymentDetails, globalData : dataReview }}/>;
            default:
            throw new Error('Unknown step');
        }
    }

    return (
        <>
             <ThemeProvider theme={defaultTheme}>
                <Container  maxWidth="sm" sx={{ mb: 3 }}>
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Typography component="h1" variant="h4" align="center">
                        Checkout
                    </Typography>
                    <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                        {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                        <React.Fragment>
                        {getStepContent(activeStep)}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {activeStep === 1 && (
                            <Button onClick={handleBack} style={{marginTop : '10px'}} hidden={!flagOrder}>
                                Back
                            </Button>
                            )}
                            {
                            flag && activeStep === 0 ?           
                                <Button
                                variant="contained"
                                onClick={handleNext}
                                disabled={paymentDetails.line_items[0].name ? false : true}
                                style={{marginTop : '10px'}}
                                >
                                {'Next'}
                                </Button> : ''
                            }
                        </Box>
                        </React.Fragment>
                    </Paper>
                </Container>
            </ThemeProvider>
        </>
    )
}