import spanishMessages from '@blackbox-vision/ra-language-spanish';

const customSpanishMessages = {
    ...spanishMessages,
    traducciones: {
        visor : {
            anotaciones:'Anotaciones',
            saveAnotaciones:'Guardar anotaciones',
            archivo : 'Documentos',
            archivoLocal : 'Abrir documento local',
            archivoUrl : 'Abrir documento desde URL',
            exportar : 'Exportar documento',
            componer : 'Componer documento',
            guardarCache : 'Guardad documento en cache',
            imprimir : 'Imprimir documento',
            imprimirPdf : 'Imprimir documento en PDF',
            cerrarEditor : 'Cerrar visualizador',
            exportarTexto : 'Exportar texto',
            propiedades : 'Propiedades',
            editar : 'Editar',
            deshacer : 'Deshacer',
            rehacer : 'Rehacer',
            borrarAnotacion: 'Borrar anotación',
            seleccionarTodo: 'Seleccionar todo',
            seleccionarTodasAnotaciones: 'Seleccionar todas las anotaciones',
            limpiarSeleccion: 'Limpiar selección',
            copiarTexto : 'Copiar texto',
            buscarTexto: 'Buscar texto',
            ver : 'Ver',
            rotarIzquierda: 'Rotar a la izquierda',
            rotarDerecha: 'Rotar a la derecha',
            alejar : 'Alejar',
            acercar : 'Acercar',
            tamañoActual : 'Tamaño Actual',
            ajustarApagina : 'Ajustar a la página',
            ajustarAancho : 'Ajustar al ancho',
            comoSVG: 'Ver como SVG',
            comoImagen: 'Ver como Imagen',
            pagina:'Página',
            primerPagina:'Primer página',
            paginaAnterior:'Página anterior',
            proximaPagina:'Próxima página',
            ultimaPagina:'Última página',
            rotarPaginaDerecha:'Rotar página a derecha',
            rotarPaginaIzquierda:'Rotar página a izquierda',
            rotarPaginas:'Rotar páginas...',
            simple:'Simple',
            doble:'Doble',
            vertical:'Vertical',
            horizontal:'Horizontal',
            interaccion:'Interacción',
            irPaginaPrevia:'Ir a página previa en el documento',
            irPaginaNext:'Ir a siguiente página en el documento',
            numPageActual:'Número de página actual',
            tamanioReal:'Mostrar el tamaño real de la página',
            tamaniEncajar:'Encajar la imagen en la ventana',
            tamanioAjustarAncho:'Ajustar el ancho de la imagen en la ventana',
            singlePageDisp:'Visualización de una sola página',
            doublePageDisp:'Visualización doble página',
            verticalDisplay:'Visualización vertical',
            horizontalDisplay:'Visualización horizontal',
            lupa:'Lupa',
            propiedadesAnotacion:'Propiedades de la anotación',
            anotacionProps:'Propiedades',
            anotacionCont:'Contenido',
            anotacionInfo:'Información',
            cerrar:'Cerrar',
            cargandoDocumento : 'Cargando documento',
            verificando : 'Verificando comunicación con el servidor',
            abriendoDocumento : 'Abriendo documento',
        },
        menu: {
            administracion : 'Administración',
            usuarios: 'Usuarios',
            grupos: 'Grupos',
            estructura: 'Estructura',
            nodos : 'Tipos de documentos',
            indices : 'Campos',
            consultas : 'Consultas',
            campos: 'Por Campos',
            general: 'General',
            contenido: 'Por Contenido',
            digitalizar: 'Digitalizar',
            desdeArchivo : 'Desde Archivos',
            indexar: 'Clasificar',
            navegar : 'Navegar',
            escritorio: 'Escritorio',
            plantillas : 'Plantillas',
            bastanteo: 'Por Bastanteo',
            predefinida : "Consultas Predefinidas",
            masivo:'Carga masiva',
            cuenta:'Mi cuenta',
            catalogos: 'Catálogos'
        },
        titulos:{
            nuevoUsuario : 'Nuevo Usuario',
            nuevoGrupo : 'Nuevo Grupo',            
            editarUsuario : 'Editar Usuario',
            editarGrupo : 'Editar Grupo',
            gestionDeGrupos : 'Gestión de Grupos',
            gestionDeUsuarios : 'Gestión de Usuarios',
            addNodosHijos : 'Agregar hijos a este tipo de documento',
            addNodosPrincipal : 'Agregar un tipo de documento principal',
            deleteNodo : 'Eliminar este tipo de documento',
            editNodo : 'Cambiar el nombre de este tipo de documento',
            nuevoNodo : 'Nuevo tipo de documento',
            nodoPadre : 'tipo de documento Padre',
            gestionDeNodos : 'Gestión de tipos de documentos',
            gestionDeIndices : 'Gestión de campos',
            nuevoIndice: 'Nuevo Campo',
            busquedaCampos : 'Consultas por campos',
            busquedaGeneral : 'Consultas general',
            busquedaContenido : 'Consultas por contenido',
            consultarCampos : "Consultar este tipo de documento",
            camposResultados: "Complete los siguientes para consultar",            
            resultadoBusqueda : 'Resultados de la búsqueda',
            archivoDigitalizar : "Archivos para digitalizar",
            archivoSeleccionar : "Arrastre y suelte aquí sus archivos, o haga click para importarlas desde archivo",
            imagenesDigitalizar : "Imágenes para digitalizar",
            imagenesSeleccionar : "Arrastre y suelte aquí sus imágenes, o haga click para importarlas desde archivo",
            previsualizacion : "Previsualización de documentos",
            digitalizar : "Digitalizar documentos",
            gestionIndexar : 'Gestión de Documentos por Indexar',
            reasignarDocumento : "Reasignar documento a otro tipo de documento",
            reasignar : "Reasignar a este tipo de documento",
            reasignarUsuario : "Reasignar documento a otro usuario",
            consultaNavegacion : "Búsqueda por Navegación",
            resultado : "Resultado",
            porcentaje : "El expediente se ha completado en ",
            colapsar : "Colapsar tipos de documentos hijos",
            mostrar : "Mostrar tipos de documentos hijos",
            restablecerContraseña : "Restablecer Contraseña",
            cambioContraseña : "Cambio de contraseña / Primer ingreso",
            plantillaSeleccionada : "Usar plantilla seleccionada",
            agregarIndice : "Seleccione índice a agregar",
            ingresando : "Ingresando...",
            selectNodo : 'Seleccionar este tipo de documento',
            cambiarNombreNodo : 'Editar nombre del tipo de documento',
            reubicarNodo : 'Reubicar tipo de documento',
            reubicar : 'Reubicar dentro de este tipo de documento',
            busquedaBastanteo : 'Consultas por bastanteo',
            resultadosBastanteo : "Resultados de la busqueda por bastanteo",
            ubicacion : "Ubicación",
            busquedaPredefinida : 'Consultas predefinidas',
            ejecutarConsulta : "Ejecutar Consulta",
            archivosTmp:'Archivos temporales',
            indexarDocumento : "Indexar Documento",
            indexarArchivos: 'Indexar archivos',
            resultadoNodos: 'Resultados encontrados',
            fullTextOn: 'Software For Intelligent Image Analysis activado',
            fullTextOff: 'Software For Intelligent Image Analysis desactivado',
            configuracionCuenta: 'Configuración de la cuenta',
            editarCatalogo: 'Editar catálogo',
            gestionCatalogos:'Gestión de catálogos',
            catalogo: 'Catálogo',
            nuevoCatalogo: 'Nuevo catálogo',
            seleccionNodos: 'Seleccione los nodos',
            vaciarNodo: 'Vaciar archivos del nodo',
            subirTmp: 'Subir archivos temporales',
            restablecer: 'Restablecer los valores',
            general:'General',
            navegar:'Navegar',
            porCampos:'Por campos',
            porContenido:'Por contenido',
            sinAcceso: 'Lo sentimos, no cuentas con los permisos requeridos para esta vista.'
        },
        permisos: {
            anotaciones : 'Anotaciones',
            exportar : 'Exportar',
            importar : 'Importar',
            digitalizar : 'Digitalizar',
            imprimir : 'Imprimir',
            enviar : 'Enviar',
            sinmarcaagua : 'Sin marca de agua',
            general:'Consulta general',
            navegar:'Consulta navegar',
            porCampos:'Consulta por campos',
            porContenido:'Consulta por contenido',
            permDocumentos: 'Permisos de documentos',
            permConsultas: 'Permisos de consultas'
        },
        campos: {
            nombre : 'Nombre',
            contrasena: 'Contraseña',
            usuario : 'Usuario',
            grupo : 'Grupo',
            nombreDeGrupo : 'Nombre del grupo',
            email : 'Correo electrónico',
            rol : 'Rol de usuario',
            estadoPassword: 'Estado de password',
            estadoUsuario: 'Estado de usuario',
            vigenciaPassword: 'Vigencia de password (días)',
            nodoNombre : 'Nombre del tipo de documento',
            indice : 'Titulo del campo',
            tipo : 'Tipo de campo',
            mascara : 'Formato de Campo',
            minimo : 'Mínimo',
            maximo : 'Máximo',
            longitud : 'Longitud',
            requerido : 'Requerido',
            multiple : 'Múltiple',
            unico : 'Único',
            verificar : 'Verificar',
            repetido : 'Repetido',
            diccionario : 'Diccionario',
            biometrico : 'Biométrico',
            indiceNombre : 'Nombre del campo',
            numeroTemporal : "Nº Documento Temporal",
            fechaDigitalizacion : "Fecha de digitalización",
            descripcion : "Descripción",
            tipoDocumento : "Tipo de Documento",
            passwordOld : "Contraseña actual",
            passwordNew : "Contraseña nueva",
            passwordNewRepeat : "Repetir contraseña nueva",
            plantillas: 'Seleccion plantilla',
            usuarioHabilitado : 'Usuario Activo',
            usuarioDesHabilitado : 'Usuario Bloqueado',
            si : 'SI',
            no : 'NO',
            usuarioDigitalizacion : 'Usuario Digitalizador',
            idQuery : 'Identificador de consulta',
            nombreQuery : 'Nombre de consulta',
            catalogo : 'Seleccione catálogo',
            gruposNotificacion : 'Grupos de Notificación',
            usuariosNotificacion : 'Usuarios de Notificación',
            nombreCatalogo:'Nombre de nuevo catálogo',
            valores:'Valores',
            valor:'Valor',
            buscador:'Buscar tipo de documento',
            checkMarcar : 'Marcar / Desmarcar Todos',
            activar: 'Activar',
            vencimiento : 'Vigencia de permiso',
            cancelar: 'Cancelar',
            aceptar: 'Aceptar',
            vaciar: 'Vaciar',
            ingDesc: 'Ingrese descripción',
            statusOn: 'Usuario habilitado',
            statusOff: 'Usuario deshabilitado',
            notificar: 'Notificar'
        },
        notificaciones: {
            addUsuarioOk : 'Usuario creado correctamente',
            editUsuarioOk : 'Usuario modificado correctamente',
            addGrupoOk : 'Grupo creado correctamente',
            editGrupoOk : 'Grupo modificado correctamente',
            noHayCampos : 'No se encontraron campos para realizar la consulta',
            actualizaAnotacionesOK : 'Las anotaciones fueron guardadas con exito',
            documentosOK : 'La digitalización fue realizada correctamente',
            catalogosOk: 'Catálogo creado',
            catalogosInfo: 'Debe agregar al menos un valor al nuevo catálogo',
            catalogosEditOk: 'Catálogo modificado',
            usuarioDuplicado: 'Ya existe el usuario: ',
            grupoDuplicado: 'Ya existe el grupo: ',
            datosDuplicados: 'Datos duplicados',
            duplicadosText: 'Revise los datos por favor',
            vaciarNodos: '¿Seguro que desea vaciar los archivos seleccionados hasta ahora?',
            vacios: 'Se han restablecido los valores',
            sinDesc: 'Debe indicar su descripción para clasificar los documentos',
            clasificacionOk: 'Los archivos se han clasificado',
            indexadoOk: '¡Archivo indexado!',
            actualizadosOk: 'Se han actualizado los datos',
            cambiosOk: 'Se han guardado los cambios',
            cambiosError: 'Lo sentimos, no se han podido guardar los cambios',
            limiteValores: 'Has alcanzado el límite de 25 valores',
            aviso: '¡Aviso importante!',
            avisoMsj: 'A partir de la descarga, el usuario es el único responsable del tratamiento y la confidencialidad de la información. Por favor, trate los datos con cuidado y de manera confidencial.'
        },
        modal : {
            eliminarGrupo : 'Eliminar Grupo',
            eliminarGrupoConfirm : '¿Está seguro que desea eliminar este grupo?',
            eliminarUsuario : 'Eliminar Usuario',
            eliminarUsuarioConfirm : '¿Está seguro que desea eliminar este usuario?',
            eliminarNodo : 'Eliminar tipo de documento',
            eliminarNodoConfirm : '¿Está seguro que desea eliminar este tipo de documento?',
            eliminarIndice : 'Eliminar Índice', ///////////////
            eliminarIndiceConfirm : '¿Está seguro que desea eliminar este índice?', ////////////////////
            editarUsuarios : 'Seleccione los usuarios con accesso al tipo de documento: ',
            editarUsuariosDocumento : 'Seleccione los usuarios con acceso a este documento',
            noResults : 'No se encontraron resultados',
            eliminarDocumentoTemporal : 'Eliminar Documento Temporal',
            eliminarDocumentoConfirmTemporal : '¿Está seguro que desea eliminar este documento temporal?',
            eliminarDocumento : 'Eliminar Documento',
            eliminarDocumentoConfirm : '¿Está seguro que desea eliminar este documento?',
            ediarDatosDocumento : 'Actualizar datos del documento',
            eliminarCatalogo: 'Eliminar catálogo',
            eliminarCatalogoConfirm: '¿Está seguro que desea eliminar el catálogo?',
            sinNombre: 'Ingrese el nombre del nodo',
            seleccionNodo: 'Seleccione la ruta del nodo que desea',
            notificaciones : 'Notificaciones',
            asigUser: 'Asignar archivos solo a',
            confirmVaciar: '¿Desea vaciar los archivos del nodo ',
            ayuda: '¿Necesitas ayuda? Puedes activar las guias interactivas aquí' 
        },
        roles : {
            administrador : 'Administrador',
            seguridad : 'Seguridad',
            supervisor : 'Supervisor',
            operador : 'Operador',
            usuario : 'Usuario',
        },
        estadoPassword : {
            caducado : 'Caducado',
            vigente : 'Vigente' ,
            noCaduca : 'No Caduca' ,
            temporalCaduca : 'Temporal que caduca' ,
            temporalNoCaduca : 'Temporal que no caduca',
        },
        botones : {
            consultar: "Consultar",
            subir: "Digitalizar Documentos",
            indiceIndividual: "Agregar índice individual",
            indicePlantilla: "Agregar índice por plantilla",
            descargarSeleccionados : "Descargar archivos seleccionados",
            descargarCsv : "Descargar datos seleccionados en CSV",
            descargarSeleccionadosAll : "Descargar Todos",
            descargarCsvAll : "Descargar todos los resultados en CSV",
            volver: 'Regresar',
            opcionesDescarga : 'Opciones de descarga',
            cambiarContraseña: 'Cambiar contraseña',
            agregarCatalogo: 'Agregar catálogo',
            login: 'Iniciar Sesión',
            crearPre : 'Crear nueva consulta',
            elegirArch : 'Elegir archivos',
            noArchivos: 'archivos',
            clasificar: 'Clasificar archivos',
            vaciar: 'Vaciar archivos',
            guiasQuerys: 'Guías interactivas de consultas',
            guiasDigit: 'Guías interactivas de digitalización',
            guiasAdmin:'Guías interactivas de administración',
            guiasEstructura:'Guías interactivas de estructuras',
            documentosFaltantes: 'Ocultar documentos faltantes (No mostrará porcentaje de completez)'
        },
        error : {
            indiceRepetido: "El índice que desea agregar ya se encuentra en el listado",
            indicePlantillaRepetido : "Alguno de los índices agregados se encuentra repetido",
            noHaySeleccion: "Debe seleccionar al menos un elemento"
        },
        login : {
            bienvenido : 'Bienvenid@',
            contactenos : 'Contáctenos',
            ayuda : 'Ayuda',
            restablecer : 'Restablecer',
            olvidepass : '¿Se te olvidó tu contraseña?',
            mensaje : 'Traspasamos fronteras a través de soluciones e implementaciones seguras y confiables',
            primera : "Si esta ingresando por primera vez, hás click aquí : "
        },
        dash : {
            indexadosXdia : 'Documentos Indexados por día',
            documentos : 'Documentos',
            digitalizados : 'digitalizados'
        },
        generales : {
            gruposTexto1 : 'Los grupos te pueden servir para asignar los mismos permisos a un equipo que tenga las mismas funciones.',
            gruposTexto2 : 'Por ejemplo: Equipo 1 se encarga de solo importar la información.',
            step1 : 'Paso1.-',
            step2 : 'Paso2.-',
            step3 : 'Paso3.-',
            grupostep1 : 'Ponle un Nombre a tu equipo',
            grupostep2 : 'Asigna a los miembros del equipo',
            grupostep3 : 'Selecciona las funciones que tendrá ese equipo.',
            dashstep1 : 'Crea tus estructuras (imagen de carpetas)',
            dashstep2 : 'Crea los campos de búsqueda los cuales son las etiquetas con las cuales vas a identificar a tus documentos dentro de la plataforma',
            dashstep3 : 'Invita a tu Equipo y configura sus permisos',
            dashtitle : 'está lista para que empieces a configurarla',
            errorTitulo : 'Ups! Ha ocurrido un error',
            errorSubtitulo : 'Hubo un error en la comunicación con el servidor, por favor intentelo nuevamente',
            noArchivos : 'No. de archivos seleccionados',
            instruccionDig: 'Para digitalizar documentos',
            inst1: 'Cargue los archivos en la carpeta que desea subirlos',
            inst2: 'Una vez seleccionados sus archivos, seleccione una opción',
            opcion: 'Opción'
        },
        introMasiva : {
            intro1: "🎉 ¡Bienvenido a esta guía introductoria! Aprenderás a digitalizar tus archivos paso a paso.",
            title1: '¡Bienvenido!',
            intro2: "📁 Aquí identificarás los nodos, donde podrás subir y gestionar tus documentos.",
            title2: 'Nodos',
            intro3: "🚀 Aquí ocurre la magia. Arrastra y suelta tus archivos o haz clic para agregarlos.",
            title3: 'Subir documentos',
            intro4: "📋 Muestra cuántos archivos has cargado en el nodo seleccionado.",
            title4: 'Archivos cargados',
            intro5: "❌ ¿Archivo equivocado? Usa este botón para vaciar el nodo y empezar de nuevo.",
            title5: 'Vaciar nodo',
            intro6: "⚙️ Después de cargar archivos, encontrarás opciones útiles para gestionar tus documentos.",
            title6: 'Opciones disponibles',
            intro7: "🗑️ Borra todos los archivos cargados en los nodos si necesitas un nuevo comienzo.",
            title7: 'Vaciar nodos',
            intro8: "📑 Carga documentos para categorizarlos posteriormente.",
            title8: 'Clasificar documentos',
            intro9: "📄 Guarda tus documentos en los nodos correspondientes si conoces su ubicación.",
            title9: 'Indexar documentos',
        },
        introBotones:{
            doneLabel: 'Finalizar',
            nextLabel: 'Siguiente',
            prevLabel: 'Anterior',
            dontShow: 'No volver a mostrar'
        },
        introIndexar : {
            intro1: "📝 Comencemos por ingresar los campos requeridos para indexar el archivo.",
            title1: 'Indexar Archivos',
            intro2: "🚀 Con los datos proporcionados, presiona el botón para iniciar la indexación de tus archivos.",
            title2: 'Indexar Archivo',
        },
        introClasificar : {
            intro1: "📝 Comencemos ingresando la descripción con la cual se guardará el documento para luego clasificarlo.",
            title1: 'Clasificar Archivos',
            intro2: "🚀 Después de haber introducido la descripción, simplemente haz clic en el botón para finalizar el proceso.",
            title2: 'Finalizar Clasificación',
        },
        introPredefinidas : {
            //Con consultas predefinidas
            cintro1: "🎉 ¡Hola y bienvenido! Aquí encontrarás las consultas predefinidas disponibles. Estas búsquedas nos permiten visualizar tanto los documentos existentes como aquellos que nos hacen falta, además de identificar en qué nodo se encuentran.",
            ctitle1: 'Consultas predefinidas',
            cintro2: "🔍 En esta sección, puedes localizar el nombre que te ayudará a identificar la consulta.",
            ctitle2: 'Nombre de la Consulta',
            cintro3: "🗑️ Utiliza este botón para eliminar consultas cuando ya no las necesites.",
            ctitle3: 'Eliminar Consultas',
            cintro4: "🔍 Usa este botón para realizar consultas y obtener resultados específicos.",
            ctitle4: 'Realizar Consultas',
            //Sin consultas predefinidas
            sintro1: "🎉 ¡Bienvenido! Aún no tienes consultas disponibles, ¡creemos una nueva!",
            stitle1: '¡Bienvenido!',
            sintro2: "🔧 Haz clic en el botón de creación para empezar.",
            stitle2: 'Crear Consulta',
        },
        introCreate : {
            intro1: "🎉 ¡Bienvenido! Aquí podrás crear una nueva consulta predefinida con la configuración que necesites.",
            title1: 'Creación de Consulta',
            intro2: "📝 Asigna un nombre a tu consulta para identificarla posteriormente.",
            title2: 'Nombre de la Consulta',
            intro3: "🔍 Selecciona el operador que la consulta usará para comparar los valores.",
            title3: 'Operador de la Consulta',
            intro4: 'Activa esta opción si deseas que todos los campos sean obligatorios para obtener resultados. Si se activa, todos los campos seleccionados deben coincidir para obtener resultados.',
            title4: 'Activar coincidencias',
            intro5: 'Activa esta opción si solo necesitas un solo campo para todos los campos seleccionados. Una vez activada, podrás asignar un nombre a este campo común. Si permanece desactivada, podrás ingresar datos en cada campo seleccionado individualmente.',
            title5: 'Activar campo común',
            intro6: 'Aquí encontrarás los campos que has seleccionado. Puedes asignarles un valor fijo o eliminarlos si te has equivocado o ya no los necesitas.',
            title6: 'Campos seleccionados',
            intro7: 'Aquí puedes agregar los campos en los que deseas realizar la consulta de tus documentos. Más adelante, volveremos para agregar tus primeros campos.',
            title7: 'Agregar campos',
            intro8: 'Es importante asignar permisos. Aquí puedes especificar a qué usuarios y/o grupos tendrán acceso a esta consulta. Una vez creada, no podrás modificar estos permisos, así que asegúrate de configurarlos correctamente.',
            title8: 'Asignar permisos',
            intro9: 'Una vez que hayas configurado correctamente tu nueva consulta, puedes hacer clic aquí para crearla y comenzar a utilizarla. Este es el último paso para poner en marcha tu consulta.',
            title9: 'Crear consulta',
        },
        introhandleOpen: {
            intro1: '¿Necesitas ayuda para agregar tus campos? Estos son los pasos que debes seguir.',
            title1: 'Agregar campos',
            intro2: 'Lo primero que debes hacer es buscar en toda la estructura el nodo del que deseas extraer los campos.',
            title2: 'Identifica el nodo',
            intro3: 'Una vez identificado el nodo que necesitas, haz clic en el botón para mostrar los campos disponibles. Luego, podrás seleccionar los campos que necesites del nodo y agregarlos a la consulta.',
            title3: 'Ver campos disponibles',
            intro4: ' Puedes agregar distintos campos de varios nodos. Una vez que hayas seleccionado tus campos, puedes regresar aquí para terminar la configuración de tu consulta.',
            title4: 'Regresar',
        },
        introValorFijo: {
            intro1: 'Aquí puedes observar el nombre del campo y el nodo al que pertenece.',
            title1: 'Detalles campo',
            intro2: 'Puedes asignar un operador distinto al que configuraste en la consulta si es necesario.',
            title2: 'Operador campo',
            intro3: 'Aquí puedes asignar un valor fijo al campo. Ten en cuenta que, una vez asignado, no podrás modificarlo al usar la consulta.',
            title3: 'Detalles campo',
            intro4: 'Si necesitas eliminar el campo, simplemente haz clic aquí.',
            title4: 'Eliminar campo',
            intro5: 'Para guardar los cambios realizados en el campo, solo tienes que hacer clic aquí.',
            title5: 'Guardar cambios',
        },
        introHandleToBack : {
            intro1: 'Aquí encontrarás los campos agregados. Puedes hacer clic en un campo para agregarle un valor fijo, asignarle un operador distinto solo para ese campo o eliminarlo de la consulta.',
            title1: 'Detalles del Campo',
        }, 
        introPreCampos : {
            intro1: "🔍 En esta sección, encontrarás el nombre de la búsqueda que hayas seleccionado previamente.",
            title1: '¡Últimos Pasos!',
            intro2: "📝 Ingresa los datos que deseas buscar en sus respectivos campos.",
            title2: 'Ingresar Valores',
            intro3: "🚀 ¡Listo! Una vez que hayas ingresado los datos, haz clic en este botón para buscar la información.",
            title3: 'Realizar Consulta',
            intro4: "🔙 ¿Quieres regresar al listado de búsquedas? Solo tienes que hacer clic aquí.",
            title4: 'Regresar al Listado',
        },
        introHelp : {
            intro1: '¿Necesitas ayuda? Puedes habilitar o deshabilitar las guías interactivas en cualquier momento. Simplemente haz clic aquí para activarlas o desactivarlas.',
            title1: 'Guías interactivas',
        },
        introCampos : {
            intro1: '🔍 ¡Bienvenido a las búsquedas por campos! Aquí puedes generar informes específicos de un tipo de documento usando sus campos correspondientes.',
            title1: 'Búsquedas por campos',
            intro2: '🌟 Antes de comenzar, es crucial elegir en qué nodo deseas llevar a cabo tu búsqueda.',
            title2: 'Selecciona un nodo',
            intro3: '🔽 Para desplegar la estructura del nodo y encontrar el tipo de documento que deseamos ubicar, simplemente haz clic aquí.',
            title3: 'Desplegar estructura',
            intro4: '💡 Una vez que hayas identificado el nodo, simplemente haz clic en este botón para mostrar los campos disponibles donde podrás realizar tu búsqueda.',
            title4: 'Mostrar campos disponibles',
            //Mostrar campo
            cintro1: "¡Echa un vistazo a los campos disponibles del nodo que seleccionaste! 📄🔍",
            ctitle1: "Campos disponibles",
            cintro2: "Ahora, solo ingresa los datos que necesitas. ¡No es necesario llenar todos los campos! 📝😊",
            ctitle2: "Ingresar datos",
            cintro3: "¡Genial! Con los datos ingresados, dale clic a este botón para buscar tus documentos. 🚀🔎",
            ctitle3: "Buscar",
        },
        introContenido : {
            intro1: '🔍 ¡Bienvenido a las Búsquedas por Contenido! Aquí puedes ubicar documentos solo ingresando palabras o contenido dentro del documento, incluso si no existen como campos. <br/><b>Importante:</b> Estas búsquedas están limitadas a los documentos que hayan sido procesados previamente con SOFIIA®.',
            title1: 'Búsquedas por Contenido',
            intro2: '🌟 Antes de comenzar, es crucial elegir en qué nodo deseas llevar a cabo tu búsqueda.',
            title2: 'Selecciona un Nodo',
            intro3: '🔽 Para desplegar la estructura del nodo y encontrar el tipo de documento que deseamos ubicar, simplemente haz clic aquí.',
            title3: 'Desplegar estructura',
            intro4: '💡 Una vez que hayas identificado el nodo, simplemente haz clic en este botón para mostrar el campo donde podrás realizar tu búsqueda.',
            title4: 'Mostrar campo',
            //Mostrar campo
            cintro1: "Ahora puedes ingresar palabras o contenido presente en los documentos que buscas. 📝😊 ¡Explora utilizando ejemplos como nombres, fechas y más!",
            ctitle1: "Ingresar datos",
            cintro2: "¡Genial! Con los datos ingresados, dale clic a este botón para buscar tus documentos. 🚀🔎",
            ctitle2: "Buscar",
        },
        introGeneral : {
            intro1: '🔍 ¡Bienvenido a las Búsquedas Generales! Aquí puedes buscar tu información utilizando cualquier dato de tu documento para localizarlo. ',
            title1: 'Búsquedas generales',
            intro2: '🌟 Antes de empezar, es fundamental seleccionar el nodo en el que deseas llevar a cabo tu búsqueda. <br/> <b>Nota:</b> Estas búsquedas nos brindan la capacidad de explorar a lo largo de toda la estructura que elijamos.',
            title2: 'Selecciona un Nodo',
            intro3: '🔽 Para desplegar la estructura del nodo y encontrar el tipo de documento que deseamos ubicar, simplemente haz clic aquí.',
            title3: 'Desplegar estructura',
            intro4: '💡 Una vez que hayas identificado el nodo, simplemente haz clic en este botón para mostrar el campo donde podrás realizar tu búsqueda.',
            title4: 'Mostrar campo',
            //Mostrar campo
            cintro1: "¡Comienza ahora! Simplemente ingresa los datos que necesitas. Esta búsqueda te permite explorar en diferentes tipos de documentos para encontrar uno o más datos. Puedes buscar hasta 25 datos al mismo tiempo para agilizar tu búsqueda.📝😊",
            ctitle1: "Ingresar datos",
            cintro2: "¡Genial! Con los datos ingresados, dale clic a este botón para buscar tus documentos. 🚀🔎",
            ctitle2: "Buscar",
        },
        introNavegar : {
            intro1: '🔍 ¡Bienvenido a las búsquedas por navegar! Aquí podrás buscar y ver los documentos más recientes de cada tipo. Esta búsqueda te permite tener una visión general de los contenidos de cada nodo.',
            title1: 'Búsquedas por navegar',
            intro2: '🌟 Antes de comenzar, es crucial elegir en qué nodo deseas llevar a cabo tu búsqueda.',
            title2: 'Selecciona un Nodo',
            intro3: '🔽 Para desplegar la estructura del nodo y encontrar el tipo de documento que deseamos ubicar, simplemente haz clic aquí.',
            title3: 'Desplegar estructura',
            intro4: '💡 Una vez que hayas identificado el nodo, simplemente haz clic en este botón para mostrar los últimos documentos almacenados en él.',
            title4: 'Buscar',
        },
        introTabla : {
            intro1: 'Estos son tus resultados de tu consulta. En esta tabla podrás ver cada uno de tus resultados y realizar algunas acciones con ellos.',
            title1: 'Resultados de Consulta',
            intro2: 'Este es un documento. Aquí encontrarás todos los datos referentes al documento y sus acciones disponibles.',
            title2: 'Explorar Documentos',
            intro3: 'Puedes seleccionar el documento si deseas exportarlo. Selecciona uno o más documentos.',
            title3: 'Seleccionar Documento',
            intro4: 'Si deseas exportar todos los resultados de una página, selecciona todos los documentos desde aquí.',
            title4: 'Seleccionar Todos los Documentos',
            intro5: 'Una vez que hayas seleccionado los documentos que necesitas exportar, haz clic aquí para elegir la opción que necesites.',
            title5: 'Exportar Documentos',
            intro6: '¿Necesitas modificar algún dato de los campos del documento? Aquí podrás realizar modificaciones.',
            title6: 'Editar Documento',
            intro7: '¿Deseas mover un documento a otro nodo? Aquí puedes reasignar el documento a otro nodo.',
            title7: 'Reasignar Documento',
            intro8: '¿Necesitas eliminar un documento? Utiliza este icono que acompaña a cada documento para eliminarlo.',
            title8: 'Eliminar Documento',
            intro9: 'Aquí podrás otorgar acceso a usuarios y grupos al documento.',
            title9: 'Dar Acceso a Usuarios y Grupos',
            intro10: '¿Deseas visualizar el documento? Esta opción te abrirá un visor de documentos donde podrás ver e interactuar con tu documento si cuentas con los permisos necesarios.',
            title10: 'Ver Documento',
        },
        introCatalogos: {
            //Con catalogos
            cintro1: '¡Bienvenido a la sección de catálogos! Aquí podrás ver los catálogos existentes, gestionarlos o eliminarlos.',
            ctitle1: 'Catálogos',
            cintro2: 'En esta sección, podrás explorar un catálogo y ver su nombre junto con las acciones disponibles.',
            ctitle2: 'Explorando un Catálogo',
            cintro3: 'Aquí podrás gestionar tu catálogo, cambiar su nombre o administrar sus valores.',
            ctitle3: 'Editar Catálogo',
            cintro4: '¿Deseas eliminar un catálogo? Puedes hacerlo aquí.',
            ctitle4: 'Eliminar Catálogo',
            cintro5: '¡Crea un nuevo catálogo en un abrir y cerrar de ojos! Solo tienes que hacer clic aquí.',
            ctitle5: 'Crear Nuevo Catálogo',
            //Sin catalogos
            sintro1: '¡Te damos la bienvenida a la sección de catálogos! Actualmente, no tienes catálogos disponibles.',
            stitle1: 'Catálogos',
            sintro2: 'Si deseas crear un nuevo catálogo, puedes hacerlo haciendo clic aquí.',
            stitle2: 'Crear Nuevo Catálogo',
        },
        introCatalogoCreate: {
            intro1: 'Para crear un nuevo catálogo, primero asigna un nombre a tu catálogo aquí.',
            title1: 'Asignar Nombre de Catálogo',
            intro2: 'Aquí puedes agregar la cantidad de valores que desees y asignarles un valor.',
            title2: 'Agregar Valores al Catálogo',
            intro3: 'Una vez que hayas asignado un nombre y agregado los valores necesarios, haz clic aquí para crear el nuevo catálogo.',
            title3: 'Crear Catálogo',
        },
        introCatalogoEdit: {
            intro1: 'En esta sección, puedes editar los valores de tu catálogo.',
            title1: 'Editar Valores del Catálogo',
            intro2: 'Si un valor ya no es necesario en tu catálogo, puedes eliminarlo aquí.',
            title2: 'Eliminar Valores del Catálogo',
            intro3: '¿Deseas guardar los cambios en el catálogo? Haz clic aquí para confirmarlos.',
            title3: 'Guardar Cambios en el Catálogo',
        },
        introDashboard: {
            intro1: "Echa un vistazo a la estructura de la plataforma para familiarizarte con ella. Esto hará que tu experiencia sea mucho más sencilla y efectiva. 🌟",
            title1: "Bienvenido a Aiidara®",
            intro2: "Este es tu panel principal, donde encontrarás gráficas y estadísticas sobre el uso de la plataforma, donde podrás ver todo en un solo vistazo. ¡Mantén un ojo en tus datos importantes aquí! 👀",
            title2: "Dashboard",
            intro3: "Aquí tienes el menú de opciones. Es como tu mapa para moverte por la plataforma. Te llevará a diferentes áreas y funciones importantes. ¡Explóralo! 🗺️",
            title3: "Menú de Opciones",
            intro4: "Estas son las diferentes consultas que puedes utilizar. Cada una te ayudará a buscar información específica. 📖",
            title4: "Consultas",
            intro5: "En este lugar, puedes gestionar tus documentos. Sube o clasifica tus documentos de manera organizada. 🗂️",
            title5: "Digitalización",
            intro6: "Aquí puedes gestionar tus usuarios y grupos. Mantén todo organizado y en control. 👥",
            title6: "Administración",
            intro7: "En este espacio, puedes gestionar la estructura de tus nodos, sus campos y los catálogos. Es como construir y mantener tus carpetas y etiquetas personalizadas. ¡Hazlo a tu manera y mantén todo organizado como desees!",
            title7: "Estructura",
            intro8: "Aquí puedes cerrar tu sesión y/o configurar tu contraseña. ¡Asegúrate de mantener tus datos seguros! 🔒",
            title8: "Cierre de Sesión", 
        },
        introDigit: {
            intro1: 'Aqui podras digitalizar tus archivos de manera temporal para posteriormente clasificarlos de la manera que necesites.',
            title1: 'Digitalización temporal',
            intro2: '🌟 Antes de comenzar, es crucial elegir en qué nodo deseas subir tus documentos.',
            title2: 'Selecciona un nodo',
            intro3: '🔽 Para desplegar la estructura del nodo y encontrar el nodo en donde deseas subir sus documentos, simplemente haz clic aquí.',
            title3: 'Desplegar estructura',
            intro4: '💡 Una vez que hayas identificado el nodo, simplemente haz clic en este botón para mostrar los campos donde podras subir tus documentos',
            title4: 'Seleccionar nodo',
            //Para subir
            intro5: 'En este espacio, puedes arrastrar o agregar las imágenes que desees subir al nodo seleccionado. 🖼️',
            title5: 'Agregar imágenes ',
            intro6: 'En este espacio, puedes arrastrar o agregar los archivos que desees subir al nodo seleccionado. 📁',
            title6: 'Agregar archivos',
            intro7: 'Aquí deberás agregar una descripción relacionada con los documentos que estás subiendo. Esto te ayudará a identificarlos más fácilmente al momento de clasificarlos. 📄',
            title7: 'Agregar descripción',
            intro8: 'En este lugar, puedes asignar los documentos a diferentes usuarios. Es como decir quién es el responsable. 💼',
            title8: 'Asignar usuarios',
            intro9: '¡Bien hecho! Una vez que hayas agregado tus documentos y los datos requeridos, dale clic a este botón para subir los documentos. 📤',
            title9: 'Subir documentos',
        },
        introCreateUser: {
            intro1: 'En primer paso, asigna un nombre único al grupo para identificarlo.',
            title1: 'Asignar nombre de grupo',
            intro2: 'Agrega a los usuarios que serán parte de este grupo y que tendrán los permisos configurados para el grupo.<br/><b>Importante:</b> Cuando agregas un usuario a un grupo, este adquiere los permisos del grupo, que se combinan con sus permisos individuales existentes.',
            title2: 'Agregar usuarios',
            intro3: 'Activa este permiso para permitir a los usuarios del grupo agregar anotaciones a los documentos a los que tenga acceso.',
            title3: 'Permiso de anotaciones',
            intro4: 'Activa este permiso para habilitar la función de descarga de documentos o informes en CSV en las búsquedas.',
            title4: 'Permiso de exportar',
            intro5: 'Activa este permiso para permitir a los usuarios del grupo subir documentos a los nodos a los que tengan acceso.',
            title5: 'Permiso de importar',
            intro6: 'Activa este permiso para permitir a los usuarios del grupo subir documentos a los nodos a los que tenga acceso.',
            title6: 'Permiso de digitalizar',
            intro7: 'Activa este permiso para permitir a los usuarios del grupo utilizar la función de imprimir documentos a los que tenga acceso dentro del visor.',
            title7: 'Permiso de imprimir',
            intro8: 'Activa este permiso si deseas permitir a los usuarios del grupo enviar un documento desde el visor de documentos.',
            title8: 'Permiso de enviar',
            intro9: 'Activa este permiso si los usuarios del grupo deben ver documentos sin marca de agua.',
            title9: 'Sin marca de agua',
            intro10: 'Configura los permisos para el acceso de los usuarios del grupo a los diferentes tipos de consultas.',
            title10: 'Permisos a consultas',
            intro11: 'Después de haber asignado un nombre, agregado a tus usuarios y configurado sus permisos, simplemente haz clic en el botón.',
            title11: 'Crear nuevo grupo',
        },
        introGrupoList: {
            //Con grupos
            cintro1: '¡Bienvenido a la sección de grupos! Aquí podrás ver y gestionar los grupos de tu plataforma. 👥',
            ctitle1: 'Grupos',
            cintro2: 'Este es un grupo, aquí podrás ubicar el nombre del grupo y observar los permisos activos que tiene.',
            ctitle2: 'Explorando un Grupo',
            cintro3: 'Aquí podrás gestionar el grupo, cambiar el nombre o administrar los permisos del grupo. 🛠️',
            ctitle3: 'Editar grupo',
            cintro4: 'Aquí podrás eliminar un grupo en caso de ya no necesitarlo. 🗑️',
            ctitle4: 'Eliminar grupo',
            cintro5: '¡Crea un nuevo grupo en un abrir y cerrar de ojos! Solo tienes que hacer clic aquí. ➕',
            ctitle5: 'Crear nuevo grupo',
            //Sin grupos
            sintro1: '¡Te damos la bienvenida a la sección de grupos! En este momento, no has creado ningún grupo, pero estamos aquí para ayudarte a empezar. 😊',
            stitle1: 'Grupos',
            sintro2: '🚀 Crear un nuevo grupo es sencillo y rápido. Solo tienes que seguir estos simples pasos:',
            stitle2: 'Crear nuevo grupo',
            sintro3: ' Ahora, todo lo que necesitas hacer es hacer clic en el botón que se encuentra justo aquí. Sigue adelante y dale un clic para empezar a crear tu primer grupo.',
            stitle3: 'Boton de crear',
        },
        introIndiceList: {
            intro1: '¡Bienvenido! En esta sección, tendrás el control total sobre la gestión de tus campos. Aquí te guiaremos a través de los pasos clave para que puedas editar y administrar tus campos de manera efectiva.',
            title1: 'Campos',
            intro2: 'Aquí podras visualizar tus nodos de manera rápida y sencilla.',
            title2: 'Explorar nodos',
            intro3: 'Si necesitas una vista más amplia de tu estructura para encontrar un nodo específico, utiliza esta opción.',
            title3: 'Desplegar estructura',
            intro4: 'Aquí podrás ver los campos del nodo y administrarlos según tus necesidades. Utiliza esta opción ubicada dentro del nodo que necesites para acceder a la información de los campos de ese nodo.',
            title4: 'Ver campos del nodo',              
        },
        introCreateCampo: {
            intro1: '¡Bienvenido! En esta sección, configurarás un nuevo campo de acuerdo a tus necesidades.',
            title1: 'Configuración de Nuevo Campo',
            intro2: 'Ingresa un nombre para identificar este campo.',
            title2: 'Asignar Nombre al Campo',
            intro3: 'Selecciona el tipo de datos que quieres que este campo almacene.',
            title3: 'Seleccionar Tipo de Campo',
            intro4: 'Opcionalmente, especifica un formato específico que el campo debe seguir (deja en blanco si no es necesario).',
            title4: 'Formato de Campo',
            intro5: 'Asigna un valor mínimo para el campo (puedes dejarlo en 0 si no es necesario).',
            title5: 'Valor Mínimo',
            intro6: 'Asigna un valor máximo para el campo (puedes dejarlo en 0 si no es necesario).',
            title6: 'Valor Máximo',
            intro7: 'Establece la longitud máxima de caracteres que el campo puede contener (deja en 0 si no deseas limitarla).',
            title7: 'Longitud Máxima',
            intro8: '¿Deseas que el campo sea obligatorio? Activa esta opción si es necesario.',
            title8: 'Hacer el Campo Obligatorio',
            intro9: '¿Quieres que el campo acepte múltiples valores? Activa esta opción si es necesario.',
            title9: 'Permitir Múltiples Valores',
            intro10: '¿Deseas que el campo contenga valores únicos que no se puedan repetir? Activa esta opción si es necesario.',
            title10: 'Hacer el Campo Único',
            intro11: 'Activa esta opción para verificar los datos ingresados en el campo.',
            title11: 'Verificación del Campo',
            intro12: 'Activa esta opción si deseas permitir datos repetidos en el campo.',
            title12: 'Permitir Datos Repetidos',
            intro13: 'Activa esta opción para notificar a los usuarios cuando se realicen cambios en este campo.',
            title13: 'Notificar Cambios en el Campo',
            intro14: 'Una vez que hayas configurado todos los detalles de tu nuevo campo, haz clic aquí para crearlo.',
            title14: 'Crear el Nuevo Campo',               
        },        
        introViewCampos: {
            intro1: 'En esta sección, podrás visualizar todos los campos del nodo. En la tabla, podrás modificar los datos, eliminar campos o crear nuevos campos.',
            title1: 'Campos del nodo',
            intro2: 'Para agregar un campo adicional al nodo seleccionado, simplemente haz clic aquí.',
            title2: 'Crear nuevo campo',
            intro3: 'Si has realizado modificaciones en los datos de los campos y deseas conservar los cambios, asegúrate de hacer clic aquí para guardarlos.',
            title3: 'Guardar cambios',               
        },
        introSelectCampo: {
            intro1: 'Antes de crear un nuevo campo, elige una de las siguientes opciones.',
            title1: 'Creación de campo',
            intro2: 'Si deseas crear un campo relacionado con una plantilla existente, selecciona esta opción.',
            title2: 'Campos con plantilla',        
            intro3: 'Para crear un campo personalizado desde cero, elige esta opción.',
            title3: 'Campo individual',               
        },
        introNodosList: {
            intro1: '¡Bienvenido! En esta sección, tendrás el control total sobre la gestión de tu estructura de nodos. Aquí te guiaremos a través de los pasos clave para que puedas organizar, editar y administrar tus nodos de manera efectiva.',
            title1: 'Tipos de documentos',
            intro2: 'Aquí podrás visualizar tus nodos principales. Estos nodos son la base sólida sobre la cual construirás tu organización y crearás tus propias estructuras de nodos.',
            title2: 'Nodos principales',
            intro3: 'Aqui podras crear un nuevo nodo principal a tu estructura, estos te ayudan a tener una mejor organización de tus documentos',
            title3: 'Crear nodo principal',
            intro4: 'Añadir nodos adicionales te permite crear subniveles dentro de un nodo principal. Esto facilita la organización y la navegación en tu estructura.',
            title4: 'Agregar nodo',
            intro5: 'La edición de nodos es útil cuando necesitas cambiar el nombre de un nodo existente para adaptarlo a tus necesidades.',
            title5: 'Editar nodo',
            intro6: 'A veces, es necesario deshacerse de nodos que ya no son relevantes. Ten en cuenta que si un nodo tiene subniveles, también se eliminarán.',
            title6: 'Eliminar nodo',
            intro7: 'Controlar quiénes pueden acceder a un nodo y su contenido es esencial para la seguridad de tu estructura. Utiliza esta opción ubicada dentro del nodo para configurar los permisos.',
            title7: 'Gestionar acceso',
            intro8: 'La reasignación de nodos te permite moverlos a otras partes de la estructura según sea necesario.',
            title8: 'Reasignar nodo',
            intro9: 'Sofiia® es una tecnología importante en algunos nodos. Puedes verificar si está activada o desactivada observando el indicador de color en el nodo. Estará en verde si está activado y en blanco y negro si está desactivado. 🟢⚪⚫',
            title9: 'Nodo con Soffia®',
            intro10: 'Si necesitas una vista más amplia de tu estructura para encontrar un nodo específico, utiliza esta opción.',
            title10: 'Desplegar estructura',
        },
        introDocumentsTmp: {
            //Con documentos
            intro1: '¡Bienvenido! En esta sección podrás ver tus documentos pendientes por clasificar. 😊',
            title1: 'Clasificar documentos',
            intro2: 'En esta sección, encontrarás el nombre del usuario al que se le ha asignado el documento. Es como saber quién es el responsable de este documento. 👤',
            title2: 'Usuario asignado',
            intro3: 'Aquí encontrarás la fecha en que el documento fue digitalizado. Es útil para identificar cuándo se ingresó el documento en el sistema. 📅',
            title3: 'Fecha de digitalización',
            intro4: 'En esta sección, encontrarás la descripción con la que se digitalizó el documento. Esto te ayudará a identificar de qué se trata el documento. 📝',
            title4: 'Descripción',
            intro5: 'Aquí podrás ver el tipo de documento. Esto te indicará la categoría a la que pertenece el documento. 📄',
            title5: 'Tipo de documento',
            intro6: 'Este boton te permite ingresar los campos del documento para indexarlo. 🖊️',
            title6: 'Indexar documento',
            intro7: '¿Te has equivocado de nodo al digitalizar? Este botón te permite reasignar el documento al nodo que desees.',
            title7: 'Reasignar nodo',
            intro8: '¿Te has equivocado de usuario al digitalizar? Este botón te permite reasignar el documento a otro usuario. Es como cambiar la responsabilidad del documento.',
            title8: 'Reasignar usuario',
            intro9: 'Si ya no necesitas el documento, este botón te permite eliminarlo. 🗑️',
            title9: 'Eliminar documento',
            //Sin documentos
            intro10: '¡Bienvenido! Actualmente no tienes documentos pendientes por clasificar. Aquí podrás ver tus documentos por indexar. 😊',
            title10: 'Clasificar documentos',
            intro11: '¡Genial! Puedes comenzar subiendo tus documentos aquí. 📤',
            title11: 'Subir documentos',        
        },
        introUsuarioCreate: {
            intro1: 'Aquí debes asignar un nombre de usuario para identificarlo.',
            title1: 'Asignar nombre de usuario',
            intro2: 'Introduce la dirección de correo electrónico del nuevo usuario. Asegúrate de que sea válida, ya que ahí se le enviará su contraseña y usuario para ingresar a la plataforma.',
            title2: 'Correo electronico',
            intro3: 'Asigna un rol dependiendo de las funciones que deseas que el nuevo usuario lleve a cabo.',
            title3: 'Rol del usuario',
            intro4: ' Selecciona el tipo de contraseña que tendrá el usuario. Se recomienda seleccionar "Temporal que no caduca" para permitir que el usuario cambie su contraseña al ingresar por primera vez.',
            title4: 'Estado de password',
            intro5: 'Indica el número de días durante los cuales la contraseña será válida. Deja en 0 días para que no venza.',
            title5: 'Vigencia de la contraseña',
            intro6: 'Activa este permiso para permitir al usuario agregar anotaciones a los documentos a los que tenga acceso.',
            title6: 'Permiso de anotaciones',
            intro7: 'Activa este permiso para habilitar la función de descarga de documentos o informes en CSV en las búsquedas.',
            title7: 'Permiso de exportar',
            intro8: 'Activa este permiso para permitir al usuario subir documentos a los nodos a los que tenga acceso.',
            title8: 'Permiso de importar',
            intro9: 'Activa este permiso para permitir al usuario subir documentos a los nodos a los que tenga acceso.',
            title9: 'Permiso de digitalizar',
            intro10: 'Activa este permiso para permitir al usuario utilizar la función de imprimir documentos a los que tenga acceso dentro del visor.',
            title10: 'Permiso de imprimir',
            intro11: 'Activa este permiso si deseas permitir al usuario enviar un documento desde el visor de documentos.',
            title11: 'Permiso de enviar',
            intro12: 'Activa este permiso si el usuario debe ver documentos sin marca de agua.',
            title12: 'Sin marca de agua',
            intro13: 'Configura los permisos para el acceso del usuario a los diferentes tipos de consultas y, por último, finaliza esta guía y haz clic en el botón de añadir.',
            title13: 'Permisos a consultas',
        },
        introUsuariosList: {
            intro1: '¡Bienvenido a la sección de usuarios! Aquí podrás ver y gestionar a los miembros de tu plataforma. 👥',
            title1: 'Usuarios',
            intro2: 'En esta sección, encontrarás el nombre de usuario. 👤',
            title2: 'Nombre de usuario',
            intro3: 'En esta sección, encontrarás el rol que tiene el usuario. Esto define sus permisos y funciones. 👤',
            title3: 'Rol de usuario',
            intro4: 'En esta sección, encontrarás el estado del usuario, te indica si la cuenta está activa o inhabilitada. 🟢🔴',
            title4: 'Estado del usuario',
            intro5: 'En esta sección, encontrarás el estado de la contraseña, te muestra la configuración actual de la contraseña.',
            title5: 'Estado de la contraseña',
            intro6: 'La vigencia de contraseña te dice cuánto tiempo es válida la contraseña del usuario, ¡o si es 0 días significa que no tiene vigencia! ⏳',
            title6: 'Vigencia de contraseña',
            intro7: 'En esta sección, encontrarás el correo electrónico del usuario. 📧',
            title7: 'Correo electrónico',
            intro8: 'Descubre a qué grupos pertenece el usuario en esta sección. 👥🔍',
            title8: 'Ver grupos',
            intro9: 'Aquí puedes modificar datos del usuario, cambiar su estado y ajustar sus permisos con la opción de editar ✏️.',
            title9: 'Editar usuario',
            intro10: 'Si ya no necesitas a un usuario, puedes eliminarlo desde esta opción. 🗑️',
            title10: 'Eliminar usuario',
            intro11: '¡Crea un nuevo usuario en un abrir y cerrar de ojos! Solo tienes que hacer clic aquí para ingresar los datos. ➕',
            title11: 'Crear nuevo usuario',
        },
        introVisor: {
            title1: 'Visor de documentos',
            intro1: '¡Bienvenido al Visor de Documentos! Exploraremos su estructura juntos.',
            title2: 'Documento 📄',
            intro2: 'Aquí podrás visualizar tu documento y realizar acciones en él.',
            title3: 'Barra de Herramientas 🛠️',
            intro3: 'Esta barra contiene todas las herramientas que necesitas para trabajar con el visor.',
            title4: 'Opciones del Documento 📤',
            intro4: 'En este menú, puedes exportar o imprimir tu documento.',
            title5: 'Opciones de Anotaciones ✍️',
            intro5: 'Accede aquí para guardar todas las anotaciones que añadas al documento.',
            title6: 'Opciones de Vista 👀',
            intro6: 'Cambia la manera en que ves el documento, rota y ajusta su tamaño desde aquí.',
            title7: 'Opciones de Página 📃',
            intro7: 'En este menú, puedes cambiar de página o ajustar la configuración de visualización de las páginas.',
            title8: 'Opciones de Interacción 🤝',
            intro8: 'Encuentra herramientas para interactuar con tu documento en esta sección.',
            title9: 'Cerrar Documento 🚪',
            intro9: 'Cierra el documento o esta pestaña desde aquí.',
            title10: 'Páginas 📚',
            intro10: 'Visualiza todas las páginas contenidas en el documento en esta sección.',
            title11: 'Herramientas de Anotaciones ✍️',
            intro11: 'Aquí encontrarás la barra de herramientas para agregar anotaciones al documento. <br/>¡Recuerda guardar tus anotaciones para que los cambios se reflejen! 📌',
        },
        loader: {
            texto1: 'Estamos recopilando sus datos.',
            texto2: 'Por favor, tenga en cuenta que este proceso podría llevar unos minutos.',
            texto3: 'Le agradecemos su paciencia mientras trabajamos en ello.'
        },
        stepsDownCsv: {
            title1: '¿Desea descargar todos los resultados en formato CSV?',
            text1: 'Se han encontrado un total de ',
            text2: 'resultados.',
            text3: 'Puede configurar la cantidad de resultados que desea descargar en formato CSV.\nEl sistema le notificará cuando los datos estén disponibles.',
            final: '¡Datos descargados correctamente!',
            btnConfig: 'Configurar',
            resultError: 'El número de resultados solicitados no es válido; por favor, revise los datos proporcionados.',
            title: 'Configurar descarga',
            inputNumber: 'Ingrese el numero de resultados que desea descargar:'
        }
    }
}

export default customSpanishMessages;