import React from "react";
export const conektaHeaders = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer key_mgColND6DMrCrFcsQdMy2Rm',
    'Accept': 'application/vnd.conekta-v2.1.0+json'
};

export const bearer_Token = {
    Authorization : 'key_mgColND6DMrCrFcsQdMy2Rm'

}

export const api = {
    URL : 'https://api.conekta.io/'
}

export const api_pay = {
    URL : 'https://pay.conekta.com/api/'
}

export const apimaxi = {
    URL : 'https://maxisky.com/APIMaximageEnrollment/Api/',
    Token : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IkFQSU1heGltYWdlRW5yb2xsbWVudCIsInJvbGUiOiIwIiwiU3BlY2lhbFRva2VuIjoidHJ1ZSIsIklkVXN1YXJpbyI6IjUzMTciLCJJZFNlc2lvbiI6IjAiLCJpZFRva2VuIjoiMThhOGM5NWYtOTdlYS00MTIxLTljMDktN2FhYjUyODY0NzlhIiwiUGVybWlzb3MiOiIiLCJuYmYiOjE2NjgwMjA4NjksImV4cCI6MTY2ODAyMDkyOSwiaWF0IjoxNjY4MDIwODY5LCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjQ0Mzg3IiwiYXVkIjoiaHR0cDovL2xvY2FsaG9zdDo0NDM4NyJ9.c_XCzhg4HQJs6PnF2wxXVkoi3IwCwvDs2oTE6L9gOUw'
}

export let clientData = {
  CustomerID: 0,
  PlatformCustomerId : ''
}

export const formatCurrency = (value) => {
	const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: value >= 1000 ? 0 : 2
	});
		return formatter.format(value);
};
export const formatText = (plan) => {
    const lines = plan.split('\r\n'); 
    const cascadingString = lines.map((line, index) => (
      <React.Fragment key={index}>
        {index > 0 && <br />} ● {line}
      </React.Fragment>
    ));
    return cascadingString
}
export const options = [
    { value: '1', label: 'Tecnología de la Información' },
    { value: '2', label: 'Salud y Medicina' },
    { value: '3', label: 'Finanzas y Banca' },
    { value: '4', label: 'Manufactura' },
    { value: '5', label: 'Educación' },
    { value: '6', label: 'Energía y Recursos Naturales' },
    { value: '7', label: 'Telecomunicaciones' },
    { value: '8', label: 'Retail' },
    { value: '9', label: 'Automoción' },
    { value: '10', label: 'Alimentación y Bebidas' },
    { value: '11', label: 'Construcción' },
    { value: '12', label: 'Turismo y Hospitalidad' },
    { value: '13', label: 'Medios de Comunicación' },
    { value: '14', label: 'Agricultura' },
    { value: '15', label: 'Servicios Profesionales'}
  ];

export const options_ = [
    { value: '1', label: 'Finanzas' },
    { value: '2', label: 'Ventas' },
    { value: '3', label: 'Marketing' },
    { value: '4', label: 'Operaciones' },
    { value: '5', label: 'Recursos humanos' },
    { value: '6', label: 'Logistica' },
  ];