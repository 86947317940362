import * as React from 'react';
import { useState , useEffect } from 'react';
import { FormWithRedirect, SaveButton, useTranslate } from 'react-admin';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import '../Libs/ExternalViewer/Resources/Styles/digitalizarStyles.css'
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import swal from 'sweetalert';

function BuscadorNodos(props) {
  const [nodoBuscado, setNodoBuscado] = useState(null);
  const [openNodosEncontrados, setOpenNodosEncontrados] = useState(false);
  const [resultadoNodos, setResultadoNodos] = useState([]);
  const translate = useTranslate();
  const nodos = props.data;

  useEffect(() => {
    props.onDataUpdated(resultadoNodos)
  }, [resultadoNodos]);

  const buscarNodo = (nodo, e) => {
        e.preventDefault();
        // console.log('Inicio buscarNodo_Buscador')
        if (nodo == null || nodo.trim() === '') {
            swal({
            icon: "info",
            text: translate('traducciones.modal.sinNombre'),
            timer: 1000,
            buttons: false
            });
        } else {
            const nodosEncontrados = [];
            const buscarNodoRecursivo = (nodoActual, rutaActual, nivel) => {
                if (nodoActual.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                    const nodoEncontrado = {
                    'ruta': rutaActual,
                    [`lvl`]: nivel,
                    [`n${nivel}`]: nodoActual
                    };

                    nodosEncontrados.push(nodoEncontrado);
                } else if (nodoActual.hijos.length > 0) {
                    nodoActual.hijos.forEach((hijo) => {
                    const nuevaRuta = `${rutaActual}/${hijo.TextoNodo}`;
                    buscarNodoRecursivo(hijo, nuevaRuta, nivel + 1);
                    });
                }
            };

        nodos.forEach((nPadre) => {
        const rutaInicial = `/${nPadre.TextoNodo}`;
        buscarNodoRecursivo(nPadre, rutaInicial, 1);
        });
          // console.log('Fin buscarNodo_Buscador: ',nodosEncontrados);
        if(nodosEncontrados.length==1){
          desplegarNodos_(nodosEncontrados[0])
        }else{
            setResultadoNodos(nodosEncontrados);
            setOpenNodosEncontrados(true)
        } 
    }
    };

  const desplegarNodos_ = (nodo) => {
    // console.log('desplegarNodos_');
    setResultadoNodos(nodo)
    setOpenNodosEncontrados(false)
  }


  return (
            <div>
              <div id="search-box">
                <div id="search-form">
                  <input
                    id="search-text"
                    placeholder="Buscar tipo de documento"
                    type="text"
                    onChange={(e) => setNodoBuscado(e.target.value)}
                  />
                  <button
                    id="search-button"
                    onClick={(e) => buscarNodo(nodoBuscado, e)}
                  >
                    <span><ManageSearchIcon style={{fontSize:'25px'}}/></span>
                  </button>
                </div>
              </div>
              <Dialog fullWidth open={openNodosEncontrados}>
                    <DialogTitle>{ translate('traducciones.titulos.resultadoNodos')}</DialogTitle>
                        <FormWithRedirect
                            resource="catalogos"
                            save={buscarNodo}
                            render={({
                                handleSubmitWithRedirect,
                                pristine,
                                saving
                            }) => (
                                <>
                                <DialogContent>
                                <div  className="row">
                                    <div  className="col-md-12 col-md-custom"  style={{ overflowX: "auto", padding: "10px"}}>
                                        <span style={{fontSize:'15px'}} hidden={ resultadoNodos.length > 0 ? false : true }>Seleccione la ruta del nodo que desea: </span><br/><br/>
                                    {resultadoNodos.length>0 ? resultadoNodos.map(item=>{
                                        return(
                                            <div>
                                               <center><input className='resultNodos' type='text' value={item.ruta} onClick={() =>desplegarNodos_(item)} readOnly/></center>     
                                            </div>
                                        )
                                    }): 
                                        <div>
                                            <span style={{marginLeft:'42%',fontSize:'2vh'}}><b>No se encontraron resultados</b></span>    
                                        </div>
                                    }    
                                    </div>
                                </div>
                                </DialogContent>
                                <DialogActions>
                                    <SaveButton
                                        label="ra.action.add"
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        hidden
                                    />
                                    <Button onClick={(e)=>setOpenNodosEncontrados(false)}>
                                        { translate('ra.action.cancel')}
                                    </Button>
                                </DialogActions>
                                </>
                            )}
                        />
              </Dialog>
    </div>
  );
}

export default BuscadorNodos;
