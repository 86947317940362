export const httpRequest = (url, body) => {
    const request = new Request(url, body);    
    return fetch(request)
    .then(async response => {
        if (response.status === 401) {
            return Promise.reject({message : "Unathoirized", status : 401});
        }
        if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
        }
        return {
            json: await response.json(), 
            status : response.status
        };
    })
    .catch((error) => {
        return Promise.reject({message : error.message, status : 401});
    })
    .then(({ status, json}) => {
        if (status === 401) {
            return Promise.reject({message : "Unathoirized", status : 401});
        }
        return Promise.resolve({ status, json });
    });
}