import * as React from "react";
import { AppBar, UserMenu, useTranslate,MenuItemLink, SimpleForm, SaveButton, TextInput, Button, BooleanInput} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";

/*
Componente que crear un App Bar personalizado

https://marmelab.com/react-admin/doc/3.18/Theming.html#replacing-the-appbar

*/

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    bar: {
        background: "linear-gradient(121deg, rgb(117 125 133) 0%, rgb(159 159 159) 63%, rgb(49 155 66) 100%)",
        maxHeight: '150px;',
        top: 'auto',
        marginTop: '-5px',
        paddingTop: '95px'
    },
    tool: {
        minHeight: '35px;'
    },
    banner: {
        height: '90px;'
    },
    userName : {
        position : 'absolute',
        right : '20px',
        top : '95px'
    }
});

const Banner = () => (
    <div className={"banner"}>
    </div>
);

//Función que devuelve el logo con estilos, dependiendo si existe un logo de la empresa o si no lo hay
const log =()=>{
    if(localStorage.getItem('logoEmpresa')==='null' || localStorage.getItem('logoEmpresa')===null || localStorage.getItem('logoEmpresa')===undefined || localStorage.getItem('logoEmpresa')==='undefined' ){
        return(
        <img src={"data:image/svg+xml;base64,"+localStorage.getItem('logoEmpresa')} style={{ height: '14vh',paddingLeft:'0px',marginTop:'-2.5vh',marginLeft:'2.5vh',width:'110px',opacity:'0'}} alt=''/>
        )
    }else{
        return(
        <div style={{display:'inline', marginBottom:'30vh',color:'white',paddingTop:'inherit'}}>
            <hr style={{width:'12%',rotate:'90deg', border:'1px solid',display:'inline', marginBottom:'30vh',color:'white',marginLeft:'2vh',paddingTop:'inherit'}}/>    
            <img src={"data:image/svg+xml;base64,"+localStorage.getItem('logoEmpresa')} style={{ height: '14vh',paddingLeft:'0px',marginTop:'-2.5vh',marginLeft:'2vh',width:'110px'}} alt=''/>
        </div>
    )
    }
}

//Componente usado para mostrar el logo en el AppBar
const Logo = () => (
    <div className={"logo"}>
        <img className={"logo-img"} src={"8.png"} alt={"MAXIMAGE"}/>
        {log()}
    </div>
);

const CustomUserMenu = (props) => {
    const translate = useTranslate();
    
    //Componente creado para agregar un nuevo Item al Menu del usuario, siguiendo la documentación de React-Admin, se creo una nueva vista que nos permite modificar en este caso la configuración de la cuenta del usuario.
    const ConfigurationMenu = React.forwardRef(({ onClick }, ref) => (
        <MenuItemLink
            ref={ref}
            to='/ConfigurationUser'
            primaryText={translate('traducciones.menu.cuenta')}
            leftIcon={<ManageAccountsIcon />}
            onClick={onClick} // close the menu on click
        />
    ));
      
    return (
    <div style={{display: 'contents'}}>
        <div style={{ float: "left"}}>{translate('traducciones.login.bienvenido')} : { localStorage.getItem("userNombre") }  </div>
        <div id="userMenu">
        <UserMenu {...props} label={props.usuario} children={
            <div style={{ padding: "10px", textAlign: 'center' }}> 
                {translate('traducciones.campos.usuario')}: { localStorage.getItem("userName") } 
            </div>} 
        >
            <ConfigurationMenu/>
        </UserMenu>
        </div>
    </div>

)};


const CustomAppBar= ({ width, ...props }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const [openHelp,setOpenHelp] = useState(false)
    const permisosGuias = {
        querys: false,
        carga: false,
        admin: false,
        estructura: false,
    }

    const inTour = localStorage.getItem('activeTour') ? JSON.parse(localStorage.getItem('activeTour')) : permisosGuias

    const handleChangeQuerys = (e) =>{
        inTour.querys = e;
        localStorage.setItem('activeTour', JSON.stringify(inTour));
        localStorage.setItem('inTourQuerys',e)
        if(e){
            if(document.cookie == 'introjs-dontShowAgain=true'){
                document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
            }
        }
    }

    const handleChangeCarga = (e) =>{
        inTour.carga = e;
        localStorage.setItem('activeTour', JSON.stringify(inTour));
        localStorage.setItem('inTourCarga',e)
        if(e){
            if(document.cookie == 'introjs-dontShowAgain=true'){
                document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
            }
        }
    }

    const handleChangeAdmin = (e) =>{
        inTour.admin = e;
        localStorage.setItem('activeTour', JSON.stringify(inTour));
        if(e){
            if(document.cookie == 'introjs-dontShowAgain=true'){
                document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
            }
        }
    }

    const handleChangeEstructura = (e) =>{
        inTour.estructura = e;
        localStorage.setItem('activeTour', JSON.stringify(inTour));
        if(e){
            if(document.cookie == 'introjs-dontShowAgain=true'){
                document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
            }
        }
    }

    var userLoged = "";
    if(window.$conf_general != undefined && window.$conf_general['nomUser'] != undefined){
        userLoged =  window.$conf_general['nomUser'];
    }
    return (
    <div style={{marginTop:'-5px'}}>
    <AppBar {...props} elevation={1} className= { classes.bar }  userMenu={<CustomUserMenu usuario={ userLoged} />}>
    <Banner className={classes.banner} />
        <Logo />
        <Toolbar className={classes.tool}>            
            <Typography variant="h6" id="react-admin-title" className={classes.title} />
        </Toolbar>
        { width != "xs" ? (
        <div style={{ position: 'absolute', right: '20px', top: '-40px'}}>
            <a onClick={()=>setOpenHelp(true)} id="help" style={{ color: 'white', textDecoration: 'none', cursor: 'pointer' }}>{translate('traducciones.login.ayuda')}</a>
            <a href="https://www.maximage-ds.com/contacto" target="_blank" style={{ marginLeft: '30px', color: 'white', textDecoration: 'none' }}>{translate('traducciones.login.contactenos')}</a>
        </div>
        ) : 
        (
        <div style={{ position: 'absolute', paddingRight: '20px', top: '-20px', width: "100%", textAlign: 'right'}}>
            <a href="#" style={{ color: 'white', textDecoration: 'none' }}>{translate('traducciones.login.ayuda')}</a>
            <a href="#" style={{ marginLeft: '30px', color: 'white', textDecoration: 'none' }}>{translate('traducciones.login.contactenos')}</a>
        </div>
        )
        }
        <span className={classes.spacer} />
        { width != "xs" ? (
        <div>
            <div style={{ width: '100%', textAlign: 'center'}}>
                © {(new Date().getFullYear())} Maximage Data Systems
            </div>
        </div>
        ) : null }
    </AppBar>
    <Dialog open={openHelp}>
        <DialogTitle>{translate('traducciones.login.ayuda')}</DialogTitle>
        <DialogContent>
            <SimpleForm toolbar = { 
                <Toolbar style={{float:'right'}}>
                    <Button label={translate('traducciones.visor.cerrar')} onClick={()=>setOpenHelp(false)}/>
                </Toolbar> } >
                    <span style={{fontWeight:'bold', fontSize:'14px'}}>{translate('traducciones.modal.ayuda')}</span>
                    <div style={{width:'100%'}}>
                    <BooleanInput label={translate('traducciones.botones.guiasQuerys')} source='GuiasQ' onChange={(e)=>handleChangeQuerys(e)} defaultValue={inTour.querys ? inTour.querys : false } style={{marginTop:'5px'}} />
                    <BooleanInput label={translate('traducciones.botones.guiasDigit')} source='GuiasD' onChange={(e)=>handleChangeCarga(e)} defaultValue={inTour.carga ? inTour.carga : false} style={{marginTop:'-20px'}}/>
                    <BooleanInput label={translate('traducciones.botones.guiasAdmin')} source='GuiasA' onChange={(e)=>handleChangeAdmin(e)} defaultValue={inTour.admin ? inTour.admin : false} style={{marginTop:'-20px'}} />
                    <BooleanInput label={translate('traducciones.botones.guiasEstructura')} source='GuiasE' onChange={(e)=>handleChangeEstructura(e)} defaultValue={inTour.estructura ? inTour.estructura : false} style={{marginTop:'-20px'}}/>
                    </div>
            </SimpleForm>
        </DialogContent>
    </Dialog> 
    </div>
)};

export default withWidth()(CustomAppBar);