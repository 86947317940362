import * as React from 'react';
import { useState, useEffect} from 'react';
import {
    FormWithRedirect,
    SaveButton,
    useCreate,
    useDelete,
    TextInput,
    useTranslate,
    SimpleForm,    
    Loading, 
    Error, 
    required, 
    Create,
    useRefresh,
    Query,
    useNotify,
    AutocompleteArrayInput,
    useDataProvider,
    DateInput,
    ArrayInput,
    SimpleFormIterator,
    AutocompleteInput
} from 'react-admin';
import Button from '@material-ui/core/Button';
import FolderIcon from '@material-ui/icons/Folder';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import "../Libs/ExternalViewer/Resources/Styles/shr.css"
import sofiia from '../Libs/ExternalViewer/Resources/Images/sofiiaLogo.png'
import sofiiaText from '../Libs/ExternalViewer/Resources/Images/sofiia.png'
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import swal from 'sweetalert';
import { ComponentPAY } from '../Components/ComponentPay'
import Container from '@mui/material/Container';
import jwt_decode from "jwt-decode";
import introJs from 'intro.js';
import 'intro.js/introjs.css';

const useStyles = makeStyles({
    nodo : {
        padding: '6px',
        border: '1px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
    },
    nodoSeleccionado :  {
        padding: '6px',
        border: '1px solid black',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        backgroundColor: "#319b4257",
        color: '#277233'
    },
    oculto : {
        visibility : "hidden"
    }
});

function dibujaNodosHijos(nodo,classes,addNode,editNode,deleteNode,translate,nodoPadreElemento,users,checkPermiso,colapsar,checknodoabierto, reubicarNodo, rol){
    if(nodo.hijos !== undefined){
        return (
            <div>
                {nodo.hijos.map(
                (item) => {
                    checknodoabierto(item);
                    return (
                    <div  style={{ marginLeft: '40px', marginTop : '-5px', width: 'max-content'}}>
                        <div style={{ float: 'left' }}>
                            <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                        </div>
                        <div className= { nodoPadreElemento !== null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > 
                        <FolderIcon style={{ marginRight: '5px'}} />
                            { item.TextoNodo }
                            { checkPermiso('AgregarNodos') &&
                            <Button title={ translate('traducciones.titulos.addNodosHijos')} onClick={() => addNode(item)} style={{ marginLeft: "5px", padding: 0}}>
                                <AddIcon/>
                            </Button>
                            }
                            { checkPermiso('EditarNodos') &&
                            <Button title={ translate('traducciones.titulos.editNodo')} onClick={() => editNode(item)} style={{ marginLeft: "5px", padding: 0}}>
                                <EditIcon/>
                            </Button>
                            }
                            {/* Validar eliminación de plantillas con el rol del usuario 0 -> Admin, 2 en Maxi */}
                            { checkPermiso('EliminarNodos') &&
                            <Button title={ translate('traducciones.titulos.deleteNodo')} onClick={() => deleteNode(item)} style={{ marginLeft: "5px", padding: 0}}>
                                <DeleteIcon/>
                            </Button>
                            }   
                            { checkPermiso('AsignarAccesoaNodos') &&
                            <Button title={ translate('traducciones.titulos.editarUsuario')} onClick={() => users(item)} style={{ marginLeft: "5px", padding: 0}}>
                                <SupervisorAccountIcon/>
                            </Button>
                            }
                            { checkPermiso('EditarNodos') &&
                            <Button title={ translate('traducciones.titulos.reubicarNodo')} onClick={() => reubicarNodo(item)} style={{ marginLeft: "5px", padding: 0}}>
                                <LocationSearchingIcon/>
                            </Button>
                            }
                            { item.FullText ? (
                                <Button title={ translate('traducciones.titulos.fullTextOn')}  style={{ marginLeft: "5px", padding: 0}}>
                                    <img src={sofiia} style={{width:'45px', filter:'brightness(1) saturate(2.5)'}} />
                                </Button>) : (
                                <Button title={ translate('traducciones.titulos.fullTextOff')}  style={{ marginLeft: "5px", padding: 0}}>
                                    <img src={sofiia} style={{width:'40px',filter: 'grayscale(1)'}} />
                                </Button>
                            ) }
                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                <ExpandLessIcon />
                            </Button>
                            }
                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                <ExpandMoreIcon />
                            </Button>
                            }
                        </div>
                        { !item.colapsado &&
                        <div>
                            { dibujaNodosHijos(item,classes,addNode, editNode,deleteNode,translate,nodoPadreElemento,users,checkPermiso,colapsar,checknodoabierto,reubicarNodo,rol) }
                        </div>
                        }
                    </div>
                )})
                }
            </div>
        )
    }
}

function dibujaNodosHijosRelocate(nodo,classes,viewNode,translate,nodoPadreElemento,nodoActual,colapsar){
    if(nodo.hijos != undefined){
        return (
            <div>
                {nodo.hijos.map(
                (item) => (
                    <div  style={{ marginLeft: '40px', marginTop : '-5px'}}>
                        <div style={{ float: 'left' }}>
                            <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                        </div>
                        <div className={ nodoActual == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > <FolderIcon style={{ marginRight: '5px'}} />
                            { item.TextoNodo }
                            <Button className= { nodoActual == item.RutaNodo ? classes.oculto : "" }  title={ translate('traducciones.titulos.reubicar')} onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                <LocationSearchingIcon style={{ fontSize: '16px'}}  />
                            </Button>
                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                <ExpandLessIcon />
                            </Button>
                            }
                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                <ExpandMoreIcon />
                            </Button>
                            }
                        </div>
                        { !item.colapsado &&
                        <div>
                            { dibujaNodosHijosRelocate(item,classes,viewNode,translate,nodoPadreElemento,nodoActual,colapsar) }
                        </div>
                        }
                    </div>
                ))
                }
            </div>
        )
    }
}

const NodosList = ({ ...props }) => {   

    const permisos = props.permissions ? props.permissions.Nodos : null;  
    const checkPermiso = (permiso) => {
        if(permisos){
            return permisos[permiso];
        }
        return false;
    }
    const inTour = JSON.parse(localStorage.getItem('activeTour'))

    const classes = useStyles();
    const notify = useNotify();
    const [nodoPadreElemento, setnodoPadreElemento] = useState(null);    
    const [loadinga, setLoadinga] = useState(false);
    const [openAdd, setopenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setopenDelete] = useState(false);
    const [openEditUser, setOpenEditUser] = useState(false);  
    const [openChangeNode, setOpenChangeNode] = useState(false);
    const [nodoActual, setNodoActual] = useState("");
    
    const [dataUsers, setDataUsers] = useState(null);
    const [dataGrupos, setDataGrupos] = useState(null);

    const [randomUpdate, setRandomUpdate] = useState(null);
    
    const [dataUsersSeleccionados, setDataUsersSeleccionados] = useState(null);
    const [dataGruposSeleccionados, setDataGruposSeleccionados] = useState(null);

    const [dataUsersNotiSeleccionados, setDataUsersNotiSeleccionados] = useState(null);
    const [dataGruposNotiSeleccionados, setDataGruposNotiSeleccionados] = useState(null);

    const [create] = useCreate('nodos');
    const [deleteOne] = useDelete();
    const translate = useTranslate();
    const refresh = useRefresh();

    const [datanodosAbiertos, setDatanodosAbiertos] = useState([]);

    const dataProvider = useDataProvider();

    //New Variable para mostrar el Modal para editar los permisos a usuarios y grupos con una fecha de vencimiento
    const [openUserVigencia,setOpenUserVigencia] = useState(false);
    //Variable para indicar si el nodo tendra habilitado el OCR o dehabilitado
    const [fullText,setFullText] = useState(false);

    //Variables agregadas para el buscador de nodos
    const [nodoBuscado,setNodoBuscado] = useState(null);
    const [nodos,setNodos] = useState(null);
    const [openNodosEncontrados,setOpenNodosEncontrados] = useState (false)
    const [resultadoNodos, setResultadoNodos] = useState([])
    
    //Validación de tipo de cliente para restringir la creación de Nodos raiz
    const token = localStorage.getItem('token')
    let isMulticliente; 
    const dataToken = jwt_decode(token);
    if(token){
        if(dataToken.MultiClientSession == 'True' || dataToken.MultiClientSession == 'true' || dataToken.MultiClientSession == true){
            isMulticliente = true; 
        }else{
            isMulticliente = false;
        }
    } 

    let introHelp;
    introHelp = introJs('#root');
    introHelp.setOptions({
        disableInteraction: false,
        doneLabel: translate('traducciones.introBotones.doneLabel'),
        nextLabel: translate('traducciones.introBotones.nextLabel'),
        prevLabel: translate('traducciones.introBotones.prevLabel'),
        tooltipPosition:'top',
        steps: [
                {
                intro: translate('traducciones.introHelp.intro1'),
                title: translate('traducciones.introHelp.title1'),
                element: '#help'
                },
        ]                
    });

    let introNodos;
    useEffect(() => {
        if(inTour.estructura){
            setTimeout(()=>{
                introNodos = introJs();
                introNodos.setOptions({
                    disableInteraction: true,
                    doneLabel: translate('traducciones.introBotones.doneLabel'),
                    nextLabel: translate('traducciones.introBotones.nextLabel'),
                    prevLabel: translate('traducciones.introBotones.prevLabel'),
                    tooltipPosition:'top',
                    steps: [
                        {
                            intro: translate('traducciones.introNodosList.intro1'),
                            title: translate('traducciones.introNodosList.title1'),
                            position: 'bottom',
                            scrollTo: 'off'
                        },
                        {
                            intro: translate('traducciones.introNodosList.intro2'),
                            title: translate('traducciones.introNodosList.title2'),
                            element: '#nodo',
                            position: 'bottom'
                        },
                        {
                            intro: translate('traducciones.introNodosList.intro3'),
                            title: translate('traducciones.introNodosList.title3'),
                            element: '#createNodoPadre',
                            position: 'bottom'
                        },
                        {
                            intro: translate('traducciones.introNodosList.intro4'),
                            title: translate('traducciones.introNodosList.title4'),
                            position: 'bottom',
                            element: '#addNodo'
                        },
                        {
                            intro: translate('traducciones.introNodosList.intro5'),
                            title: translate('traducciones.introNodosList.title5'),
                            element: '#editNodo',
                            position: 'bottom'
                        },
                        {
                            intro: translate('traducciones.introNodosList.intro6'),
                            title: translate('traducciones.introNodosList.title6'),
                            position: 'bottom',
                            element: '#deleteNodo'
                        },
                        {
                            intro: translate('traducciones.introNodosList.intro7'),
                            title: translate('traducciones.introNodosList.title7'),
                            element: '#accessUser',
                            position: 'bottom'
                        },
                        {
                            intro: translate('traducciones.introNodosList.intro8'),
                            title: translate('traducciones.introNodosList.title8'),
                            element: '#asigNodo',
                            position: 'bottom'
                        },
                        {
                            intro: translate('traducciones.introNodosList.intro9'),
                            title: translate('traducciones.introNodosList.title9'),
                            element: '#sofiia',
                            position: 'bottom'
                        },
                        {
                            intro: translate('traducciones.introNodosList.intro10'),
                            title: translate('traducciones.introNodosList.title10'),
                            element: '#more',
                            position: 'bottom'
                        }                        
                    ]                
                  });
        
                introNodos.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                    showHelp()
                }).onexit(()=>{
                    showHelp()
                }).start();
            },1000)
        }
      }, []);

    const showHelp = () =>{
        const offPermisos = {
            querys: false,
            carga: false,
            admin: false,
            estructura: false,
        }
        if(document.cookie == 'introjs-dontShowAgain=true'){
            document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
                setTimeout(()=>{
                    introHelp.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).onexit(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).start();                         
                },500)
        }
    }

    function crearCookie(nombre, valor, diasParaExpirar) {
    var fechaExpiracion = new Date();
    fechaExpiracion.setDate(fechaExpiracion.getDate() + diasParaExpirar);
    var cookie = nombre + '=' + valor + '; expires=' + fechaExpiracion.toUTCString() + '; path=/';
    document.cookie = cookie;
    }

    const handleCloseClick = () => {
        setopenAdd(false);
        setopenDelete(false);
        setOpenEditUser(false);
        setOpenEdit(false);
        setOpenChangeNode(false);
        setOpenUserVigencia(false);
        setOpenNodosEncontrados(false);
    }

    const handleSubmit = async values => {
        setLoadinga(true);
        create(
            { payload: { data: {NodeName : values.nodoName, IdParentNode : nodoPadreElemento != null ? nodoPadreElemento.RutaNodo : "/", FullText: fullText} } },
            {
                onSuccess: ({ data }) => {
                    setopenAdd(false);
                    setLoadinga(false);
                },
                onFailure: ({ error, message }) => {
                    setLoadinga(false);
                    notify(message, 'error');
                }
            }
        );
    };

    const handleUpdateName = async values => {
        setLoadinga(true);
        values.IdNode = nodoPadreElemento.RutaNodo;
        dataProvider.update('actualizarNombreNodo', { values })
        .then((json) => {
            if(json.data.Success){
                setOpenEdit(false);
                setLoadinga(false);
            }else{
                setLoadinga(false);
            }
        })
        .catch(error => {
            swal({
                icon:'error',
                text: error.message
            })
            setLoadinga(false)
        }); 
    };

    const handleSubmitDelete = async values => {
        setLoadinga(true);        
        deleteOne('nodos', nodoPadreElemento.id, nodoPadreElemento,{
            onSuccess: ({ data }) => {
                setopenDelete(false);
                setLoadinga(false);
                refresh();
            },
            onFailure: ({ error }) => {
                setLoadinga(false);
                notify(error, 'error');
            }
        });
        setLoadinga(false);  
        
    };

    //Funcion que actualiza los usuarios y grupos sin fecha de vencimiento, al igual que usuarios y grupos de notificación
    const handleSubmitEditUsers = async values => {
        setLoadinga(true);
        dataProvider.update('accessNode', 
        { 
            id: nodoPadreElemento != null ? nodoPadreElemento.RutaNodo : "/", 
            Users : values.usuarios, 
            Grupos : values.grupos,
            NotifyUsers : values.usuariosNoty,
            NotifyGroups : values.gruposNoty
        })
        .then(({ data }) => {
            setLoadinga(false);
            setOpenEditUser(false);
        })
        .catch(error => {
            notify('Revise los datos ingresados')
            setLoadinga(false);
        });   
    };

    //New Funcion que actualiza los permisos de los usuarios y grupos con una fecha de vencimiento, al igual que agregar usuarios y grupos de notificaciones
    const handleSubmitEditUsersVig = async values => {
        setLoadinga(true);
        let repUser = false;
        let repGroup = false;

        values.usuarios.forEach((usuario, index) => {
        const indexRepetido = values.usuarios.findIndex((u, i) => u.UserName === usuario.UserName && i !== index);
            if (indexRepetido !== -1) {
                repUser = true;
            }
        });

        values.grupos.forEach((usuario, index) => {
            const indexRepetido = values.grupos.findIndex((u, i) => u.GroupName === usuario.GroupName && i !== index);
                if (indexRepetido !== -1) {
                    repGroup = true;
                }
        });

        //New Arrays que filtran los campos vacios
        let userFilt=values.usuarios.filter((user)=> user.UserName !=undefined)
        let groupFilt=values.grupos.filter((group)=>group.GroupName !=undefined)

        //New Arrays que filtran los registros duplicados
        // const newArrayUser = userFilt.filter((item, index, arr) => {
        //     return arr.findIndex((t) => t.UserName === item.UserName) === index;
        // });
        // const newArrayGroup = groupFilt.filter((item, index, arr) => {
        //     return arr.findIndex((t) => t.GroupName === item.GroupName) === index;
        // });

        if( repUser || repGroup ){
            setLoadinga(false);
            swal({
                title: translate('traducciones.notificaciones.datosDuplicados'),
                text: translate('traducciones.notificaciones.duplicadosText'),
                icon:'info'
            })
        }else{
            dataProvider.update('setAccessNode', 
            { 
                id: nodoPadreElemento != null ? nodoPadreElemento.RutaNodo : "/", 
                Users : userFilt, 
                Grupos : groupFilt,
                NotifyUsers : values.usuariosNoty,
                NotifyGroups : values.gruposNoty
            })
            .then(({ data }) => {
                setLoadinga(false);
                setOpenUserVigencia(false);
                swal({
                    icon:'success',
                    text: translate('traducciones.notificaciones.actualizadosOk'),
                    timer:1500,
                })
            })
            .catch(error => {
                setLoadinga(false);
                swal({
                    icon:'error',
                    text: error.message ? error.message : 'Error con el servicio'
                })
            });   
        }
    };

    const deleteNode = (nodo) => {
        setnodoPadreElemento(nodo);
        setopenDelete(true);
    }

    const viewNode =  (nodo) => {
        setLoadinga(true);
        dataProvider.update('documentoMover', { IdSourceNode: nodoActual, IdDestinationNode: nodo.RutaNodo })
        .then(() => {
            setLoadinga(false);
            setOpenChangeNode(false);
        })
        .catch(error => {
            notify(error, 'error');
            setLoadinga(false);
        }); 
    }

    //Función que realiza el listado de los usuarios, grupos que tienen acceso al nodo, sin fechas de vencimiento para los permisos
    const editarUsuarios = (nodo) => {
        dataProvider.getList('usuarios', { filter:{} })
        .then(({ data }) => {
            if(dataUsers == null){
                setDataUsers(data);
            }
            dataProvider.getList('accessNode', { IdNode: nodo != null ? nodo.RutaNodo : "/"})
            .then(({ data }) => {                
                setnodoPadreElemento(nodo);
                var filtrados = [];
                var filtradosg = [];
                var filtradosn = [];
                var filtradosgn = [];
                if(data != null && data != undefined){
                    data.forEach(function (record) { 
                        if(record.type == "U"){
                            filtrados.push(record.id);
                        }
                        if(record.type == "G"){
                            filtradosg.push(record.id);
                        }
                        if(record.type == "UN"){
                            filtradosn.push(record.id);
                        }
                        if(record.type == "GN"){
                            filtradosgn.push(record.id);
                        }
                    });     
                    setDataUsersNotiSeleccionados(filtradosn)
                    setDataGruposNotiSeleccionados(filtradosgn)
                    setDataUsersSeleccionados(filtrados);
                    setDataGruposSeleccionados(filtradosg);
                }    
                dataProvider.getList('grupos', { filter:{} })
                .then(({ data }) => {
                    
                    if(dataGrupos == null){
                        setDataGrupos(data);
                    }  
                    setOpenEditUser(true);
                    // setOpenUserVigencia(true)
                });
            })
            .catch(error => {
            });   
        })
        .catch(error => {
        });
        
    }

    //New Funcion que obtiene el listado de los usuarios, grupos que tienen acceso al nodo, con fecha de vencimiento
    const editarUsuariosVigencia = (nodo) => {
        dataProvider.getList('usuarios', { filter:{} })
        .then(({ data }) => {
            if(dataUsers == null){
                setDataUsers(data);
            }
            dataProvider.getList('getNodeAccess', { IdNode: nodo != null ? nodo.RutaNodo : "/"})
            .then(({ data }) => {      
                setnodoPadreElemento(nodo);
                var filtrados = [];
                var filtradosg = [];
                var filtradosn = [];
                var filtradosgn = [];
                if(data != null && data != undefined){
                    data.forEach(function (record) { 
                        if(record.type == "U"){
                            filtrados.push(record.id);
                        }
                        if(record.type == "G"){
                            filtradosg.push(record.id);
                        }
                        if(record.type == "UN"){
                            filtradosn.push(record.id);
                        }
                        if(record.type == "GN"){
                            filtradosgn.push(record.id);
                        }
                    });     
                    setDataUsersSeleccionados(filtrados);
                    setDataGruposSeleccionados(filtradosg);
                    setDataUsersNotiSeleccionados(filtradosn);
                    setDataGruposNotiSeleccionados(filtradosgn);
                }    
                dataProvider.getList('grupos', { filter:{} })
                .then(({ data }) => {
                    if(dataGrupos == null){
                        setDataGrupos(data);
                    }  
                    setOpenUserVigencia(true);
                });
            })
            .catch(error => {
            });   
        })
        .catch(error => {
        });
        
    }

    const addNode = (nodoPadre) => {
        if(nodoPadre != null){
            setnodoPadreElemento(nodoPadre);
        }else{
            setnodoPadreElemento(null);
        }
        setopenAdd(true);
    }

    const editNode = (nodoPadre) => {
        if(nodoPadre != null){
            setnodoPadreElemento(nodoPadre);
        }else{
            setnodoPadreElemento(null);
        }
        setOpenEdit(true);
    }

    const reubicarNodo = (nodoPadre) => {
        if(nodoPadre != null){
            setNodoActual(nodoPadre.RutaNodo);
        }else{
            setNodoActual(null);
        }
        setOpenChangeNode(true);
    }

    const colapsar = (nodo) => {
        setRandomUpdate(Math.random());
        if(nodo.colapsado){
            datanodosAbiertos.push(nodo.RutaNodo)
            setDatanodosAbiertos(datanodosAbiertos);
        }else{
            setDatanodosAbiertos(datanodosAbiertos.filter(function(nodoguardado) { 
                return nodoguardado !== nodo.RutaNodo;
            }));
        }
        nodo.colapsado = !nodo.colapsado;
    }

    const checknodoabierto = (nodo) => {
        if(datanodosAbiertos.includes(nodo.RutaNodo)){
            nodo.colapsado = false;
        }
    }

    if(loadinga){
        return <Loading />
    }

    const checkFullText = (nodo, e) => {
        // e.preventDefault();
        // nodo.FullText ? alert('¿Desea desactivar Sofiia?') : alert('¿Desea activar Sofiia?')        
    }

    const buscarNodo = (nodo,e) => {
        e.preventDefault();
        if(nodo==null || nodo.trim()==''){
            swal({
                icon: "info",
                text: translate('traducciones.modal.sinNombre'),
                timer: 1000,
                buttons:false
              });
        }else{
        let nodosEncontrados=[];
        nodos.map((nPadre) => {
          if (nPadre.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
            nodosEncontrados.push({
                'ruta':"/"+nPadre.TextoNodo,
                'n1':nPadre,
                'lvl':1
            })
          } else if (nPadre.hijos.length > 0) {
            nPadre.hijos.map((n2) => {
              if (n2.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                nodosEncontrados.push({
                    'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo,
                    'n1':nPadre,
                    'n2':n2,
                    'lvl':2
                })
              } else if (n2.hijos.length > 0) {
                n2.hijos.map((n3) => {
                  if (n3.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                    nodosEncontrados.push({
                        'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo,
                        'n1':nPadre,
                        'n2':n2,
                        'n3':n3,
                        'lvl':3
                    })
                  } else if (n3.hijos.length > 0) {
                    n3.hijos.map((n4) => {
                      if (n4.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                        nodosEncontrados.push({
                            'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo + "/" + n4.TextoNodo,
                            'n1':nPadre,
                            'n2':n2,
                            'n3':n3,
                            'n4':n4,
                            'lvl':4
                        })
                      } else if (n4.hijos.length > 0) {
                        n4.hijos.map((n5) => {
                          if (n5.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                            nodosEncontrados.push({
                                'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo + "/" + n4.TextoNodo + "/" + n5.TextoNodo,
                                'n1':nPadre,
                                'n2':n2,
                                'n3':n3,
                                'n4':n4,
                                'n5':n5,
                                'lvl':5
                            })
                          }else if(n5.hijos.length > 0){
                            n5.hijos.map(n6=>{
                                if (n6.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                                    nodosEncontrados.push({
                                        'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo + "/" + n4.TextoNodo + "/" + n5.TextoNodo + "/" + n6.TextoNodo,
                                        'n1':nPadre,
                                        'n2':n2,
                                        'n3':n3,
                                        'n4':n4,
                                        'n5':n5,
                                        'n6':n6,
                                        'lvl':6
                                    })
                                }
                            })
                          }
                        });
                      }else{
                        //Si no hay hijos en nivel 5
                      }
                    });
                  } else {
                    //Si no hay hijos en nivel 4
                  }
                });
              } else {
                //Si no hay hijos en nivel 3
              }
            });
          } else {
            //Si no hay hijos en nivel 2
          }
        });
        if(nodosEncontrados.length==1){
            desplegarNodos(nodosEncontrados[0])
        }else{
            setResultadoNodos(nodosEncontrados);
            setOpenNodosEncontrados(true)
        }
    }
    };

    const desplegarNodos = (nodo) =>{
        switch(nodo.lvl){
            case 1: 
                setnodoPadreElemento(nodo.n1)
                setOpenNodosEncontrados(false)
            break;
            case 2:
                colapsar(nodo.n1);
                setnodoPadreElemento(nodo.n2)
                setOpenNodosEncontrados(false) 
            break;
            case 3:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                setnodoPadreElemento(nodo.n3)
                setOpenNodosEncontrados(false) 
            break;
            case 4:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                colapsar(nodo.n3);
                setnodoPadreElemento(nodo.n4)
                setOpenNodosEncontrados(false) 
            break;
            case 5:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                colapsar(nodo.n3);
                colapsar(nodo.n4);
                setnodoPadreElemento(nodo.n5)
                setOpenNodosEncontrados(false)
            break;
            case 6:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                colapsar(nodo.n3);
                colapsar(nodo.n4);
                colapsar(nodo.n5);
                setnodoPadreElemento(nodo.n6)
                setOpenNodosEncontrados(false)
            break;
            default: ;
        }
    }

    const validate = (values) =>{
        const users = [];
        const groups = [];

        setTimeout(() => {
            if(values.usuarios && values.grupos){
                values.usuarios.map((u) => {
                    if(u!=undefined){
                        if(u.UserName){
                            if(users.includes(u.UserName)){
                                swal({
                                    icon: 'info',
                                    text: translate('traducciones.notificaciones.usuarioDuplicado')+u.UserName
                                })
                            }else{
                                users.push(u.UserName)
                            }
                        }
                    }
                })
    
                values.grupos.map((g) => {
                    if(g!=undefined){
                        if(g.GroupName){
                            if(groups.includes(g.GroupName)){
                                swal({
                                    icon: 'info',
                                    text: translate('traducciones.notificaciones.grupoDuplicado')+g.GroupName
                                })
                            }else{
                                groups.push(g.GroupName)
                            }
                        }
                    }
                })
            }
        }, 500);
    }

    const handleClose = () => {
        setFullText(false)
    }
    const rol = localStorage.getItem('userRole');

    return (
        <Create {...props} title={ translate('traducciones.titulos.gestionDeNodos')} >
            <SimpleForm {...props} toolbar={ null } className="col-md-custom">
                <div id='content' fullWidth>
                { checkPermiso('AgregarNodos') && <Button id='createNodoPadre' hidden={isMulticliente} title={ translate('traducciones.titulos.addNodosPrincipal')} onClick={() => addNode(null)} style={{ marginLeft: "5px", padding: 0}}><AddIcon/></Button> }
                </div>
                <div fullWidth style={{ padding: '10px', overflow: 'auto'}} >
                <div id='search-box' style={{marginBottom:'15px'}}>
                    <div id='search-form' >
                    <input id='search-text' placeholder={translate('traducciones.campos.buscador')} type='text' onChange={(e) => setNodoBuscado(e.target.value)}/>
                    <button id='search-button' onClick={(e)=>buscarNodo(nodoBuscado,e)}><span><ManageSearchIcon style={{fontSize:'25px'}}/></span></button>
                    </div>
                </div>   
                <div id='listaNodos'>                    
                <Query type="getList" resource="nodos" payload={ null }>
                    {({ data, loading, error }) => {
                        setNodos(data)
                        if (loading) { return <Loading />; }
                        if (error) { return <Error />; }
                        return (
                            <div>
                            {   data.map(
                                (item) => {
                                    checknodoabierto(item);
                                    return (
                                    <div>
                                        <div id='nodo' className= { nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > 
                                            <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                            { item.TextoNodo } 
                                            { checkPermiso('AgregarNodos') &&
                                            <Button id='addNodo' title={ translate('traducciones.titulos.addNodosHijos')}  onClick={() => addNode(item)} style={{ marginLeft: "5px", padding: 0}}>
                                                <AddIcon/>
                                            </Button>
                                            }
                                            { checkPermiso('EditarNodos') &&
                                            <Button id='editNodo' title={ translate('traducciones.titulos.editNodo')} onClick={() => editNode(item)} style={{ marginLeft: "5px", padding: 0}}>
                                                <EditIcon/>
                                            </Button>
                                            }
                                            { checkPermiso('EliminarNodos') &&
                                            <Button id='deleteNodo' title={ translate('traducciones.titulos.deleteNodo')} hidden={isMulticliente} onClick={() => deleteNode(item)} style={{ marginLeft: "5px", padding: 0}}>
                                                <DeleteIcon/>
                                            </Button>
                                            }
                                            { checkPermiso('AsignarAccesoaNodos') &&
                                            <Button id='accessUser' title={ translate('traducciones.titulos.editarUsuario')} onClick={() => editarUsuariosVigencia(item)} style={{ marginLeft: "5px", padding: 0}}>
                                                <SupervisorAccountIcon/>
                                            </Button>
                                            }

                                            { checkPermiso('EditarNodos') &&
                                            <Button id='asigNodo' title={ translate('traducciones.titulos.reubicarNodo')} onClick={() => reubicarNodo(item)} style={{ marginLeft: "5px", padding: 0}}>
                                                <LocationSearchingIcon/>
                                            </Button>
                                            }
                                            { item.FullText ? (
                                                <Button id='sofiia' onClick={(e)=>checkFullText(item,e)} title={ translate('traducciones.titulos.fullTextOn')}  style={{ marginLeft: "5px", padding: 0}}>
                                                    <img src={sofiia} style={{width:'45px', filter:'brightness(1) saturate(2.5)'}} />
                                                </Button>) : (
                                                <Button id='sofiia' onClick={(e)=>checkFullText(item,e)} title={ translate('traducciones.titulos.fullTextOff')}  style={{ marginLeft: "5px", padding: 0}}>
                                                    <img src={sofiia} style={{width:'40px',filter: 'grayscale(1)'}} />
                                                </Button>
                                            ) }
                                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                <ExpandLessIcon />
                                            </Button>
                                            }
                                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                            <Button id='more' style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                <ExpandMoreIcon/>
                                            </Button>
                                            }
                                        </div>
                                        { !item.colapsado &&
                                        <div style={{ marginLeft: '-20px'}}>
                                            { dibujaNodosHijos(item,classes,addNode, editNode,deleteNode,translate,nodoPadreElemento,editarUsuariosVigencia, checkPermiso,colapsar,checknodoabierto, reubicarNodo,rol) }
                                        </div>
                                        }
                                    </div>
                                )})
                            }
                            </div>
                        );
                    }}
                </Query>
                </div>
                </div>
                {/**
                <Container  maxWidth="sm" sx={{ mb: 3 }}>
                 <Dialog open={fullText}>
                     <DialogTitle>Proceso de pago</DialogTitle>
                         <DialogContent>
                             <ComponentPAY/>
                         </DialogContent>
                         <DialogActions>
                             <Button onClick={handleClose} color="primary">Cerrar</Button>
                         </DialogActions>
                    </Dialog>
                </Container>
                 */}
                <Dialog
                    fullWidth
                    open={openAdd}
                    >
                    <DialogTitle>{ translate('traducciones.titulos.nuevoNodo')}</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmit}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            { nodoPadreElemento != null ? (
                            <div style={{ padding: '10px 0', fontWeight: 'bold'}}>{ translate('traducciones.titulos.nodoPadre') }: { nodoPadreElemento.TextoNodo }</div>
                            ) : null }
                            <TextInput style={{ width: '100%'}} source="nodoName" label="traducciones.campos.nodoNombre" validate={required()} />
                            <div class="checkbox-wrapper-7">
                                <input class="tgl tgl-ios" id="cb2-7" type="checkbox" checked={fullText} onChange={(e) => {
                                    setFullText(e.target.checked)
                                    localStorage.setItem('fullText',e.target.checked);
                                    }} source="fullText" name="fullText" value={fullText} defaultValue={fullText}/>
                                    <label class="tgl-btn" for="cb2-7" style={{ top: '10px' }} />
                           </div><p style={{marginLeft:'65px',marginTop:'-16px',fontSize:'16px',fontWeight:'bold'}}>{translate('traducciones.campos.activar')}  <img src={sofiia} style={{width:'50px',marginLeft:'5px',marginTop:'-3px'}}/> <img src={sofiiaText} style={{width:'400px',marginLeft:'5px'}}/>®</p>
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.add"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>  
                <Dialog
                    fullWidth
                    open={openDelete}
                    >
                    <DialogTitle>{ translate('traducciones.modal.eliminarIndice')}</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmitDelete}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            { translate('traducciones.modal.eliminarIndiceConfirm')}
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.confirm"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                                icon = { <DeleteIcon /> }
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>   
            
                {/* Dialog para editar los usuarios y grupos que tienen acceso a un nodo, sin fecha de vigencia */}
                <Dialog
                    fullWidth
                    open={openEditUser}
                    >
                    <DialogTitle>{ translate('traducciones.modal.editarUsuarios') + " " + (nodoPadreElemento != null ? nodoPadreElemento.TextoNodo : "") }</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmitEditUsers}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            <div className='row'>
                                <div className='col-md-6'>
                                    {dataUsers ? (
                                    <AutocompleteArrayInput options= { {fullWidth: true } }  optionText="Nombre" defaultValue={ dataUsersSeleccionados } optionValue="Nombre" source="usuarios" choices= { dataUsers ? dataUsers : null } style={{width:'100%'}} />
                                    ) : null }
                                </div>
                                <div className='col-md-6'>
                                    {dataGrupos ? (
                                    <AutocompleteArrayInput options= { {fullWidth: true } }  optionText="GrupoNombre" defaultValue={ dataGruposSeleccionados } optionValue="GrupoNombre" source="grupos" choices= { dataGrupos ? dataGrupos : null } style={{width:'100%'}} />
                                    ) : null }
                                </div>
                            </div>

                            <div className='row'>   
                                <div className='col-md-6'>   
                                {dataUsers ? (
                                    <AutocompleteArrayInput options= { {fullWidth: true } } label="traducciones.campos.usuariosNotificacion"  optionText="Nombre" defaultValue={ dataUsersNotiSeleccionados } optionValue="Nombre" source="usuariosNoty" choices= { dataUsers ? dataUsers : null } style={{width:'100%'}} />
                                    ) : null }
                                </div>
                                <div className='col-md-6'>   
                                    {dataGrupos ? (
                                    <AutocompleteArrayInput options= { {fullWidth: true } } label="traducciones.campos.gruposNotificacion"  optionText="GrupoNombre" defaultValue={ dataGruposNotiSeleccionados } optionValue="GrupoNombre" source="gruposNoty" choices= { dataGrupos ? dataGrupos : null } style={{width:'100%'}} />
                                    ) : null }
                                </div>
                            </div>

                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.save"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>      

                {/* Nuevo Dialog */}
                <Dialog
                    fullWidth={true}
                    maxWidth='md'
                    open={openUserVigencia}
                    style={{padding:'25px'}} 
                    >
                    <DialogTitle>{ translate('traducciones.modal.editarUsuarios') + " " + (nodoPadreElemento != null ? nodoPadreElemento.TextoNodo : "") }</DialogTitle>
                    <FormWithRedirect
                    validate={validate}
                    resource="nodos"
                    save={handleSubmitEditUsersVig}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => ( 
                        <>  
                        <DialogContent>
                                <TextInput type={'hidden'} optionText="Nombre" defaultValue={ dataUsersSeleccionados } optionValue="Nombre" source="usuarios" disabled label={translate('traducciones.menu.usuarios')} options={{InputLabelProps:{style:{color:'#545454',fontSize:'16px'}}}} />     
                                <ArrayInput source='usuarios' label=''>
                                    <SimpleFormIterator disableReordering  > 
                                            <AutocompleteInput choices= { dataUsers ? dataUsers : null } source="UserName" optionText='Nombre' optionValue='Nombre' options={{InputProps:{style:{maxWidth:'60%',display:'flex',float:'left'}}}} label={translate('traducciones.campos.usuario')}/>
                                            <DateInput source='Validity' id='fechaU' defaultValue={''}  style={{maxWidth:'35%',float:'left',display:'flex',marginLeft:'0px','. RaSimpleFormIterator-action':{marginTop:'20px'}}} label={translate('traducciones.campos.vencimiento')}/>
                                    </SimpleFormIterator>
                                </ArrayInput>
                                <TextInput type={'hidden'} optionText="GrupoNombre" defaultValue={ dataGruposSeleccionados } optionValue="Nombre" source="grupos" disabled label={translate('traducciones.menu.grupos')} options={{InputLabelProps:{style:{color:'#545454',fontSize:'16px'}}}}/>
                                <ArrayInput source='grupos' label=''>
                                    <SimpleFormIterator disableReordering>
                                        <AutocompleteInput choices= { dataGrupos ? dataGrupos : null } source="GroupName" optionText='GrupoNombre' optionValue='GrupoNombre' options={{InputProps:{style:{maxWidth:'60%',display:'flex',float:'left'}}}} label={translate('traducciones.campos.grupo')}  />
                                        <DateInput source='Validity' id='fechaG' defaultValue={''} style={{maxWidth:'35%',float:'left',display:'flex',marginLeft:'0px'}} label={translate('traducciones.campos.vencimiento')}/>
                                    </SimpleFormIterator>
                                </ArrayInput><hr className='hr-text' data-content={translate('traducciones.modal.notificaciones')}/>
                                <div className='row'>   
                                <div className='col-md-6'>   
                                {dataUsers ? (
                                    <AutocompleteArrayInput options= { {fullWidth: true } } label="traducciones.campos.usuariosNotificacion"  optionText="Nombre" defaultValue={ dataUsersNotiSeleccionados } optionValue="Nombre" source="usuariosNoty" choices= { dataUsers ? dataUsers : null } style={{width:'100%'}} />
                                    ) : null }
                                </div>
                                <div className='col-md-6'>   
                                    {dataGrupos ? (
                                    <AutocompleteArrayInput options= { {fullWidth: true } } label="traducciones.campos.gruposNotificacion"  optionText="GrupoNombre" defaultValue={ dataGruposNotiSeleccionados } optionValue="GrupoNombre" source="gruposNoty" choices= { dataGrupos ? dataGrupos : null } style={{width:'100%'}} />
                                    ) : null }
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.save"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog> 
                {/* Fin nuevo Dialog */}


                 <Dialog
                    fullWidth
                    open={openEdit}
                    >
                    <DialogTitle>{ translate('traducciones.titulos.cambiarNombreNodo')}</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleUpdateName}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            <TextInput style={{ width: '100%'}} source="nodoName" label="traducciones.campos.nodoNombre" validate={required()} defaultValue= { nodoPadreElemento ? nodoPadreElemento.TextoNodo : null} />
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.update"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>   


                <Dialog 
                fullWidth
                open={openChangeNode}>
            <DialogTitle>{ translate('traducciones.titulos.reubicarNodo')}</DialogTitle>
            <DialogContent>
                <div style={{ padding: '10px', minWidth: "400px"}}>       
                    <Query type="getList" resource="nodos">
                        {({ data, loading, error }) => {
                            if (loading) { return <Loading />; }
                            if (error) { return <Error />; }
                            return (
                                <div  className="row">
                                    <div  className="col-md-12">
                                    {   data.map(
                                        (item) => (
                                            <div>
                                                <div className= { nodoActual == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > 
                                                    <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                                    { item.TextoNodo } 
                                                    <Button className= { nodoActual == item.RutaNodo ? classes.oculto : "" } title={ translate('traducciones.titulos.reubicar')}  onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                                        <LocationSearchingIcon style={{ fontSize: '16px'}}/>
                                                    </Button>
                                                    { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                        <ExpandLessIcon />
                                                    </Button>
                                                    }
                                                    { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                        <ExpandMoreIcon />
                                                    </Button>
                                                    }
                                                </div>
                                                { !item.colapsado &&
                                                <div style={{ marginLeft: '-20px'}}>
                                                    { dibujaNodosHijosRelocate(item,classes,viewNode,translate,nodoPadreElemento,nodoActual,colapsar) }
                                                </div>
                                                }
                                            </div>
                                        ))
                                    }
                                    </div>                                        
                                </div>
                            );
                        }}
                    </Query>
                </div> 
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseClick} >
                    { translate('ra.action.close')}
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth open={openNodosEncontrados}>
                    <DialogTitle>{ translate('traducciones.titulos.resultadoNodos')}</DialogTitle>
                        <FormWithRedirect
                            resource="catalogos"
                            save={buscarNodo}
                            render={({
                                handleSubmitWithRedirect,
                                pristine,
                                saving
                            }) => (
                                <>
                                <DialogContent>
                                <div  className="row">
                                    <div  className="col-md-12 col-md-custom"  style={{ overflowX: "auto", padding: "10px"}}>
                                        <span style={{fontSize:'15px'}} hidden={ resultadoNodos.length > 0 ? false : true }>{translate('traducciones.modal.seleccionNodo')}: </span><br/><br/>
                                    {resultadoNodos.length>0 ? resultadoNodos.map(item=>{
                                        return(
                                            <div>
                                               <center><input className='resultNodos' type='text' value={item.ruta} onClick={()=>desplegarNodos(item)} readOnly/></center>     
                                            </div>
                                        )
                                    }): 
                                        <div>
                                            <span style={{marginLeft:'42%',fontSize:'2vh'}}><b>{translate('traducciones.modal.noResults')}</b></span>    
                                        </div>
                                    }    
                                    </div>
                                </div>
                                </DialogContent>
                                <DialogActions>
                                    <SaveButton
                                        label="ra.action.add"
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        hidden
                                    />
                                    <Button onClick={handleCloseClick} >
                                        { translate('ra.action.cancel')}
                                    </Button>
                                </DialogActions>
                                </>
                            )}
                        />
                </Dialog> 

            </SimpleForm>


            
        </Create>
)};

export default NodosList;
