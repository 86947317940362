import * as React from 'react';
import { useState  } from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import WrapTextIcon from '@material-ui/icons/WrapText';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BackupIcon from '@material-ui/icons/Backup';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ListIcon from '@material-ui/icons/List';
import BallotIcon from '@material-ui/icons/Ballot';
import jwt_decode from "jwt-decode";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from '@material-ui/core/Typography';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import '../index.css'

/*
Componente personalizado del menu de acciones.

https://marmelab.com/react-admin/doc/3.18/Theming.html#using-a-custom-menu

*/

function arrowGenerator(color) {
    return {
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.95em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '0 1em 1em 1em',
          borderColor: `transparent transparent ${color} transparent`,
        },
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.95em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '1em 1em 0 1em',
          borderColor: `${color} transparent transparent transparent`,
        },
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: '5px',
        height: '4em',
        top :"45%",
        width: '2em',
        '&::before': {
          borderWidth: '1em 1em 1em 0',
          borderColor: `transparent ${color} transparent transparent`,
        },
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: '-0.95em',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 0 1em 1em',
          borderColor: `transparent transparent transparent ${color}`,
        },
      },
    };
  }

const useStyles = makeStyles({
    divHead: {
        paddingLeft: '16px',
        paddingTop : '5px',
        height:'100%'
    },
    arrow: {
        position: 'absolute',
        fontSize: 6,
        width: '3em',
        height: '3em',
        '&::before': {
          content: '""',
          margin: 'auto',
          display: 'block',
          width: 0,
          height: 0,
          borderStyle: 'solid',
        },
    },
    menuTitle: {
        marginTop: '-20px',
        paddingLeft : '40px',
        marginBottom: '6px',
        fontWeight: '500' 
    },
    subMenu : {
        marginLeft : '-16px',
    },
    subMenuClose : {
        marginLeft : '-16px',
        paddingLeft : '30px',
    },
    top: {
        marginTop : '5px',
        paddingLeft: '16px',
        paddingTop : '5px',
        height:'135%'
    },
    opa : {
        width: '80%',
        marginLeft: '10%',
        position: 'absolute',
        bottom: '20px',
        opacity: '0.10',
    }
    ,
    htmlPopper: arrowGenerator('#368a48'),
    htmlTooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 300,
      fontSize: "12px",
      border: '3px solid #368a48',
      '& b': {
        fontWeight: '12px',
      },
      boxShadow : '2px 2px 23px #338c46',
      borderRadius: '7px',
      marginTop: '10px'
    },
});

const Menu = ({ onMenuClick, ...props }) => {

    const [arrowRef, handleArrowRef] = useState(null);

    const [firstTime, setFirsTime] = useState(false);

    var token = localStorage.getItem('token');
    var permissions = {};
    var decoded = {};        

    //Se asignan los permisos del localstorage a la variable para validar las opciones del Menu
    const permisos = JSON.parse(localStorage.getItem('permisos'))
    
    //Necesitamos evaluar si existe el token para obtener los permisos del mismo.
    let isMulticliente;
    if(token){
        decoded = jwt_decode(token); 
        const permisos = JSON.parse(decoded.Permisos); 
        if(decoded.MultiClientSession == 'True' || decoded.MultiClientSession == 'true' || decoded.MultiClientSession == true){
            isMulticliente = true; 
        }else{
            isMulticliente = false;
        }
        
        //Obtenemos los permisos del token para poder despues verificar menu por menu.
        permisos.RolePermissions.forEach(function (record) { 
                var permisoLsit = {};
                record.Permissions.forEach(function (perm) {
                    permisoLsit[perm.PermissionName.replaceAll(" ","")] = perm.Value; 
                });
                permissions[record.Module.replaceAll(" ","")] = permisoLsit;
        }); 
    }

    
    //Funcion para verificar si el usuario tiene permiso a un módulo especifico.
    const checkPermiso = (modulo,permiso) => {
        if(permissions && typeof permissions === 'object'){
            if(permissions[modulo]){
                return permissions[modulo][permiso];
            }else{
                return false;
            }
        }
        return false;
    }

    const classes = useStyles();
    const open = useSelector(state => state.admin.ui.sidebarOpen);

    const translate = useTranslate();

    document.addEventListener('click',function(e){
        const seleccionado= e.target
    })

    return (
        <div className={classes.top} id='menuScroll'>
            <div>
                <MenuItemLink
                    to="/dashboard"
                    primaryText={translate('traducciones.menu.escritorio')}
                    leftIcon={<DashboardIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    className={!open ? classes.subMenu: classes.subMenuClose}
                />
            </div>
            { 
            //Evaluamos la variable firstTime que muestra el menu de una manera distinta la primera vez que ingresa
            !firstTime ? (
            <div>

            { (checkPermiso('ConsultadeDocumentos','AccesoaModuloConsultadeDocumentos')) &&
            <div className={classes.divHead} id='querys'>
                <div>
                    <FolderOpenIcon />  
                    <div className = {classes.menuTitle} >
                        <Tooltip
                            open={false}
                            placement="right"
                            classes={{ popper: classes.htmlPopper,tooltip: classes.htmlTooltip }}
                            PopperProps={{ popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef, },},},}}
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">{translate('traducciones.menu.consultas')}</Typography>
                                        Agregar: leyenda “ingreso por primera vez” click aquí
                                    <span className={classes.arrow} ref={handleArrowRef} />
                                </React.Fragment>
                            }>
                                <span>{translate('traducciones.menu.consultas')}</span>
                        </Tooltip>
                    </div>
                </div>    
                    <MenuItemLink
                        to="/campos"
                        primaryText={translate('traducciones.menu.campos')}
                        leftIcon={<ViewAgendaIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                        disabled={!permisos.CONSULTAPORCAMPOS}
                    />
                    <MenuItemLink
                        to="/general"
                        primaryText={translate('traducciones.menu.general')}
                        leftIcon={<FileCopyIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                        disabled={!permisos.CONSULTAGENERAL}
                    />
                    <MenuItemLink
                        to="/bastanteo"
                        primaryText={translate('traducciones.menu.bastanteo')}
                        leftIcon={<FileCopyIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                        disabled
                    />
                    <MenuItemLink
                        to="/contenido"
                        primaryText={translate('traducciones.menu.contenido')}
                        leftIcon={<WrapTextIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                        disabled={!permisos.CONSULTAPORCONTENIDO}
                    />
                    <MenuItemLink
                        to="/navegar"
                        primaryText={translate('traducciones.menu.navegar')}
                        leftIcon={<BallotIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                        disabled={!permisos.CONSULTANAVEGAR}
                    />
                    <MenuItemLink
                        to="/predefinida"
                        primaryText={translate('traducciones.menu.predefinida')}
                        leftIcon={<BallotIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                    />
            </div>
            }
            { 
            //Ejemplo de como verificamos el permiso de un modulo
            (checkPermiso('IndexacióndeDocumentos','AccesoaModuloIndexacióndeDocumentos')) &&
            <div className={classes.divHead} id='digitalizacion'>
                <BackupIcon />  
                <div className = {classes.menuTitle} >
                    <Tooltip
                        open={firstTime}
                        placement="right"
                        classes={{ popper: classes.htmlPopper,tooltip: classes.htmlTooltip }}
                        PopperProps={{ popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef, },},},}}
                        title={
                            <React.Fragment>
                                <Typography color="inherit">{translate('traducciones.menu.digitalizar')}</Typography>
                                    Agregar: leyenda “ingreso por primera vez” click aquí
                                <span className={classes.arrow} ref={handleArrowRef} />
                            </React.Fragment>
                        }>
                        <span>{translate('traducciones.menu.digitalizar')}</span>
                    </Tooltip>
                </div>
                    <div id='indexar'>
                    <MenuItemLink
                        to="/digitalizarImagenes"
                        primaryText={translate('traducciones.menu.desdeArchivo')}
                        leftIcon={<PhotoLibraryIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                    />
                    
                    {/* Acceso al componente que permite la digitalización masiva */}
                    <MenuItemLink
                        to="/digitalizacionMasiva"
                        primaryText={translate('traducciones.menu.masivo')}
                        leftIcon={<PermMediaIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                    />
                    </div>
                    <MenuItemLink
                        to="/documentos"
                        primaryText={translate('traducciones.menu.indexar')}
                        leftIcon={<ListIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                    />
            </div>
            }
            { (checkPermiso('Grupos','AccesoaModuloGrupos') || checkPermiso('Grupos','AccesoaModuloGrupos')) &&
            <div className={classes.divHead} id='administracion'>
                <SupervisorAccountIcon />  
                
                    <div className = {classes.menuTitle} >
                        <Tooltip
                            open={false}
                            placement="right"
                            classes={{ popper: classes.htmlPopper,tooltip: classes.htmlTooltip }}
                            PopperProps={{ popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef, },},},}}
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">{translate('traducciones.menu.administracion')}</Typography>
                                        Agregar: leyenda “ingreso por primera vez” click aquí
                                    <span className={classes.arrow} ref={handleArrowRef} />
                                </React.Fragment>
                            }>
                                <span>{translate('traducciones.menu.administracion')}</span>
                        </Tooltip>
                    </div>
                    { checkPermiso('Usuarios','AccesoaModuloUsuarios') &&
                    <MenuItemLink
                        to="/usuarios"
                        primaryText={translate('traducciones.menu.usuarios')}
                        leftIcon={<PersonIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                    />
                    }
                    { checkPermiso('Grupos','AccesoaModuloGrupos') &&
                    <MenuItemLink
                        to="/grupos"
                        primaryText={translate('traducciones.menu.grupos')}
                        leftIcon={<GroupIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                    />
                    }
            </div>
            }
            { (checkPermiso('Nodos','AccesoaModuloNodos') || checkPermiso('Indices','AccesoaModuloIndices')) &&
            <div className={classes.divHead} id='estructura'>
                <SettingsIcon />  
                <div className = {classes.menuTitle} >
                    <Tooltip
                            open={false}
                            placement="right"
                            classes={{ popper: classes.htmlPopper,tooltip: classes.htmlTooltip }}
                            PopperProps={{ popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef, },},},}}
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">{translate('traducciones.menu.estructura')}</Typography>
                                        Agregar: leyenda “ingreso por primera vez” click aquí
                                    <span className={classes.arrow} ref={handleArrowRef} />
                                </React.Fragment>
                            }>
                                <span>{translate('traducciones.menu.estructura')}</span>
                    </Tooltip>
                </div>
                    { checkPermiso('Nodos','AccesoaModuloNodos') &&
                    <MenuItemLink
                        to="/nodos"
                        primaryText={translate('traducciones.menu.nodos')}
                        leftIcon={<AccountTreeIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                    />
                    }
                    { checkPermiso('Indices','AccesoaModuloIndices') && 
                    <MenuItemLink
                        to="/indices"
                        primaryText={translate('traducciones.menu.indices')}
                        leftIcon={<FormatIndentIncreaseIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                    />
                    }
                     <MenuItemLink
                        to="/catalogos"
                        primaryText={translate('traducciones.menu.catalogos')}
                        leftIcon={<BallotIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                                />
                    {/* <MenuItemLink
                        to="/plantillass"
                        primaryText={translate('traducciones.menu.plantillas')}
                        leftIcon={<BallotIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                        hidden={!isMulticliente}
                                /> */}

                                {/**
                                <div>
                            <MenuItemLink
                                to="/plantillas"
                                primaryText={translate('traducciones.menu.plantillas')}
                                leftIcon={<DashboardIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                className={!open ? classes.subMenu: classes.subMenuClose}
                            />
                        </div>            
                                 
                                */}
            </div>
            }
            </div>

            ):(

            <div>
            { (checkPermiso('Grupos','AccesoaModuloGrupos') || checkPermiso('Grupos','AccesoaModuloGrupos')) &&
            <div className={classes.divHead}>
                <SupervisorAccountIcon />  
                
                    <div className = {classes.menuTitle} >
                        <Tooltip
                            open={firstTime}
                            placement="right"
                            classes={{ popper: classes.htmlPopper,tooltip: classes.htmlTooltip }}
                            PopperProps={{ popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef, },},},}}
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">{translate('traducciones.menu.administracion')}</Typography>
                                        Agregar: leyenda “ingreso por primera vez” click aquí
                                    <span className={classes.arrow} ref={handleArrowRef} />
                                </React.Fragment>
                            }>
                                <span>{translate('traducciones.menu.administracion')}</span>
                        </Tooltip>
                    </div>
                    { checkPermiso('Usuarios','AccesoaModuloUsuarios') &&
                    <MenuItemLink
                        to="/usuarios"
                        primaryText={translate('traducciones.menu.usuarios')}
                        leftIcon={<PersonIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                    />
                    }
                    { checkPermiso('Grupos','AccesoaModuloGrupos') &&
                    <MenuItemLink
                        to="/grupos"
                        primaryText={translate('traducciones.menu.grupos')}
                        leftIcon={<GroupIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                    />
                    }
            </div>
            }
            { (checkPermiso('Nodos','AccesoaModuloNodos') || checkPermiso('Indices','AccesoaModuloIndices')) &&
            <div className={classes.divHead}>
                <SettingsIcon />  
                <div className = {classes.menuTitle} >
                    <Tooltip
                            open={false}
                            placement="right"
                            classes={{ popper: classes.htmlPopper,tooltip: classes.htmlTooltip }}
                            PopperProps={{ popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef, },},},}}
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">{translate('traducciones.menu.estructura')}</Typography>
                                        Agregar: leyenda “ingreso por primera vez” click aquí
                                    <span className={classes.arrow} ref={handleArrowRef} />
                                </React.Fragment>
                            }>
                                <span>{translate('traducciones.menu.estructura')}</span>
                    </Tooltip>
                </div>
                    { checkPermiso('Nodos','AccesoaModuloNodos') &&
                    <MenuItemLink
                        to="/nodos"
                        primaryText={translate('traducciones.menu.nodos')}
                        leftIcon={<AccountTreeIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                    />
                    }
                    { checkPermiso('Indices','AccesoaModuloIndices') && 
                    <MenuItemLink
                        to="/indices"
                        primaryText={translate('traducciones.menu.indices')}
                        leftIcon={<FormatIndentIncreaseIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                    />
                     } 
            </div>
            }            
            { (checkPermiso('IndexacióndeDocumentos','AccesoaModuloIndexacióndeDocumentos')) &&
            <div className={classes.divHead}>
                <BackupIcon />  
                <div className = {classes.menuTitle} >
                    <Tooltip
                        open={false}
                        placement="right"
                        classes={{ popper: classes.htmlPopper,tooltip: classes.htmlTooltip }}
                        PopperProps={{ popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef, },},},}}
                        title={
                            <React.Fragment>
                                <Typography color="inherit">{translate('traducciones.menu.digitalizar')}</Typography>
                                    Agregar: leyenda “ingreso por primera vez” click aquí
                                <span className={classes.arrow} ref={handleArrowRef} />
                            </React.Fragment>
                        }>
                        <span>{translate('traducciones.menu.digitalizar')}</span>
                    </Tooltip>
                </div>
                    <MenuItemLink
                        to="/digitalizarImagenes"
                        primaryText={translate('traducciones.menu.desdeArchivo')}
                        leftIcon={<PhotoLibraryIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                    />
                    <MenuItemLink
                        to="/documentos"
                        primaryText={translate('traducciones.menu.indexar')}
                        leftIcon={<ListIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                    />
            </div>
            }
            { (checkPermiso('ConsultadeDocumentos','AccesoaModuloConsultadeDocumentos')) &&
            <div className={classes.divHead}>
                <div>
                    <FolderOpenIcon />  
                    <div className = {classes.menuTitle} >
                        <Tooltip
                            open={false}
                            placement="right"
                            classes={{ popper: classes.htmlPopper,tooltip: classes.htmlTooltip }}
                            PopperProps={{ popperOptions: { modifiers: { arrow: { enabled: Boolean(arrowRef), element: arrowRef, },},},}}
                            title={
                                <React.Fragment>
                                    <Typography color="inherit">{translate('traducciones.menu.consultas')}</Typography>
                                        Agregar: leyenda “ingreso por primera vez” click aquí
                                    <span className={classes.arrow} ref={handleArrowRef} />
                                </React.Fragment>
                            }>
                                <span>{translate('traducciones.menu.consultas')}</span>
                        </Tooltip>
                    </div>
                </div>    
                    <MenuItemLink
                        to="/campos"
                        primaryText={translate('traducciones.menu.campos')}
                        leftIcon={<ViewAgendaIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                    />
                    <MenuItemLink
                        to="/general"
                        primaryText={translate('traducciones.menu.general')}
                        leftIcon={<FileCopyIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                    />
                    <MenuItemLink
                        to="/contenido"
                        primaryText={translate('traducciones.menu.contenido')}
                        leftIcon={<WrapTextIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                    />
                    <MenuItemLink
                        to="/navegar"
                        primaryText={translate('traducciones.menu.navegar')}
                        leftIcon={<BallotIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                    />
                    <MenuItemLink
                        to="/predefinida"
                        primaryText={translate('traducciones.menu.predefinida')}
                        leftIcon={<BallotIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        className={!open ? classes.subMenu: classes.subMenuClose}
                    />
            </div>
            }
            
            </div>
            ) }
            
        </div>
    );
};

export default Menu;