import * as React from 'react';
import { useState ,useEffect } from 'react';
import {
    FormWithRedirect,
    useDelete,
    useTranslate,
    SimpleForm,  
    Loading, 
    Error,  
    Create,
    useRefresh,
    Query, 
    SaveButton,
    useDataProvider,
    TextInput,
    SelectInput,
    DateInput,
    NumberInput,
    required,
    ArrayInput,
    SimpleFormIterator
} from 'react-admin';
import Button from '@material-ui/core/Button';
import FolderIcon from '@material-ui/icons/Folder';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import WarningIcon from '@material-ui/icons/Warning';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import '../Libs/ExternalViewer/Resources/Styles/digitalizarStyles.css'
import {
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Card,
  } from '@material-ui/core';
import form from '../Libs/ExternalViewer/Resources/Images/form.png'
import folder from '../Libs/ExternalViewer/Resources/Images/folder.png'
import diagrama from '../Libs/ExternalViewer/Resources/Images/diagrama.png'
import basura from '../Libs/ExternalViewer/Resources/Images/folder2.png'
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import swal from 'sweetalert';
import introJs from 'intro.js';
import 'intro.js/introjs.css';

const useStyles = makeStyles({
    nodo : {
        paddingLeft:'8px',
        border: '0.5px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '5px',
        fontSize: '13px',
        maxWidth:'fit-content',
        paddingRight:'5px',
        paddingBottom:'0px',
        maxHeight:'fit-content'
    },
    nodoSeleccionado :  {
        padding: '6px',
        border: '1px solid black',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        backgroundColor: "#319b4257",
        color: '#277233'
    }
});

const DigitalizacionMasiva = ({ ...props }) => {   

    const classes = useStyles();
    const [nodoPadreElemento, setnodoPadreElemento] = useState(null);    
    const [loadinga, setLoadinga] = useState(false);
    const [openNoResult, setOpenNoResult] = useState(false);
    const [openResult, setOpenResult] = useState(false);
    const [openDelete, setopenDelete] = useState(false);
    const [openDocument, setopenDocument] = useState(false);    
    const [indiceSeleccionado, setindiceSeleccionado] = useState(null);
    const [deleteOne] = useDelete();
    const translate = useTranslate();
    const refresh = useRefresh();
    const [dataIndices, setDataIndices] = useState([]);
    const [dataSearch, setDataSearch] = useState([]);
    const [dataUsers, setDataUsers] = useState(null);
    const [usuarioLocal, setUsuarioLocal] = useState(null);
    const [desc,setDesc] = useState(null);
    const [openCampos,setOpenCampos] = useState(false);
    const [openDesc,setOpenDesc] = useState(false);
    const [checkPermiso,setCheckPermiso] = useState(false);

    const [randomUpdate, setRandomUpdate] = useState(null);
    const [datanodosAbiertos, setDatanodosAbiertos] = useState([]);

    const dataProvider = useDataProvider();
    const [docs,setDocs] = useState ([]); //Datos de documentos para subirlos como temporales
    const [dataCampos,setDataCampos] = useState([]); //Datos campos comunes
    const [listaCampos,setListaCampos] = useState([]); //Listado de todos los campos por nodo
    const [nodoSeleccionado,setNodoSeleccionado] = useState([]); //Arreglo de nodos que contienen documentos
    const [docsToIndexar,setDocsToIndexar] = useState([]); //Datos de documentos para indexar
    const [dataNodes, setDataNodes] = useState(null); //Listados de todos los nodos para obtener la prop de fullText y asignarla al documento

    //Variables agregadas para el buscador de nodos
    const [nodoBuscado,setNodoBuscado] = useState(null);
    const [nodos,setNodos] = useState(null);
    const [openNodosEncontrados,setOpenNodosEncontrados] = useState (false)
    const [resultadoNodos,setResultadoNodos] = useState([])
    const [tutorial,setTutorial] = useState({ indexar:false, clasificar:false})
    const inTour = JSON.parse(localStorage.getItem('activeTour'))
    let introNodos;

    let introHelp;
    introHelp = introJs('#root');
    introHelp.setOptions({
        disableInteraction: false,
        doneLabel: translate('traducciones.introBotones.doneLabel'),
        nextLabel: translate('traducciones.introBotones.nextLabel'),
        prevLabel: translate('traducciones.introBotones.prevLabel'),
        tooltipPosition:'top',
        steps: [
                {
                intro: translate('traducciones.introHelp.intro1'),
                title: translate('traducciones.introHelp.title1'),
                element: '#help'
                },
        ]                
    });

    const showHelp = () =>{
        const offPermisos = {
            querys: false,
            carga: false,
            admin: false,
            estructura: false,
        }
        if(document.cookie == 'introjs-dontShowAgain=true'){
            document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
                setTimeout(()=>{
                    introHelp.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).onexit(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).start();                         
                },500)
        }
    }

    function crearCookie(nombre, valor, diasParaExpirar) {
    var fechaExpiracion = new Date();
    fechaExpiracion.setDate(fechaExpiracion.getDate() + diasParaExpirar);
    var cookie = nombre + '=' + valor + '; expires=' + fechaExpiracion.toUTCString() + '; path=/';
    document.cookie = cookie;
    }

    useEffect(() => {
        if(inTour.carga){
            setTimeout(()=>{
                introNodos = introJs('#search-box');
                introNodos.setOptions({
                    disableInteraction: false,
                    doneLabel: translate('traducciones.introBotones.doneLabel'),
                    nextLabel: translate('traducciones.introBotones.nextLabel'),
                    prevLabel: translate('traducciones.introBotones.prevLabel'),
                    tooltipPosition:'top',
                    steps: [
                        {
                            intro: translate('traducciones.introMasiva.intro1'),
                            title: translate('traducciones.introMasiva.title1')
                        },
                        {
                            element: '#nodo',
                            intro: translate('traducciones.introMasiva.intro2'),
                            title: translate('traducciones.introMasiva.title2')
                        },
                        {
                            element: '.file-input',
                            intro: translate('traducciones.introMasiva.intro3'),
                            title: translate('traducciones.introMasiva.title3')
                        },
                        {
                            element: '.fake-text',
                            intro: translate('traducciones.introMasiva.intro4'),
                            title: translate('traducciones.introMasiva.title4'),
                            position: 'bottom'
                        },
                        {
                            element: '.btn-trash',
                            intro: translate('traducciones.introMasiva.intro5'),
                            title: translate('traducciones.introMasiva.title5')
                        },
                        {
                            element: '#opciones',
                            intro: translate('traducciones.introMasiva.intro6'),
                            title: translate('traducciones.introMasiva.title6'),
                            position: 'left',
                            disableInteraction: true
                        },
                        {
                            element: '#vaciarNodos',
                            intro: translate('traducciones.introMasiva.intro7'),
                            title: translate('traducciones.introMasiva.title7'),
                            disableInteraction: true,
                        },
                        {
                            element: '#clasificar',
                            intro: translate('traducciones.introMasiva.intro8'),
                            title: translate('traducciones.introMasiva.title8'),
                            disableInteraction: true,
                        },
                        {
                            element: '#indexar',
                            intro: translate('traducciones.introMasiva.intro9'),
                            title: translate('traducciones.introMasiva.title9'),
                            disableInteraction: true,
                        }
                    ]                
                  });
        
                introNodos.setOption("dontShowAgain", true).oncomplete(()=>{
                    setTutorial({ indexar: true , clasificar: true });
                    showHelp()
                }).onexit(()=>{showHelp()}).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).start();
            },1000)
        }
      }, []);

    //Función que restablece los valores mediante un mensaje de confirmación
    const vaciarEstados = (e) => {  
        e.preventDefault();
        swal({
            text: translate('traducciones.notificaciones.vaciarNodos'),
            buttons:{
                cancel: {
                  text: translate('traducciones.campos.cancelar'),
                  value: false,
                  visible: true,
                  className: "bg-danger text-white",
                  closeModal: true,
                },
                confirm: {
                  text: translate('traducciones.campos.vaciar'),
                  value: true,
                  visible: true,
                  className: "bg-success text-white",
                  closeModal: true
                }
              }
        }).then((res)=>{
           if(res){
            vaciar();
            swal({
                icon: "success",
                text: translate('traducciones.notificaciones.vacios'),
                timer: 1000,
                buttons:false
              });
           }
          })
    }

    //Función que restablece los valores sin mensaje de confirmación
    const vaciar = () => {  
            setDocs([])
            setDataCampos([])
            setListaCampos([])
            setNodoSeleccionado([])
            setDocsToIndexar([])
            setDesc()
            refresh();  
    }

    //Función que vacia los archivos cargados a un nodo especifico
    const vaciarNodo = (e,nodo) => {
        e.preventDefault();
        swal({
            text:translate('traducciones.modal.confirmVaciar') + nodo.TextoNodo + ' ?',
            buttons:{
                cancel: {
                  text: translate('traducciones.campos.cancelar'),
                  value: null,
                  visible: true,
                  className: "bg-danger text-white",
                  closeModal: true,
                },
                confirm: {
                  text: translate('traducciones.campos.vaciar'),
                  value: true,
                  visible: true,
                  className: "bg-success text-white",
                  closeModal: true
                }
              }
          }).then((res)=>{
           if(res){

                //Filtra los archivos
                const result = docs.filter(doc => doc.IdNode !== nodo.RutaNodo);
                const result2 = docsToIndexar.filter(doc=> doc.IdNode !== nodo.RutaNodo);
                const result3 = nodoSeleccionado.filter(node=> node !== nodo.RutaNodo);
                
                //Se guardan los resultados filtrados
                setDocs(result)
                setDocsToIndexar(result2)
                setNodoSeleccionado(result3)
                
                //Se cambia el texto de la etiqueta
                const btnBorrar = document.getElementById(nodo.RutaNodo);
                btnBorrar.previousElementSibling.querySelector('span').innerHTML='0 '+translate('traducciones.botones.noArchivos')
                btnBorrar.previousElementSibling.querySelector('input').value='';

                swal({
                    icon: "success",
                    text: translate('traducciones.notificaciones.vacios'),
                    timer: 1000,
                    buttons:false
                });
           }
          })
    }

    const apiUrl = window.$parametros_generales.REACT_APP_BASE_URL;
    useEffect(() => {
        if(dataUsers == null){
            setDataUsers([]);
            dataProvider.getList('usuarios', { filter:{} })
            .then(({ data }) => {
                var datareturn = [];
                data.forEach(function (record) { 
                    var rd = {};
                    rd.id = record.IdUsuario;
                    rd.Nombre = record.Nombre;
                    datareturn.push(rd);
                });  
                setDataUsers(datareturn);
                setUsuarioLocal(parseInt(localStorage.getItem("IdUsuario")));
            }).catch(error=>{});
        }
        if(dataNodes == null){
            fetch(apiUrl+'Nodes/listNode',{
                method: 'GET',
                headers: new Headers({ 'Content-Type': 'application/json','Authorization': 'Bearer ' + localStorage.getItem('token')}),
            }).then(( response ) => response.json())
            .then((data)=>{
                setDataNodes(data.Information)
            })
            .catch(error => {});
        }
    },[]);

    if(dataUsers == null){
        return <Loading />;
    }

    const viewNode = (nodoPadre) => {
        setnodoPadreElemento(nodoPadre);   
    }

    const handleCloseClick = () => {
        setOpenNoResult(false);
        setOpenResult(false);
        setopenDelete(false);
        setOpenCampos(false);
        setOpenDesc(false);
        setOpenNodosEncontrados(false);
    }

    const colapsar = (nodo) => {
        setRandomUpdate(Math.random());
        if(nodo.colapsado){
            datanodosAbiertos.push(nodo.RutaNodo)
            setDatanodosAbiertos(datanodosAbiertos);
        }else{
            setDatanodosAbiertos(datanodosAbiertos.filter(function(nodoguardado) { 
                return nodoguardado !== nodo.RutaNodo;
            }));
        }
        nodo.colapsado = !nodo.colapsado;
    }

    const checknodoabierto = (nodo) => {
        if(datanodosAbiertos.includes(nodo.RutaNodo)){
            nodo.colapsado = false;
        }
    }

    if(loadinga){
        return <Loading />
    }

    const handleSubmitDelete = async values => {
        setLoadinga(true);        
        deleteOne('indices', indiceSeleccionado.id, indiceSeleccionado,{
            onSuccess: ({ data }) => {
                setDataIndices(data.data);
                setopenDelete(false);
                setLoadinga(false);
                refresh();
            },
            onFailure: ({ error }) => {
                setLoadinga(false);
            }
        });
        setLoadinga(false);  
        
    };

    const consultaDocumento = (elem) => {
        dataProvider.getOne('documento', { IdDocumento: elem.IdDocumento})
            .then(({ data }) => {
            })
            .catch(error => {
        });
    } 


    function dibujaNodosHijos(nodo,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto){
        if(nodo.hijos != undefined){
            return (
                <div>
                    {nodo.hijos.map(
                    (item) => {
                        checknodoabierto(item);
                        return (
                        <div  style={{ marginLeft: '40px', marginTop : '5px'}}>
                            <div style={{ float: 'left' }}>
                                <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                            </div>
                            <div className={ nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > <FolderIcon style={{ marginRight: '5px'}} />
                                { item.TextoNodo }
                                <div  className="col-md-custom" style={{marginBottom:'0px',marginTop:'0px',maxHeight:'fit-content', 
                                                                                                width:'245px', display:'inline-flex',justifyContent:'space-between',textAlign:'center'
                                                                                                }}>
                                                                <SimpleForm toolbar={''} >
                                                                    <div className='row d-flex'>
                                                                        <div className='col-md-6'> 
                                                                            <div className="file-area">
                                                                                <input className="file-input" data-multiple-caption="{count} archivos"  source="archivos" type="file" name="archivos" id="archivos" aria-label="Archivo"  multiple accept='image/*,application/*,text/plain' onChange={(e)=>agregarArchivos(e.target.files,item,desc,e)} />   
                                                                                <label for="file-input">
                                                                                    <button className="fake-btn">{translate('traducciones.botones.elegirArch')}</button>
                                                                                    <span className="fake-text">0 {translate('traducciones.botones.noArchivos')}</span>
                                                                                </label>
                                                                            </div>
                                                                            <button className='btn-trash' onClick={(e)=>vaciarNodo(e,item)} id={item.RutaNodo} title={translate('traducciones.titulos.vaciarNodo')} disabled={!nodoSeleccionado.includes(item.RutaNodo)}>
                                                                                <img src={basura} style={{width:'20px',height:'20px'}}/>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </SimpleForm>  
                                                        </div>
                                { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                <Button style={{ marginLeft: "0px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                    <ExpandLessIcon />
                                </Button>
                                }
                                { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                <Button style={{ marginLeft: "0px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                    <ExpandMoreIcon />
                                </Button>
                                }
                            </div>
                            { !item.colapsado &&
                            <div>
                                { dibujaNodosHijos(item,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                            </div>
                            }
                        </div>
                    )})
                    }
                </div>
            )
        }
    }
    //Función que se llama al detectar un cambio el input de cualquier nodo, mandando como parametros; los archivos, Nodo y descripción.
    const agregarArchivos = (archivos,nodoPadre,descc,e) => {
        e.preventDefault();
        //Se verifica si el OCR en el nodo esta activo o desactivado 
        let fullText=false;
        dataNodes.map(n=>{
            if(n.RutaNodo==nodoPadre.RutaNodo){
                n.FullText ? fullText = true : fullText = false
            }
        })
        
        Array.from(archivos).forEach(archivo=>{
            let reader=new FileReader();
            reader.readAsDataURL(archivo);
            reader.onload=function(){
                let arrayAux=[];
                let b64= reader.result;
                arrayAux=b64.split(',');
                var extension = b64.split(';')[0].split('/')[1];

                if(extension.includes("application/x-zip-compressed") || extension.includes("application/zip") || extension.includes("application/zip-compressed") || extension.includes("zip")){
                    extension = "zip";    
                }

                if(extension.includes('text') || extension.includes('plain')){
                    extension='txt';
                }
                if(extension.includes("officedocument.wordprocessingml")){
                    extension =  "docx";    
                }
                if(extension.includes("officedocument.spreadsheet")){
                    extension =  "xlsx";    
                }

                //Se almacena el nodo seleccionado
                setNodoSeleccionado(prevState=>([...prevState,nodoPadre.RutaNodo]))

                //Se agrega el objeto del documento que sirve para subir como Tmp
                setDocs(prevState=>([...prevState,{
                    IdNode : nodoPadre != null ? nodoPadre.RutaNodo : "/",
                    OperationType : "DIGITALIZACION",
                    AssignedUserId : usuarioLocal,
                    DocumentPages : 6,
                    Description : descc,
                    DocumentType : extension,
                    IdPlantilla : 0,
                    Document : arrayAux[1]
                }]))

                //Se agrega el objeto del documento que sirve para indexar
                setDocsToIndexar(prevState=>([...prevState,{
                        IdNode: nodoPadre != null ? nodoPadre.RutaNodo : "/",
                        TipoDocumento: extension,
                        Base64file: arrayAux[1],
                        SetUserPermission:checkPermiso,
                        GetFullText: fullText,
                        DatosDocumento: []
                }]))
            }
        })
        const etiqueta= e.target.nextElementSibling;
        etiqueta.querySelector('span').innerHTML= (archivos.length+' ' +translate('traducciones.botones.noArchivos'))
    }

    //Función que verifica que la descripción haya sido agregada, despues digitaliza todos los archivos seleccionados
    const digitalizarArchivos = () => {
        if(docs.length<=0){
            swal({
                icon: "error",
                text:'Sin archivos, debe seleccionar al menos un archivo'
              });
        }else if(desc===null || desc===undefined || desc===''){
            swal({
                icon: "warning",
                text: translate('traducciones.notificaciones.sinDesc')
              });
        }else{

        docs.map((doc)=>doc.Description!=desc ? doc.Description=desc : '' )
       
            dataProvider.create('documentos', { data: docs })
            .then(({ data }) => {
               refresh();
               swal({
                icon: "success",
                text: translate('traducciones.notificaciones.clasificacionOk'),
                timer: 1500
              });
               vaciar();
            }).catch(({error}) => {
                swal({
                    icon:'error',
                    text: error.message,
                    timer: 1000
                })
            });
            setOpenDesc(false)
        }
    }

    //Función que obtiene los campos comunes y abre el dialog donde los muestra para ser capturados e indexar los documentos.
    const ingresarCampos = (e) => {
        e.preventDefault();
        const nodosSel = eliminaDuplicados(nodoSeleccionado)
        setNodoSeleccionado(nodosSel)
        const ToImport = true;

        dataProvider.getList('indicesComunes', { nodosSel: nodosSel , ToImport: ToImport })
            .then(({ data }) => {
                if(data.length>0){
                    setDataCampos(data)
                }
            }).catch((error)=>{
                console.log(error)
            })
            dataProvider.getList('IndicesComunesPorNodo', { nodosSel: nodosSel , ToImport: ToImport })
            .then(({ data }) => {
                if(data.length>0){
                    setListaCampos(data)
                    setOpenCampos(true)
                    if(inTour.carga){
                        setTimeout(()=>{
                            // if(tutorial.indexar){
                                introNodos = introJs('.MuiDialog-container');
                                introNodos.setOptions({
                                    disableInteraction: false,
                                    doneLabel: translate('traducciones.introBotones.doneLabel'),
                                    nextLabel: translate('traducciones.introBotones.nextLabel'),
                                    prevLabel: translate('traducciones.introBotones.prevLabel'),
                                    steps: [
                                        {
                                            element: '.MuiDialogContent-root',
                                            intro: translate('traducciones.introIndexar.intro1'),
                                            title: translate('traducciones.introIndexar.title1'),
                                            position: 'bottom-middle-aligned'
                                        },
                                        {
                                            element: '#BtnIndexar',
                                            intro: translate('traducciones.introIndexar.intro2'),
                                            title: translate('traducciones.introIndexar.title2'),
                                            scrollTo: 'off'
                                        },
                                    ]
                                })
                                introNodos.setOption("dontShowAgain", true).oncomplete(()=>{
                                    setTutorial(prevTutorial => ({...prevTutorial, indexar: false }));
                                    showHelp()
                                }).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).onexit(()=>{showHelp()}).start();
                            // }
                        },800)
                    }
                }
            }).catch((error)=>{
                console.log(error)
            })
    }

    const ingresarDescripcion = (e) => {
        e.preventDefault();
        setOpenDesc(true)
        if(inTour.carga){
            setTimeout(()=>{
                // if(tutorial.clasificar){
                    introNodos = introJs('.MuiDialog-container');
                    introNodos.setOptions({
                        disableInteraction: false,
                        doneLabel: translate('traducciones.introBotones.doneLabel'),
                        nextLabel: translate('traducciones.introBotones.nextLabel'),
                        prevLabel: translate('traducciones.introBotones.prevLabel'),
                        steps: [
                            {
                                element: '.MuiDialogContent-root',
                                intro: translate('traducciones.introClasificar.intro1'),
                                title: translate('traducciones.introClasificar.title1'),
                                position: 'left'
                            },
                            {
                                element: '#btnClasificar',
                                intro: translate('traducciones.introClasificar.intro2'),
                                title: translate('traducciones.introClasificar.title2'),
                            },
                        ]                    
                    })
                    introNodos.setOption("dontShowAgain", true).oncomplete(()=>{
                        setTutorial(prevTutorial => ({...prevTutorial, clasificar: false }));
                        showHelp()
                    }).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).onexit(()=>{showHelp()}).start();
                // }
            },800)
        }
    }

    //Función que elimina datos duplicados dentro de un Array, pasando el Array como parametro
    const eliminaDuplicados = (arr) => {
        return [... new Set(arr)];
    }

    const indexarDos = async values =>{
        var campos = Object.entries(values);
        //Se iteran los values para obtener el IdIndice y su valor
            var meta = [];
            campos.forEach(function (record, index) { 
                if(record[1] != undefined && record[1] != null && record[0].includes("campo")){
                    meta.push(
                        {
                            "IdIndice": record[0].replace('campo-', ''),
                            "ValorLlave": typeof record[1] != "string" ? record[1] : record[1].includes('\r\n') ? record[1].replace(/\r\n/g, "") : record[1],
                        }
                    );
                }
                if(record[1] != undefined && record[1] != null && record[0].includes("multiple")){
                    var valores = [];
                    record[1].forEach(function(e){
                        valores.push(e["valor-" + record[0].replace('multiple-', '')]);
                    });
                    const valoresAux = [];
                    valores.map((v)=>{
                        if (typeof v === "string"){
                            if(v.includes('\r\n')){
                               valoresAux.push(v.replace(/\r\n/g, ""))
                            }else{
                                valoresAux.push(v)
                            }
                        }else{
                            valoresAux.push(v)
                        }
                    })
                    meta.push(
                        {
                            "IdIndice": record[0].replace('multiple-', ''),
                            "ValoresLlave": valoresAux,
                        }
                    );
                    
                }
            });
            
        const listaFiltrada = eliminaDuplicados(listaCampos);
        setListaCampos(listaFiltrada)
        let valores=[];
        
        //Se mapean los valores ordenados para ahora asignarle el nombre que les corresponde para posteriormente asignarle su Id
        meta.map(m=>{
            listaFiltrada.map(l=>{
                let i = l.NodeFields.findIndex(e => e.IdIndice == m.IdIndice);
                    if(i != -1){
                        valores.push({
                            key: l.NodeFields[i].NombreIndice,
                            Val: m.ValoresLlave ?  m.ValoresLlave : m.ValorLlave
                        })
                    }
            })
        })
        
        let camposComunes = [];       
        docsToIndexar.map(doc=>{
            camposComunes = [];
            let i = listaCampos.findIndex(e => e.Node === doc.IdNode);
            if(i != -1){
                listaCampos[i].NodeFields.map(indice=>{
                    if(indice.Multiple){
                        let inC = valores.findIndex(e => e.key == indice.NombreIndice);
                        camposComunes.push({
                            IdIndice:indice.IdIndice,
                            NombreIndice:indice.NombreIndice,
                            ValoresLlave:  inC != -1 ? valores[inC].Val : [''],
                            ValorLlave: ''
                        })
                    }else{
                        let inC = valores.findIndex(e => e.key == indice.NombreIndice);
                        camposComunes.push({
                            IdIndice:indice.IdIndice,
                            NombreIndice:indice.NombreIndice,
                            ValorLlave: inC != -1 ? valores[inC].Val : ''
                        })
                    }
                })
            }
            doc.DatosDocumento=camposComunes;
        })
        // INDEXAR DOCUMENTOS
        docsToIndexar.map((doc)=>{
                dataProvider.create('documentosToIndexar', { data: doc })
                .then(({ data }) => {
                    swal({
                        icon: "success",
                        text: translate('traducciones.notificaciones.indexadoOk')
                        });
                })
                .catch(error => {
                    swal({
                        icon: "error",
                        text:'Error: ' + error.message
                      });
                });
            })
            setOpenCampos(false)
            vaciar();
            refresh();
    }

    const buscarNodo = (nodo,e) => {
        e.preventDefault();
        if(nodo==null || nodo.trim()==''){
            swal({
                icon: "info",
                text: translate('traducciones.modal.sinNombre'),
                timer: 1000,
                buttons:false
              });
        }else{
        let nodosEncontrados=[];
        nodos.map((nPadre) => {
          if (nPadre.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
            nodosEncontrados.push({
                'ruta':"/"+nPadre.TextoNodo,
                'n1':nPadre,
                'lvl':1
            })
          } else if (nPadre.hijos.length > 0) {
            nPadre.hijos.map((n2) => {
              if (n2.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                nodosEncontrados.push({
                    'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo,
                    'n1':nPadre,
                    'n2':n2,
                    'lvl':2
                })
              } else if (n2.hijos.length > 0) {
                n2.hijos.map((n3) => {
                  if (n3.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                    nodosEncontrados.push({
                        'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo,
                        'n1':nPadre,
                        'n2':n2,
                        'n3':n3,
                        'lvl':3
                    })
                  } else if (n3.hijos.length > 0) {
                    n3.hijos.map((n4) => {
                      if (n4.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                        nodosEncontrados.push({
                            'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo + "/" + n4.TextoNodo,
                            'n1':nPadre,
                            'n2':n2,
                            'n3':n3,
                            'n4':n4,
                            'lvl':4
                        })
                      } else if (n4.hijos.length > 0) {
                        n4.hijos.map((n5) => {
                          if (n5.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                            nodosEncontrados.push({
                                'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo + "/" + n4.TextoNodo + "/" + n5.TextoNodo,
                                'n1':nPadre,
                                'n2':n2,
                                'n3':n3,
                                'n4':n4,
                                'n5':n5,
                                'lvl':5
                            })
                          }else if(n5.hijos.length > 0){
                            n5.hijos.map(n6=>{
                                if (n6.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                                    nodosEncontrados.push({
                                        'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo + "/" + n4.TextoNodo + "/" + n5.TextoNodo + "/" + n6.TextoNodo,
                                        'n1':nPadre,
                                        'n2':n2,
                                        'n3':n3,
                                        'n4':n4,
                                        'n5':n5,
                                        'n6':n6,
                                        'lvl':6
                                    })
                                }
                            })
                          }
                        });
                      }else{
                        //Si no hay hijos en nivel 5
                      }
                    });
                  } else {
                    //Si no hay hijos en nivel 4
                  }
                });
              } else {
                //Si no hay hijos en nivel 3
              }
            });
          } else {
            //Si no hay hijos en nivel 2
          }
        });
        if(nodosEncontrados.length==1){
            desplegarNodos(nodosEncontrados[0])
        }else{
            setResultadoNodos(nodosEncontrados);
            setOpenNodosEncontrados(true)
        }
    }
    };

    const desplegarNodos = (nodo) =>{
        switch(nodo.lvl){
            case 1: 
                viewNode(nodo.n1)
                setOpenNodosEncontrados(false)
            break;
            case 2:
                colapsar(nodo.n1);
                viewNode(nodo.n2)
                setOpenNodosEncontrados(false) 
            break;
            case 3:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                viewNode(nodo.n3)
                setOpenNodosEncontrados(false) 
            break;
            case 4:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                colapsar(nodo.n3);
                viewNode(nodo.n4)
                setOpenNodosEncontrados(false) 
            break;
            case 5:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                colapsar(nodo.n3);
                colapsar(nodo.n4);
                viewNode(nodo.n5)
                setOpenNodosEncontrados(false)
            break;
            case 6:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                colapsar(nodo.n3);
                colapsar(nodo.n4);
                colapsar(nodo.n5);
                viewNode(nodo.n6)
                setOpenNodosEncontrados(false)
            break;
            default: ;
        }
    }

    return (
        <Create {...props} title={ translate('traducciones.titulos.digitalizar')} >
            <SimpleForm {...props} save={digitalizarArchivos} toolbar= {false}  >
            <div fullWidth>
                <div style={{ padding: '10px'}} id='container'>     
                    <div id='search-box' className='uno' style={{marginBottom:'10px'}}>
                        <div id='search-form' >
                        <input id='search-text' placeholder={translate('traducciones.campos.buscador')} type='text' onChange={(e) => setNodoBuscado(e.target.value)}/>
                        <button id='search-button' onClick={(e)=>buscarNodo(nodoBuscado,e)}><span><ManageSearchIcon style={{fontSize:'25px'}}/></span></button>
                        </div>
                    </div>      
                    <Query type="getList" resource="nodos" payload={ nodoPadreElemento }>
                        {({ data, loading, error }) => {
                            setNodos(data)
                            if (loading) { return <Loading />; }
                            if (error) { return <Error />; }
                            return (
                                <div  className="row">
                                    <div  className="col-md-7 col-md-custom" style={{ overflowX: "auto"}}>
                                    {   data.map(
                                        (item) => {
                                            checknodoabierto(item);
                                            return (
                                            <div>
                                                <div className= { nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } id='nodo'> 
                                                    <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                                    <span id='nodoName'>{ item.TextoNodo }</span> 
                                                    <div  className="col-md-custom" style={{marginBottom:'0px',marginTop:'0px',maxHeight:'fit-content', marginLeft:'5px',
                                                                                            width:'245px', display:'inline-flex',justifyContent:'space-around',textAlign:'center'
                                                                                            }}>
                                                            <SimpleForm toolbar={''} >
                                                                <div className='row d-flex'>
                                                                    <div> 
                                                                        <div className="file-area">
                                                                            <input className="file-input" data-multiple-caption="{count} archivos"  source="archivos" type="file" name="archivos" id="archivos" aria-label="Archivo"  multiple accept='image/*,application/*,text/plain' onChange={(e)=>agregarArchivos(e.target.files,item,desc,e)} />   
                                                                            <label for="file-input">
                                                                                <button className="fake-btn">{translate('traducciones.botones.elegirArch')}</button>
                                                                                <span className="fake-text">0 {translate('traducciones.botones.noArchivos')}</span>
                                                                            </label>
                                                                        </div>
                                                                        <button className='btn-trash' onClick={(e)=>vaciarNodo(e,item)} id={item.RutaNodo} title={translate('traducciones.titulos.vaciarNodo')} disabled={!nodoSeleccionado.includes(item.RutaNodo)} >
                                                                                <img src={basura} style={{width:'20px',height:'20px'}}/>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </SimpleForm>  
                                                    </div>
                                                    { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                                    <Button id='less' style={{ marginLeft: "0px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                        <ExpandLessIcon />
                                                    </Button>
                                                    }
                                                    { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                                    <Button id='more' style={{ marginLeft: "0px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                        <ExpandMoreIcon />
                                                    </Button>
                                                    }
                                                </div>
                                                { !item.colapsado &&
                                                <div style={{ marginLeft: '-20px'}}>
                                                    { dibujaNodosHijos(item,classes,viewNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                                                </div>
                                                }
                                            </div>
                                        )})
                                    }
                                    </div>
                                    <div className="col-md-5 mt-2" >
                                            <span style={{marginLeft:'12px', fontWeight:'bold'}}>{translate('traducciones.generales.noArchivos')}: {docs.length}</span><br/>
                                            <span style={{marginLeft:'12px'}}>{translate('traducciones.generales.instruccionDig')}: </span><br/>
                                            <span style={{marginLeft:'12px'}}>1.{translate('traducciones.generales.inst1')}. </span><br/>
                                            <span style={{marginLeft:'12px'}}>2.{translate('traducciones.generales.inst2')}: </span><br/><br/>
                                            
                                            <div id='opciones'>
                                            <span style={{marginLeft:'12px'}}>{translate('traducciones.generales.opcion')} 1 - {translate('traducciones.titulos.digitalizar')}: </span><br/>
                                            <button id='indexar' className="button-79" role="button" onClick={ingresarCampos} disabled={docs.length>0 || docs.length>0 ?false:true} data-toggle="tooltip" data-placement="top" title="Indexa los documentos directamente a los nodos cargados, capturando sus campos comunes"><img src={form} style={{width:'25px',marginRight:'5px'}}/>{translate('traducciones.titulos.digitalizar')}</button><br/>
                            
                                            <span style={{marginLeft:'12px'}}>{translate('traducciones.generales.opcion')} 2 - {translate('traducciones.titulos.subirTmp')}: </span><br/>
                                            <button id='clasificar' className="button-79" role="button" onClick={ingresarDescripcion} disabled={docs.length>0 || docs.length>0 ?false:true} data-toggle="tooltip" data-placement="top" title="Clasifica los documentos y los sube como archivos temporales, capturando una descripción"><img src={diagrama} style={{width:'25px',marginRight:'5px'}}/>{translate('traducciones.botones.clasificar')}</button><br/>

                                            <span style={{marginLeft:'12px'}}>{translate('traducciones.generales.opcion')} 3 - {translate('traducciones.titulos.restablecer')}: </span><br/>
                                            <button id='vaciarNodos' className="button-78" role="button" onClick={vaciarEstados} disabled={docs.length>0 || docs.length>0 ?false:true} data-toggle="tooltip" data-placement="top" title="Reestablece los valores vaciando todos los archivos seleccionados al momento"><img src={folder} style={{width:'25px',marginRight:'5px'}}/>{translate('traducciones.botones.vaciar')}</button><br/>
                                            </div>
                                    </div>    
                                </div>
                            );
                        }}
                    </Query>
                </div> 
                 
            </div>
            
            <Dialog
                open={openNoResult}>
                <DialogTitle>{ translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                <DialogContent>
                    <WarningIcon style={{ color: '#c18a2d', fontSize: '60px'}} /> { translate('traducciones.modal.noResults')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClick} >
                        { translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog>   

            <Dialog
                open={openResult}>
                <DialogTitle>{ translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                <DialogContent>
                    <Card >
                        <Table className={"customDataGrid"}>
                            <TableHead>
                            <TableRow>
                                <TableCell>

                                </TableCell>
                                {
                                dataSearch && dataSearch.length > 0 && dataSearch[0].DatosDocumento && dataSearch[0].DatosDocumento.map(({ NombreIndice }) => {
                                    return  (
                                            <TableCell>
                                                { NombreIndice}
                                            </TableCell>
                                        )
                                    }
                                )            
                                }
                                <TableCell>

                                </TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                   dataSearch && dataSearch.map(elem => {
                                        return (  
                                        <TableRow>
                                            <TableCell>
                                                <Button onClick={() => consultaDocumento(elem) } >
                                                <VisibilityIcon />
                                                </Button>
                                            </TableCell>  
                                            {
                                                elem && elem.DatosDocumento.map(({ ValorLlave},index) => {
                                                    
                                                return  (                                                    
                                                        <TableCell>
                                                            { ValorLlave}
                                                        </TableCell>
                                                    )
                                                }
                                                
                                                )
                                            }
                                             <TableCell>
                                                <DeleteIcon />
                                            </TableCell>
                                        </TableRow>
                                        )                
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClick} >
                        { translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog>     

            <Dialog
                open={openDocument}>
                <DialogTitle>{ translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                <DialogContent>
                    <WarningIcon style={{ color: '#c18a2d', fontSize: '60px'}} /> { translate('traducciones.modal.noResults')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClick} >
                        { translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog>      

                <Dialog
                    open={openDelete}
                    >
                    <DialogTitle>{ translate('traducciones.modal.eliminarNodo')}</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmitDelete}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            { translate('traducciones.modal.eliminarNodoConfirm')}
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.confirm"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                                icon = { <DeleteIcon /> }
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>  

                <Dialog fullWidth open={openCampos}>
                    <DialogTitle>{ translate('traducciones.titulos.indexarArchivos')}</DialogTitle>
                        <FormWithRedirect 
                            resource="catalogos"
                            save={indexarDos}
                            render={({
                                handleSubmitWithRedirect,
                                pristine,
                                saving
                            }) => (
                                <>
                                <DialogContent>                                    
                                    <div class="checkbox-wrapper-7">
                                    <input checked={checkPermiso} class="tgl tgl-ios" id="cb2-7" type="checkbox" onChange={(e) => setCheckPermiso(e.target.checked)} />
                                    <label class="tgl-btn" for="cb2-7"/>
                                    </div><p style={{marginLeft:'70px',marginTop:'-23px'}}>{translate('traducciones.modal.asigUser')} <b>{localStorage.getItem('userName')}</b></p>
                                    {dataCampos.length<=0 ? <div style={{padding:'10px',fontWeight:'bold',fontSize:'15px'}}><center>{ translate('No se encontrarón campos en los nodos seleccionados') }</center></div> : <div><span>{translate('traducciones.generales.noArchivos')}: {docs.length}</span><br/>
                                    </div> }
                                { dataCampos ? (
                                    <div id='modalIndexar' className="row">
                                        {   dataCampos.map(
                                            (item) => {
                                                return (
                                                <div className="col-md-6">
                                                { item.Multiple ? (
                                                    <div style={{marginTop:'-10px'}}>
                                                         <ArrayInput className="valor_iterator" label={ "Campos " + item.NombreIndice }  source={"multiple-" + item.IdIndice} style={{ width: "100%" }}>
                                                            <SimpleFormIterator>
                                                            { item.Tipo == 1 ? (<TextInput label={ "Campo " + item.NombreIndice }  source={"valor-" + item.IdIndice} defaultValue=''/> ) : null }
                                                            { item.Tipo == 7 && item.ValoresTabla ? (
                                                                <SelectInput label={ "Campo " + item.NombreIndice } resettable={ true } validate={ item.Requerido ? [required()] : null} source={"valor-" + item.IdIndice}  choices={ item.ValoresTabla.map(value => ({id: value, name: value})) } defaultValue='' />
                                                            ) : null }                                                            
                                                            { item.Tipo == 2 ? (<NumberInput  resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice} defaultValue=''/>) : null }
                                                            { item.Tipo == 3 ? (<TextInput resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice} defaultValue=''/>) : null }
                                                            { item.Tipo == 4 ? (<DateInput resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice}  defaultValue=''/>) : null }
                                                            { item.Tipo == 5 ? (<TextInput type="time" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice}  defaultValue=''/>) : null }
                                                            { item.Tipo == 6 ? (<TextInput type="email" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={"valor-" + item.IdIndice}  defaultValue=''/>) : null }
                                                            </SimpleFormIterator>
                                                         </ArrayInput>   
                                                    </div>       
                                                ) : (
                                                 <div>       

                                                { item.Tipo == 1 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="text" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice  }  />) : null }

                                                { item.Tipo == 7 && item.ValoresTabla ? (
                                                    <div>
                                                        <SelectInput style={{ width: "100%" }} name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  choices={ item.ValoresTabla.map(value => ({id: value, name: value})) } defaultValue='' />
                                                    </div>
                                                ) : null }

                                                { item.Tipo == 2 ? (<NumberInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.Tipo == 3 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="text" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.Tipo == 4 ? (<DateInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } name={ "campo-" + item.IdIndice + "" }  resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.Tipo == 5 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="time" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                { item.Tipo == 6 ? (<TextInput style={{ width: "100%" }} defaultValue={ item.ValorLlave ? item.ValorLlave : ""  } type="email" name={ "campo-" + item.IdIndice + "" } resettable={ true } validate={ item.Requerido ? [required()] : null} label={ "Campo " + item.NombreIndice } source={item.IdIndice }  />) : null }
                                                </div>
                                                )}
                                            </div>
                                        )})
                                        }    
                                    </div>
                                ) : null
                            }
                                </DialogContent>
                                <DialogActions>
                                    <SaveButton
                                        label="ra.action.add"
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        disabled={dataCampos <= 0 ? true : false}
                                        id='BtnIndexar'
                                    />
                                    <Button onClick={handleCloseClick} >
                                        { translate('ra.action.cancel')}
                                    </Button>
                                </DialogActions>
                                </>
                            )}
                        />
                </Dialog>

                <Dialog fullWidth open={openDesc}>
                    <DialogTitle>{ translate('traducciones.titulos.archivosTmp')}</DialogTitle>
                        <FormWithRedirect
                            resource="catalogos"
                            save={digitalizarArchivos}
                            render={({
                                handleSubmitWithRedirect,
                                pristine,
                                saving
                            }) => (
                                <>
                                <DialogContent>
                                    <span style={{fontWeight:'bold'}}>{translate('traducciones.campos.descripcion')}:</span> <input className='form-control' type='text' onChange={(e)=>setDesc(e.target.value)}
                                    style={{width:'50%', display:'inline-flex', border:'0.5px solid gray',backgroundColor:'#F2F2F2'}} placeholder={translate('traducciones.campos.ingDesc')}/>
                                </DialogContent>
                                <DialogActions>
                                    <SaveButton
                                        label="ra.action.add"
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        id='btnClasificar'
                                    />
                                    <Button onClick={handleCloseClick} >
                                        { translate('ra.action.cancel')}
                                    </Button>
                                </DialogActions>
                                </>
                            )}
                        />
                </Dialog>
                <Dialog fullWidth open={openNodosEncontrados}>
                    <DialogTitle>{ translate('traducciones.titulos.resultadoNodos')}</DialogTitle>
                        <FormWithRedirect
                            resource="catalogos"
                            save={buscarNodo}
                            render={({
                                handleSubmitWithRedirect,
                                pristine,
                                saving
                            }) => (
                                <>
                                <DialogContent>
                                <div  className="row">
                                    <div  className="col-md-12 col-md-custom"  style={{ overflowX: "auto", padding: "10px"}}>
                                        <span style={{fontSize:'15px'}} hidden={ resultadoNodos.length > 0 ? false : true }>{translate('traducciones.modal.seleccionNodo')}: </span><br/><br/>
                                    {resultadoNodos.length>0 ? resultadoNodos.map(item=>{
                                        return(
                                            <div>
                                               <center><input className='resultNodos' type='text' value={item.ruta} onClick={()=>desplegarNodos(item)} readOnly/></center>     
                                            </div>
                                        )
                                    }): 
                                        <div>
                                            <span style={{marginLeft:'42%',fontSize:'2vh'}}><b>{translate('traducciones.modal.noResults')}</b></span>    
                                        </div>
                                    }    
                                    </div>
                                </div>
                                </DialogContent>
                                <DialogActions>
                                    <SaveButton
                                        label="ra.action.add"
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        hidden
                                    />
                                    <Button onClick={handleCloseClick} >
                                        { translate('ra.action.cancel')}
                                    </Button>
                                </DialogActions>
                                </>
                            )}
                        />
                </Dialog>      

            </SimpleForm>
        </Create>
)};

export default DigitalizacionMasiva;
