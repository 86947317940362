import * as React from 'react';
import { useState,useEffect  } from 'react';
import {
    FormWithRedirect,
    TextInput,
    useDelete,
    useTranslate,
    SimpleForm,  
    Loading, 
    Error, 
    Create,
    Query, 
    BooleanInput,
    SaveButton,
    useDataProvider,
    Pagination,
    useNotify
} from 'react-admin';
import Button from '@material-ui/core/Button';
import FolderIcon from '@material-ui/icons/Folder';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SearchIcon from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/Warning';
import withWidth from '@material-ui/core/withWidth';
import CardResultado from './Responsive/CardResultado';
import ExternalViewer from '../Libs/ExternalViewer/ExternalViewer';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TableResultado from '../Components/TableResultado';
import MyLoading from '../Components/MyLoading';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import swal from 'sweetalert';
import NoAccess from '../Components/NoAccess';
import DownloadButton from '../Components/DownloadButton';
import introJs from 'intro.js';
import 'intro.js/introjs.css';

const useStyles = makeStyles({
    nodo : {
        padding: '6px',
        border: '1px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        width: 'max-content'
    },
    nodoSeleccionado :  {
        padding: '6px',
        border: '1px solid black',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        backgroundColor: "#319b4257",
        color: '#277233'
    }
});

/*
Funcion recursiva que crear la visualizacion y opciones de los nodos hijos
Esta funcion recibe como parametros:
nodo : nodo padre,
clases : objeto de clases css dinamico,
addnode : funcion para seleccionar cada nodo,
translate : funcion de react admin declarada en la clase principal para las traducciones
nodoPadreelemento: nodo seleccionado principal,
colapsar: funcion para colapsar o no el nodo
checknodoabierto: funcion para verificar el estado de colapso del nodo que se esta dibujando
*/
function dibujaNodosHijos(nodo,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto){
    if(nodo.hijos != undefined){
        return (
            <div>
                {nodo.hijos.map(
                (item) => {
                    checknodoabierto(item);
                    return (
                    <div  style={{ marginLeft: '40px', marginTop : '-5px'}}>
                        <div style={{ float: 'left' }}>
                            <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                        </div>
                        <div className={ nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > <FolderIcon style={{ marginRight: '5px'}} />
                            { item.TextoNodo }
                            <Button title={ translate('traducciones.titulos.consultarCampos')} onClick={() => addNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                <SearchIcon style={{ fontSize: '16px'}}  />
                            </Button>
                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                <ExpandLessIcon />
                            </Button>
                            }
                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                <ExpandMoreIcon />
                            </Button>
                            }
                        </div>
                        { !item.colapsado &&
                        <div>
                            { dibujaNodosHijos(item,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                        </div>
                        }
                    </div>
                )})
                }
            </div>
        )
    }
}

const BusquedaContenido = ({ width, ...props }) => {   

    const permisos = props.permissions ? props.permissions.ConsultadeDocumentos : null;  
    const checkPermiso = (permiso) => {
        if(permisos){
            return permisos[permiso];
        }
        return false;
    }
    const access= JSON.parse(localStorage.getItem('permisos'))

    const classes = useStyles();
    const [nodoPadreElemento, setnodoPadreElemento] = useState(null);    
    const [loadinga, setLoadinga] = useState(false);
    const [openNoResult, setOpenNoResult] = useState(false);
    const [openResult, setOpenResult] = useState(false);
    const [openDelete, setopenDelete] = useState(false);
    const [openDocument, setopenDocument] = useState(false);    
    const [documentt, setDocument] = useState(null);
    const [deleteOne] = useDelete();
    const translate = useTranslate();
    const [dataSearch, setDataSearch] = useState([]);
    const [datoBusqueda, setDatoBusqueda] = useState(null);
    const [visor, setVisor] = useState(null); 
    const notify = useNotify();
    const [randomUpdate, setRandomUpdate] = useState(null);
    const [datanodosAbiertos, setDatanodosAbiertos] = useState([]);    
    const [openDeleteDocumento, setOpenDeleteDocumento] = useState(false);
    const dataProvider = useDataProvider();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [order, setOrder] = useState("");
    const [sort, setSort] = useState(true);
    const [cargando,setCargando] = useState (false);
    const [allDocs,setAllDocs] = useState([]);
    const [nodoBuscado,setNodoBuscado] = useState(null);
    const [nodos,setNodos] = useState(null);
    const [openNodosEncontrados,setOpenNodosEncontrados] = useState (false)
    const [resultadoNodos,setResultadoNodos] = useState([])
    const [isCheck, setIsCheck] = useState([]);
    const [todosSeleccionados, setTodosSeleccionados] = useState(false);
    const inTour = JSON.parse(localStorage.getItem('activeTour'))

    let introHelp;
    introHelp = introJs('#root');
    introHelp.setOptions({
        disableInteraction: false,
        doneLabel: translate('traducciones.introBotones.doneLabel'),
        nextLabel: translate('traducciones.introBotones.nextLabel'),
        prevLabel: translate('traducciones.introBotones.prevLabel'),
        tooltipPosition:'top',
        steps: [
            {
                intro: translate('traducciones.introHelp.intro1'),
                title: translate('traducciones.introHelp.title1'),
                element: '#help'
            },
        ]                
    });

    const showHelp = () =>{
        const offPermisos = {
            querys: false,
            carga: false,
            admin: false,
            estructura: false,
        }
        if(document.cookie == 'introjs-dontShowAgain=true'){
            document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
                setTimeout(()=>{
                    introHelp.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).onexit(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).start();                         
                },500)
        }
    }

    function crearCookie(nombre, valor, diasParaExpirar) {
    var fechaExpiracion = new Date();
    fechaExpiracion.setDate(fechaExpiracion.getDate() + diasParaExpirar);
    var cookie = nombre + '=' + valor + '; expires=' + fechaExpiracion.toUTCString() + '; path=/';
    document.cookie = cookie;
    }

    let introNodos;
    useEffect(() => {
        if(inTour.querys){
            setTimeout(()=>{
                introNodos = introJs('#search-box');
                introNodos.setOptions({
                    disableInteraction: true,
                    doneLabel: translate('traducciones.introBotones.doneLabel'),
                    nextLabel: translate('traducciones.introBotones.nextLabel'),
                    prevLabel: translate('traducciones.introBotones.prevLabel'),
                    tooltipPosition:'top',
                    steps: [
                        {
                            intro: translate('traducciones.introContenido.intro1'),
                            title: translate('traducciones.introContenido.title1')
                        },
                        {
                            intro: translate('traducciones.introContenido.intro2'),
                            title: translate('traducciones.introContenido.title2'),
                            element: '#nodo'
                        },
                        {
                            intro: translate('traducciones.introContenido.intro3'),
                            title: translate('traducciones.introContenido.title3'),
                            element: '#more'
                        },
                        {
                            intro: translate('traducciones.introContenido.intro4'),
                            title: translate('traducciones.introContenido.title4'),
                            element: '#btnSearch'
                        },
                        
                    ]                
                  });
        
                introNodos.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).onexit(()=>{showHelp()}).oncomplete(()=>{showHelp()}).start();
            },1000)
        }
      }, []);

    const onToggleItem = (e) => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    }

    const handleSelectAll = () => {
        setTodosSeleccionados(!todosSeleccionados);
        if(dataSearch && dataSearch.data){
            let marcas = dataSearch.data.map(li => li.IdDocument + "");
            setIsCheck(marcas);
        }

        if (todosSeleccionados) {
          setIsCheck([]);
        }
    };

    let introQueryByCampos = introJs()
    const viewNode = (nodoPadre) => {
        setPage(1);
        setnodoPadreElemento(nodoPadre);
        if(inTour.querys){
            setTimeout(()=>{
                introQueryByCampos = introJs('#search-box')
                introQueryByCampos.setOptions({
                    disableInteraction: false,
                    doneLabel: translate('traducciones.introBotones.doneLabel'),
                    nextLabel: translate('traducciones.introBotones.nextLabel'),
                    prevLabel: translate('traducciones.introBotones.prevLabel'),
                    tooltipPosition:'top',
                    steps: [
                            {
                                intro: translate('traducciones.introContenido.cintro1'),
                                title: translate('traducciones.introContenido.ctitle1'),
                                element: "#formu",
                                scrollTo: "off"
                            },
                            {
                                intro: translate('traducciones.introContenido.cintro2'),
                                title: translate('traducciones.introContenido.ctitle2'),
                                element: "#btnSave",
                                position: 'right',
                                scrollTo: 'off'
                            }
                    ]                
                  });
        
                introQueryByCampos.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).onexit(()=>{showHelp()}).oncomplete(()=>{showHelp()}).start();
            },800)
        }
    }

    const handleCloseClick = () => {
        setOpenNoResult(false);
        setOpenResult(false);
        setopenDelete(false);
        setOpenNodosEncontrados(false);
    }

    const changePage = (nPage) => {
        setDataSearch([])
        setPage(nPage);
        reloadPages(nPage,perPage);
    }

    const ChangePerPage = (rows) => {
        setPage(1);
        setPerPage(rows);   
        reloadPages(1,rows);
    }

    const cambiaOrden = (column) => {
        setOrder(column);
        reloadPages(null,null,!sort,column);
        setSort(!sort);
    }

    const reloadPages = (npage,nperpage,nsort,norder) => {
        setDataSearch([]);
        setCargando(true);
        dataProvider.getList('queryContenido', { 
            IdNode: nodoPadreElemento != null ? nodoPadreElemento.RutaNodo : "/", 
            PageNumber : npage? npage : page,
            DocumentsNumber : nperpage ? nperpage : perPage,
            OrderField : norder ? norder : order,	
            AscendingOrder : nsort != null ? nsort : sort,
            Text: datoBusqueda.valorContenido, 
            SearchWords : datoBusqueda.palabras
        })
            .then((data) => {
                if(data != null && data.data.length == 0){
                    setCargando(false);
                    setOpenNoResult(true);
                }else{
                    if(data != null && data.data.length > 0){
                        setCargando(false);
                        setDataSearch(data);  
                        setOpenResult(true);
                    }
                }
            })
            .catch(error => {
        });   
    }

    if(loadinga){
        return <Loading />
    }

    const handleSubmitSearch = async values => {
        introQueryByCampos.exit();
        setAllDocs(null)
        setPage(1)
        setPerPage(10)
        setTodosSeleccionados(false)
        setIsCheck([])
        setDatoBusqueda(values);
        setCargando(true)
        dataProvider.getList('queryContenido', { 
                IdNode: nodoPadreElemento != null ? nodoPadreElemento.RutaNodo : "/", 
                Text: values.valorContenido, 
                SearchWords : values.palabras,
                PageNumber : 1,
                DocumentsNumber : 100,
                OrderField : "",	
                AscendingOrder : true,
            })
            .then((data) => {
                setCargando(false)
                if(data != null && data.data.length == 0){
                    
                    setOpenNoResult(true);
                }else{
                    if(data != null && data.data.length > 0){
                        const dataSlice = {
                            data: data.data.slice(0,10),
                            porcentaje: data.porcentaje,
                            total: data.total
                        }
                        setAllDocs(data)
                        setDataSearch(dataSlice);
                        setOpenResult(true);
                    }
                }
            })
            .catch(error => {
                setCargando(false)
        });   
    }

    const consultaDocumento = (elem) => {
         //Obtenemos la url completa y sustituimos el pathName por el pathname del visor con el parametro del id del documento
         const urlFull = window.location.href;
         const pathName = props.location.pathname;
         let urlHost = urlFull.replace(pathName,`/visor?id_document=${elem.IdDocument}`)
         
         /*
         La funcion consulta documento se cambio  por esta linea para abrir una nueva ventana  y mostrar el visor, se obtine el nombre del host y se pasa 
         el id del documento como parametro en la URL para su posterior consulta.
          */
         window.open(urlHost, '_blank', 'fullscreen=yes');
    } 


    const saveAnotations = ()=>{
        var anotations = visor.getAnnotations();
        if(anotations.length > 0){
            dataProvider.update('anotaciones', { DocumentId: documentt.IdDocument,Annotations:anotations})
                .then(({ data }) => {
                    swal({
                        icon:'success',
                        text: translate('traducciones.notificaciones.actualizaAnotacionesOK')
                    })
                })
                .catch(error => {
            });   
        }
    }

    const closeExternal = () =>{
        setOpenResult(true);
        setopenDocument(false);
    }

    const colapsar = (nodo) => {
        setRandomUpdate(Math.random());
        if(nodo.colapsado){
            datanodosAbiertos.push(nodo.RutaNodo)
            setDatanodosAbiertos(datanodosAbiertos);
        }else{
            setDatanodosAbiertos(datanodosAbiertos.filter(function(nodoguardado) { 
                return nodoguardado !== nodo.RutaNodo;
            }));
        }
        nodo.colapsado = !nodo.colapsado;
    }

    const checknodoabierto = (nodo) => {
        if(datanodosAbiertos.includes(nodo.RutaNodo)){
            nodo.colapsado = false;
        }
    }

    const deleteDocument = (doc) =>{
        setDocument(doc);
        setOpenDeleteDocumento(true);
    }


    const handleSubmitDeleteDocument = async values => {
        deleteOne('documento', documentt.IdDocument, documentt,{
            onSuccess: ({ data }) => {
                changePage(page);
                setOpenDeleteDocumento(false);
                setLoadinga(false);
            },
            onFailure: ({ error }) => {
                setLoadinga(false);
            }
        });        
    };

    const handleCloseClick2 = () => {
        setOpenDeleteDocumento(false);
    }

    var count = 0;

    const buscarNodo = (nodo,e) => {
        e.preventDefault();
        if(nodo==null || nodo.trim()==''){
            swal({
                icon: "info",
                text: translate('traducciones.modal.sinNombre'),
                timer: 1000,
                buttons:false
              });
        }else{
        let nodosEncontrados=[];
        nodos.map((nPadre) => {
          if (nPadre.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
            nodosEncontrados.push({
                'ruta':"/"+nPadre.TextoNodo,
                'n1':nPadre,
                'lvl':1
            })
          } else if (nPadre.hijos.length > 0) {
            nPadre.hijos.map((n2) => {
              if (n2.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                nodosEncontrados.push({
                    'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo,
                    'n1':nPadre,
                    'n2':n2,
                    'lvl':2
                })
              } else if (n2.hijos.length > 0) {
                n2.hijos.map((n3) => {
                  if (n3.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                    nodosEncontrados.push({
                        'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo,
                        'n1':nPadre,
                        'n2':n2,
                        'n3':n3,
                        'lvl':3
                    })
                  } else if (n3.hijos.length > 0) {
                    n3.hijos.map((n4) => {
                      if (n4.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                        nodosEncontrados.push({
                            'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo + "/" + n4.TextoNodo,
                            'n1':nPadre,
                            'n2':n2,
                            'n3':n3,
                            'n4':n4,
                            'lvl':4
                        })
                      } else if (n4.hijos.length > 0) {
                        n4.hijos.map((n5) => {
                          if (n5.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                            nodosEncontrados.push({
                                'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo + "/" + n4.TextoNodo + "/" + n5.TextoNodo,
                                'n1':nPadre,
                                'n2':n2,
                                'n3':n3,
                                'n4':n4,
                                'n5':n5,
                                'lvl':5
                            })
                          }else if(n5.hijos.length > 0){
                            n5.hijos.map(n6=>{
                                if (n6.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                                    nodosEncontrados.push({
                                        'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo + "/" + n4.TextoNodo + "/" + n5.TextoNodo + "/" + n6.TextoNodo,
                                        'n1':nPadre,
                                        'n2':n2,
                                        'n3':n3,
                                        'n4':n4,
                                        'n5':n5,
                                        'n6':n6,
                                        'lvl':6
                                    })
                                }
                            })
                          }
                        });
                      }else{
                        //Si no hay hijos en nivel 5
                      }
                    });
                  } else {
                    //Si no hay hijos en nivel 4
                  }
                });
              } else {
                //Si no hay hijos en nivel 3
              }
            });
          } else {
            //Si no hay hijos en nivel 2
          }
        });
        if(nodosEncontrados.length==1){
            desplegarNodos(nodosEncontrados[0])
        }else{
            setResultadoNodos(nodosEncontrados);
            setOpenNodosEncontrados(true)
        }
    }
    };

    const desplegarNodos = (nodo) =>{
        switch(nodo.lvl){
            case 1: 
                viewNode(nodo.n1)
                setOpenNodosEncontrados(false)
            break;
            case 2:
                colapsar(nodo.n1);
                viewNode(nodo.n2)
                setOpenNodosEncontrados(false) 
            break;
            case 3:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                viewNode(nodo.n3)
                setOpenNodosEncontrados(false) 
            break;
            case 4:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                colapsar(nodo.n3);
                viewNode(nodo.n4)
                setOpenNodosEncontrados(false) 
            break;
            case 5:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                colapsar(nodo.n3);
                colapsar(nodo.n4);
                viewNode(nodo.n5)
                setOpenNodosEncontrados(false)
            break;
            case 6:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                colapsar(nodo.n3);
                colapsar(nodo.n4);
                colapsar(nodo.n5);
                viewNode(nodo.n6)
                setOpenNodosEncontrados(false)
            break;
            default: ;
        }
    }

    let startIndex = (page - 1) * perPage;
    return (
        <Create {...props} title={ translate('traducciones.titulos.busquedaContenido')} >
            <SimpleForm {...props} toolbar={ null }  >

            { openDocument ? (     
            <ExternalViewer closeExternal = { closeExternal } saveAnotations= {saveAnotations} />
            ): access.CONSULTAPORCONTENIDO ? (
            
            <div fullWidth style={{minHeight:'600px'}}>
                <div style={{ padding: '10px'}}>  
                <div id='search-box'>
                        <div id='search-form' >
                        <input id='search-text' placeholder={translate('traducciones.campos.buscador')} type='text' onChange={(e) => setNodoBuscado(e.target.value)}/>
                        <button id='search-button' onClick={(e)=>buscarNodo(nodoBuscado,e)}><span><ManageSearchIcon style={{fontSize:'25px'}}/></span></button>
                        </div>
                    </div>      
                    <Query type="getList" resource="nodos" payload={ nodoPadreElemento }>
                        {({ data, loading, error }) => {
                            setNodos(data)
                            if (loading) { return <Loading />; }
                            if (error) { return <Error />; }
                            return (
                                <div  className="row">
                                    <div  className="col-md-5 col-md-custom"  style={{ overflowX: "auto", padding: "10px"}}>
                                    {   data.map(
                                        (item) => {
                                            checknodoabierto(item);
                                            return (
                                            <div>
                                                <div id='nodo' className= { nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > 
                                                    <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                                    { item.TextoNodo } 
                                                    <Button id='btnSearch' title={ translate('traducciones.titulos.consultarCampos')}  onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                                        <SearchIcon style={{ fontSize: '16px'}}/>
                                                    </Button>
                                                    { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                        <ExpandLessIcon />
                                                    </Button>
                                                    }
                                                    { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                                    <Button id='more' style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                        <ExpandMoreIcon />
                                                    </Button>
                                                    }
                                                </div>
                                                { !item.colapsado &&
                                                <div style={{ marginLeft: '-20px'}}>
                                                    { dibujaNodosHijos(item,classes,viewNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                                                </div>
                                                }
                                            </div>
                                        )})
                                    }
                                    </div>
                                    {
                                        nodoPadreElemento != null ? (
                                    <div  className="col-md-7 col-md-custom">
                                        <div>
                                            <div>
                                                {translate('traducciones.titulos.camposResultados')}
                                            </div>
                                            <SimpleForm id='formu' save={ handleSubmitSearch } toolbar= { <SaveButton id='btnSave' label={translate('traducciones.botones.consultar')} icon= { <SearchIcon /> } disabled={cargando}/> } >
                                                <div className="row">
                                                <TextInput multiline={true} rows="4"  style={{ width: "100%" }} type="text" name={ "valorContenido" } resettable={ true } label={ "Campo contenido"} source="valorContenido" value="" defaultValue="" />
                                                <BooleanInput label="Buscar por palabras" source="palabras" />
                                                </div>   
                                            </SimpleForm>  
                                        </div>    
                                    </div>
                                    ) : null }
                                </div>
                            );
                        }}
                    </Query>
                </div> 
            </div> 

            ):( <NoAccess/> )} 

            <Dialog
                fullWidth
                open={openNoResult}>
                <DialogTitle>{ translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                <DialogContent>
                    <WarningIcon style={{ color: '#c18a2d', fontSize: '60px'}} /> { translate('traducciones.modal.noResults')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClick} >
                        { translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog>   


                <Dialog
                    fullWidth
                    open={openResult}>
                    <DialogTitle>{ translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                    <DialogContent>
                        {cargando ? <MyLoading style={{marginTop:'-20px'}}/> : width != "xs" ? 
                    (
                      <TableResultado 
                      dataSearch= { dataSearch } 
                      consultaDocumento = { consultaDocumento } 
                      deleteDocument = { deleteDocument} 
                      cambiaOrden = { cambiaOrden } 
                      order = {order}
                      sort = { sort }
                      checkPermiso = { checkPermiso }
                      perPage= {perPage} 
                      setPage= {changePage} 
                      setPerPage = {ChangePerPage} 
                      page={page}
                      allDocuments={allDocs}
                  />  
                    ) : ( 
                        <div>
                        {
                        dataSearch && dataSearch.data && dataSearch.data.map(elem => {
                                count++;
                                startIndex++;
                                return (  
                                    <CardResultado dataSearch= {dataSearch} i = { startIndex } cambiaOrden= {cambiaOrden} order= {order} sort={sort} elem = {elem} onToggleItem={onToggleItem} todosSeleccionados={todosSeleccionados} isCheck={isCheck}/>
                                )                
                            })
                         }
                         <input type='checkbox'
                            style={{padding: "0px", marginBottom:'10px'}}
                            id='checkall'
                            name='checkall'
                                checked={todosSeleccionados}
                                onClick={handleSelectAll}
                        />
                        <span style={{marginLeft:'10px', color: 'rgb(92, 157, 102)'}}><b>{translate('traducciones.campos.checkMarcar')}</b></span>
                        <DownloadButton isCheck={isCheck} dataSearch={dataSearch} allDocuments={allDocs} todosSeleccionados={todosSeleccionados}/>
                        <Pagination perPage= {perPage} setPage= {changePage} setPerPage = {ChangePerPage} page={page} total= {dataSearch.total} />
                        </div>)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseClick} >
                            { translate('ra.action.close')}
                        </Button>
                    </DialogActions>
                </Dialog>      

                <Dialog
                    fullWidth
                    open={openDeleteDocumento}
                    >
                    <DialogTitle>{ translate('traducciones.modal.eliminarDocumento')}</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmitDeleteDocument}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            { translate('traducciones.modal.eliminarDocumentoConfirm')}
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.confirm"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                                icon = { <DeleteIcon /> }
                            />
                            <Button onClick={handleCloseClick2} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>
                <Dialog fullWidth open={openNodosEncontrados}>
                    <DialogTitle>{ translate('traducciones.titulos.resultadoNodos')}</DialogTitle>
                        <FormWithRedirect
                            resource="catalogos"
                            save={buscarNodo}
                            render={({
                                handleSubmitWithRedirect,
                                pristine,
                                saving
                            }) => (
                                <>
                                <DialogContent>
                                <div  className="row">
                                    <div  className="col-md-12 col-md-custom"  style={{ overflowX: "auto", padding: "10px"}}>
                                        <span style={{fontSize:'15px'}} hidden={ resultadoNodos.length > 0 ? false : true }>{translate('traducciones.modal.seleccionNodo')}: </span><br/><br/>
                                    {resultadoNodos.length>0 ? resultadoNodos.map(item=>{
                                        return(
                                            <div>
                                               <center><input className='resultNodos' type='text' value={item.ruta} onClick={()=>desplegarNodos(item)} readOnly/></center>     
                                            </div>
                                        )
                                    }): 
                                        <div>
                                            <span style={{marginLeft:'42%',fontSize:'2vh'}}><b>{translate('traducciones.modal.noResults')}</b></span>    
                                        </div>
                                    }    
                                    </div>
                                </div>
                                </DialogContent>
                                <DialogActions>
                                    <SaveButton
                                        label="ra.action.add"
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        hidden
                                    />
                                    <Button onClick={handleCloseClick} >
                                        { translate('ra.action.cancel')}
                                    </Button>
                                </DialogActions>
                                </>
                            )}
                        />
                </Dialog>      

            </SimpleForm>
        </Create>
)};

export default withWidth()(BusquedaContenido);
