import React from 'react';
import {
  Card,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import {
  useTranslate,
  EditButton,
  DeleteButton,
  Link,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PostFilter from '../../Components/PostFilter';

const useStyles = makeStyles({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0.5rem 0',
    borderRadius : '16px',
    marginBottom: '15px',
    boxShadow: '1px 3px 7px #3b824b'
  },
  cardTitleContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
  },
  cardContentRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0.5rem 0',
  },
  cardHeader : {
    backgroundColor: '#525b59',
    paddingBottom: '10px',
    paddingTop: '10px',
    color: 'white'
  }
});

const MobileTempDocumentList = (propsDataGrid) => {
  const { ids, data, checkPermiso } = propsDataGrid;
  const classes = useStyles();
  const translate = useTranslate();

  const ChangeNodeButton = ({ record }) => (
    <Button
        style={{ backgroundColor: "white", color : "#319b42", padding: "3px 4px", border: "none !important"}}
        component={Link}
        onClick={ () => { propsDataGrid.selectNode(record) } }>
            <CallSplitIcon /> 
    </Button>
);

const ChangeUserButton = ({ record }) => (
    <Button
        style={{ backgroundColor: "white", color : "#319b42", padding: "3px 4px", border: "none !important"}}
        component={Link}
        onClick={ () => { propsDataGrid.selectUser(record) } }>
            <SupervisorAccountIcon /> 
    </Button>
);


  return (
    <div style={{ margin: '0.5em' }}>
      <div style={{marginLeft:'15%'}}>
        <PostFilter/>
      </div>
      {ids && ids.map(id => (
            <Card key={id} className={classes.card}>
               <CardHeader
                        className={classes.cardHeader }
                        title={
                          <div className={classes.cardTitleContent} alignItems="center">
                                <span style={{ fontSize: 'large' }}>
                                { translate("traducciones.campos.numeroTemporal") } : { data[id].IdDocumentTmp } 
                                </span>
                            </div>
                        }
                    />
                <div style= { { padding: '16px', paddingTop: '5px'} }>
                  <div style= { { marginBottom: '5px '}}>
                      <span>
                        <span  style= {{ marginRight: '5px', fontWeight: 'bold'}}>{ translate("traducciones.campos.fechaDigitalizacion") }:</span>  
                        { data[id].DateTime } 
                      </span>
                  </div> 
                  <div style= { { marginBottom: '5px '}}>
                      <span>
                        <span  style= {{ marginRight: '5px', fontWeight: 'bold'}}>{ translate("traducciones.campos.descripcion") }:</span>  
                        { data[id].Description } 
                      </span>
                  </div>
                  <div style= { { marginBottom: '5px '}}>
                      <span>
                        <span  style= {{ marginRight: '5px', fontWeight: 'bold'}}>{ translate("traducciones.campos.tipoDocumento") }:</span>  
                        { data[id].DocumentType } 
                      </span>
                  </div>
                  
                  <div  align="right" >
                  { checkPermiso('Indexar') && <EditButton record={ data[id] } label="" className="actionButton" /> }
                  { checkPermiso('Indexar') &&<ChangeNodeButton record = { data[id] } />     }
                  { checkPermiso('Indexar') &&<ChangeUserButton record = { data[id] } /> }
                  { checkPermiso('EliminarDocumentosPendientesdeIndexar') && <DeleteButton record = { data[id] } label="" className="actionButton" undoable={false} confirmTitle={translate('traducciones.modal.eliminarUsuario')} confirmContent={translate('traducciones.modal.eliminarUsuarioConfirm')}></DeleteButton> }
                  </div>             
                </div>
            </Card>
          ))
          }
    </div>
  );
};


MobileTempDocumentList.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};


export default MobileTempDocumentList;