import React, { useState } from 'react';
import { 
    Edit, 
    TextInput, 
    SimpleForm, 
    Toolbar,
    SaveButton,
    SelectInput,
    BooleanInput,
    NumberInput,
    required, 
    useEditController,
    useTranslate,
    ReferenceInput
} from 'react-admin';
import Button from '@material-ui/core/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Chip } from '@mui/material';

const EditToolbar = ({ onCancel, ...props }) =>{    
    const translate = useTranslate();
    return (
       <Toolbar {...props}>
           <div>
                <SaveButton label="ra.action.save" {...props} />
                <Button onClick={onCancel} style={{position: 'absolute', right: '26px'}}>{ translate('ra.action.cancel')}</Button>
           </div>
       </Toolbar>
    )
};

const UsuarioEdit = ({ onCancel, onSuccessOk, ...props }) =>
{

    const { record } = useEditController(props);
    const translate = useTranslate();
    const [status,setStatus] = useState(null);

    const getValues = async values =>{
        setStatus(values)
    }

    const stiloOff = {
        color:'red',
        marginTop: '-22px'
    }

    const stiloempty = {
        marginTop: '-22px'
    }

    const stiloOn = {
        color:'#3CAF0C',
    }

    return (
        <Edit {...props} undoable={false} onSuccess={ onSuccessOk }>
            <SimpleForm  toolbar={<EditToolbar onCancel={onCancel} />}>
                <div>
                    <div style={{ textAlign: 'center', width: '100%'}}>
                        <div>{translate('traducciones.titulos.editarUsuario')}</div>
                        <img src={"adduser.png"} style={{width: '60%'}} />
                    </div>
                    <TextInput source="Nombre" label="traducciones.campos.nombre" validate={required()} />
                    <TextInput source="Email" label="traducciones.campos.email" validate={required()} />     
                    <ReferenceInput label="traducciones.campos.rol"  source="IdTipo" reference="rolesUsuario">
                        <SelectInput  validate={required()}  />
                    </ReferenceInput>   
                    <ReferenceInput label="traducciones.campos.estadoPassword"  source="EstadoPassword" reference="estadoPassword">
                        <SelectInput  validate={required()}  />
                    </ReferenceInput>
                    <NumberInput source="Vigencia" label="traducciones.campos.vigenciaPassword" defaultValue={ 0 } />
                    <div className="col-md-12" style={{marginLeft:'10px'}}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <span style={!status ? stiloOff: stiloempty}>{translate('traducciones.campos.statusOff')}</span>
                            <BooleanInput style={status ? stiloOn : null} labelPlacement="bottom" size="medium" label={translate('traducciones.campos.statusOn')} source="EstadoUsuario" validate={getValues}/>
                        </Stack>
                    </div>
                    { record.Nombre != undefined ? (
                    <div className="row rowNoMessage" style={{ width: '100%', marginTop:'15px'}}>
                        <hr className='hr-text' data-content={translate('traducciones.permisos.permDocumentos')}/>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.anotaciones" source="Permissions.Anotaciones" />
                        </div>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.exportar"  source="Permissions.Exportar" />
                        </div>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.importar"  source="Permissions.Importar" />
                        </div>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.digitalizar"  source="Permissions.Digitalizar" />
                        </div>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.imprimir"  source="Permissions.Imprimir" />
                        </div>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.enviar"  source="Permissions.Enviar" />
                        </div>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.sinmarcaagua" source="Permissions.Sin_marca_de_agua" />
                        </div>
                        <hr className='hr-text' data-content={translate('traducciones.permisos.permConsultas')}/>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.general" defaultValue={false} source="Permissions.Consulta_general" />
                        </div>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.navegar" defaultValue={false} source="Permissions.Consulta_navegar" />
                        </div>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.porCampos" defaultValue={false} source="Permissions.Consulta_por_campos" />
                        </div>
                        <div className="col-md-6">
                            <BooleanInput label="traducciones.permisos.porContenido" defaultValue={false} source="Permissions.Consulta_por_contenido" />
                        </div>
                    </div>
                    ) : null }
                </div>
            </SimpleForm>
        </Edit>
    )
};

export default UsuarioEdit;