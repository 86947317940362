import React from 'react'
import a1 from '../Libs/ExternalViewer/Resources/Images/no-autorizado.png'
import { useTranslate } from 'react-admin'
// import '../Libs/ExternalViewer/Resources/Styles/predefinidasStyle.css'
// import '../Libs/ExternalViewer/Resources/Styles/digitalizarStyles.css'
import DashboardIcon from '@mui/icons-material/Dashboard';

const NoAccess = () => {
    const translate = useTranslate();

    const handleBack = (e) => {
        e.preventDefault();
        window.location.href='#/dashboard'
    }

  return (
    <div fullWidth style={{marginTop:'-50px'}}>
        <center>
        <img src={a1} style={{width:'70vh',marginBottom:'-20px'}}></img><br/>
        <span style={{fontSize:'20px'}}><b>{translate('traducciones.titulos.sinAcceso')}</b></span><br/>
        <button className='btn' style={{width:'150px',marginTop:'25px',fontSize:'17px',borderRadius:'10px', backgroundColor:'rgb(49 155 66)', color:'#F7F7F7'}} onClick={handleBack}>
            Ir a inicio
            <DashboardIcon style={{marginTop:'-4px', marginLeft:'10px'}}/>
        </button>
        </center>
    </div>
  )
}

export default NoAccess