import React from 'react';
import './App.css';
import { Admin, Resource, Logout,resolveBrowserLocale } from 'react-admin';
import { provider } from './DataProviders/GlobalDataProvider';
import { AuthProvider } from './DataProviders/AuthProvider';
import { LoginPage } from './Views/Login'
import CustomLayout from './Components/CustomLayout';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import UsuariosList from './Views/UsuariosList';
import GruposList from './Views/GruposList';
import IndicesList from './Views/IndicesList'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import 'bootstrap/dist/css/bootstrap.min.css';
import NodosList from './Views/NodosList';
import customTranslationMessagesES from './i18n/es'
import customTranslationMessagesEN from './i18n/en'
import customTranslationMessagesPT from './i18n/pt'
import customTranslationMessagesFR from './i18n/fr'
import BusquedaCampos from './Views/BusquedaCampos';
import BusquedaGeneral from './Views/BusquedaGeneral';
import BusquedaContenido from './Views/BusquedaContenido';
import DigitalizarImagenes from './Views/DigitalizarImagenes';
import TempDocumentList from './Views/TempDocumentList';
import DocumentIndexar from './Views/TempDocumentIndexar'
import BusquedaNavegar from './Views/BusquedaNavegar';
import DashBoard from './Views/DashBoard';
import DocumentAnalizer from './Components/DocumentAnalizer';
import BusquedaBastanteo from './Views/BusquedaBastanteo';
import { BrowserRouter } from 'react-router-dom';
import BusquedaPredefinida from './Views/BusquedaPredefinida';
import PredefinidaCampos from './Views/PredefinidaCampos';
import DigitalizacionMasiva from './Views/DigitalizacionMasiva';
import ConfigurationUser from './Views/ConfigurationUser';
import CatalogosList from './Views/CatalogosList';
import VisorLead from './Views/VisorLead';
import  ComponentPlantillas  from './Views/Responsive/Prueba'
import { Route } from "react-router-dom";
import Plantillas from './Views/Plantillas';

const messages = {
    'es': customTranslationMessagesES,
    'en': customTranslationMessagesEN,
    'fr': customTranslationMessagesFR,
    'pt': customTranslationMessagesPT,
};

const i18nProvider = polyglotI18nProvider(
    locale => messages[locale] ? messages[locale] : messages.en,
    resolveBrowserLocale(),
    {
      allowMissing: true
    }
);

const App = () => {

    const LogoutButton = props => <Logout {...props} icon={<ExitToAppIcon/>} />;    
    return (
        <BrowserRouter basename='./'>
        <Admin 
            locale="es" 
            i18nProvider={i18nProvider}
            layout={ CustomLayout }
            dataProvider={provider}
            authProvider={AuthProvider}
            logoutButton={LogoutButton}
            loginPage={ LoginPage }
            customRoutes={[
              <Route
                  path="/visor"
                  component={props => <VisorLead {...props} width="xs"/>} // Se agrega nuevo recurso para cargar visor, sin el layout predeterminado
                  noLayout
              />,
          ]}
            >
              <Resource name="dashboard" list={DashBoard} />
              <Resource name="general" list={BusquedaGeneral} />
              <Resource name="bastanteo" list={BusquedaBastanteo} />
              <Resource name="usuarios" list={UsuariosList} />
              <Resource name="grupos" list={GruposList}  />
              <Resource name="nodos" list={NodosList} />
              <Resource name="indices" list={IndicesList} />
              <Resource name="campos" list={BusquedaCampos} />
              <Resource name="contenido" list={BusquedaContenido} />
              <Resource name="navegar" list={BusquedaNavegar} />
              <Resource name="digitalizarImagenes" list={DigitalizarImagenes} />
              <Resource name="documentos" list={TempDocumentList} edit= {DocumentIndexar} />  
              <Resource name="catalogos" list={CatalogosList}/>
              <Resource name="estadoPassword" />
              <Resource name="rolesUsuario" />
              <Resource name="bastanteoSearch" list={DocumentAnalizer} />
              <Resource name="predefinida" list={BusquedaPredefinida} edit={PredefinidaCampos} />
              <Resource name="plantillas" list={ComponentPlantillas}/>
                {/*Recurso nuevo para la digitalización masiva*/}
              <Resource name='digitalizacionMasiva' list={DigitalizacionMasiva}/>
              <Resource name='configurationUser' list={ConfigurationUser} />
              <Resource name='plantillass' list={Plantillas} />

        </Admin>
    </BrowserRouter>
)};

export default App;