import * as React from 'react';
import Typography from '@mui/material/Typography';
import Checkout from '../Components/Checkout'

export function PaymentForm({ onSubmit , order}) {
  
  const handleToken = (event) => {
    onSubmit(event)
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Payment method
      </Typography>
      <Checkout onSubmit={ handleToken } checkout={order.checkout.id} />
    </React.Fragment>
  );
}