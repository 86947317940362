import * as React from 'react';
import { useState  } from 'react';
import {
    FormWithRedirect,
    TextInput ,
    useCreate,
    useDelete,
    useTranslate,
    SimpleForm,  
    Loading, 
    Error, 
    required, 
    Create,
    useRefresh,
    Query, 
    NumberInput ,
    SelectInput,
    SaveButton,
    BooleanInput,
    useDataProvider,
    ReferenceInput,
    AutocompleteInput,
    Toolbar,
    Button as Button2,
    useNotify
} from 'react-admin';
import Button from '@material-ui/core/Button';
import FolderIcon from '@material-ui/icons/Folder';
import CustomDatagrid from '../Components/CustomDatagrid';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withWidth from '@material-ui/core/withWidth';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import $ from 'jquery';
import swal from 'sweetalert';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import { useEffect } from 'react';

const useStyles = makeStyles({
    nodo : {
        padding: '6px',
        border: '1px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px'
    },
    nodoSeleccionado :  {
        padding: '6px',
        border: '1px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        backgroundColor: "lightgray"
    },
    botonNuevo : {
        marginLeft : "10px"
    }
});

function dibujaNodosHijos(nodo,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto){
    if(nodo.hijos != undefined){
        return (
            <div>
                {nodo.hijos.map(
                (item) => {
                    checknodoabierto(item);
                    return (
                    <div  style={{ marginLeft: '40px', marginTop : '-5px', width: 'max-content'}}>
                        <div style={{ float: 'left' }}>
                            <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                        </div>
                        <div className={ nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > <FolderIcon style={{ marginRight: '5px'}} />
                            { item.TextoNodo }
                            <Button title={ translate('traducciones.titulos.selectNodo')} onClick={() => addNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                <VisibilityIcon style={{ fontSize: '16px'}}  />
                            </Button>
                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                            <Button style={{ marginLeft: "0px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                <ExpandLessIcon />
                            </Button>
                            }
                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                            <Button style={{ marginLeft: "0px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                <ExpandMoreIcon />
                            </Button>
                            }
                        </div>
                        { !item.colapsado &&
                        <div>
                            { dibujaNodosHijos(item,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                        </div>
                        }
                    </div>
                )})
                }
            </div>
        )
    }
}

const IndicesList = ({ width,...props }) => {      
    const permisos = props.permissions ? props.permissions.Indices : null;    
    const checkPermiso = (permiso) => {
        if(permisos){
            return permisos[permiso];
        }
        return false;
    }
    const inTour = JSON.parse(localStorage.getItem('activeTour'))

    const classes = useStyles();
    const [nodoPadreElemento, setnodoPadreElemento] = useState(null);    
    const [loadinga, setLoadinga] = useState(false);
    const [openAdd, setopenAdd] = useState(false);
    const [openDelete, setopenDelete] = useState(false);
    const [indiceSeleccionado, setindiceSeleccionado] = useState(null);
    const [create] = useCreate('indices');
    const [deleteOne] = useDelete();
    const translate = useTranslate();
    const refresh = useRefresh();
    const [dataIndices, setDataIndices] = useState([]);
    const [indicePlantilla, setIndicePlantilla] = useState(false);
    const [openCampos,setOpenCampos] = useState(false);

    //Variables agregadas para el buscador de nodos
    const [nodoBuscado,setNodoBuscado] = useState(null);
    const [nodos,setNodos] = useState(null);
    const [openNodosEncontrados,setOpenNodosEncontrados] = useState (false)
    const [resultadoNodos,setResultadoNodos] = useState([])

    const [datanodosAbiertos, setDatanodosAbiertos] = useState([]);

    const notify = useNotify();

    const [openModalAddIndice, setOpenModalAddIndice] = useState(false);

    const [randomUpdate, setRandomUpdate] = useState(null);

    const dataProvider = useDataProvider();

    let introNodos = introJs()
    const stepsTo = {
        welcome: [
            {
                intro: translate('traducciones.introIndiceList.intro1'),
                title: translate('traducciones.introIndiceList.title1'),
                position: 'bottom',
                scrollTo: 'off'
            },
            {
                intro: translate('traducciones.introIndiceList.intro2'),
                title: translate('traducciones.introIndiceList.title2'),
                element: '#nodo',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introIndiceList.intro3'),
                title: translate('traducciones.introIndiceList.title3'),
                element: '#more',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introIndiceList.intro4'),
                title: translate('traducciones.introIndiceList.title4'),
                element: '#verCampos',
                position: 'bottom'
            },                      
        ],
        createCampo: [
            {
                intro: translate('traducciones.introCreateCampo.intro1'),
                title: translate('traducciones.introCreateCampo.title1'),
                scrollTo: 'off'
            },     
            {
                intro: translate('traducciones.introCreateCampo.intro2'),
                title: translate('traducciones.introCreateCampo.title2'),
                element: '#createNombre',
                position: 'bottom',
                scrollTo: 'off'
            },
            {
                intro: translate('traducciones.introCreateCampo.intro3'),
                title: translate('traducciones.introCreateCampo.title3'),
                position: 'bottom',
                scrollTo: 'off',
                element: '#createTipo',
            },
            {
                intro: translate('traducciones.introCreateCampo.intro4'),
                title: translate('traducciones.introCreateCampo.title4'),
                element: '#createMascara',
                position: 'bottom',
                scrollTo: 'off'
            },
            {
                intro: translate('traducciones.introCreateCampo.intro5'),
                title: translate('traducciones.introCreateCampo.title5'),
                element: '#createMin',
                position: 'bottom',
                scrollTo: 'off'
            },
            {
                intro: translate('traducciones.introCreateCampo.intro6'),
                title: translate('traducciones.introCreateCampo.title6'),
                position: 'bottom',
                scrollTo: 'off',
                element: '#createMax',
            },
            {
                intro: translate('traducciones.introCreateCampo.intro7'),
                title: translate('traducciones.introCreateCampo.title7'),
                element: '#createLong',
                position: 'bottom',
                scrollTo: 'off'
            },
            {
                intro: translate('traducciones.introCreateCampo.intro8'),
                title: translate('traducciones.introCreateCampo.title8'),
                element: '#createReq',
                position: 'bottom',
                scrollTo: 'off'
            },
            {
                intro: translate('traducciones.introCreateCampo.intro9'),
                title: translate('traducciones.introCreateCampo.title9'),
                position: 'bottom',
                scrollTo: 'off',
                element: '#createMul',
            },
            {
                intro: translate('traducciones.introCreateCampo.intro10'),
                title: translate('traducciones.introCreateCampo.title10'),
                element: '#createUnico',
                position: 'bottom',
                scrollTo: 'off'
            },
            {
                intro: translate('traducciones.introCreateCampo.intro11'),
                title: translate('traducciones.introCreateCampo.title11'),
                element: '#createVer',
                position: 'bottom',
                scrollTo: 'off'
            },
            {
                intro: translate('traducciones.introCreateCampo.intro12'),
                title: translate('traducciones.introCreateCampo.title12'),
                position: 'bottom',
                scrollTo: 'off',
                element: '#createRep',
            },
            {
                intro: translate('traducciones.introCreateCampo.intro13'),
                title: translate('traducciones.introCreateCampo.title13'),
                element: '#createNot',
                position: 'bottom',
                scrollTo: 'off'
            }, 
            {
                intro: translate('traducciones.introCreateCampo.intro14'),
                title: translate('traducciones.introCreateCampo.title14'),
                element: '#createNew',
                position: 'bottom',
                scrollTo: 'off'
            },                 
        ],        
        viewCampos: [
            {
                intro: translate('traducciones.introViewCampos.intro1'),
                title: translate('traducciones.introViewCampos.title1'),
                scrollTo: 'off',
            },     
            {
                intro: translate('traducciones.introViewCampos.intro2'),
                title: translate('traducciones.introViewCampos.title2'),
                element: '#addCampo',
                position: 'bottom',
                scrollTo: 'off'
            },
            {
                intro: translate('traducciones.introViewCampos.intro3'),
                title: translate('traducciones.introViewCampos.title3'),
                position: 'bottom',
                scrollTo: 'off',
                element: '#btnSaveCambios',
            },               
        ],
        selectCampo: [
            {
                intro: translate('traducciones.introSelectCampo.intro1'),
                title: translate('traducciones.introSelectCampo.title1'),
                position: 'bottom',
                scrollTo: 'off',
                element: '#toolCampos'
            },
            {
                intro: translate('traducciones.introSelectCampo.intro2'),
                title: translate('traducciones.introSelectCampo.title2'),
                scrollTo: 'off',
                element: '#campoPlantilla'
            },     
            {
                intro: translate('traducciones.introSelectCampo.intro3'),
                title: translate('traducciones.introSelectCampo.title3'),
                element: '#campoIndividual',
                position: 'bottom',
                scrollTo: 'off'
            },               
        ]     
    }

    const getTour = (stepsGuide, isDisable, time, compInit) =>{
        if(inTour.estructura){
            setTimeout(()=>{
                compInit != '' ? introNodos = introJs(compInit) : introNodos = introJs()
                introNodos.setOptions({
                    disableInteraction: isDisable,
                    doneLabel: translate('traducciones.introBotones.doneLabel'),
                    nextLabel: translate('traducciones.introBotones.nextLabel'),
                    prevLabel: translate('traducciones.introBotones.prevLabel'),
                    steps: stepsGuide             
                  });
        
                introNodos.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                    showHelp()
                }).onexit(()=>{
                    showHelp()
                }).start();
            },time)
        }
    }

    let introHelp;
    introHelp = introJs('#root');
    introHelp.setOptions({
        disableInteraction: false,
        doneLabel: translate('traducciones.introBotones.doneLabel'),
        nextLabel: translate('traducciones.introBotones.nextLabel'),
        prevLabel: translate('traducciones.introBotones.prevLabel'),
        tooltipPosition:'top',
        steps: [
                {
                intro: translate('traducciones.introHelp.intro1'),
                title: translate('traducciones.introHelp.title1'),
                element: '#help'
                },
        ]                
    });

    useEffect(() => {
        getTour(stepsTo.welcome,true,1000)
    }, []);

    const showHelp = () =>{
        const offPermisos = {
            querys: false,
            carga: false,
            admin: false,
            estructura: false,
        }
        if(document.cookie == 'introjs-dontShowAgain=true'){
            document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
                setTimeout(()=>{
                    introHelp.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).onexit(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).start();                         
                },500)
        }
    }

    function crearCookie(nombre, valor, diasParaExpirar) {
    var fechaExpiracion = new Date();
    fechaExpiracion.setDate(fechaExpiracion.getDate() + diasParaExpirar);
    var cookie = nombre + '=' + valor + '; expires=' + fechaExpiracion.toUTCString() + '; path=/';
    document.cookie = cookie;
    }

    const addNode = () => {
        setOpenModalAddIndice(true);
        getTour(stepsTo.selectCampo, false, 800, '#addNewIndice')
    }

    const individual = () => {
        introNodos.exit();
        setOpenModalAddIndice(false);
        setopenAdd(true);
        getTour(stepsTo.createCampo, false, 800, '#dialogFormCampo')
    }

    const plantilla = () => {
        introNodos.exit();
        setIndicePlantilla(true);
    }

    const viewNode = (nodoPadre) => {
        dataProvider.getList('indices', { IdNode: nodoPadre != null ? nodoPadre.RutaNodo : "/"})
            .then(({ data }) => {
                setnodoPadreElemento(nodoPadre);
                setDataIndices(data);
                setOpenCampos(true);
                getTour(stepsTo.viewCampos,true,1000,'.MuiDialog-container')
            })
            .catch(error => {
        });   
    }

    const convertKeyToIndice = (data) => {
        let ids = [];
        const dataKey = [];      
        if (data) {
          ids = data.map((elemento) => {
            dataKey[elemento.id] = elemento;
            return elemento.id;
          });
          return ({ dataKey, ids });
        }
        return ({});
    };

    const handleCloseClick = () => {
        setIndicePlantilla(false);
        setopenAdd(false);
        setopenDelete(false);
        setOpenModalAddIndice(false);
        setOpenCampos(false);
        setOpenNodosEncontrados(false)
    }

    if(loadinga){
        return <Loading />
    }

    const deleteRecord = (record) => {
        setindiceSeleccionado(record);
        setopenDelete(true);
    }

    const changeTipo = (elem) => {
        var div =  $("[id='" + elem.target.name +"_ID']");
        if(elem.target.value == "7"){                            
            div.show();
        }else{            
            div.hide();
        }
    }

    const changeTipoCat = (elem) => {
        var div =  $("[id='catalogo_add']");
        var div2 =  $("[id='catalogo_hide']");
        if(elem.target.value == "7"){  
            div.show();
            div2.hide();
        }else{
            div.hide();
            div2.show();
        }
    }

    const handleSubmitDelete = async values => {
        setLoadinga(true);        
        deleteOne('indices', indiceSeleccionado.id, indiceSeleccionado,{
            onSuccess: ({ data }) => {
                setDataIndices(data.data);
                setopenDelete(false);
                setLoadinga(false);
                refresh();
            },
            onFailure: ({ error }) => {
            }
        });
        setLoadinga(false);  
        
    };


    const handlePlantilla = async values => {
        if(values== undefined || values.plantilla == undefined){
            //return { returnPromise: true }
        }
        setLoadinga(true);
        dataProvider.getOne('tagsTemplate', { id: values.plantilla })
            .then(({ data }) => {
                var datos = data.data.templateFields;
                var keywords = [];
                datos.forEach(function (record, index) { 
                    keywords.push({
                        "IdIndice": 1,
                        "IdNodo": nodoPadreElemento.RutaNodo,
                        "NombreIndice": datos[index],
                        "Tipo": 1,
                        "Formato": "",
                        "Mascara": "",
                        "Minimo": 0,
                        "Maximo": 999,
                        "IdTabla": 0,
                        "Longitud": 999,
                        "Requerido": false,
                        "Multiple": false,
                        "Unico": false,
                        "Verificar": false,
                        "Repetido": false,
                        "IdDiccionario": 1,
                        "IdModel" : values.plantilla,
                        "Biometrico": 1
                    });
                });


                var repetidas = false;
                keywords.forEach(function (nuevas, index) { 
                    dataIndices.forEach(function (viejas, index) { 
                            if(nuevas.NombreIndice == viejas.NombreIndice){
                                repetidas = true;
                            }
                    });
                });
                if(repetidas){
                    setLoadinga(false);
                    notify("traducciones.error.indicePlantillaRepetido", 'error');
                    return { returnPromise: true }
                }

                if(keywords.length > 0){
                    create(
                        "indices",
                        { 
                            "Keywords": keywords
                        },{
                            onSuccess: ({ data }) => {
                                setLoadinga(false);
                                setDataIndices(data.data);
                                handleCloseClick();
                            },
                            onFailure: ({ error }) => {
                                setLoadinga(false);
                            }
                        }
                    );
                }
            })
            .catch(error => {
        });  
        return { returnPromise: true }
    }

    const handleSubmitUpdate = async values => {
        var keywords = [];
        values.NombreIndice.forEach(function (record, index) { 
            keywords.push({
                "IdIndice": values.IdIndice[index],
                "IdNodo": nodoPadreElemento.RutaNodo,
                "NombreIndice": values.NombreIndice[index],
                "Tipo": values.Tipo[index],
                "Formato": "",
                "Mascara": values.Mascara ? values.Mascara[index] : "",
                "Minimo": values.Minimo[index],
                "Maximo": values.Maximo[index],
                "IdTabla": parseInt(values.Tipo[index]) == 7 ? values.Catalogo[index] : 0,
                "Longitud": values.Longitud[index],
                "Requerido": values.Requerido[index],
                "Multiple": values.Multiple[index],
                "Unico": values.Unico[index],
                "Verificar": values.Verificar[index],
                "Repetido": values.Repetido[index],
                "IdDiccionario": 1,//values.IdDiccionario[index],
                "Biometrico": 1,//values.Biometrico[index]
                "Notificar": values.Notificar[index]
            });
        });
        setLoadinga(true);
       
        create(
            "indices",
            { 
                "Keywords": keywords,
                "function" : "update"
            },
            {
                onSuccess: ({ data }) => {
                    setDataIndices(data.data);
                    setopenAdd(false);
                    setLoadinga(false);
                    setOpenCampos(false);
                    swal({
                        icon: "success",
                        text: translate('traducciones.notificaciones.cambiosOk'),
                        timer: 1000,
                        buttons:false
                      });
                    //refresh();
                },
                onFailure: ({ error }) => {
                    //Esperando msj del back para mandar el mensaje del error especifico
                    swal({
                        icon:'info',
                        text: translate('traducciones.notificaciones.cambiosError')
                    })
                    setLoadinga(false);
                    //notify(error.message, 'error');
                }
            }
        );
    }

    const colapsar = (nodo) => {
        setRandomUpdate(Math.random());
        if(nodo.colapsado){
            datanodosAbiertos.push(nodo.RutaNodo)
            setDatanodosAbiertos(datanodosAbiertos);
        }else{
            setDatanodosAbiertos(datanodosAbiertos.filter(function(nodoguardado) { 
                return nodoguardado !== nodo.RutaNodo;
            }));
        }
        nodo.colapsado = !nodo.colapsado;
    }

    const checknodoabierto = (nodo) => {
        if(datanodosAbiertos.includes(nodo.RutaNodo)){
            nodo.colapsado = false;
        }
    }

    const handleSubmit = async values => {
        var repetidas = false;
        dataIndices.forEach(function (viejas, index) { 
                if(values.indiceName == viejas.NombreIndice){
                    repetidas = true;
                }
        });
        if(repetidas){
            setLoadinga(false);
            notify("traducciones.error.indiceRepetido", 'error');
            return { returnPromise: true }
        }


        create(
            "indices",
            { 
                "Keywords": [
                    {
                        "IdIndice": 1,
                        "IdNodo": nodoPadreElemento.RutaNodo,
                        "NombreIndice": values.indiceName,
                        "Tipo": values.Tipo,
                        "Formato": "",
                        "Mascara": values.Mascara,
                        "Minimo": values.Minimo,
                        "Maximo": values.Maximo,
                        "IdTabla": parseInt(values.Tipo) == 7 ? values.IdTabla : 0,
                        "Longitud": values.Longitud,
                        "Requerido": values.Requerido ? true : false,
                        "Multiple": values.Multiple ? true :false,
                        "Unico": values.Unico ? true :false,
                        "Verificar": values.Verificar ? true :false,
                        "Repetido": values.Repetido ? true :false,
                        "IdDiccionario": 1,
                        "Biometrico": 1,
                        "Notificar": values.Notificar
                    },
                ]
            },
            {
                onSuccess: ({ data }) => {
                    setDataIndices(data.data);
                    setopenAdd(false);
                    setLoadinga(false);
                    swal({
                        icon:'success',
                        text: translate('traducciones.notificaciones.cambiosOk')
                    })
                    //refresh();
                },
                onFailure: ({ error }) => {
                    //Esperando msj del back para mandar el mensaje del error especifico
                    swal({
                        icon:'info',
                        text: translate('traducciones.notificaciones.cambiosError')
                    })
                    setLoadinga(false);
                    //notify(error.message, 'error');
                }
            }
        );
    };

    const dataGridValues2 = {
        ...convertKeyToIndice(dataIndices),
        total: dataIndices.lenght,
        records : dataIndices
    };

    const buscarNodo = (nodo,e) => {
        e.preventDefault();
        if(nodo==null || nodo.trim()==''){
            swal({
                icon: "info",
                text: translate('traducciones.modal.sinNombre'),
                timer: 1000,
                buttons:false
              });
        }else{
        let nodosEncontrados=[];
        nodos.map((nPadre) => {
          if (nPadre.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
            nodosEncontrados.push({
                'ruta':"/"+nPadre.TextoNodo,
                'n1':nPadre,
                'lvl':1
            })
          } else if (nPadre.hijos.length > 0) {
            nPadre.hijos.map((n2) => {
              if (n2.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                nodosEncontrados.push({
                    'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo,
                    'n1':nPadre,
                    'n2':n2,
                    'lvl':2
                })
              } else if (n2.hijos.length > 0) {
                n2.hijos.map((n3) => {
                  if (n3.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                    nodosEncontrados.push({
                        'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo,
                        'n1':nPadre,
                        'n2':n2,
                        'n3':n3,
                        'lvl':3
                    })
                  } else if (n3.hijos.length > 0) {
                    n3.hijos.map((n4) => {
                      if (n4.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                        nodosEncontrados.push({
                            'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo + "/" + n4.TextoNodo,
                            'n1':nPadre,
                            'n2':n2,
                            'n3':n3,
                            'n4':n4,
                            'lvl':4
                        })
                      } else if (n4.hijos.length > 0) {
                        n4.hijos.map((n5) => {
                          if (n5.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                            nodosEncontrados.push({
                                'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo + "/" + n4.TextoNodo + "/" + n5.TextoNodo,
                                'n1':nPadre,
                                'n2':n2,
                                'n3':n3,
                                'n4':n4,
                                'n5':n5,
                                'lvl':5
                            })
                          }else if(n5.hijos.length > 0){
                            n5.hijos.map(n6=>{
                                if (n6.TextoNodo.toLowerCase() === nodo.toLowerCase().trim()) {
                                    nodosEncontrados.push({
                                        'ruta':"/"+nPadre.TextoNodo + "/" + n2.TextoNodo + "/" + n3.TextoNodo + "/" + n4.TextoNodo + "/" + n5.TextoNodo + "/" + n6.TextoNodo,
                                        'n1':nPadre,
                                        'n2':n2,
                                        'n3':n3,
                                        'n4':n4,
                                        'n5':n5,
                                        'n6':n6,
                                        'lvl':6
                                    })
                                }
                            })
                          }
                        });
                      }else{
                        //Si no hay hijos en nivel 5
                      }
                    });
                  } else {
                    //Si no hay hijos en nivel 4
                  }
                });
              } else {
                //Si no hay hijos en nivel 3
              }
            });
          } else {
            //Si no hay hijos en nivel 2
          }
        });
        if(nodosEncontrados.length==1){
            desplegarNodos(nodosEncontrados[0])
        }else{
            setResultadoNodos(nodosEncontrados);
            setOpenNodosEncontrados(true)
        }
    }
    };

    const desplegarNodos = (nodo) =>{
        switch(nodo.lvl){
            case 1: 
                viewNode(nodo.n1)
                setOpenNodosEncontrados(false)
            break;
            case 2:
                colapsar(nodo.n1);
                viewNode(nodo.n2)
                setOpenNodosEncontrados(false) 
            break;
            case 3:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                viewNode(nodo.n3)
                setOpenNodosEncontrados(false) 
            break;
            case 4:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                colapsar(nodo.n3);
                viewNode(nodo.n4)
                setOpenNodosEncontrados(false) 
            break;
            case 5:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                colapsar(nodo.n3);
                colapsar(nodo.n4);
                viewNode(nodo.n5)
                setOpenNodosEncontrados(false)
            break;
            case 6:
                colapsar(nodo.n1);
                colapsar(nodo.n2);
                colapsar(nodo.n3);
                colapsar(nodo.n4);
                colapsar(nodo.n5);
                viewNode(nodo.n6)
                setOpenNodosEncontrados(false)
            break;
            default: ;
        }
    }

    return (
        <Create {...props} title={ translate('traducciones.titulos.gestionDeIndices')} >
            <SimpleForm {...props} toolbar={ null }  >
            <div fullWidth>
                
                <div style={{ padding: '10px'}}>      
                     <div id='search-box'>
                        <div id='search-form' >
                        <input id='search-text' placeholder={translate('traducciones.campos.buscador')} type='text' onChange={(e) => setNodoBuscado(e.target.value)}/>
                        <button id='search-button' onClick={(e)=>buscarNodo(nodoBuscado,e)}><span><ManageSearchIcon style={{fontSize:'25px'}}/></span></button>
                        </div>
                    </div>     
                    <Query type="getList" resource="nodos" payload={ nodoPadreElemento }>
                        {({ data, loading, error }) => {
                            setNodos(data)
                            if (loading) { return <Loading />; }
                            if (error) { return <Error />; }   
                           
                            const dataGridValues2 = {
                                ...convertKeyToIndice(dataIndices),
                                total: dataIndices.lenght,
                                records : dataIndices
                            };
                            return (                                
                                <div  className="row">
                                    <div  className="col-md-6 col-md-custom"  style={{ overflowX: "auto", padding: "10px"}}>
                                    {   data.map(
                                        (item) => {
                                            checknodoabierto(item);
                                            return (
                                            <div>
                                                <div id='nodo' className= { nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > 
                                                    <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                                    { item.TextoNodo } 
                                                    <Button id='verCampos' title={ translate('traducciones.titulos.selectNodo')}  onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                                        <VisibilityIcon style={{ fontSize: '16px'}}/>
                                                    </Button>
                                                    { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                                    <Button style={{ marginLeft: "0px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                        <ExpandLessIcon />
                                                    </Button>
                                                    }
                                                    { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                                    <Button id='more' style={{ marginLeft: "0px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                        <ExpandMoreIcon />
                                                    </Button>
                                                    }
                                                </div>
                                                { !item.colapsado &&
                                                <div style={{ marginLeft: '-20px'}}>
                                                    { dibujaNodosHijos(item,classes,viewNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                                                </div>
                                                }
                                            </div>
                                        )})
                                    }
                                    </div>
                                </div>
                            );
                        }}
                    </Query>
                </div> 
            </div>     
            <Dialog
                fullWidth
                id='dialogFormCampo'
                open={openAdd}>
                <DialogTitle>{ translate('traducciones.titulos.nuevoIndice')}</DialogTitle>
                <FormWithRedirect
                resource="indices"
                save={handleSubmit}
                render={({
                    handleSubmitWithRedirect,
                    pristine,
                    saving
                }) => (
                    <>
                    <DialogContent>
                        <div className='row'>
                        <div className='col-md-6'>
                        <TextInput id='createNombre' style={{ width: '100%'}} source="indiceName" label="traducciones.campos.indiceNombre" validate={required()} />
                        </div>
                        <div className='col-md-3'>
                        <SelectInput id='createTipo' style={{ width: '100%'}} onChange={ changeTipoCat } validate={required()} source="Tipo" label="traducciones.campos.tipo" choices={[
                            { id: '1', name: translate('Alfa Numerico') },
                            { id: '2', name: translate('Numérico') },
                            { id: '3', name: translate('Alfabético') },
                            { id: '4', name: translate('Fecha') },
                            { id: '5', name: translate('Hora') },
                            { id: '6', name: translate('Email Automático') },
                            { id: '7', name: translate('Catálogo') },
                        ]} />   
                        </div>
                        <div className='col-md-3' id="catalogo_add" style={{display: 'none'}} >
                        <ReferenceInput style={{ width: '100%'}} label="traducciones.campos.catalogo" source='IdTabla' reference="catalogos">
                          <SelectInput style={{ width: '100%'}} optionText="NombreTabla" optionValue="IdTabla" />  
                        </ReferenceInput>
                        </div>
                        <div className='col-md-3' id="catalogo_hide">                                              
                        <TextInput id='createMascara' style={{ width: '100%'}}  source="Mascara" label="traducciones.campos.mascara" defaultValue={""} />
                        </div>
                        <div className='col-md-4'>
                        <NumberInput id='createMin' style={{ width: '100%'}}  source="Minimo" label="traducciones.campos.minimo" defaultValue={0} />
                        </div>
                        <div className='col-md-4'>
                        <NumberInput id='createMax' style={{ width: '100%'}} source="Maximo" label="traducciones.campos.maximo" defaultValue={0} />
                        </div>
                        <div className='col-md-4'>
                        <NumberInput id='createLong' style={{ width: '100%'}}  source="Longitud" label="traducciones.campos.longitud" defaultValue={0} />
                        </div>
                        <div className='col-md-2' id='createReq'>
                        <BooleanInput label= "traducciones.campos.requerido" source='Requerido' />
                        </div>
                        <div className='col-md-2' id='createMul'>
                        <BooleanInput label= "traducciones.campos.multiple" source='Multiple' />
                        </div>
                        <div className='col-md-2' id='createUnico'>
                        <BooleanInput label= "traducciones.campos.unico" source='Unico' />
                        </div>
                        <div className='col-md-2' id='createVer'>
                        <BooleanInput label= "traducciones.campos.verificar" source='Verificar' />
                        </div>
                        <div className='col-md-2' id='createRep'>
                        <BooleanInput label= "traducciones.campos.repetido" source='Repetido' />  
                        </div>   
                        <div className='col-md-2' id='createNot'>
                        <BooleanInput label= "traducciones.campos.notificar" source='Notificar' />  
                        </div>   
                        </div>      

                    </DialogContent>
                    <DialogActions>
                        <SaveButton
                            id='createNew'
                            resource="indices"
                            label="ra.action.add"
                            handleSubmitWithRedirect={
                                handleSubmitWithRedirect
                            }
                            pristine={pristine}
                            saving={saving}
                        />
                        <Button onClick={(e)=>{setopenAdd(false); setOpenModalAddIndice(true);}} >
                            { translate('ra.action.cancel')}
                        </Button>
                    </DialogActions>
                    </>
                )}
                />
                </Dialog>   
            <Dialog
                    fullWidth
                    open={openDelete}
                    >
                    <DialogTitle>{ translate('traducciones.modal.eliminarIndice')}</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmitDelete}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            { translate('traducciones.modal.eliminarIndiceConfirm')}
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.confirm"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                                icon = { <DeleteIcon /> }
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>     


                <Dialog 
                    fullWidth
                    id='addNewIndice'
                    open={openModalAddIndice}>
                    <DialogTitle>{ translate('traducciones.titulos.agregarIndice')}</DialogTitle>
                    <DialogContent>
                        <SimpleForm save={handlePlantilla} toolbar = { 
                            <Toolbar id='toolCampos'> 
                                { indicePlantilla ? (
                                <SaveButton style={{ marginRight : "10px !important "}} label ="traducciones.titulos.plantillaSeleccionada" /> 
                                ) : null }
                                { !indicePlantilla ? (
                                <Button2 id='campoPlantilla' label="traducciones.botones.indicePlantilla" className={ classes.botonNuevo } onClick={() => plantilla() } /> 
                                ) :null }
                                { !indicePlantilla ? (
                                <Button2 id='campoIndividual' label="traducciones.botones.indiceIndividual" className={ classes.botonNuevo } onClick={() => individual() } />
                                ) : null }
                            </Toolbar>}>
                            { indicePlantilla ? (
                            <ReferenceInput label="traducciones.campos.plantillas" source="plantilla" reference="catalogos">
                                <AutocompleteInput optionText="ModelName" optionValue="IdModel" validate={required()} />
                            </ReferenceInput> 
                            ) : null }
                        </SimpleForm>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{setOpenModalAddIndice(!openModalAddIndice); setIndicePlantilla(false)}} >
                            { translate('ra.action.close')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog id='dialogCampos' fullWidth open={openCampos}>
                    <DialogTitle>{nodoPadreElemento ? nodoPadreElemento.TextoNodo : ''} - Campos</DialogTitle>
                        <FormWithRedirect
                            resource="indices"
                            save={''}
                            render={({
                                handleSubmitWithRedirect,
                                pristine,
                                saving
                            }) => (
                                <>
                                <DialogContent>
                                    <div>
                                    { checkPermiso('AgregarIndices') && <Button id='addCampo' className="MuiButton-containedPrimary" title={ translate('traducciones.titulos.addNodosPrincipal')} onClick={() => addNode()} style={{ marginLeft: "18px", padding: "8px"}}><AddIcon/> {translate("traducciones.titulos.nuevoIndice")}</Button> }
                                    </div>
                                        <div id='listaCampos' className="customDataGrid" style={{display:'grid'}}>
                                            <SimpleForm save={ handleSubmitUpdate } toolbar= { checkPermiso('EditarIndices') ? <SaveButton id='btnSaveCambios' style={{ marginTop: "20px", marginLeft: "15px"}} /> : false } >                                            
                                                <CustomDatagrid dataGridValues={dataGridValues2}>
                                                    <TextInput id='nombreCampo' tdstyle={{ minWidth: '25vh', verticalAlign: "initial"}} disabled = { !checkPermiso('EditarIndices') }  source="NombreIndice"  label="traducciones.campos.indice" />
                                                    <SelectInput id='tipoCampo' tdstyle={{ minWidth: '20vh', verticalAlign: "initial"}} onChange={ changeTipo } source="Tipo" disabled = { !checkPermiso('EditarIndices') } label="traducciones.campos.tipo" choices={[
                                                        { id: '1', name: translate('Alfa Numerico') },
                                                        { id: '2', name: translate('Numérico') },
                                                        { id: '3', name: translate('Alfabético') },
                                                        { id: '4', name: translate('Fecha') },
                                                        { id: '5', name: translate('Hora') },
                                                        { id: '6', name: translate('Email Automático') },
                                                        { id: '7', name: translate('Catálogo') },
                                                    ]} />                                                   
                                                    <TextInput tdstyle={{ minWidth: '16vh', verticalAlign: "initial"}} disabled = { !checkPermiso('EditarIndices') } source="Mascara" label="traducciones.campos.mascara" />
                                                    <NumberInput tdstyle={{ minWidth: '11vh', verticalAlign: "initial"}} disabled = { !checkPermiso('EditarIndices') }  source="Minimo" label="traducciones.campos.minimo" />
                                                    <NumberInput tdstyle={{ minWidth: '11vh', verticalAlign: "initial"}} disabled = { !checkPermiso('EditarIndices') } source="Maximo" label="traducciones.campos.maximo" />
                                                    <NumberInput tdstyle={{ minWidth: '11vh', verticalAlign: "initial"}} disabled = { !checkPermiso('EditarIndices') } source="Longitud" label="traducciones.campos.longitud" />
                                                    <BooleanInput  tdstyle={{ verticalAlign: "initial"}} source="Requerido" disabled = { !checkPermiso('EditarIndices') } label= "traducciones.campos.requerido" />
                                                    <BooleanInput tdstyle={{ verticalAlign: "initial"}}  source="Multiple" disabled = { !checkPermiso('EditarIndices') } label= "traducciones.campos.multiple" />
                                                    <BooleanInput  tdstyle={{ verticalAlign: "initial"}} source="Unico" disabled = { !checkPermiso('EditarIndices') }label= "traducciones.campos.unico" />
                                                    <BooleanInput tdstyle={{ verticalAlign: "initial"}}  source="Verificar" disabled = { !checkPermiso('EditarIndices') } label= "traducciones.campos.verificar" />
                                                    <BooleanInput tdstyle={{ verticalAlign: "initial"}} source="Repetido" disabled = { !checkPermiso('EditarIndices') } label= "traducciones.campos.repetido" />
                                                    <BooleanInput tdstyle={{ verticalAlign: "initial"}} source="Notificar" disabled = { !checkPermiso('EditarIndices') } label= "traducciones.campos.notificar" />
                                                    {/* <TextField  source="IdDiccionario" label= "traducciones.campos.diccionario" /> */ }
                                                    { /* <TextField  source="Biometrico" label= "traducciones.campos.biometrico" /> */ }
                                                    { checkPermiso('EliminarIndices') && <Button id='deleteCamp' tdstyle={{ verticalAlign: "initial"}} action= { deleteRecord } icon={ <DeleteIcon /> } /> }
                                                    <TextInput type="hidden" source="IdIndice"  label="" />
                                                </CustomDatagrid>
                                            </SimpleForm>
                                        </div>                                                                    
                                </DialogContent>
                                <DialogActions>
                                    <SaveButton
                                        label="ra.action.add"
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        hidden
                                    />
                                    <Button onClick={handleCloseClick} >
                                        { translate('ra.action.close')}
                                    </Button>
                                </DialogActions>
                                </>
                            )}
                        />
                </Dialog>
                <Dialog fullWidth open={openNodosEncontrados}>
                    <DialogTitle>{ translate('traducciones.titulos.resultadoNodos')}</DialogTitle>
                        <FormWithRedirect
                            resource="catalogos"
                            save={buscarNodo}
                            render={({
                                handleSubmitWithRedirect,
                                pristine,
                                saving
                            }) => (
                                <>
                                <DialogContent>
                                <div  className="row">
                                    <div  className="col-md-12 col-md-custom"  style={{ overflowX: "auto", padding: "10px"}}>
                                        <span style={{fontSize:'15px'}} hidden={ resultadoNodos.length > 0 ? false : true }>{translate('traducciones.modal.seleccionNodo')}: </span><br/><br/>
                                    {resultadoNodos.length>0 ? resultadoNodos.map(item=>{
                                        return(
                                            <div>
                                               <center><input className='resultNodos' type='text' value={item.ruta} onClick={()=>desplegarNodos(item)} readOnly/></center>     
                                            </div>
                                        )
                                    }): 
                                        <div>
                                            <span style={{marginLeft:'42%',fontSize:'2vh'}}><b>{translate('traducciones.modal.noResults')}</b></span>    
                                        </div>
                                    }    
                                    </div>
                                </div>
                                </DialogContent>
                                <DialogActions>
                                    <SaveButton
                                        label="ra.action.add"
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        hidden
                                    />
                                    <Button onClick={handleCloseClick} >
                                        { translate('ra.action.cancel')}
                                    </Button>
                                </DialogActions>
                                </>
                            )}
                        />
                </Dialog>     

            </SimpleForm>
        </Create>
)};

export default withWidth()(IndicesList);
