import React from 'react';
import {useState} from 'react';
import {
    Datagrid,
    List,
    TextField,
    TopToolbar,
    CreateButton,
    DeleteButton,
    EditButton,
    useRefresh,
    useTranslate,
    Pagination,
    TextInput,
    FormWithRedirect,
    SaveButton,
    required,
    useDataProvider,
    ArrayInput,
    SimpleFormIterator,
    SearchInput,
    Filter,
    useListContext
    } from 'react-admin';
import withWidth from '@material-ui/core/withWidth';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';
import MobileCatalogosList from './Responsive/MobileCatalogoList';
import PostFilter from '../Components/PostFilter'
import '../Libs/ExternalViewer/Resources/Styles/predefinidasStyle.css'
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import caja from '../Libs/ExternalViewer/Resources/Images/caja-vacia.png'

const CatalogosList = ({ width,...props }) => {
    const permisos = props.permissions ? props.permissions.Catalogos : null;    
    const checkPermiso = (permiso) => {
        if(permisos){
            return permisos[permiso];
        }
        return false;
    }

    const CatalogosActions = ({ 
        basePath,
        checkPermiso
        }) => {   
        return (
        <TopToolbar>
            { checkPermiso('AgregarCatalogos') && <CreateButton id='createCatalogo' onClick={handleOpenClick} basePath={basePath} label={translate('traducciones.botones.agregarCatalogo')}/> }
        </TopToolbar>
    )};

    const [openAdd,setOpenAdd] = useState (false);
    const [openEdit,setOpenEdit] = useState (false);
    const [dataCatalogo,setDataCatalogo] = useState({});
    const dataProvider = useDataProvider();
    const inTour = JSON.parse(localStorage.getItem('activeTour'))
    let introNodos

    const handleCloseClick = (e) =>{
        e.preventDefault();
        setOpenAdd(false)
        setOpenEdit(false)
    }

    const handleOpenClick = (e) =>{
        e.preventDefault();
        setOpenAdd(true)
        getTour(stepsToIntro.create, false, 800, '#dialogCreate')
    }

    const refresh = useRefresh();
    const ListPagination = props => <Pagination rowsPerPageOptions={[5,10, 25, 50, 100]} {...props} />;
    const translate = useTranslate();

    const handleSubmit = (e) =>{       
        const nuevoCatalogo= {
            IdTabla: 1,
            NombreTabla: e.NombreTabla,
            ValorTabla: e.catalogos !== undefined ? e.catalogos : [] 
        }

        if(nuevoCatalogo.ValorTabla.length>0){
            dataProvider.create('catalogos', { data: nuevoCatalogo })
                .then(({ data }) => {
                    setOpenAdd(false)
                    refresh();
                    swal({
                        icon: "success",
                        text: translate('traducciones.notificaciones.catalogosOk'),
                      });
                })
                .catch(error => {
                    swal({
                        icon: "error",
                        text: error,
                      });
                });
        }else{
            swal({
                icon: "info",
                text: translate('traducciones.notificaciones.catalogosInfo'),
              });
        }
    }

    const handleEditCatalogo = (values) =>{
      const catalogoEditado= {
        IdTabla: values.IdTabla, 
        ValorTabla: values.ValoresTabla ? values.ValoresTabla : []
        }

    if(catalogoEditado.ValorTabla.length>0){    
    dataProvider.update('catalogos', {data:catalogoEditado})
      .then(({ data }) => {
          swal({
            icon: "success",
            text: translate('traducciones.notificaciones.catalogosEditOk'),
          });
          setOpenEdit(false)
          refresh();
      })
      .catch(error => {
          swal({
            icon: "error",
            text: error,
          });
      });
    }else{
        swal({
            icon: "info",
            text: translate('traducciones.notificaciones.catalogosInfo'),
          });
    }
    }

    const handleOpenEdit = (e) =>{
        e.preventDefault();
        const url = e.target.href;
        const lastSlashIndex = url.lastIndexOf('/');
        const trimmedUrl = url.substring(lastSlashIndex+1,url.length);
        
        dataProvider.getOne('catalogos', {id:trimmedUrl })
                .then(({ data }) => {
                    setDataCatalogo(data.data)
                    setOpenEdit(true)
                    getTour(stepsToIntro.edit, false, 500, '#dialogEdit')
                })
                .catch(error => {
                    swal({
                        icon: "error",
                        text: error,
                      });  
                });
    }

    let introHelp;
    introHelp = introJs('#root');
    introHelp.setOptions({
        disableInteraction: false,
        doneLabel: translate('traducciones.introBotones.doneLabel'),
        nextLabel: translate('traducciones.introBotones.nextLabel'),
        prevLabel: translate('traducciones.introBotones.prevLabel'),
        tooltipPosition:'top',
        steps: [
                {
                intro: translate('traducciones.introHelp.intro1'),
                title: translate('traducciones.introHelp.title1'),
                element: '#help'
                },
        ]                
    });

    const showHelp = () =>{
        const offPermisos = {
            querys: false,
            carga: false,
            admin: false,
            estructura: false,
        }
        if(document.cookie == 'introjs-dontShowAgain=true'){
            document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
                setTimeout(()=>{
                    introHelp.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).onexit(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).start();                         
                },500)
        }
    }

    function crearCookie(nombre, valor, diasParaExpirar) {
    var fechaExpiracion = new Date();
    fechaExpiracion.setDate(fechaExpiracion.getDate() + diasParaExpirar);
    var cookie = nombre + '=' + valor + '; expires=' + fechaExpiracion.toUTCString() + '; path=/';
    document.cookie = cookie;
    }

    const stepsToIntro = {
        conCatalogos: [
          {
            intro: translate('traducciones.introCatalogos.cintro1'),
            title: translate('traducciones.introCatalogos.ctitle1'),
            element: '.listaMovil',
            scrollTo: 'off'
          },
          {
            intro: translate('traducciones.introCatalogos.cintro2'),
            title: translate('traducciones.introCatalogos.ctitle2'),
            element: '.MuiTableRow-hover',
            scrollTo: 'off',
            position: 'bottom'
          },
          {
            intro: translate('traducciones.introCatalogos.cintro3'),
            title: translate('traducciones.introCatalogos.ctitle3'),
            element: '#editCatalogo',
            scrollTo: 'off',
            position: 'bottom'
          },
          {
            intro: translate('traducciones.introCatalogos.cintro4'),
            title: translate('traducciones.introCatalogos.ctitle4'),
            element: '#deleteCatalogo',
            scrollTo: 'off',
            position: 'bottom'
          },
          {
            intro: translate('traducciones.introCatalogos.cintro5'),
            title: translate('traducciones.introCatalogos.ctitle5'),
            element: '#createCatalogo',
            scrollTo: 'off',
            position: 'bottom',
            disableInteraction: false
          }
        ],
        sinCatalogos: [
          {
            intro: translate('traducciones.introCatalogos.sintro1'),
            title: translate('traducciones.introCatalogos.stitle1'),
            element: '.listaMovil',
            scrollTo: 'off'
          },
          {
            intro: translate('traducciones.introCatalogos.sintro2'),
            title: translate('traducciones.introCatalogos.stitle2'),
            element: '#createCatalogo',
            scrollTo: 'off',
            position: 'bottom'
          }
        ],
        create: [
          {
            intro: translate('traducciones.introCatalogoCreate.intro1'),
            title: translate('traducciones.introCatalogoCreate.title1'),
            element: '#NombreTabla',
            scrollTo: 'off'
          },
          {
            intro: translate('traducciones.introCatalogoCreate.intro2'),
            title: translate('traducciones.introCatalogoCreate.title2'),
            element: '#createValues',
            scrollTo: 'off',
            position: 'bottom',
            disableInteraction: true
          },
          {
            intro: translate('traducciones.introCatalogoCreate.intro3'),
            title: translate('traducciones.introCatalogoCreate.title3'),
            element: '#btnCreate',
            scrollTo: 'off',
            position: 'bottom'
          }
        ],
        edit: [
          {
            intro: translate('traducciones.introCatalogoEdit.intro1'),
            title: translate('traducciones.introCatalogoEdit.title1'),
            element: '#editValues',
            scrollTo: 'off',
            position: 'bottom'
          },
          {
            intro: translate('traducciones.introCatalogoEdit.intro2'),
            title: translate('traducciones.introCatalogoEdit.title2'),
            element: '.button-remove',
            scrollTo: 'off'
          },
          {
            intro: translate('traducciones.introCatalogoEdit.intro3'),
            title: translate('traducciones.introCatalogoEdit.title3'),
            element: '#btnEdit',
            scrollTo: 'off',
            position: 'bottom'
          }
        ]
    }
      
    const getTour = (stepsGuide, isDisable, time, compInit) =>{
        if(inTour.estructura){
            setTimeout(()=>{
                compInit != '' ? introNodos = introJs(compInit) : introNodos = introJs()
                introNodos.setOptions({
                    disableInteraction: isDisable,
                    doneLabel: translate('traducciones.introBotones.doneLabel'),
                    nextLabel: translate('traducciones.introBotones.nextLabel'),
                    prevLabel: translate('traducciones.introBotones.prevLabel'),
                    steps: stepsGuide             
                  });
        
                introNodos.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                    showHelp()
                }).onexit(()=>{
                    showHelp()
                }).start();
            },time)
        }
    }

    let introDocuments;
    useEffect(() => {
        if(inTour.estructura){
            setTimeout(()=>{
                introDocuments = introJs();
                const noCatalogos = document.querySelectorAll('#nombreCatalogo')
                introDocuments.setOptions({
                    disableInteraction: true,
                    doneLabel: translate('traducciones.introBotones.doneLabel'),
                    nextLabel: translate('traducciones.introBotones.nextLabel'),
                    prevLabel: translate('traducciones.introBotones.prevLabel'),
                    tooltipPosition:'top',
                    steps: noCatalogos.length > 0 ? stepsToIntro.conCatalogos : stepsToIntro.sinCatalogos          
                  });
        
                introDocuments.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                    showHelp()
                }).onexit(()=>{
                    showHelp()
                }).start();
            },1000)
        }
    }, [])

    // const PostFilter = (props) => (
    //     <Filter {...props}>
    //       <SearchInput source="q" alwaysOn style={{marginLeft:'2px',marginBottom:'10px'}}/>
    //     </Filter>
    //   );

    const Empty = () => {
        const { basePath, resource } = useListContext();
        return (
            <div style={{marginTop:'-10px'}}>
            <CatalogosActions checkPermiso = { checkPermiso } /><br/>
            <Box textAlign="center" m={1}>
                <img src={caja} style={{width:'160px', marginBottom:'18px'}}/>
                <Typography variant="h6" paragraph>
                    No hay catálogos disponibles 
                </Typography>
            </Box>
            </div>
        );
    };

    return (
    <div className='listaMovil'>    
        <List
            {...props}
            title={translate('traducciones.titulos.gestionCatalogos')}
            actions = { <CatalogosActions checkPermiso = { checkPermiso } /> }
            sort={{ field: 'NombreTabla', order: 'ASC' }}
            registro={ props.record} 
            bulkActionButtons={false}
            pagination={<ListPagination />}
            filters={<PostFilter/>}
            empty={<Empty/>}
            >
            { width !== "xs" ? (    
            <Datagrid isRowSelectable={record => record.id < 1} >
                <TextField id='nombreCatalogo' source="NombreTabla" label={translate('traducciones.titulos.catalogo')}/> 
                
                { checkPermiso('EditarCatalogos') && <EditButton id='editCatalogo' label="" className="actionButton" onClick={handleOpenEdit} /> }
                { checkPermiso('EliminarCatalogos') && <DeleteButton id='deleteCatalogo' label="" className="actionButton" undoable={false} confirmTitle={translate('traducciones.modal.eliminarCatalogo')} confirmContent={translate('traducciones.modal.eliminarCatalogoConfirm')}></DeleteButton> }
            </Datagrid>
            
            ) : (
                <MobileCatalogosList checkPermiso = { checkPermiso } edit={handleOpenEdit} />
            )
            }
        </List>

    
        {/*Dialogo para creación de catalogos nuevos*/}
        <Dialog id='dialogCreate' fullWidth open={openAdd}>
            <DialogTitle>{ translate('traducciones.titulos.nuevoCatalogo')}</DialogTitle>
                <FormWithRedirect
                    resource="catalogos"
                    save={handleSubmit}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            <TextInput style={{ width: '100%'}} source="NombreTabla" name='NombreTabla' id='NombreTabla' label="traducciones.campos.nombreCatalogo" validate={required()} />
                            <span>{translate('traducciones.campos.valores')}:</span>
                            <ArrayInput source='catalogos' label='' id='createValues'>
                                    <SimpleFormIterator disableReordering > 
                                            <TextInput label={translate('traducciones.campos.valor')} fullWidth={true} validate={required()} /> 
                                    </SimpleFormIterator>
                                </ArrayInput>
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                id='btnCreate'
                                label="ra.action.add"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
        </Dialog>

         {/*Dialog para la edición de un catalogo*/}
         <Dialog id='dialogEdit' fullWidth open={openEdit}>
                    <DialogTitle>{ translate('traducciones.titulos.editarCatalogo')}</DialogTitle>
                        <FormWithRedirect
                            resource="catalogos"
                            save={handleEditCatalogo}
                            render={({
                                handleSubmitWithRedirect,
                                pristine,
                                saving
                            }) => (
                                <>
                                <DialogContent>
                                    <TextInput source='IdTabla' defaultValue={dataCatalogo.IdTabla} hidden/>
                                    <br/><span>{translate('traducciones.campos.valores')}:</span>
                                    <ArrayInput source='ValoresTabla' label='' defaultValue={dataCatalogo.ValoresTabla}>
                                            <SimpleFormIterator disableReordering > 
                                                    <TextInput id='editValues' label={translate('traducciones.campos.valor')}  fullWidth={true} validate={required()} /> 
                                            </SimpleFormIterator>
                                        </ArrayInput>
                                </DialogContent>
                                <DialogActions>
                                    <SaveButton
                                        id='btnEdit'
                                        label="ra.action.save"
                                        handleSubmitWithRedirect={
                                            handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                    />
                                    <Button onClick={handleCloseClick} >
                                        { translate('ra.action.cancel')}
                                    </Button>
                                </DialogActions>
                                </>
                            )}
                        />
            </Dialog>
    </div> 
)};

export default withWidth()(CatalogosList);
