import React from 'react';
import { useState , useEffect } from  'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, Grid, Typography , Dialog , DialogTitle , DialogContent , DialogActions } from '@material-ui/core';
import aiidara from '../images/aidara-plantilla.png'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { ComponentPAY } from './ComponentPay';
import Container from '@mui/material/Container';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { options, options_ } from '../helpers/helpers';
import { InsertDriveFile } from '@mui/icons-material';
import { useDataProvider, SelectInput, SimpleForm} from 'react-admin';
import swal from 'sweetalert';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: '100%',
    margin: '0 auto',
    position: 'relative',
    height: '100%',
    marginTop : '10px'
  },
  cardContent: {
    padding: theme.spacing(3),
    marginBlockStart: '10px',
    marginInlineStart : '10px'
  },
  image: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
  },
  caption: {
    marginTop: theme.spacing(1),
  },
  navigation: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-60%)',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(1, 1),
    marginLeft : '-35px'
  },
  button: {
      color: 'white',
      backgroundColor : 'green'
    },
  text: {
        textAlign: 'center',
        fontSize : '20px'
  },
  description: {
    textAlign: 'justify',
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '100px',
    fontSize: '20px',
    width: '100%',
    height : '100%'
  }
}));

const cardContainerStyle = {
 display: 'grid',
 gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
 gap: '20px',
};

const imageStyle = {
 flex: '1 1 auto',
 objectFit: 'cover',
 width: '100%',
 height : '100%'
};

const imageStyle_ = {
  height : '100%',
  width: '100%',
}

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

 const containerStyleDescription = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
  gap: '16px',
   marginTop: '10px',
  height : '420px'
};

const Carousel = ({ images }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [modal, setModal] = useState(false)  
  const [ eventimage , setEventImage] = useState(null)
  const [images_, setImages] = useState([])
  const [searchText, setSearchText] = useState('');
  const [filteredImages, setFilteredImages] = useState([]);
  const [selectedOption, setSelectedOption] = React.useState('');
  const [selectedOptionArea, setSelectedOptionArea] = React.useState('');
  const [arrayTemplates] = useState([])
  const [choicesPlantillas, setChoicesPlantillas] = useState(null)
  
  useEffect(() => {
    setImages(images)
    dataProvider.getList('nodos', {})
      .then((json) => {
        json.data.filter((e) => {
          e.hijos.map((h) => {
            arrayTemplates.push({
              id : h.id
            })
          })
        });
      })
      .catch((error) => {
        console.log(error);
      })
    dataProvider.getList('plantillaNodos',{})
    .then((res)=>{
      setChoicesPlantillas(res.data)
    }).catch((err)=> console.log(err))
  }, [])


  useEffect(() => {
    if (searchText !== '') {
      setSelectedOption('')
      setSelectedOptionArea('')
      setEventImage(null)
    }
    if (selectedOption !== '' || selectedOptionArea !== '') {
      setEventImage(null)
      const filtered = images_.filter((image) =>
        selectedOption !== '' ?
          image.caption.toLowerCase().includes(selectedOption.toLowerCase()) :
          selectedOptionArea !== '' ? image.caption.toLowerCase().includes(selectedOptionArea.toLowerCase()) : ''
      );
      setFilteredImages(filtered);
    } else {
      setEventImage(null)
      const filtered = images_.filter((image) =>
        image.caption.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredImages(filtered);
    }
  }, [searchText, images_,selectedOption,selectedOptionArea])
  
  useEffect(() => {
    setCurrentImageIndex(0);
  }, [filteredImages]);

  const dataTemplate = (event) => {
    setEventImage(event)
  }

  const [ nodeName, setNodeName] = useState('')

  const modalAndNode = (node) => {
    const t = arrayTemplates.find((t) => t.id.includes(node));
    if (t) {
      swal({
             icon: "warning",
             text: 'Ya cuentas con este template'
         });
    } else {
      setNodeName(node)
      setModal(true)
    }
  }

  const DescriptionComponent = () => {
    return (
      <>
        <div style={{fontSize : '30px' , marginTop : '10px'}}>{eventimage.caption}</div>
        <div style={containerStyleDescription}>
          <Card>
            <img src={eventimage.src} alt="Imagen" style={imageStyle} />
          </Card>
          <Card style={{cursor : 'pointer'}}>
            <CardContent>
              <div style={{ textAlign: 'center', marginTop: '40px'}} onClick={() => modalAndNode(eventimage.caption)}>
                {
                  eventimage.descriptions.map((image , index) => (
                    <div key={index}>
                      <div className={classes.description}>
                        <div><InsertDriveFile fontSize="large"/> { image.desc}</div>
                      </div>
                    </div>
                  ))  
                }
              </div>
            </CardContent>
          </Card>
        </div>
      </>
    )
  }

  const handleChange = (event) => {
    setSelectedOptionArea('');
    setSelectedOption(event.target.value);
  };

  const handleChangeArea = (event) => {
    setSelectedOption('')
    setSelectedOptionArea(event.target.value);
  };

  const SelectIndustries = () => {
    return (
      <>
      <FormControl fullWidth>
        <InputLabel id="select-label">Seleccciona una industria</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          value={selectedOption}
          label="Select an option"
          onChange={handleChange}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </>
    )
  }

  const SelectIndustries2 = () => {
    return (
      <>
      <FormControl fullWidth>
        <InputLabel id="select-label">Seleccciona una industria</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          value={selectedOption}
          label="Select an option"
          onChange={handleChange}
        >
          {choicesPlantillas && choicesPlantillas.map((option) => (
            <MenuItem key={option.IndustryId} value={option.IndustryName}>
              {option.IndustryName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </>
    )
  }

  const SelectArea = () => {
    return (
      <>
      <FormControl fullWidth>
        <InputLabel id="select-label">Seleccciona una area</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          value={selectedOptionArea}
          label="Select an option"
          onChange={handleChangeArea}
        >
          {options_.map((option) => (
            <MenuItem key={option.value} value={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </>
    )
  }
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const handlePrevImage = () => {
    setEventImage(null)
    setSelectedOption('')
    setSelectedOptionArea('')
    setSearchText('')
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? filteredImages.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setEventImage(null)
    setSelectedOption('')
    setSelectedOptionArea('')
    setSearchText('')
    setCurrentImageIndex((prevIndex) =>
      prevIndex === filteredImages.length - 1 ? 0 : prevIndex + 1
    );
  };
  
  return (
    <div>
      <div style={cardContainerStyle}>
        <Card>
          <img src={aiidara} alt="Imagen" style={imageStyle_} />
        </Card>
        <Card style={{backgroundColor : '#E7E4E3'}}>
          <CardContent>
            <div className={classes.text}>  Buscar plantillas </div>
          </CardContent>
          <CardContent>
              <div style={containerStyle}>
                <OutlinedInput
                  placeholder="Buscar"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon style={{ color: 'grey' }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  style={{
                    borderRadius: '20px',
                    backgroundColor: 'white',
                    width: '600px',
                  }}
                />
              </div>
          </CardContent>
      </Card>
      </div>
      <Card style={{marginTop : '10px'}}>
        <CardContent style={cardContainerStyle}>
          <div>
            <SelectIndustries2/>
            {/* <SimpleForm toolbar={null}>
              <SelectInput source="operador" id='operador' choices={choicesOperator} optionText="operador" optionValue="idOperador"/>
            </SimpleForm> */}
          </div>
          <div>
            <SelectArea/>
          </div>
        </CardContent>
      </Card>
      <div style={{ fontSize: '20px', marginTop: '10px' }}>Recomendaciones</div>
      <Card className={classes.card}>
        <Box
          sx={{
            width: '100%',
            height: '400px', 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '50%', 
              height: '85%', 
              backgroundImage: `url(${filteredImages[currentImageIndex]?.src ?? ''})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              transition: 'background-image 0.3s ease-in-out',
              cursor: 'pointer',
              borderRadius: '12px'
            }}
            onClick={() => dataTemplate(filteredImages[currentImageIndex])}
          />
        </Box>
        <IconButton
          sx={{
            position: 'absolute',
            top: '50%',
            left: '10px',
            transform: 'translateY(-50%)',
          }}
          onClick={handlePrevImage}
        >
          <ArrowBack />
        </IconButton>
        <IconButton
          sx={{
            position: 'absolute',
            top: '50%',
            right: '10px',
            transform: 'translateY(-50%)',
          }}
          onClick={handleNextImage}
        >
          <ArrowForward />
        </IconButton>
      </Card>
      {eventimage ? <DescriptionComponent /> : ''}
      <Container  maxWidth="sm" sx={{ mb: 3 }}>
        <Dialog open={modal} >
          <DialogTitle>Proceso de pago</DialogTitle>
            <DialogContent>
            <ComponentPAY node={ nodeName } />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setModal(false)} color="primary">Cerrar</Button>
            </DialogActions>
          </Dialog>
      </Container>
    </div>
  );
};

export default Carousel;