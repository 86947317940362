import React from 'react';
import { 
    Create, 
    TextInput, 
    SimpleForm, 
    Toolbar,
    SaveButton,
    BooleanInput,
    AutocompleteArrayInput,
    useTranslate,
    required } from 'react-admin';
import Button from '@material-ui/core/Button';
import { ReferenceInput } from 'react-admin';
import { useEffect } from 'react';
import introJs from 'intro.js';
import 'intro.js/introjs.css';

const CreateToolbar = ({ onCancel, ...props }) => {
    
    const translate = useTranslate();

    return(
       <Toolbar {...props}>
           <div>
                <SaveButton id='btnCreateGroups' label="ra.action.add" {...props} />
                <Button onClick={onCancel} style={{position: 'absolute', right: '26px'}}>{translate('ra.action.cancel')}</Button>
           </div>
       </Toolbar>
)};

const GrupoCreate = ({ onCancel, onSuccessOk, ...props }) => {
    
    const translate = useTranslate();
    const dataUsuarios=props.dataUsers
    const inTour = JSON.parse(localStorage.getItem('activeTour'))

    let introHelp;
    introHelp = introJs('#root');
    introHelp.setOptions({
        disableInteraction: false,
        doneLabel: translate('traducciones.introBotones.doneLabel'),
        nextLabel: translate('traducciones.introBotones.nextLabel'),
        prevLabel: translate('traducciones.introBotones.prevLabel'),
        tooltipPosition:'top',
        steps: [
                {
                intro: translate('traducciones.introHelp.intro1'),
                title: translate('traducciones.introHelp.title1'),
                element: '#help'
                },
        ]                
    });

    const showHelp = () =>{
        const offPermisos = {
            querys: false,
            carga: false,
            admin: false,
            estructura: false,
        }
        if(document.cookie == 'introjs-dontShowAgain=true'){
            document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
                setTimeout(()=>{
                    introHelp.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).onexit(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).start();                         
                },500)
        }
    }

    function crearCookie(nombre, valor, diasParaExpirar) {
    var fechaExpiracion = new Date();
    fechaExpiracion.setDate(fechaExpiracion.getDate() + diasParaExpirar);
    var cookie = nombre + '=' + valor + '; expires=' + fechaExpiracion.toUTCString() + '; path=/';
    document.cookie = cookie;
    }

    const stepsToIntro = {
        createUser:
        [
            {
                intro: translate('traducciones.introCreateUser.intro1'),
                title: translate('traducciones.introCreateUser.title1'),
                element: "#createNombreGrupo",
                scrollTo: 'off',
            },
            {
                intro: translate('traducciones.introCreateUser.intro2'),
                title: translate('traducciones.introCreateUser.title2'),
                element: '#listUsers',
                scrollTo: 'off',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introCreateUser.intro3'),
                title: translate('traducciones.introCreateUser.title3'),
                element: "#createAnotaciones",
                scrollTo: 'off',
            },
            {
                intro: translate('traducciones.introCreateUser.intro4'),
                title: translate('traducciones.introCreateUser.title4'),
                element: '#createExportar',
                scrollTo: 'off',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introCreateUser.intro5'),
                title: translate('traducciones.introCreateUser.title5'),
                element: '#createImportar',
                scrollTo: 'off',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introCreateUser.intro6'),
                title: translate('traducciones.introCreateUser.title6'),
                element: '#createDigitalizar',
                scrollTo: 'off',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introCreateUser.intro7'),
                title: translate('traducciones.introCreateUser.title7'),
                element: '#createImprimir',
                scrollTo: 'off',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introCreateUser.intro8'),
                title: translate('traducciones.introCreateUser.title8'),
                element: '#createEnviar',
                scrollTo: 'off',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introCreateUser.intro9'),
                title: translate('traducciones.introCreateUser.title9'),
                element: '#createSinMarca',
                scrollTo: 'off',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introCreateUser.intro10'),
                title: translate('traducciones.introCreateUser.title10'),
                element: '#permisosConsultas',
                scrollTo: 'off',
                position: 'bottom'
            },
            {
                intro: translate('traducciones.introCreateUser.intro11'),
                title: translate('traducciones.introCreateUser.title11'),
                element: '#btnCreateGroups',
                scrollTo: 'off',
                position: 'left'
            },
          ]       
    }

    let introDocuments;
    useEffect(() => {
        if(inTour.admin){
            setTimeout(()=>{
                introDocuments = introJs('#cont');
                introDocuments.setOptions({
                    disableInteraction: false,
                    doneLabel: translate('traducciones.introBotones.doneLabel'),
                    nextLabel: translate('traducciones.introBotones.nextLabel'),
                    prevLabel: translate('traducciones.introBotones.prevLabel'),
                    tooltipPosition:'top',
                    steps: stepsToIntro.createUser          
                  });
        
                introDocuments.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                    showHelp()
                }).onexit(()=>{
                    showHelp()
                }).start();
            },800)
        }
      }, []);

    return (
    <div>
    <Create {...props} onSuccess={ onSuccessOk }>
        <SimpleForm  toolbar={<CreateToolbar onCancel={onCancel} />}>
            <div id='cont' style={{ textAlign: 'center', width: '100%'}}>
                <div>{translate('traducciones.titulos.nuevoGrupo')}</div>
                <img src={"grupo.png"} style={{width: '60%'}} />
            </div>
            <TextInput id='createNombreGrupo' source="GrupoNombre" label="traducciones.campos.nombreDeGrupo" validate={required()} />  
            <div className="col-md-6" id='listUsers'>
                <AutocompleteArrayInput source='Users' optionText="Nombre" optionValue="Nombre" key='IdUsuario' choices={dataUsuarios}/>
            </div>
            <div className="row rowNoMessage" style={{ width: '100%'}}>
                <hr className='hr-text' data-content={translate('traducciones.permisos.permDocumentos')}/>
                <div className="col-md-6" id='createAnotaciones'>
                    <BooleanInput label="traducciones.permisos.anotaciones" defaultValue={false} source="Permissions.Anotaciones" />
                </div>
                <div className="col-md-6" id='createExportar'>
                    <BooleanInput label="traducciones.permisos.exportar" defaultValue={false} source="Permissions.Exportar" />
                </div>
                <div className="col-md-6" id='createImportar'>
                    <BooleanInput label="traducciones.permisos.importar" defaultValue={false} source="Permissions.Importar" />
                </div>
                <div className="col-md-6" id='createDigitalizar'>
                    <BooleanInput label="traducciones.permisos.digitalizar" defaultValue={false} source="Permissions.Digitalizar" />
                </div>
                <div className="col-md-6" id='createImprimir'>
                    <BooleanInput label="traducciones.permisos.imprimir" defaultValue={false} source="Permissions.Imprimir" />
                </div>
                <div className="col-md-6" id='createEnviar'>
                    <BooleanInput label="traducciones.permisos.enviar" defaultValue={false} source="Permissions.Enviar" />
                </div>
                <div className="col-md-6" id='createSinMarca'>
                    <BooleanInput label="traducciones.permisos.sinmarcaagua" defaultValue={false} source="Permissions.Sin_marca_de_agua" />
                </div>
                <hr className='hr-text' data-content={translate('traducciones.permisos.permConsultas')}/>
                <div className='row' id='permisosConsultas'>
                    <div className="col-md-6">
                        <BooleanInput label="traducciones.permisos.general" defaultValue={false} source="Permissions.Consulta_general" />
                    </div>
                    <div className="col-md-6">
                        <BooleanInput label="traducciones.permisos.navegar" defaultValue={false} source="Permissions.Consulta_navegar" />
                    </div>
                    <div className="col-md-6">
                        <BooleanInput label="traducciones.permisos.porCampos" defaultValue={false} source="Permissions.Consulta_por_campos" />
                    </div>
                    <div className="col-md-6">
                        <BooleanInput label="traducciones.permisos.porContenido" defaultValue={false} source="Permissions.Consulta_por_contenido" />
                    </div>
                </div>
            </div>
        </SimpleForm>
    </Create>
    </div>
)};

export default GrupoCreate;