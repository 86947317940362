import { fetchUtils } from 'react-admin';
import { httpRequest } from './CustomHttpClient';
import swal from 'sweetalert';
import { apimaxi , api , conektaHeaders, api_pay } from '../helpers/helpers'

/*
Para operar las apis, la url principal de la api se toma de ub objeto del index de la publicacion final.
En este caso se encuentra en el archivo public/index.html
en el siguiente sector
<script>
        window.$parametros_generales = {
              REACT_APP_BASE_URL      : "https://maxisky.com/ApiMaxImage/api/",
          }
    </script>
*/
const apiUrl = window.$parametros_generales.REACT_APP_BASE_URL;
const httpClient = fetchUtils.fetchJson;
const dataCache = [];
dataCache["cache"] = true;
let dataCustomer = {}
/**
 * FUNCION PARA IDEALIZAR, REACT-ADMIN, REQUIERE UN CONJUNTO DE DATO SON UN CAMPO ID
 * Solo usar en el caso que el conjunto no tenga una columna ID, y solo en el caso de listados.
 * **/
function idealizar(data,campo){
    if(data != undefined && data != null){
        if(campo != "" && campo != null && data.forEach != undefined){
            data.forEach(function (record) { 
                if(record != null){
                    record.id = String(record[campo]);
                    //record.seleccionado = false;
                }
            });
        }
        return data;
    }
    return null;
}
/*
* FUNCION PARA OBTENER LOS DATOS DE LOS LISTADOS EN LOS EDIT, YA QUE LAS APIS PROPORCIONADAS NO TIENEN UN GETONE
*/
function getDataElement(data,id){
    if(data != undefined && data != null){
        for ( var i in data){
            if(data[i].id == id || data[i].id == parseInt(id)){
                return data[i];
            }
        }
    }
    return null;
}

/*
* FUNCIÓN PARA OBTENER LOS DATOS DE LOS LISTADOS EN LOS EDIT POR NOMBRE, YA QUE LAS APIS PROPORCIONADAS NO TIENEN UN GETONE
*/
function getDataElementName(data,id,nombre){
    if(data != undefined && data != null){
        for ( var i in data){
            if(data[i][nombre] == id){
                return data[i];
            }
        }
    }
    return null;
}


/*
Función para realizar el cambio de variables del estado del usuario que viene en 1 o 0. a booleano
*/
function modificaUsuarioParaLista(record){
    if(record['Vigencia'] == 0){
        record.VigenciaDias = "-----";   
   }else{
       record.VigenciaDias = record.Vigencia + " días";
   }
   switch (record['EstadoUsuario']){
       case 0 : {
           record.EstadoUsuario = false;
           break;
       }
       case 1 : {
           record.EstadoUsuario = true;
           break;
       }
       case 2 : {
           record.EstadoUsuario = false;
           break;
       }
       default : {
           record.EstadoUsuario = false;
       }
   }
}

export async function getPlans () {
    return await fetch(apimaxi.URL.concat('MaximagePlans/getMaximagePlans'), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${apimaxi.Token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(( response  ) => {
          if(response.status == 200){
            return response.json()
        };
      })
      .catch(error => {
          console.error(error);
            swal({
                icon: "error",
                text: error
            });
        return Promise.reject({ message : error != null ?  error : "ERR0RPLANS230720" });
    });
}

export async function getHistoryUser (id) {
    return await fetch(apimaxi.URL.concat('Register/getCustomerItems/'+id), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${apimaxi.Token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(( response  ) => {
          if(response.status == 200){
            return response.json()
        };
      })
      .catch(error => {
          console.error(error);
            swal({
                icon: "error",
                text: error
            });
        return Promise.reject({ message : error != null ?  error : "ERR0RPLANS230720" });
    });
}

export async function conektaFetch(type, url, options) {
    switch (type) {

        case 'post_order':
            return await httpRequest(api.URL.concat(url), {
              method: 'POST',
              headers: conektaHeaders,
              body: JSON.stringify(options),
            }).then((response) => {
                if (response.status == 200) {
                    return response.json
                }
            }).catch(error => {
                console.error(error);
                swal({
                    icon: "error",
                    text: 'Ocurrio un error al crear la orden'
                });
                return Promise.reject({ message : error != null ?  error : "ERR0RCONEKTAPOSTORDER230720" });
            });  
        
        case 'payment_source':
             return await httpRequest(api_pay.URL.concat(url), {
              method: 'POST',
              headers: conektaHeaders,
              body: JSON.stringify(structureJSON(options)),
            }).then((response) => {
                if (response.status == 200) {
                    return processChargue(options,response.json)
                } 
                
            }).catch(error => {
                console.error(error);
                swal({
                    icon: "error",
                    text: 'Ocurrio un error al crear el recurso de pago'
                });
                return Promise.reject({ message : error != null ?  error : "ERR0RCONEKTAPAYMENT230720" });
            }); 
        
        case 'order': 
            return await httpRequest(api_pay.URL.concat(url), {
              method: 'POST',
              headers: conektaHeaders,
              body: JSON.stringify(options),
            }).then((response) => {
                if (response.status == 200) {
                    return response.json
                }
            }).catch(error => {
                console.error(error);
                swal({
                    icon: "error",
                    text: 'Ocurrio un error al registrar la orden'
                });
                return Promise.reject({ message : error != null ?  error : "ERR0RCONEKTAORDER230720" });
            });
        
        case 'regpayment':
            return await fetch(apimaxi.URL.concat(url), {
                method: 'POST',
                headers: {
                Authorization: `Bearer ${apimaxi.Token}`,
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(options)
            })
            .then(( response  ) => {
                if(response.status == 200){
                    return response.json()
                };
            })
            .catch(error => {
                console.error(error);
                swal({
                    icon: "error",
                    text: 'Ocurrio un error al almacenar la orden'
                });
                return Promise.reject({ message : error != null ?  error : "ERR0RREGPAYMENT230720" });
            });
        default: 
            break;
    }
}

export function structureJSON(data) {
    return {
        checkoutRequestId: data.metadata.order.checkout.id,
        fingerprint: '',
        monthlyInstallmenst: 1,
        paymentMethod: 'Card',
        savePaymentSource: false,
        tokenId: data.metadata.token.id
    }
}

async function processChargue(data, response) {
    let node = await extractDataNode();
    let json = structureJSON(data);
    let chargue_order = {
        ...json,
        customerInfo: data.metadata.order.customer_info,
        paymentKey: response.paymentKey,
        paymentSourceId: response.paymentSourceId,
        fillPaymentFormTime : 47
    }
    try {
        const order = await conektaFetch('order','order',chargue_order)
        if (order.id) {
            const orden_id = order.id;
            const payment = await conektaFetch('regpayment', 'Register/regPayment', structureRegPayment(chargue_order,orden_id));      
            return {
                order: order,
                payment: payment,
                node : node
            }
        }
    } catch (error) {
        return error
    }
}

function structureRegPayment(chargue,ord) {
    const date = new Date().toISOString();
    return {
        Payment: 273.57, //Cantidad pagada
        CustomerID: dataCustomer.metadata.customerIdMaxisky,
        platformPaymentId: ord,
        platformName: chargue.customerInfo.name,
        PlatformCustomerId: chargue.customerInfo.customer_id,
        PlatformCheckOutId: chargue.checkoutRequestId,
        PaymentDate : date,
        PaymentConcepts: [{
            "ItemId": 8,
            "ItemName": "Plantillas",
            "Value": 1
        }]
    }
}


export function dataCustomerMaxisky(data) {
    dataCustomer = data
}

async function extractDataNode() {
    return await provider.getList('nodos', { filter: {} })
    .then(({ data }) => {
        return data.map(e => e.RutaNodo  )
    }).catch(error => {
        console.error(error);
            swal({
                icon: "error",
                text: 'Ocurrio un error al traer el nodo'
            });
        return Promise.reject({ message : error != null ?  error : "ERR0RRLISTNODE230720" });
    });
}

/*
Función para reccorer la jerarquia de nodos, y traer todos los hijos del mismo de manera recursiva.
*/
function getHijos(elem,lista,nivel){
    const listaRetorno = [];
    lista.forEach(function (record) { 
        if(record.RutaNodo.startsWith(elem.RutaNodo) && record.NivelNodo == nivel){
            record.hijos = getHijos(record,lista,(nivel + 1));
            listaRetorno.push(record);            
        }
    });
    return listaRetorno;
}


/*
Función para setear correctamente el campo fecha.
*/
function changeDateT(date){
    var fecha =  date.split("T")[0];
    fecha =  fecha.split("-");
    var hora = date.split("T")[1];
    hora = hora.split(".")[0];
    hora = hora.split(":");

    return fecha[2] + "-" + fecha[1] + "-" + fecha[0] + " " + hora[0] + ":" + hora[1];
}


export const provider = {
    /*
    Esta manera de retornar los datos y de consultar la api es especifica de REACT-ADMIN, y las funciones deben estar en ciertas agrupaciones como getList, create,update,etc
    React admin solo, realiza el match del recurso y la funcion para obtener los datos, si estos asi estan indicados.
    Es decir si tenemos un recurso llamado usuarios, y creamos un listado de usuarios, el framework solo llamara al servicio getList con el paramtros resourse = usuarios.
    */
    /**
     * AGRUPACION DE SERVICIOS DE LISTADOS
     */    
    getList: (resource, params) => {
        switch(resource){
            case "usuarios" : {
                var url = apiUrl + 'Users/getUsersPage';
                return httpRequest(url,{
                    method: 'PUT',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin' : "'*'", 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                    body: JSON.stringify({
                        PageNumber : params.pagination? params.pagination.page: 1,
                        DocumentsNumber : Object.entries(params.filter).length !== 0 ? 1000 : params.pagination ? params.pagination.perPage : 1000,
                        OrderField :  params.sort && params.sort.field != "id"? params.sort.field : "Nombre",	
                        AscendingOrder : params.sort? (params.sort.order == "ASC") : true,
                    }),
                }).then(({ json }) => 
                {                      
                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }
                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    if(json.Success == false){
                        var error = {}
                        error.message = json.Message;
                        return Promise.reject(error);
                    }
                    var datos = json.Information.ItemsPage;
                    
                    //Si existe un filtro, se compara con el nombre del usuario y se filtra el arreglo de usuarios con los que tiene coincidencia el filtro
                    if(Object.entries(params.filter).length !== 0){
                        let newDatos = datos.filter(d => (d.Nombre.toLowerCase().trim()).includes(params.filter.q.toLowerCase().trim()))
                        datos=newDatos
                    }
                    
                    if(datos != undefined && datos != null){
                        datos.forEach(function (record) { 
                            modificaUsuarioParaLista(record);
                        });
                    }else{
                        return ({
                            data: [],            
                            total: null,
                        });
                    }
                    datos = idealizar(datos, 'IdUsuario');

                    datos.forEach(function (record) {
                        var PermissionsMaped = {};
                        record.Permissions.forEach(function (recorde) {          
                            let name = recorde.PermissionName[0] + recorde.PermissionName.substring(1).toLowerCase(); 
                            name = name.replaceAll(" ", "_");                 
                            PermissionsMaped[name] = recorde.Value;                        
                        });
                        record.Permissions = PermissionsMaped;
                    });

                    dataCache[resource] = datos;

                    /*
                    Si o si en el caso de los listado React Admin nos pide como requerido un objeto con dos varibles, 
                    data: con el listado de resultados, y total: con la cantidad de resultados
                    */
                    return ({
                        data: datos,  //Debemos Idealizar los resultos si el conjunto no tiene una propiedad ID que es obligatoria en react-admin framework        
                        total: json.Information.TotalItems,
                    });
                }).catch(error => {
                    return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
                });
            }
            case "grupos" : {
                var url = apiUrl + 'Groups/getGroupsPage';
                return httpRequest(url,{
                    method: 'PUT',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                    body: JSON.stringify({
                        PageNumber : params.pagination? params.pagination.page: 1,
                        DocumentsNumber : Object.entries(params.filter).length !== 0 ? 1000 : params.pagination ? params.pagination.perPage : 1000,
                        OrderField :  params.sort && params.sort.field != "id"? params.sort.field : "GrupoNombre",	
                        AscendingOrder : params.sort? (params.sort.order == "ASC") : true,
                    }),
                }).then(({ json }) => 
                {   

                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }

                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    if(json.Success == false){
                        var error = {}
                        error.message = json.Message;
                        return Promise.reject(error);
                    }
                

                    var datos = idealizar(json.Information.ItemsPage, 'IdGrupo');

                    if(Object.entries(params.filter).length !== 0){
                        let newDatos = datos.filter(d => (d.GrupoNombre.toLowerCase().trim()).includes(params.filter.q.toLowerCase().trim()))
                        datos=newDatos
                    }
                    /*
                    En este caso a cada usuario le asignamos los permisos de manera dinamica para que en vez de tenerlos como propiedad
                    los tengan como un arreglo manipulable.
                    */
                    datos.forEach(function (record) {
                        var PermissionsMaped ={};
                        record.Permissions.forEach(function (recorde) { 
                            let name = recorde.PermissionName[0] + recorde.PermissionName.substring(1).toLowerCase(); 
                            name = name.replaceAll(" ", "_");                 
                            PermissionsMaped[name] = recorde.Value;                         
                        });
                        record.Permissions = PermissionsMaped;
                    });
                    dataCache[resource] = datos;

                    return ({
                        data: datos,            
                        total: json.Information.TotalItems,
                    });
                }).catch(error => {
                    return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
                });
            }

            case "nodos" : {
                var url = apiUrl + 'Nodes/listNode';
                return httpRequest(url,{
                    method: 'GET',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                }).then(({ json }) => 
                {   
                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }

                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    if(json.Success == false){
                        var error = {}
                        error.message = json.Message;
                        return Promise.reject(error);
                    }

                    const datos = json.Information;

                    var nodosPadre = [];       
                    /*
                    Este recorrido a cada nodo se hizo para:
                    mostrarlos como colapsado ( record.colapsado = true), si sacamos esto abrirá la rama de nodos.

                    Ademas identificamos todos los que son nodo padre para agregarlos a un arreglo que despues reccorreremos
                    para poder crear la jerarquía
                    */
                    datos.forEach(function (record) { 
                        record.id = record.TextoNodo;
                        record.colapsado = true;
                        if(record.NivelNodo == 1){
                            nodosPadre.push(record);
                        }
                    });
                    
                    /*
                    Recorremos cada nodo padre para asignarle sus hijos y de esta manera poder devolver una estructura con forma de árbol
                    ya que el servicio los devuelve en un listado plano.
                    */
                    nodosPadre.forEach(function (record) { 
                        record.hijos = getHijos(record,datos,2);
                    });
                    dataCache[resource] = nodosPadre;
                    return ({
                        data: nodosPadre,            
                        total: nodosPadre.length,
                    });
                }).catch(error => {
                    const cod = error.status;
                    return Promise.reject(error);
                });
            }

            // Caso creado para listar los catalogos
            case "catalogos" : {   
                var url = apiUrl + 'Catalogues/listCatalogue'; 
                return httpClient(url,{
                    method: 'GET',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                }).then(({ json }) => 
                {   
                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }

                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }

                    if(json.Success == false){

                        return ({
                            data: [],            
                            total: 0,
                        });
                    }

                    var datos = json.Information;
                    datos = idealizar(datos, 'IdTabla');

                    if(Object.entries(params.filter).length !== 0){
                        let newDatos = datos.filter(d => (d.NombreTabla.toLowerCase().trim()).includes(params.filter.q.toLowerCase().trim()))
                        datos=newDatos
                    }

                    let datos2;
                    if(params.sort.order=='ASC'){
                        datos2=datos.sort(compareAsc);
                    }else{
                        datos2=datos.sort(compareDesc);
                    }
                    
                    function compareAsc(a, b) {
                        if (a.NombreTabla < b.NombreTabla) {
                          return -1;
                        }
                        if (a.NombreTabla > b.NombreTabla) {
                          return 1;
                        }
                        return 0;
                    }
                      
                    function compareDesc(a, b) {
                        if (a.NombreTabla > b.NombreTabla) {
                          return -1;
                        }
                        if (a.NombreTabla < b.NombreTabla) {
                          return 1;
                        }
                        return 0;
                    }

                    function paginate(items, pageNumber, pageSize) {
                        const startIndex = (pageNumber - 1) * pageSize;
                        const endIndex = startIndex + pageSize;
                        return items.slice(startIndex, endIndex);
                    }
                    datos2=paginate(datos2,params.pagination.page,params.pagination.perPage)

                    dataCache[resource] = datos;

                    return ({
                        data: datos2,  //Debemos Idealizar los resultos si el conjunto no tiene una propiedad ID que es obligatoria en react-admin framework        
                        total: json.Information.length,
                    });
                    
                }).catch(error => {
                    return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
                });
            }
            case "plantillaNodos" : {   
                var url = apiUrl + 'MaximageEnrollment/getIndustries'; 
                return httpClient(url,{
                    method: 'GET',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                }).then(({ json }) => 
                {   
                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }

                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }

                    if(json.Success == false){

                        return ({
                            data: [],            
                            total: 0,
                        });
                    }

                    var datos = json.Information;
                    datos = idealizar(datos, 'IndustryId');

                    dataCache[resource] = datos;

                    return ({
                        data: datos,  //Debemos Idealizar los resultos si el conjunto no tiene una propiedad ID que es obligatoria en react-admin framework        
                        total: json.Information.length,
                    });
                    
                }).catch(error => {
                    return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
                });
            }

            case "indices" : {                
                var url = apiUrl + 'Keywords/listKeyword'; 
                return httpRequest(url,{
                    method: 'POST',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                    body: JSON.stringify({ IdNode : params.IdNode, Prediction : params.Prediction != undefined, IdDocumentTmp : params.IdDocumentTmp }),
                }).then(({ json }) => 
                {   
                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }

                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }

                    if(json.Success == false){

                        return ({
                            data: [],            
                            total: 0,
                        });
                    }

                    var datos = json.Information;

                    datos.forEach(function (record) { 
                        const valoresMulti = []
                        if(record.ValoresLlave){
                            record.ValoresLlave.forEach( function(val) {
                                valoresMulti.push({
                                    ["valor-" + record.IdIndice] : val
                                })
                            })
                            record.ValoresLlaveObj =  valoresMulti;
                        }
                    });
                    
                    datos = idealizar(datos, 'IdIndice');
                    dataCache[resource] = datos;

                    return ({
                        data: datos,  //Debemos Idealizar los resultos si el conjunto no tiene una propiedad ID que es obligatoria en react-admin framework        
                        total: json.Information.length,
                    });
                    
                }).catch(error => {
                    return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
                });
            }

            //Caso para listar los campos comunes entre diferentes nodos
            case "indicesComunes" : {    
                var url = apiUrl + 'Keywords/getIntersectionFields'; 
                return httpClient(url,{
                    method: 'POST',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                    body: JSON.stringify({ Nodes : params.nodosSel, ToImportDocuments: params.ToImport }),
                }).then(({ json }) => {   
                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }

                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }

                    if(json.Success == false){

                        swal({
                            text: json.Message,
                            icon: 'error'
                        })

                        return ({
                            data: [],            
                            total: 0,
                        });
                    }

                    var datos = json.Information.IntersectionFields;
                    // var datosTo = json.Information.listFields;
                    // let auxilio = idealizar(datos,'IdIndice')
                    datos = idealizar(datos, 'IdIndice');
                    dataCache[resource] = datos;

                    return ({
                        data: datos,  //Debemos Idealizar los resultos si el conjunto no tiene una propiedad ID que es obligatoria en react-admin framework        
                        total: json.Information.length,
                    });
                    
                }).catch(error => {
                    return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
                });
            }

            //Caso para listar los campos de cada nodo seleccionado
            case "IndicesComunesPorNodo" : {
                var url = apiUrl + 'Keywords/getIntersectionFields'; 
                return httpClient(url,{
                    method: 'POST',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                    body: JSON.stringify({ Nodes : params.nodosSel , ToImportDocuments: params.ToImport}),
                }).then(({ json }) => {   
                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }

                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }

                    if(json.Success == false){

                        swal({
                            text: json.Message,
                            icon: 'error'
                        })

                        return ({
                            data: [],            
                            total: 0,
                        });
                    }

                    var datos = json.Information.listFields;

                    datos = idealizar(datos, 'Node');
                    dataCache[resource] = datos;

                    return ({
                        data: datos,  //Debemos Idealizar los resultos si el conjunto no tiene una propiedad ID que es obligatoria en react-admin framework        
                        total: json.Information.length,
                    });
                    
                }).catch(error => {
                    return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
                });
            }

            case "plantillas" : {       
                
                if(dataCache[resource] && dataCache[resource].length > 0){
                    return ({
                        data: dataCache[resource],  //Debemos Idealizar los resultos si el conjunto no tiene una propiedad ID que es obligatoria en react-admin framework        
                        total: dataCache[resource].length,
                    });
                }
                var url = apiUrl + 'Template/listTemplate'; 
                return httpRequest(url,{
                    method: 'GET',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })
                }).then(({ json }) => 
                {   
                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }
                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    if(json.Success == false){

                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    var datos = json.Information;                    
                    datos = idealizar(datos, 'IdModel');
                    dataCache[resource] = datos;
                    return ({
                        data: datos,  //Debemos Idealizar los resultos si el conjunto no tiene una propiedad ID que es obligatoria en react-admin framework        
                        total: json.Information.length,
                    });
                    
                }).catch(error => {
                    return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
                });
            }

            

            case "accessNode" : {     
                var url = apiUrl + 'Access/getAccessByNode';
                return httpRequest(url,{
                    method: 'POST',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                    body: JSON.stringify({ IdNodo : params.IdNode}),
                }).then(({ json }) => 
                {   
                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }
                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    if(json.Success == false){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    var datosUsers = json.Information.Users;  
                    var datareturn = [];

                    /*
                    En este caso reccorreremos dos listados ya que es el mismo servicio quien nos devuelve
                    los accesos de usuarios y de grupos
                    para poder devolverlos en un solo listado identificandolos si son de usuario o de grupos.
                    */

                    datosUsers.forEach(function (record) { 
                        var rd = {};
                        rd.id = record;
                        rd.Nombre = record;
                        rd.type = "U"; // Identificador de acceso de Usuario
                        datareturn.push(rd);
                    });  
                    
                    var datosGroups = json.Information.Groups;  
                    datosGroups.forEach(function (record) { 
                        var rd = {};
                        rd.id = record;
                        rd.Nombre = record;
                        rd.type = "G"; // Identificador de acceso de Grupo
                        datareturn.push(rd);
                    });  

                    var datosGroupsNoti = json.Information.NotifyGroups;  
                    datosGroupsNoti.forEach(function (record) { 
                        var rd = {};
                        rd.id = record;
                        rd.Nombre = record;
                        rd.type = "GN"; // Identificador de acceso de Grupo
                        datareturn.push(rd);
                    });  

                    var NotifyUsers = json.Information.NotifyUsers;  
                    NotifyUsers.forEach(function (record) { 
                        var rd = {};
                        rd.id = record;
                        rd.Nombre = record;
                        rd.type = "UN"; // Identificador de acceso de Usuario
                        datareturn.push(rd);
                    });  

                    dataCache[resource] = datareturn;
                    return ({
                        data: datareturn,       
                        total: datareturn.length,
                    });                    
                }).catch(error => {
                    return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
                });
            }

            //New Implementación servicio con Vigencia
            case "getNodeAccess" : {   
                var url = apiUrl + 'Access/getNodeAccess';
                return httpClient(url,{
                    method: 'POST',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                    body: JSON.stringify({ IdNodo : params.IdNode}),
                }).then(({ json }) => 
                {   
                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }
                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    if(json.Success == false){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    var datosUsers = json.Information.Users; 
                    var datareturn = [];

                    /*
                    En este caso reccorreremos dos listados ya que es el mismo servicio quien nos devuelve
                    los accesos de usuarios y de grupos
                    para poder devolverlos en un solo listado identificandolos si son de usuario o de grupos.
                    */

                    datosUsers.forEach(function (record) { 
                        var rd = {};
                        rd.id = record;
                        rd.Nombre = record;
                        rd.type = "U"; // Identificador de acceso de Usuario
                        datareturn.push(rd);
                    });  
                    
                    var datosGroups = json.Information.Groups; 
                    
                    datosGroups.forEach(function (record) { 
                        var rd = {};
                        rd.id = record;
                        rd.Nombre = record;
                        rd.type = "G"; // Identificador de acceso de Grupo
                        datareturn.push(rd);
                    }); 
                    
                    var datosGroupsNoti = json.Information.NotifyGroups;
                    if(datosGroupsNoti!=null){  
                    datosGroupsNoti.forEach(function (record) { 
                        var rd = {};
                        rd.id = record;
                        rd.Nombre = record;
                        rd.type = "GN"; // Identificador de acceso de Grupo
                        datareturn.push(rd);
                    });  
                    }

                    var NotifyUsers = json.Information.NotifyUsers;  
                    if(NotifyUsers!=null){
                    NotifyUsers.forEach(function (record) { 
                        var rd = {};
                        rd.id = record;
                        rd.Nombre = record;
                        rd.type = "UN"; // Identificador de acceso de Usuario
                        datareturn.push(rd);
                    });
                    }

                    dataCache[resource] = datareturn;
                    return ({
                        data: datareturn,       
                        total: datareturn.length,
                    });                    
                }).catch(error => {
                    return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
                });
            }

            case "queryCampos" : {  
                var url = apiUrl + 'Query/browseFields';
                params.idUser = localStorage.getItem("userName");
                return httpRequest(url,{
                    method: 'PUT',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                    body: JSON.stringify(params),
                }).then(({ json }) => 
                {   
                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }
                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    if(json.Success == false){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    var datos = json.Information.ItemsPage;  
                    datos = idealizar(datos, 'IdDocumento');

                    datos.forEach(function (doc) { 
                        if(doc.DatosDocumento){
                            doc.DatosDocumento.forEach(function (record) { 
                                const valoresMulti = []
                                if(record.ValoresLlave){
                                    record.ValoresLlave.forEach( function(val) {
                                        valoresMulti.push({
                                            ["valor-" + record.IdIndice] : val
                                        })
                                    })
                                    record.ValoresLlaveObj =  valoresMulti;
                                }
                            })
                        }
                    });

                    dataCache[resource] = datos;
                    return ({
                        data: datos,       
                        porcentaje : json.Information.PercentComplete,  //Este parametros se agrego por que indicaron la necesidad de tener porcentaje de completez
                        total: json.Information.TotalItems,
                    });                    
                }).catch(error => {
                    return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
                });
            }


            case "queryPredefinida" : {  
                var url = apiUrl + 'Query/browseQuery';
                params.idUser = localStorage.getItem("userName");
                if(params.Metadata.length > 0){
                    return httpRequest(url,{
                        method: 'PUT',
                        headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                        body: JSON.stringify(params),
                    }).then(({ json }) => 
                    {   
                        if(json.Token != null){
                            localStorage.setItem('token', json.Token);
                        }
                        if(json == undefined){
                            return ({
                                data: [],            
                                total: 0,
                            });
                        }
                        if(json.Success == false){
                            return ({
                                data: [],            
                                total: 0,
                            });
                        }
                        var datos = json.Information.ItemsPage;  
                        datos = idealizar(datos, 'IdDocumento');
    
                        datos.forEach(function (doc) { 
                            if(doc.DatosDocumento){
                                doc.DatosDocumento.forEach(function (record) { 
                                    const valoresMulti = []
                                    if(record.ValoresLlave){
                                        record.ValoresLlave.forEach( function(val) {
                                            valoresMulti.push({
                                                ["valor-" + record.IdIndice] : val
                                            })
                                        })
                                        record.ValoresLlaveObj =  valoresMulti;
                                    }
                                })
                            }
                        });
    
                        dataCache[resource] = datos;
                        return ({
                            data: datos,       
                            porcentaje : json.Information.PercentComplete,  //Este parametros se agrego por que indicaron la necesidad de tener porcentaje de completez
                            total: json.Information.TotalItems,
                        });                    
                    }).catch(error => {
                        return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
                    });
                }else{
                    swal({
                        icon: 'info',
                        text: 'Por favor, debe llenar los campos solicitados.'
                    })
                   return Promise.reject({ message : 'Por favor, debe llenar los campos solicitados.' });
                }
            }

           
            case "queryGeneral" : {  
                var url = apiUrl + 'Query/browseGeneral';
                return httpRequest(url,{
                    method: 'PUT',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                    body: JSON.stringify(params),
                }).then(({ json }) => 
                {   
                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }
                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    if(json.Success == false){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    var datos = json.Information.ItemsPage;  
                    datos = idealizar(datos, 'IdDocumento');

                    datos.forEach(function (doc) { 
                        if(doc.DatosDocumento){
                            doc.DatosDocumento.forEach(function (record) { 
                                const valoresMulti = []
                                if(record.ValoresLlave){
                                    record.ValoresLlave.forEach( function(val) {
                                        valoresMulti.push({
                                            ["valor-" + record.IdIndice] : val
                                        })
                                    })
                                    record.ValoresLlaveObj =  valoresMulti;
                                }
                            })
                        }
                    });

                    dataCache[resource] = datos;
                    return ({
                        data: datos,       
                        porcentaje : json.Information.PercentComplete,  //Este parametros se agrego por que indicaron la necesidad de tener porcentaje de completez
                        total: json.Information.TotalItems,
                    });                    
                }).catch(error => {
                    return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
                });
            }

            case "queryContenido" : {  
                var url = apiUrl + 'Query/browseContent';
                return httpRequest(url,{
                    method: 'PUT',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                    body: JSON.stringify(params),
                }).then(({ json }) => 
                {   
                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }
                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    if(json.Success == false){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    var datos = json.Information.ItemsPage;  
                    datos = idealizar(datos, 'IdDocumento');

                    datos.forEach(function (doc) { 
                        if(doc.DatosDocumento){
                            doc.DatosDocumento.forEach(function (record) { 
                                const valoresMulti = []
                                if(record.ValoresLlave){
                                    record.ValoresLlave.forEach( function(val) {
                                        valoresMulti.push({
                                            ["valor-" + record.IdIndice] : val
                                        })
                                    })
                                    record.ValoresLlaveObj =  valoresMulti;
                                }
                            })
                        }
                    });
                    
                    dataCache[resource] = datos;
                    return ({
                        data: datos,       
                        porcentaje : json.Information.PercentComplete,  //Este parametros se agrego por que indicaron la necesidad de tener porcentaje de completez
                        total: json.Information.TotalItems,
                    });                    
                }).catch(error => {
                    return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
                });
            }


            case "queryDocument" : {  
                var url = apiUrl + 'Query/browseDocument';
                return httpRequest(url,{
                    method: 'PUT',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                    body: JSON.stringify(params),
                }).then(({ json }) => 
                {   
                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }
                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    if(json.Success == false){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    var datos = json.Information.ItemsPage;  
                    datos = idealizar(datos, 'IdDocumento');

                    datos.forEach(function (doc) { 
                        if(doc.DatosDocumento){
                            doc.DatosDocumento.forEach(function (record) { 
                                const valoresMulti = []
                                if(record.ValoresLlave){
                                    record.ValoresLlave.forEach( function(val) {
                                        valoresMulti.push({
                                            ["valor-" + record.IdIndice] : val
                                        })
                                    })
                                    record.ValoresLlaveObj =  valoresMulti;
                                }
                            })
                        }
                    });
                    
                    dataCache[resource] = datos;
                    return ({
                        data: datos,     
                        porcentaje : json.Information.PercentComplete, //Este parametros se agrego por que indicaron la necesidad de tener porcentaje de completez
                        total: json.Information.TotalItems,
                    });                    
                }).catch(error => {
                    return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
                });
            }

            case "documentos" : {
                var url = apiUrl + 'Documents/getTemporaryDocumentsPage';
                return httpRequest(url,{
                    method: 'PUT',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin' : "'*'", 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                    body: JSON.stringify({
                        PageNumber : params.pagination.page,
                        DocumentsNumber :  Object.entries(params.filter).length !== 0 ? 1000 : params.pagination ? params.pagination.perPage : 1000,
                        OrderField :  params.sort.field,	
                        AscendingOrder : params.sort.order == "ASC",
                    }),
                }).then(({ json }) => 
                {   
                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }

                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    if(json.Success == false){
                        var error = {}
                        error.message = json.Message;
                        return Promise.reject(error);
                    }

                    var datos = json.Information.ItemsPage;

                    datos.forEach(function (record) { 
                        record.DateTime = changeDateT(record.DateTime);
                    });  

                    datos = idealizar(datos, 'IdDocumentTmp');
                    dataCache[resource] = datos;

                    if(Object.entries(params.filter).length !== 0){
                        let newDatos = datos.filter(d => (d.Description.toLowerCase().trim()).includes(params.filter.q.toLowerCase().trim()))
                        datos=newDatos
                    }

                    return ({
                        data: datos,  //Debemos Idealizar los resultos si el conjunto no tiene una propiedad ID que es obligatoria en react-admin framework        
                        total: json.Information.TotalItems,
                    });
                }).catch(error => {
                    return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
                });
            }
            case "permission" : {
                var url = apiUrl + 'RolesAndPermissions/getRolePermissions/' + params.idRol;
                return httpRequest(url,{
                    method: 'GET',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                }).then(({ json }) => 
                {   
                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }

                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    if(json.Success == false){
                        var error = {}
                        error.message = json.Message;
                        return Promise.reject(error);
                    }

                    const datos = json.Information;  
                                     

                    return ({
                        data: { datos },  
                    });
                }).catch(error => {
                    return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
                });
            }
            case "estadoPassword" : {
                var url = apiUrl + 'Users/listPasswordStates';
                return httpRequest(url,{
                    method: 'GET',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                }).then(({ json }) => 
                {   
                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }

                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    if(json.Success == false){
                        var error = {}
                        error.message = json.Message;
                        return Promise.reject(error);
                    }

                    var datos = json.Information;

                    /*
                    En estos casos en cada registro creamos un registro nuevo con un dato existente.
                    En general eso se hace por que debemos usuar el dato de otra manera.
                    en este caso necesitabamos la variable con name. pero con el mismo valor de PasswordStateName
                    */
                    datos.forEach(function (record) { 
                        record.name = record.PasswordStateName;
                    });  

                    datos = idealizar(datos, 'PasswordStateId');

                    dataCache[resource] = datos;

                    return ({
                        data: datos,  
                        total: datos.length,
                    });

                }).catch(error => {
                    return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
                });
            }


            case "rolesUsuario" : {
                var url = apiUrl + 'Users/listUserRoles';
                return httpRequest(url,{
                    method: 'GET',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                }).then(({ json }) => 
                {   
                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }

                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    if(json.Success == false){
                        var error = {}
                        error.message = json.Message;
                        return Promise.reject(error);
                    }

                    var datos = json.Information;
                    
                    datos.forEach(function (record) { 
                        record.name = record.RoleName;
                    });  

                    datos = idealizar(datos, 'IdTipo');

                    dataCache[resource] = datos;

                    return ({
                        data: datos,  
                        total: datos.length,
                    });

                }).catch(error => {
                    return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });;
                });
            }


            case "predefinida" : {
                const pageNumber = params.pagination.page;
                const documentsNumber = params.pagination.perPage;
                
                // Calcular el índice de inicio y fin
                const startIndex = (pageNumber - 1) * documentsNumber;
                const endIndex = startIndex + documentsNumber;
                
                var url = apiUrl + 'Query/listQuery';
                return httpRequest(url,{
                    method: 'PUT',
                    headers: new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin' : "'*'", 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                    body: JSON.stringify({
                        PageNumber : 1,
                        DocumentsNumber : 200,
                        OrderField :  params.sort.field,	
                        AscendingOrder : params.sort.order,
                    }),
                }).then(({ json }) => 
                {   
                    if(json.Token != null){
                        localStorage.setItem('token', json.Token);
                    }

                    if(json == undefined){
                        return ({
                            data: [],            
                            total: 0,
                        });
                    }
                    if(json.Success == false){
                        var error = {}
                        error.message = json.Message;
                        return ({
                            data: [],            
                            total: 0,
                        });
                        return Promise.reject(error);
                    }

                    var datos = json.Information.ItemsPage;
                    if(params.sort.order == 'ASC'){
                        datos.sort((a, b) => a.IdQuery - b.IdQuery);
                    }else if(params.sort.order == 'DESC'){
                        datos.sort((a, b) => b.IdQuery - a.IdQuery);
                    }

                    datos = idealizar(datos, 'IdQuery');
                    dataCache[resource] = datos;

                    return ({
                        data: datos.slice(startIndex,endIndex),  //Debemos Idealizar los resultos si el conjunto no tiene una propiedad ID que es obligatoria en react-admin framework        
                        total: json.Information.TotalItems,
                    });
                }).catch(error => {
                    return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
                });
            }

            // case "catalogos" : {       
            //     if(dataCache[resource] && dataCache[resource].length > 0){
            //         return Promise.resolve({
            //             data: dataCache[resource],  //Debemos Idealizar los resultos si el conjunto no tiene una propiedad ID que es obligatoria en react-admin framework        
            //             total: dataCache[resource].length,
            //         });
            //     }
            //     var url = apiUrl + 'Catalogues/listCatalogue'; 
            //     return httpRequest(url,{
            //         method: 'GET',
            //         headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })
            //     }).then(({ json }) => 
            //     {   
            //         if(json.Token != null){
            //             localStorage.setItem('token', json.Token);
            //         }
            //         if(json == undefined){
            //             return ({
            //                 data: [],            
            //                 total: 0,
            //             });
            //         }
            //         if(json.Success == false){

            //             return ({
            //                 data: [],            
            //                 total: 0,
            //             });
            //         }
            //         var datos = json.Information;                    
            //         datos = idealizar(datos, 'IdTabla');
            //         dataCache[resource] = datos;
            //         return ({
            //             data: datos,  //Debemos Idealizar los resultos si el conjunto no tiene una propiedad ID que es obligatoria en react-admin framework        
            //             total: json.Information.length,
            //         });
                    
            //     }).catch(error => {
            //         return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
            //     });
            // }
            
        }
    },
    /**
     * AGRUPACION DE SERVICIOS DE CREACIÓN
     */  
    create : (resource, params) => {
        if(resource == "usuarios") { 
            params.data.Traductor = true;
            //Convertimos el conjunto de permisos en un arreglo clave valor
            var PermissionsMaped = Object.keys(params.data.Permissions).reduce((array, key) => {
                return [...array, {
                    PermissionName : key.replaceAll("_"," "),
                    Value : params.data.Permissions[key]
                }]
            }, []);
            params.data.Permissions = PermissionsMaped;
            var url = apiUrl + 'Users/createUser';
            return httpRequest(url, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(params.data),
            }).then(({ json }) => {

                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }

                if(!json.Success){
                    return Promise.reject(json.Message);
                }

                /*
                En el caso de los CREATE, REACT-ADMIN nos exige, un objeto con dos componentes
                data: con el valor del objeto,
                id: con el id del nuevo objeto.
                Actualmente no usamos el id. por que redibujamos la pantalla cada vez que hay un cambio de elemento por lo cual lo trae de nuevo.
                esto se usa generalmente a la hora de no hacer un hard refresh
                */

                return ({
                data: { ...params.data, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }
        if(resource == "grupos") { 
            //Convertimos el conjunto de permisos en un arreglo clave valor
            var PermissionsMaped = Object.keys(params.data.Permissions).reduce((array, key) => {
                return [...array, {
                    PermissionName : key.replaceAll("_"," "),
                    Value : params.data.Permissions[key]
                }]
            }, []);
            params.data.Permissions = PermissionsMaped;
            var url = apiUrl + 'Groups/createGroup';
            return httpRequest(url, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(params.data),
            }).then(({ json }) => {
                
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }
                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { ...params.data, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }
        if (resource == "nodos") { 
            var url = apiUrl + 'Nodes/setNode';
            return httpRequest(url, {
                method: 'PUT',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(params.data),
            }).then(({ json }) => {
                
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }
                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { ...params.data, id: 0 , information : json.Information},
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }
        if (resource == "plantilla") { 
            var url = apiUrl + 'MaximageEnrollment/setIndustryTemplate';
            return httpRequest(url, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(params.data),
            }).then(({ json }) => {
                
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }
                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { ...params.data, id: 0 , information : json.Message},
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }

        if(resource == "indices") { 
            var url = apiUrl + 'Keywords/setKeyword';
            if(params.data.function){
                url = apiUrl + 'Keywords/updateKeyword';
                params.data.function =  null;
            }

            return httpRequest(url, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(params.data),
            }).then(({ json }) => {
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }

                if(json == undefined){
                    return ({
                        data: [],            
                        total: 0,
                    });
                }

                if(json.Success == false){

                    return ({
                        data: [],            
                        total: 0,
                    });
                }

                var datos = json.Information;
                
                datos = idealizar(datos, 'IdIndice');
                dataCache[resource] = datos;

                return ({
                    data: {id: params.id, data : datos},     
                    total: json.Information.length,
                });
        }).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
          });
        }

        if(resource == "documentos") { 
            var url = apiUrl + 'Documents/setTemporaryDocuments';
            return httpRequest(url, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(params.data),
            }).then(({ json }) => {
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }
                if(!json.Success){
                    swal({
                        text: json.Message,
                        icon: 'error'
                    })
                    return Promise.reject(json.Message);
                }
                return ({
                data: { ...params.data, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });;
              });
        }

        //Resource para indexar los documentos
        if(resource == "documentosToIndexar") { 
            var url = apiUrl + 'Document/createDocument';
            return httpClient(url, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(params.data),
            }).then(({ json }) => {
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { ...params.data, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });;
              });
        }

         //Resource para crear un catalogo nuevo
         if(resource == "catalogos") { 
            var url = apiUrl + 'Catalogues/setCatalogue';
            return httpClient(url, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(params.data),
            }).then(({ json }) => {
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { ...params.data, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });;
              });
        }

        if(resource == "predefinida") { 
            var url = apiUrl + 'Query/setQuery';
            return httpClient(url, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(params.data),
            }).then(({ json }) => {
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { ...params.data, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });;
              });
        }

    },
    /**
     * AGRUPACION DE SERVICIOS DE ELIMINACIÓN
     */  
    delete : (resource, params) => {
        //console.log("Servicio de delete - servicio: " + resource + " , token" + localStorage.getItem('token'));
        if(resource == "usuarios") { 
            var url = apiUrl + 'Users/deleteUserById/' + params.previousData.IdUsuario;
            return httpRequest(url, {
                method: 'DELETE',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
            }).then(({ json }) => {
                
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }
                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                /*
                En el caso de los DELETE al igual que en el CREATE, REACT-ADMIN nos exige, un objeto con dos componentes
                data: con el valor del objeto,
                id: con el id del nuevo objeto.
                Actualmente no usamos el id. por que redibujamos la pantalla cada vez que hay un cambio de elemento por lo cual lo trae de nuevo.
                esto se usa generalmente a la hora de no hacer un hard refresh
                */
                data: { ...params.data, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }
        // Modificacion en eliminación de un grupo; 
        // Anteriormente se eliminaba mediante el Nombre del grupo, pasando la prop params.previousData.GrupoNombre
        // Ahora se realiza mediante el Id del grupo
        if(resource == "grupos") { 
            var url = apiUrl + 'Groups/deleteGroup/' + params.previousData.IdGrupo;
            return httpRequest(url, {
                method: 'DELETE',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
            }).then(({ json }) => {
                
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }
                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { ...params.data, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }
        if(resource == "nodos") { 
            var url = apiUrl + 'Nodes/deleteNode';
            const datos = {
                IdParentNode : params.previousData.RutaNodo,
                NodeName : params.previousData.TextoNodo
            }

            return httpRequest(url, {
                method: 'PUT',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(datos),
            }).then(({ json }) => {
                
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }
                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { ...params.data, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }
        if(resource == "indices") { 
            var url = apiUrl + 'Keywords/deleteKeyword';
            const datos = {
                IdParentNode : params.previousData.IdNodo,
                IdKeyword : params.id
            }

            return httpRequest(url, {
                method: 'PUT',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(datos),
            }).then(({ json }) => {
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }

                if(json == undefined){
                    return ({
                        data: [],            
                        total: 0,
                    });
                }

                if(json.Success == false){

                    return ({
                        data: [],            
                        total: 0,
                    });
                }

                var datos = json.Information;
                
                datos = idealizar(datos, 'IdIndice');
                dataCache[resource] = datos;

                return ({
                    data: {id: params.id, data : datos},     
                    total: json.Information.length,
                });
        }).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }
        if(resource == "documentos") { 
            var url = apiUrl + 'Documents/deleteTemporaryDocument/' + params.id;
            return httpRequest(url, {
                method: 'DELETE',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
            }).then(({ json }) => {
                
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }
                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { ...params.data, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }
        if(resource == "documento") { 
            var url = apiUrl + 'Document/deleteDocument/' + params.id;
            return httpRequest(url, {
                method: 'DELETE',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
            }).then(({ json }) => {
                
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }
                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { ...params.data, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }

        //Nueva condición para eliminar un catalogo
        if(resource == "catalogos") { 
            var url = apiUrl + 'Catalogues/deleteCatalogue/' + params.id;
            return httpClient(url, {
                method: 'GET',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
            }).then(({ json }) => {
                
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }
                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { ...params.data, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }

        if(resource == "predefinida") { 
            var url = apiUrl + 'Query/deleteQuery';
            return httpClient(url, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify({ IdQuery: params.id })
            }).then(({ json }) => {
                
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }
                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { ...params.data, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }
    },
     /**
     * AGRUPACION DE SERVICIOS DE ACTUALIZACIÓN
     */  
      update : (resource, params) => {
        //console.log("Servicio de actualizacion - servicio: " + resource);
        if(resource == "usuarios") { 
            const permisos=params.data.Permissions;
            const idCheck = localStorage.getItem('IdUsuario')
            params.data.Traductor = true;

            //Convertimos el conjunto de permisos en un arreglo clave valor
            var PermissionsMaped = Object.keys(params.data.Permissions).reduce((array, key) => {
                return [...array, {
                    PermissionName :  key.replaceAll("_"," "),
                    Value : params.data.Permissions[key] ? params.data.Permissions[key] : false
                }]
            }, []);
            params.data.Permissions = PermissionsMaped;
            
            //Se convierte el objeto al formato indicado para actualizar los permisos del usuario en la sesion
            const nuevoPermisos = Object.entries(permisos).reduce((acc, [clave, valor]) => {
                const nuevaClave = clave.replace(/_/g, '').toUpperCase();
                return { ...acc, [nuevaClave]: valor };
              }, {});

            if(params.data.EstadoUsuario){
                params.data.EstadoUsuario = 1;
            }else{
                params.data.EstadoUsuario = 2;
            }

            var url = apiUrl + 'Users/updateUser';
            return httpRequest(url, {
                method: 'PUT',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(params.data),
            }).then(({ json }) => {
                
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }
                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }

                //Se almacenan los nuevos permisos en el localstorage
                if(params.data.IdUsuario.toString() == idCheck){
                    localStorage.setItem('permisos', JSON.stringify(nuevoPermisos))                    
                }
                
                /*
                En el caso de los UPDATE al igual que en los CREATE, REACT-ADMIN nos exige, un objeto con dos componentes
                data: con el valor del objeto,
                id: con el id del nuevo objeto.
                Actualmente no usamos el id. por que redibujamos la pantalla cada vez que hay un cambio de elemento por lo cual lo trae de nuevo.
                esto se usa generalmente a la hora de no hacer un hard refresh
                */
                return ({
                data: { ...params.data, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });


        }
        if(resource == "grupos") { 
            //Convertimos el conjunto de permisos en un arreglo clave valor
            var PermissionsMaped = Object.keys(params.data.Permissions).reduce((array, key) => {
                return [...array, {
                    PermissionName : key.replaceAll("_"," "),
                    Value : params.data.Permissions[key]? params.data.Permissions[key] : false
                }]
            }, []);
            params.data.id = null;
            params.data.Permissions = PermissionsMaped;
            var url = apiUrl + 'Groups/updateGroup';
            return httpRequest(url, {
                method: 'PUT',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(params.data),
            }).then(({ json }) => {
                
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }
                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }

                return ({
                data: { ...params.data, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });


        }

        if(resource == "accessNode") { 
            var url = apiUrl + 'Access/setAccess';
            var body = {
                Users : params.Users,
                Groups: params.Grupos,
                NotifyUsers : params.NotifyUsers,
                NotifyGroups : params.NotifyGroups,
                IdNodo : params.id
            }
            return httpRequest(url, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(body),
            }).then(({ json }) => {                
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { body, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }

        //New Servicio encargado de actualizar los accesos hacia un nodo, con un usuario, grupo y una fecha limite
        if(resource == "setAccessNode") { 
            var url = apiUrl + 'Access/setNodeAccess';
            var body = {
                IdNodo : params.id,
                Users : params.Users,
                Groups: params.Grupos,
                NotifyUsers : params.NotifyUsers,
                NotifyGroups : params.NotifyGroups
            }
            return httpClient(url, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(body),
            }).then(({ json }) => {                
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { body, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }

        //Caso para actualizar un catalogo
        if(resource == "catalogos") { 
            var url = apiUrl + 'Catalogues/modifyCatalogue';
            return httpClient(url, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(params.data),
            }).then(({ json }) => {                
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { body, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }

        if(resource == "accessDocument") { 
            var url = apiUrl + 'Access/setDocumentPermissions';
            var body = {
                Users : params.Users,
                Groups: params.Grupos,
                IdDocument : params.id
            }
            return httpRequest(url, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(body),
            }).then(({ json }) => {       
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { body, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }

        if(resource == "anotaciones") {
            var url = apiUrl + 'Document/setAnnotations';
            var body = {
                IdDocument : params.DocumentId,
                Base64AnnotationsFile : params.Annotations
            }
            return httpRequest(url, {
                method: 'PUT',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(body),
            }).then(({ json }) => {                
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { body, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }

        if(resource == "documentos") { 
            var campos = Object.entries(params.data);
            var meta = [];
            campos.forEach(function (record, index) { 
                if(record[1] != undefined && record[1] != null && record[0].includes("campo")){
                    meta.push(
                        {
                            "IdDocumento": "",
                            "IdIndice": record[0].replace('campo-', ''),
                            "ValorLlave": typeof record[1] != "string" ? record[1] : record[1].includes('\r\n') ? record[1].replace(/\r\n/g, "") : record[1],
                            "NombreIndice": "",
                        }
                    );
                }
                if(record[1] != undefined && record[1] != null && record[0].includes("multiple")){
                    var valores = [];
                    record[1].forEach(function(e){
                        valores.push(e["valor-" + record[0].replace('multiple-', '')]);
                    });
                    const valoresAux = [];
                    valores.map((v)=>{
                        if (typeof v === "string"){
                            if(v.includes('\r\n')){
                               valoresAux.push(v.replace(/\r\n/g, ""))
                            }else{
                                valoresAux.push(v)
                            }
                        }else{
                            valoresAux.push(v)
                        }
                    })
                    meta.push(
                        {
                            "IdDocumento": "",
                            "IdIndice": record[0].replace('multiple-', ''),
                            "ValoresLlave": valoresAux,
                            "ValorLlave": "",
                            "NombreIndice": "",
                        }
                    );
                    
                }
            });
            var url = apiUrl + 'Document/createDocument';
            var body = {
                IdNode: params.previousData.data.IdNode,
                RutaDocumento: "",
                IdDocumentTmp : params.previousData.data.IdDocumentTmp,
                TipoDocumento: params.previousData.data.DocumentType,
                NumPaginas : params.previousData.data.DocumentPages,
                TamanoArchivo : 0,
                Base64file: params.previousData.data.Document,
                GetFullText: params.previousData.ft,
                DatosDocumento : meta
            }
            return httpRequest(url, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(body),
            }).then(({ json }) => {                
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { body, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }

        if(resource == "documentoTemporal") { 
            var url = apiUrl + 'Documents/assignNode';
            var body = {
                IdNode : params.IdNode,
                IdDocumentTmp : params.id
            }
            return httpRequest(url, {
                method: 'PUT',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(body),
            }).then(({ json }) => {                
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { body, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }

        if(resource == "assignNode") { 
            var url = apiUrl + 'Document/reassignNode';
            var body = {
                IdNode : params.IdNode,
                IdDocument : params.id
            }
            return httpRequest(url, {
                method: 'PUT',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(body),
            }).then(({ json }) => {     
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { json, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }

        if(resource == "documentoTemporalUser") { 
            var url = apiUrl + 'Documents/assignTemporaryDocuments';
            var body = {
                IdDocumentTmp : params.IdDocumentTmp,
                AssignedUserId : params.idUser
            }
            return httpRequest(url, {
                method: 'PUT',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(body),
            }).then(({ json }) => {       
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { body, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }

        if(resource == "actualizarNombreNodo") { 
            var url = apiUrl + 'Nodes/updateNode';
            var body = {
                IdNode : params.values? params.values.IdNode: null,
                NodeName : params.values? params.values.nodoName : null
            }
            return httpRequest(url, {
                method: 'PUT',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(body),
            }).then(({ json }) => {     
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { Success : json.Success, id: 0, data: json.Information, Message : json.Message },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }

        if(resource == "documentoMover") { 
            var url = apiUrl + 'Nodes/moveNode';
            return httpRequest(url, {
                method: 'PUT',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(params),
            }).then(({ json }) => {      
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }
                return ({
                data: { Success : json.Success, id: 0, data: json.Information, Message : json.Message },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }

        if(resource == "documento") { 
            var url = apiUrl + 'Document/updateDocument';
            var body = {
                IdNode: params.IdNode,
                IdDocument : params.IdDocument,
                DocumentData : params.DocumentData
            }
            return httpRequest(url, {
                method: 'PUT',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify(body),
            }).then(({ json }) => {                
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }                
                if(!json.Success){
                    return Promise.reject(json.Message);
                }

                body.DocumentData.forEach(function (record) { 
                    const valoresMulti = []
                    if(record.ValoresLlave){
                        record.ValoresLlave.forEach( function(val) {
                            valoresMulti.push({
                                ["valor-" + record.IdIndice] : val
                            })
                        })
                        record.ValoresLlaveObj =  valoresMulti;
                    }
                });


                return ({
                data: { body, id: 0 },
            })}).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
              });
        }
    },
    /**
     * AGRUPACION DE GET UNIDAD
     * En el caso de los GetOne, muchos de estos servicios consultan una cache de memoria de los servicios actuales
     * ya que no poseemos apis para consulta unitaria, ademas que es una optimizacion de tiempo de trabajo.
     */    
    getOne : (resource, params) => {
        if(resource == "usuarios") { 
            //Obtenemos el registro desde la cache.
            var datos = getDataElement(dataCache[resource], params.id);
            if(datos == null){
                datos = [];
            }

            return Promise.resolve({data  : datos});
        }
        if(resource == "grupos") { 
            var datos = getDataElement(dataCache[resource], params.id);
            if(datos == null){
                datos = [];
            }
            var permisos = {
                Anotaciones : datos.Permissions.Anotaciones,
                Exportar : datos.Permissions.Exportar,
                Importar : datos.Permissions.Importar,
                Imprimir : datos.Permissions.Imprimir,
                Digitalizar : datos.Permissions.Digitalizar,
                Enviar : datos.Permissions.Enviar,
                Sin_marca_de_agua: datos.Permissions.Sin_marca_de_agua,
                Consulta_general: datos.Permissions.Consulta_general,
                Consulta_navegar: datos.Permissions.Consulta_navegar,
                Consulta_por_campos: datos.Permissions.Consulta_por_campos,
                Consulta_por_contenido: datos.Permissions.Consulta_por_contenido
            };
            datos.Permissions = permisos;
            
            return Promise.resolve({data  : datos});
        }
        if(resource == "documento") {
            var url = apiUrl + 'Document/getDocument/';
            return httpRequest(url,{
                method: 'PUT',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                body: JSON.stringify({ IdDocument : params.IdDocument }),
            }).then(({ json }) => 
            {   
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }

                if(json == undefined){
                    return ({
                        data: [],            
                        total: 0,
                    });
                }
                if(json.Success == false){
                    var error = {}
                    error.message = json.Message;
                    return Promise.reject(error);
                }

                const datos = json.Information;

                return ({
                    data: { id : params.IdDocument , data : datos },  
                });
            }).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
            });
        }

        if(resource == "documentos") {
            var url = apiUrl + 'Documents/getTemporaryDocument/' + params.id;
            return httpRequest(url,{
                method: 'GET',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                //body: JSON.stringify({ IdDocument : params.IdDocument }),
            }).then(({ json }) => 
            {   
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }

                if(json == undefined){
                    return ({
                        data: [],            
                        total: 0,
                    });
                }
                if(json.Success == false){
                    var error = {}
                    error.message = json.Message;
                    return Promise.reject(error);
                }

                const datos = json.Information;

                return ({
                    data: { id : params.id , data : datos },  
                });
            }).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
            });
        }

        if(resource == "tagsTemplate") {       
            var url = apiUrl + 'Template/getTagsTemplate/' + params.id; 
            return httpRequest(url,{
                method: 'GET',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })
            }).then(({ json }) => 
            {   
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }

                if(json == undefined){
                    return ({
                        data: [],            
                        total: 0,
                    });
                }
                if(json.Success == false){
                    var error = {}
                    error.message = json.Message;
                    return Promise.reject(error);
                }

                const datos = json.Information;

                return ({
                    data: { id : params.id , data : datos },  
                });
                
            }).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
            });
        }

        if(resource == "permisosDocumentos") {
            var url = apiUrl + 'Access/getDocumentPermissions/' + params.id;
            return httpRequest(url,{
                method: 'GET',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
            }).then(({ json }) => 
            {   
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }

                if(json == undefined){
                    return ({
                        data: [],            
                        total: 0,
                    });
                }
                if(json.Success == false){
                    var error = {}
                    error.message = json.Message;
                    return Promise.reject(error);
                }

                const datos = json.Information;

                return ({
                    data: { id : params.id , data : datos },  
                });
            }).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
            });
        }

        if(resource == "dashboard") {
            var url = apiUrl + 'Dashboard/' + params.type;
            return httpRequest(url,{
                method: 'GET',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
            }).then(({ json }) => 
            {   
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }

                if(json == undefined){
                    return ({
                        data: [],            
                        total: 0,
                    });
                }
                if(json.Success == false){
                    /*
                    var error = {}
                    error.message = json.Message;
                    return Promise.reject(error);
                    */
                    return ({
                        data: { id : 0 , data : [] },  
                    });
                }

                var datos = json.Information;                    

                return ({
                    data: { id : 0 , data : datos },  
                });

            }).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
            });
        }

        if(resource == "catalogos") {
            var url = apiUrl + 'Catalogues/listValuesCatalogue/'+params.id;
            return httpClient(url,{
                method: 'GET',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })  ,
                //body: JSON.stringify({ IdDocument : params.IdDocument }),
            }).then(({ json }) => 
            {   
                if(json.Token != null){
                    localStorage.setItem('token', json.Token);
                }

                if(json == undefined){
                    return ({
                        data: [],            
                        total: 0,
                    });
                }
                if(json.Success == false){
                    var error = {}
                    error.message = json.Message;
                    return Promise.reject(error);
                }

                const datos = json.Information;

                return ({
                    data: { id : params.id , data : datos },  
                });
            }).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
            });
        }

        if(resource == "predefinida") { 
            //Obtenemos el registro desde la cache.
            var datos = getDataElement(dataCache[resource], params.id);
            if(datos == null){
                datos = [];
            }

            return Promise.resolve({data  : datos});
        }

        return ({
            data: { id : 0 , data : [] },  
        });
    },  
    /*
    AGRUPACIÓN DE SERVICIOS DE GETMANY
    Estos servicios son todos de memoria, ya que son servicios que consultaria una cierta cantidad de items de un listado, 
    por lo cual buscan dichos datos dentro de la cache de memoria del cliente para no llamar al servicio nuevamente.
     */
    
    getMany : (resource, params) => {
        if(dataCache[resource] == null){
            return Promise.resolve({ data : [] });
        }
        if(resource == "usuarios") {
            var datos = [];
            if(params.ids[0] != undefined){
                params.ids[0].forEach(function (record) { 
                    datos = [...datos, getDataElementName(dataCache[resource], record,"Nombre")];
                });
            }
            datos = idealizar(datos, 'Nombre');
            return Promise.resolve({data  : datos});
        }
        if(resource == "plantillas") {
            var datos = [];
            if(params.ids != undefined && params.ids[0] != undefined){
                params.ids.forEach(function (record) { 
                    datos = [...datos, getDataElementName(dataCache[resource], record,"IdModel")];
                });
            }
            datos = idealizar(datos, 'IdModel');
            return Promise.resolve({data  : datos});
        }
        if(resource == "grupos") {
            var datos = [];
            if(params.ids[0] != undefined){
                params.ids[0].forEach(function (record) { 
                    datos = [...datos, getDataElementName(dataCache[resource], record,"GrupoNombre")];
                });
            }
            datos = idealizar(datos, 'IdGrupo');
            return Promise.resolve({data  : datos});
        }
        if(resource == "estadoPassword") {
            var datos = [];
            if(params.ids != undefined){
                params.ids.forEach(function (record) { 
                    datos = [...datos, getDataElementName(dataCache[resource], record,"PasswordStateId")];
                });
            }
            datos = idealizar(datos, 'PasswordStateId');
            return Promise.resolve({data  : datos});
        }
        if(resource == "rolesUsuario") {
            var datos = [];
            if(params.ids != undefined){
                params.ids.forEach(function (record) { 
                    datos = [...datos, getDataElementName(dataCache[resource], record,"IdTipo")];
                });
            }
            
            return Promise.resolve({data  : datos});
        }

        return Promise.resolve({data  : []});
    }, 

    password : (resource, params) => {
        if(resource == "restore") {
            var url = apiUrl + 'Login/setTemporaryPassword';
            return httpRequest(url,{
                method: 'PUT',
                headers: new Headers({ 'Content-Type': 'application/json'})  ,
                body: JSON.stringify({ Email : params.Email }),
            }).then(({ json }) => 
            {   
                return ({data: json});
            }).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
            });
        }

        if(resource == "change") {
            var url = apiUrl + 'Login/updatePassword';
            return httpRequest(url,{
                method: 'PUT',
                headers: new Headers({ 'Content-Type': 'application/json'})  ,
                body: JSON.stringify(params.values),
            }).then(({ json }) => 
            {   
                return ({data: json});
            }).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
            });
        }
    }, 

    session : (resource, params) => {
        if(resource == "logout") {
            var url = apiUrl + 'Sessions/logOut';
            return httpRequest(url,{
                method: 'DELETE',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') }),
                body: JSON.stringify({ Email : params.Email }),
            }).then(({ json }) => 
            {   
                return ({data: json});
            }).catch(error => {
                return Promise.reject({ message : error != null ?  error : "ERR0R09222022" });
            });
        }
    }, 
    

};