import * as React from 'react';
import { useState,useEffect } from 'react';
import { 
    useTranslate,
    SimpleForm,
    SaveButton,
    Button,
    TextInput,
    required,
    Toolbar,
    PasswordInput,
    useDataProvider,
    Loading,
    useLogout
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import EmailIcon from '@material-ui/icons/Email';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import swal from 'sweetalert';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { getHistoryUser } from '../DataProviders/GlobalDataProvider';
import { clientData } from '../helpers/helpers';
import jwt_decode from "jwt-decode";

//Modulo para la configuración de la cuenta del usuario, por el momento solo cuenta con la funcionalidad de cambio de contraseña, que antes se encontraba en el login.
const ConfigurationUser = ({ width, ...props }) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const apiUrl = window.$parametros_generales.REACT_APP_BASE_URL;
    const [openCambia, setOpenCambia] = useState(false);
    const [dataUser,setDataUser] = useState(null)
    const [dataRoles,setDataRoles] = useState(null)
    const [loadinga, setLoadinga] = useState(false);
    const [openHistory, setOpenHistory] = useState(false)
    const [userHistory, setUserHistory] = useState([])

    //Validación de tipo de cliente para restringir la creación de Nodos raiz
    const token = localStorage.getItem('token')
    let isMulticliente; 
    const dataToken = jwt_decode(token);
    if(token){
        if(dataToken.MultiClientSession == 'True' || dataToken.MultiClientSession == 'true' || dataToken.MultiClientSession == true){
            isMulticliente = true; 
        }else{
            isMulticliente = false;
        }
    }
    
    useEffect(()=>{
      if(props.permissions.Usuarios.AccesoaModuloUsuarios) {
        setLoadinga(true)
        fetch(apiUrl+'Users/getUserById/'+localStorage.getItem('IdUsuario'),{
          method: 'GET',
          headers: new Headers({ 'Content-Type': 'application/json','Authorization': 'Bearer ' + localStorage.getItem('token')}),
        }).then(( response ) => response.json())
        .then((data)=>{
          setDataUser(data.Information)
  
          dataProvider.getList('rolesUsuario')
            .then((data)=>{
              setDataRoles(data.data)
              setLoadinga(false)
            }).catch((error)=>{
              setLoadinga(false)
            })
        
          }).catch(error => {
            setLoadinga(false)
        });
      }
    },[])

    const handleCambiaPass = async values => {
      if(values.NewPassword !== values.pass2){
        swal({
          icon: "error",
          text: "Las contraseñas no coinciden",
        });
          return;
      }
      dataProvider.password('change', { values })
      .then((json) => {
          if(json.data.Success){
              swal({
                icon: "success",
                text: json.data.Message,
              });
              setOpenCambia(false);
          }else{
              swal({
                icon: "error",
                text: json.data.Message,
              });
              return;
          }
      })
      .catch(error => {
      }); 
    };

    if(loadinga){
      return <Loading />
    }

    const handleOpenHistory = async() =>{
      try {
        const response = await getHistoryUser(clientData.CustomerID);
          setUserHistory(response.Information)
      } catch (error) {
          console.log(error);
      }
      setOpenHistory(true)
    }

    return(
      <>
      <div>
        <div className='row'>
          <h1>{translate('traducciones.titulos.configuracionCuenta')}</h1>
        </div>
        <div class="row d-flex justify-content-center align-items-center h-45">
          <div class="col-md-12 col-xl-4">
            <div class="card" style={{borderRadius:'5vh',boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
              <div class="card-body text-center">
                
                <h4 class="mb-2">{translate('traducciones.login.bienvenido')}, {dataUser && dataUser.Nombre ? dataUser.Nombre : localStorage.getItem('userNombre')} </h4>
                <div class="mt-3 mb-4">
                  <AccountCircleIcon style={{fontSize:'10vh'}}/>
                </div>
                {dataUser && dataRoles ? (
                  <p class="text-muted mb-4">{ dataRoles ? dataRoles.map((rol) => rol.IdTipo === dataUser.IdTipo ? rol.RoleName : '' ) : '' }<span class="mx-2">|</span>{ dataUser && dataUser.Email ? dataUser.Email : ''}</p>
                ): null}
                <button type="button" class="btn btn-success btn-rounded btn-lg" onClick={()=>setOpenCambia(true)} style={{marginBottom:'3vh'}}>
                  {translate('traducciones.botones.cambiarContraseña')}
                </button>
              </div>
            </div>

          </div>
        </div>
        {isMulticliente ? (
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-12 col-xl-4">
              <div class="card" style={{borderRadius:'3.5vh',boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
                <div class="card-body text-center">
                  <h4 class="mb-2">Historial de compras</h4>
                  <div>
                    <button type="button" class="btn btn-success btn-rounded" onClick={handleOpenHistory} style={{marginBottom:'1vh', width:'12vh'}}>
                      <ProductionQuantityLimitsIcon/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ): null}
        <Dialog open={openCambia}>
          <DialogTitle>{ translate('traducciones.titulos.cambioContraseña')}</DialogTitle>
          <DialogContent>
              <SimpleForm save={handleCambiaPass} noValidate toolbar = { 
                  <Toolbar style={{ justifyContent: "space-between" }}>
                      <SaveButton  label ="traducciones.login.restablecer" icon={<EmailIcon />} /> 
                      <Button label ="ra.action.cancel"  onClick={e => setOpenCambia(false)} />
                  </Toolbar> } >
                  <TextInput style={{ minWidth: "400px"}} source="UserName" label="traducciones.campos.usuario" validate={required()} />
                  <PasswordInput  style={{ minWidth: "400px"}} source="CurrentPassword" label="traducciones.campos.passwordOld" validate={required()} />
                  <PasswordInput  style={{ minWidth: "400px"}} source="NewPassword" label="traducciones.campos.passwordNew" validate={required()} />
                  <PasswordInput  style={{ minWidth: "400px"}} source="pass2" label="traducciones.campos.passwordNewRepeat" validate={required()} />
              </SimpleForm>
          </DialogContent>
        </Dialog>

        {/* Dialog para mostrar el historial de compras */}
        <Dialog open={openHistory}>
          {/* <DialogTitle>Historial de compras</DialogTitle> */}
          <DialogContent>
            <div className='row'>
              <div className='col-md-12'>
              {userHistory.length > 0 && userHistory.map((item)=>{
                return(
                    <div class="card" style={{borderRadius:'3.5vh',boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
                      <div class="card-body text-center">
                        <span className="mb-2" style={{fontWeight:700}}>{item.ItemName}</span><br/>
                        <span className='mb-2'>{'Cantidad: '+item.Value}</span>
                      </div>
                    </div>
                )
              })}
              </div>
              <SimpleForm save={handleCambiaPass} noValidate toolbar = { 
                  <Toolbar style={{float:'right', backgroundColor:'white'}}>
                      <Button label ="ra.action.close"  onClick={e => setOpenHistory(false)} />
                  </Toolbar> }>
                    
              </SimpleForm>
            </div>
          </DialogContent>
        </Dialog> 
      </div>
      </>
    )}

export default ConfigurationUser