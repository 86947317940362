import * as React from "react";
import { useState, useEffect  } from 'react';
import { Buffer } from 'buffer';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import TreeItem from '@material-ui/lab/TreeItem';
import SearchIcon from '@material-ui/icons/Search';
import $ from 'jquery';
import {
    PdfLoader,
    PdfHighlighter,
    Highlight,
    Popup,
} from "react-pdf-highlighter";
import { 
    Loading,
    SimpleForm,
    TextInput,
    SaveButton,
    useTranslate,
    Button,
    useDataProvider,
    Create,
    Show,
    Edit
} from "react-admin";


/*
Este es el archivo para crear el componente de Analisis de documentos.
*/

const DocumentAnalizer= (props) => {

    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [highlights, setResaltados] = useState([]);
    const [elementToMove, setElementToMove] = useState(1);
    const [textoaBuscar, setTextoaBuscar] = useState("");
    const [cantidadResultados, setCantidadResultados] = useState(0);    
    const [pdfFile, setPdfFile] = useState(null);
    const [arbolFile, setArbolFile] = useState(null);
    const [coordsFile, setCoordsFile] = useState(null);
    const [cantidadPalabras, setCantidadPalabras] = useState(0);  

    const useStyles = makeStyles({
        root: {
            height: 240,
            flexGrow: 1,
            maxWidth: 400,
        },
        cont : {
            padding:'10px',
            backgroundColor : 'white',
            marginLeft : '10px',
            height: '80vh',
            border: '1px solid #e9e9e9',
            overflowY: 'auto'
        }
    });

    const classes = useStyles();

    var pdfID = 0;
    var arbolID = 0;
    var coordID = 0;

    if(props.location.data == undefined){        
        window.location.href = "/#/bastanteo";
    }else{

        props.location.data.forEach(element => {
            if(element.IdNode == "/63/6/3/"){
                pdfID = element.IdDocument;
            }
            if(element.IdNode == "/63/6/2/"){
                arbolID = element.IdDocument;
            }
            if(element.IdNode == "/63/6/4/"){
                coordID = element.IdDocument;
            }
        });
    }

    useEffect(() => {
        if(pdfID != 0){
            //GET PDF
            dataProvider.getOne('documento', { IdDocument: pdfID })
            .then(({ data }) => {
                setPdfFile(data.data.Base64file);
                //GET ARBOL
                dataProvider.getOne('documento', { IdDocument: arbolID })
                .then(({ data }) => {
                    setArbolFile(data.data.Base64file)
                    //GET COORDENADAS
                    dataProvider.getOne('documento', { IdDocument: coordID })
                    .then(({ data }) => {
                        setCoordsFile(data.data.Base64file);
                    });
                });
            });
        }
    }, []);

    if(pdfFile == null || arbolFile == null || coordsFile == null){
        return (<Loading />);
    }

    let arbolbase64ToString = Buffer.from(arbolFile, "base64").toString().replace(/(\r\n|\n|\r)/gm, "").trim().replace("ï»¿","");
    let arbolObject = JSON.parse(arbolbase64ToString,true);

    // console.log(arbolObject);

    const dataArbol = {
        id: '99999',
        name: 'RESULTADOS',
        children: arbolObject
      };


    let plabrasBase64ToString = Buffer.from(coordsFile, "base64").toString().replace(/(\r\n|\n|\r)/gm, "").trim().replace("ï»¿","");
    let palabrasObjeto = JSON.parse(plabrasBase64ToString,true);

    palabrasObjeto.filter(record => record.word.trim() != ",");
    palabrasObjeto.filter(record => record.word.trim() != ".");

    // console.log(palabrasObjeto);

    const palabras = palabrasObjeto;

    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } 

    const url = "data:application/pdf;base64," + pdfFile;

    const parseIdFromHash = () =>  document.location.hash.slice("#highlight-".length);

    const resetHash = () => {
        document.location.hash = "";
    }

    const getHighlightById = (id) => {    
        return highlights.find((highlight) => highlight.id === id);
    }

    const scrollViewerTo = (highlight) => {};

    const scrollToHighlightFromHash = () => {
      const highlight = getHighlightById(parseIdFromHash());  
      if (highlight) {
        scrollViewerTo(highlight);
      }
    };

    const HighlightPopup = (palabra) => {
        return (
        <div style={{backgroundColor: 'white', padding: '10px', border: '1px solid black'}}>
            { palabra.content.text }
            <div>{ palabra.content.indice } de { palabra.content.repeticiones } coincidencias</div>
        </div>
        )
    }

    const filtrarResultado = (texto) => {
        var resaltados = [];
        var splitter =  texto.trim().split(" ");
        if(splitter.length > 1){
            splitter = splitter.filter(record => record != ""); 
        }
        var filtrado = [];
        var cantidad_resul = 0;
        if(splitter.length > 1){
            var indices = [];
            palabras.forEach(function (record, index) { 
                 if(removeAccents(record.word.toLowerCase()) == removeAccents(splitter[0])){
                    indices.push(index);
                 }   
            });         
            indices.forEach(function (ind) { 
                var agregar_frase = true;
                for (let index = 1; index < splitter.length; index++) {
                    if(removeAccents(palabras[ind + index].word.toLowerCase())  != removeAccents(splitter[index].toLowerCase())){
                         agregar_frase = false;   
                    }                    
                }   
                if(agregar_frase){
                    cantidad_resul++;
                    for (let index = 0; index < splitter.length; index++) {
                        filtrado.push(palabras[ind + index]);
                    }
                }
            });

        }else{
            filtrado = palabras.filter(record => removeAccents(record.word.toLowerCase())== removeAccents(texto.trim()) );      
            cantidad_resul = filtrado.length;      
        }

        var index = 0;
        filtrado.forEach(function (record) { 
            index++;
            record.pageNumber = record.page;
            resaltados.push(
                {
                    content: {
                        text: record.word,
                        indice : index,
                        repeticiones : filtrado.length
                    },
                    position: {
                        boundingRect: record,
                        rects: [
                            record
                        ],
                        pageNumber: record.page,
                        height: record.height
                    },
                    id: "WO" + String(Math.random()).slice(2),
                }, 
            )
        });
        setCantidadPalabras(splitter.length);
        setCantidadResultados(cantidad_resul);
        setResaltados(resaltados);
        setElementToMove(1);    
        setTextoaBuscar(texto);
    }

    const handleSubmitSearch = async values => {     
        if(values.textoaBuscar != ""){   
            filtrarResultado(values.textoaBuscar.toLowerCase());
        }
    }

    if(highlights.length > 0){
        $(".PdfHighlighter").scrollTop(highlights[(elementToMove - 1) * cantidadPalabras].position.boundingRect.y1 + ((highlights[(elementToMove - 1) * cantidadPalabras].position.pageNumber - 1) * highlights[(elementToMove - 1) * cantidadPalabras].position.height) - 50);
    }

    const renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
            {Array.isArray(nodes.children)
            ? nodes.children.map((node) => renderTree(node))
            : null}
        </TreeItem>
    );

    return (
        <Create {...props} title={ translate('traducciones.titulos.resultadosBastanteo')} >
        <SimpleForm {...props} toolbar={ null }  >
        <div fullWidth className="row rowAnalizer">
            <div fullWidth className={classes.cont + " col-md-4"} >
            <SimpleForm  save={ handleSubmitSearch } toolbar= { <SaveButton label={translate('traducciones.botones.consultar')} icon= { <SearchIcon /> } /> } >
                <div fullWidth className="row">
                    <div className="col-md-9">
                        <TextInput onChange={() => {
                            setResaltados([]);
                        }} style={{ width: "100%" }} type="text" name={ "textoaBuscar" } resettable={ true } label={ "Ingrese su busqueda"} source={textoaBuscar} defaultValue= {textoaBuscar} />
                    </div>
                    <div className="col-md-2    " style={{padding:'10px'}}>
                        <Button disabled={ highlights != null && cantidadResultados > 1 && elementToMove != 1 ? false : true }
                        onClick = { () => {
                            setElementToMove(elementToMove - 1);
                        }}
                        >
                            <NavigateBeforeIcon />
                        </Button>
                    </div>
                    <div className="col-md-1" style={{padding:'10px'}}>
                        <Button disabled={ highlights != null && cantidadResultados > elementToMove ? false : true } 
                        onClick = { () => {
                            setElementToMove(elementToMove + 1);
                        }}
                        >
                            <NavigateNextIcon />
                        </Button>
                    </div>
                </div>   
            </SimpleForm>
            <TreeView
                className={classes.root}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                onNodeSelect={(event) => {
                    if($(event.target).hasClass("MuiTreeItem-label")){
                        setTextoaBuscar(event.target.innerHTML);
                        filtrarResultado(event.target.innerHTML.toLowerCase());
                    }
                }}
                >
                {renderTree(dataArbol)}
                </TreeView>
            </div>
            <div className={classes.cont + " col-md-7"}>
                <PdfLoader url={url} beforeLoad={<Loading />} onError= { (e) => { alert(e)}}>
                {
                (pdfDocument) => {
                    return (
                <PdfHighlighter
                    pdfDocument={pdfDocument}
                    enableAreaSelection={(event) => event.altKey}
                    scrollRef = {() => {
                    }
                    }
                    onScrollChange={resetHash}
                    highlightTransform={(
                        highlight,
                        index,
                        setTip,
                        hideTip
                        ) => {
                            const component = (
                                <Highlight
                                    isScrolledTo={false}
                                    position={highlight.position}
                                    comment={highlight.comment}
                                />
                            );
                            return (
                                <Popup
                                    popupContent={<HighlightPopup {...highlight} />}
                                    onMouseOver={(popupContent) =>
                                        setTip(highlight, (highlight) => popupContent)
                                    }
                                    onMouseOut={hideTip}
                                    key={index}
                                    children={component}
                                />
                            );
                        }}
                    highlights={highlights}
                />
                )
                }
            }
            </PdfLoader>
            </div>
        </div>
        </SimpleForm>
        </Create>
    )

}

export default DocumentAnalizer;