import React from 'react'
import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin'
import PlantillaCards from '../Components/PlantillaCards';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ComponentPAY } from '../Components/ComponentPay';
import '../Libs/ExternalViewer/Resources/Styles/Cards.css'

const Plantillas = () => {

  const dataProvider = useDataProvider();
  const [plantillas, setPlantillas] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [details, setDetails] = useState(null);
  const [openPay, setOpenPay] = useState(false);

  useEffect(() => {
    dataProvider.getList('plantillaNodos',{})
    .then((res)=>{
      setPlantillas(res.data)
    }).catch((err)=> console.log(err))
  }, [])

  const handleOpenDetails = (e,plan) =>{
    setOpenDetails(true);
    setDetails(plan);
  }

  if(plantillas == null){
    return(
      <div>
        Cargando plantilas...
      </div>
    )
  }

  return (
    <div>
        <div style={{paddingInline:'24px'}}>
            <h3>¡Descubre nuestras plantillas!</h3>
            <p style={{fontSize:'17px',fontFamily:'Arial, Helvetica, sans-serif'}}>¿No sabes cómo organizar la estructura de carpetas para tu empresa? En nuestro catálogo, encontrarás diversas plantillas específicas para tu industria o sector. Al adquirirlas, te proporcionaremos una plantilla completa con carpetas preconfiguradas y campos personalizados. Olvídate de la complejidad de crear la estructura desde cero; tendrás todo listo al instante para comenzar a cargar tus documentos de manera eficiente.</p>
        </div>
      <PlantillaCards dataTemplates={plantillas} handleOpenDetails={handleOpenDetails}/>
      
      {/* Dialog para mostrar detalles de la plantilla */}
      <Dialog open={openDetails}>
        <DialogContent>
            <div className='row' style={{maxWidth:'1000px'}}>
                <div className='col-md-5'>
                    <PlantillaCards dataTemplates={[details]} handleOpenDetails={handleOpenDetails}/>
                </div>
                <div className='col-md-4 list' style={{fontSize:'17px', marginLeft:'50px'}}>
                    <h2>Estrutura de la plantilla: </h2>
                    <ul>
                        {details != null && details.descriptions.map((d)=>{
                            return(
                                <li><span>{d.desc}</span></li>
                            )
                        })
                        }
                    </ul>
                </div>
            </div>
            </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpenDetails(false)} className='bg-danger'>Cerrar</Button>
            <Button onClick={() => {setOpenPay(true)}} color="primary">Comprar</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog para procesar pago */}
      <Dialog open={openPay}>
        <DialogTitle>Proceso de pago</DialogTitle>
        <DialogContent>
            <ComponentPAY item={details} />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpenPay(false)} className='bg-danger'>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Plantillas