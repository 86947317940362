//import { AUTH_LOGIN, AUTH_CHECK,AUTH_LOGOUT,AUTH_ERROR, DeleteWithUndoButton } from 'react-admin';
import jwt_decode from "jwt-decode";
import swal from 'sweetalert';
import '../Libs/ExternalViewer/Resources/Styles/shr.css'
import { clientData } from "../helpers/helpers";

export const AuthProvider = {
    
    //Funcion para realizar el inicio de session
    login: async ({ user, password }) => {
        //Obtenemos la Ip Publica
        const publicIp = require("react-public-ip");
        const ipv4 = await publicIp.v4() || "";

        //Obtenemos la url base de todas las apis que esta guardada en una variable global de JS en el index.
        const apiUrl = window.$parametros_generales.REACT_APP_BASE_URL;
        
        //Realizamos el Loguin
        const request = new Request(apiUrl + 'Login', {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json'}),
            body : JSON.stringify({
                "UserName": user,
                "Password": password,
                "MacAddress": "AC-D5-64-23-13-9D", //Estos datos estan hardcodeados por que que no se pueden obtener desde el cliente JS
                "StationName": "Dust-Elias", //Estos datos estan hardcodeados por que que no se pueden obtener desde el cliente JS
                "Ip": ipv4,
                "Client": "DEVELOPMENT" //Se debe cambiar este perfil.
            })
        })
        
        return fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .catch((error) => {
            throw new Error(error)
        })
        .then(({ Token, Message , Information}) => {
            if (Token) {
                if(Information.ClientData){
                    clientData.CustomerID = Information.ClientData.CustomerID
                    clientData.PlatformCustomerId = Information.ClientData.PlatformCustomerId
                }else{
                    clientData.CustomerID = 0
                    clientData.PlatformCustomerId = ''
                }
                //Si el login es correcto guardamos el token y todas las variables de session importantes para la plataforma.
                localStorage.setItem('token', Token);
                var decoded = jwt_decode(Token); 
                localStorage.setItem("userName", decoded.unique_name);
                localStorage.setItem("userRole", decoded.role); 
                localStorage.setItem("userNombre",decoded.unique_name);    
                localStorage.setItem("IdUsuario",decoded.IdUsuario); 
                const permisosGuias = {
                    querys: document.cookie == 'introjs-dontShowAgain=true' ? false : false,
                    carga: document.cookie == 'introjs-dontShowAgain=true' ? false : false,
                    admin: document.cookie == 'introjs-dontShowAgain=true' ? false : false,
                    estructura: document.cookie == 'introjs-dontShowAgain=true' ? false : false,
                }
                localStorage.setItem('activeTour', JSON.stringify(permisosGuias))                  
                const permisos = JSON.parse(decoded.Permisos).UserPermissions;
                var permissions = {};
                permisos.forEach(function (perm) {
                    permissions[perm.PermissionName.replaceAll(" ","")] = perm.Value; 
                });

                localStorage.setItem("permisos", JSON.stringify(permissions));                                 
                return Promise.resolve(true);                 
            }
            else{
                throw new Error(Message);        
            }
        });
    },
    checkError: (error) => {
        // console.log('checkError->',error);
        const status = error.status != undefined ? error.status : error.message.status;
        // console.log('statusCheck->',status);
        if (status === 401 || status === 403) {
            if(localStorage.getItem('token')){
                  if(error.message.message==undefined || error.message.message=='undefined' || error.message.message=='Failed to fetch'){
                    localStorage.clear();
                    window.location.reload();
                    return Promise.resolve();
                }
            }
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        //Verificacion permanente de se el token es valido.
        // return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
        const token = localStorage.getItem('token');
        if (!token) {
            return Promise.reject();
        }
        const { exp } = jwt_decode(token);
        const now = Date.now() / 1000;
        if (now > exp) {

            //Removemos las variables de session.
            localStorage.removeItem('token');
            localStorage.removeItem('userName');
            localStorage.removeItem('userRole');
            localStorage.removeItem('permisos');
            localStorage.removeItem('userNombre');
            localStorage.removeItem('IdUsuario');
            localStorage.removeItem('activeTour')

            window.location.href = "#/Login";
            setTimeout(() => {
                swal({
                        icon: "warning",
                        text:'Su sesión ha finalizado, ingrese de nuevo',
                      });
              }, 1500);
            return Promise.resolve();
        }
        return Promise.resolve();
        },
    logout: () => {
        //Funcion para realizar el cierre de session
        const apiUrl = window.$parametros_generales.REACT_APP_BASE_URL;    

        if(localStorage.getItem('token')  != null && localStorage.getItem('token')  != undefined){
            //Llamamos a la api para eliminar o cerrar la session.        
            const request = new Request(apiUrl + 'Sessions/logOut', {
                method: 'DELETE',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') })
            });      

            //Removemos las variables de session.
            localStorage.removeItem('token');
            localStorage.removeItem('userName');
            localStorage.removeItem('userRole');
            localStorage.removeItem('permisos');
            localStorage.removeItem('userNombre');
            localStorage.removeItem('IdUsuario');
            localStorage.removeItem('activeTour');

            try{
            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                .catch(error => {    
                    window.location.href = "#/Login";
                })
                .then(({ Success, Message }) => {
                    if(Success){
                        return Promise.resolve();              
                    }
                    else{
                        return Promise.resolve();        
                    }
                });
            }catch(e){
                return Promise.resolve(); 
            }
        }else{
            return Promise.resolve();
        }
    },
    getIdentity: () => { /* ... */ },
    getPermissions: () => {
        if(localStorage.getItem('token') != null || localStorage.getItem('token') != undefined ){
            //Verificacion comun en cada una de las ventanas para checkear los permisos y poder realizar actividades de seguridad.
            const token = localStorage.getItem('token');
            var decoded = jwt_decode(token);        
            const permisos = JSON.parse(decoded.Permisos).RolePermissions;
            var permissions = {};
            permisos.forEach(function (record) { 
                    var permisoLsit = {};
                    record.Permissions.forEach(function (perm) {
                        permisoLsit[perm.PermissionName.replaceAll(" ","")] = perm.Value; 
                    });
                    permissions[record.Module.replaceAll(" ","")] = permisoLsit;
            });
            
            return Promise.resolve(permissions);
        }else{
            return Promise.resolve();
        }
    }
};

/*
La documentacion de como crear un Auth provider personalizado de react admin es la siguiente:
https://marmelab.com/react-admin/doc/3.18/Authentication.html
*/