import * as React from 'react';
import { useState  } from 'react';
import {
    FormWithRedirect,
    TextInput ,
    useTranslate,
    SimpleForm,  
    Loading, 
    Error, 
    Create,
    Query, 
    SaveButton,
    useDataProvider,
    Pagination,
    useNotify,
    useDelete
} from 'react-admin';
import Button from '@material-ui/core/Button';
import FolderIcon from '@material-ui/icons/Folder';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SearchIcon from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/Warning';
import withWidth from '@material-ui/core/withWidth';
import CardResultado from './Responsive/CardResultado';
import ExternalViewer from '../Libs/ExternalViewer/ExternalViewer';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TableResultado from '../Components/TableResultado';
import MyLoading from '../Components/MyLoading';
import swal from 'sweetalert';


const useStyles = makeStyles({
    nodo : {
        padding: '6px',
        border: '1px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        width: 'max-content'
    },
    nodoSeleccionado :  {
        padding: '6px',
        border: '1px solid black',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        backgroundColor: "#319b4257",
        color: '#277233'
    }
});

/*
Funcion recursiva que crear la visualizacion y opciones de los nodos hijos
Esta funcion recibe como parametros:
nodo : nodo padre,
clases : objeto de clases css dinamico,
addnode : funcion para seleccionar cada nodo,
translate : funcion de react admin declarada en la clase principal para las traducciones
nodoPadreelemento: nodo seleccionado principal,
colapsar: funcion para colapsar o no el nodo
checknodoabierto: funcion para verificar el estado de colapso del nodo que se esta dibujando
*/
function dibujaNodosHijos(nodo,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto){
    if(nodo.hijos != undefined){
        return (
            <div>
                {nodo.hijos.map(
                (item) => {
                    checknodoabierto(item);
                    return (
                    <div  style={{ marginLeft: '40px', marginTop : '-5px'}}>
                        <div style={{ float: 'left' }}>
                            <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                        </div>
                        <div className={ nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > <FolderIcon style={{ marginRight: '5px'}} />
                            { item.TextoNodo }
                            <Button title={ translate('traducciones.titulos.consultarCampos')} onClick={() => addNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                <SearchIcon style={{ fontSize: '16px'}}  />
                            </Button>
                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                <ExpandLessIcon />
                            </Button>
                            }
                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                <ExpandMoreIcon />
                            </Button>
                            }
                        </div>
                        { !item.colapsado &&
                        <div>
                            { dibujaNodosHijos(item,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                        </div>
                        }
                    </div>
                )})
                }
            </div>
        )
    }
}

const BusquedaBastanteo = ({ width, ...props }) => {   

    const permisos = props.permissions ? props.permissions.ConsultadeDocumentos : null;  
    const checkPermiso = (permiso) => {
        if(permisos){
            return permisos[permiso];
        }
        return false;
    }

    const classes = useStyles();
    const [nodoPadreElemento, setnodoPadreElemento] = useState(null);    
    const [loadinga, setLoadinga] = useState(false);
    const [openNoResult, setOpenNoResult] = useState(false);
    const [openResult, setOpenResult] = useState(false);
    const [openDelete, setopenDelete] = useState(false);
    const [openDocument, setopenDocument] = useState(false);    
    const [document, setDocument] = useState(null);
    const translate = useTranslate();
    const [dataSearch, setDataSearch] = useState([]);
    const [datoBusqueda, setDatoBusqueda] = useState(null);
    const [visor, setVisor] = useState(null); 
    const notify = useNotify();
    const [deleteOne] = useDelete();
    
    const [randomUpdate, setRandomUpdate] = useState(null);
    const [datanodosAbiertos, setDatanodosAbiertos] = useState([]);
    const [openDeleteDocumento, setOpenDeleteDocumento] = useState(false);
    const [cargando,setCargando] = useState (false);
    const [allDocs,setAllDocs] = useState([]);

    const dataProvider = useDataProvider();

    const viewNode = (nodoPadre) => {
        setPage(1);
        setnodoPadreElemento(nodoPadre);
    }

    const handleCloseClick = () => {
        setOpenNoResult(false);
        setOpenResult(false);
        setopenDelete(false);
    }

    const handleCloseClickDocument = () => {
        setopenDocument(false);
    }

    //Paginación///////////////////////////////

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [order, setOrder] = useState("");
    const [sort, setSort] = useState(true);

    const changePage = (nPage) => {
        setDataSearch([])
        setPage(nPage);
        reloadPages(nPage,perPage);
    }
    const ChangePerPage = (rows) => {
        setPage(1);
        setPerPage(rows);   
        reloadPages(1,rows);
    }

    const cambiaOrden = (column) => {
        setOrder(column);
        reloadPages(null,null,!sort,column);
        setSort(!sort);
    }

    const reloadPages = (npage,nperpage,nsort,norder) => {
        setDataSearch([]);
        setCargando(true);
        // getDocuments(nodoPadreElemento.RutaNodo,datoBusqueda)
        dataProvider.getList('queryGeneral', { 
            IdNode: nodoPadreElemento != null ? nodoPadreElemento.RutaNodo : "/", 
            PageNumber : npage? npage : page,
            DocumentsNumber : nperpage ? nperpage : perPage,
            OrderField : norder ? norder : order,	
            AscendingOrder : nsort != null ? nsort : sort,
            Data: datoBusqueda,
        })
            .then((data) => {
                if(data != null && data.data.length == 0){
                    setCargando(false);
                    setOpenNoResult(true);
                }else{
                    if(data != null && data.data.length > 0){
                        setCargando(false);
                        setDataSearch(data);  
                        setOpenResult(true);
                    }
                }
            })
            .catch(error => {
        });   
    }

    const getDocuments = (nodo,d) =>{
        dataProvider.getList('queryGeneral', { 
            IdNode: nodo, 
            PageNumber : 1,
            DocumentsNumber : 50,
            OrderField : '',	
            AscendingOrder : true,
            Data: d,
        })
            .then((data) => {                
                if(data != null && data.data.length > 0){
                    setAllDocs(data)
                }
            })
            .catch(error => {

            });
    }

    ///////////////////////////////////////////

    if(loadinga){
        return <Loading />
    }

    const handleSubmitDelete = async values => {
        
    };

    const handleSubmitSearch = async values => {
        setAllDocs(null)
        setDatoBusqueda(values.campoGeneral);
        // getDocuments(nodoPadreElemento.RutaNodo,values.campoGeneral);
        dataProvider.getList('queryGeneral', { 
            IdNode: nodoPadreElemento != null ? nodoPadreElemento.RutaNodo : "/", 
            Data: values.campoGeneral,
            PageNumber : 1,
            DocumentsNumber : 10,
            OrderField : "",	
            AscendingOrder : true,
        })
            .then((data) => {
                if(data != null && data.data.length == 0){
                    
                    setOpenNoResult(true);
                }else{
                    if(data != null && data.data.length > 0){
                        getDocuments(nodoPadreElemento.RutaNodo,values.campoGeneral);
                        setDataSearch(data);  
                        setOpenResult(true);
                    }
                }
            })
            .catch(error => {
        });   
    }

    const consultaDocumento = (elem) => {
         //Obtenemos la url completa y sustituimos el pathName por el pathname del visor con el parametro del id del documento
         const urlFull = window.location.href;
         const pathName = props.location.pathname;
         let urlHost = urlFull.replace(pathName,`/visor?id_document=${elem.IdDocument}`)
         
         /*
         La funcion consulta documento se cambio  por esta linea para abrir una nueva ventana  y mostrar el visor, se obtine el nombre del host y se pasa 
         el id del documento como parametro en la URL para su posterior consulta.
          */
         window.open(urlHost, '_blank', 'fullscreen=yes');
    }

    var count = 0;

    const saveAnotations = ()=>{
        var anotations = visor.getAnnotations();
        if(anotations.length > 0){
            dataProvider.update('anotaciones', { DocumentId: document.IdDocument,Annotations:anotations})
                .then(({ data }) => {
                    swal({
                        icon:'success',
                        text: translate('traducciones.notificaciones.actualizaAnotacionesOK')
                    })
                })
                .catch(error => {
            });   
        }
    }

    const closeExternal = () =>{
        setOpenResult(true);
        setopenDocument(false);
    }

    const colapsar = (nodo) => {
        setRandomUpdate(Math.random());
        if(nodo.colapsado){
            datanodosAbiertos.push(nodo.RutaNodo)
            setDatanodosAbiertos(datanodosAbiertos);
        }else{
            setDatanodosAbiertos(datanodosAbiertos.filter(function(nodoguardado) { 
                return nodoguardado !== nodo.RutaNodo;
            }));
        }
        nodo.colapsado = !nodo.colapsado;
    }

    const checknodoabierto = (nodo) => {
        if(datanodosAbiertos.includes(nodo.RutaNodo)){
            nodo.colapsado = false;
        }
    }

    const deleteDocument = (doc) =>{
        setDocument(doc);
        setOpenDeleteDocumento(true);
    }


    const handleSubmitDeleteDocument = async values => {
        deleteOne('documento', document.IdDocument, document,{
            onSuccess: ({ data }) => {
                changePage(page);
                setOpenDeleteDocumento(false);
                setLoadinga(false);
            },
            onFailure: ({ error }) => {
                setLoadinga(false);
            }
        });        
    };

    const handleCloseClick2 = () => {
        setOpenDeleteDocumento(false);
    }

    let startIndex = (page - 1) * perPage;
    return (
        <Create {...props} title={ translate('traducciones.titulos.busquedaBastanteo')} >
            <SimpleForm {...props} toolbar={ null }  >

            { openDocument ? (     
            <ExternalViewer closeExternal = { closeExternal } saveAnotations= {saveAnotations} />
            ):(

            <div fullWidth style={{minHeight:'600px'}}>
                
                <div style={{ padding: '10px'}}>       
                    <Query type="getList" resource="nodos" payload={ nodoPadreElemento }>
                        {({ data, loading, error }) => {
                            if (loading) { return <Loading />; }
                            if (error) { return <Error />; }
                            return (
                                <div  className="row">
                                    <div  className="col-md-5 col-md-custom"  style={{ overflowX: "auto", padding: "10px"}}>
                                    {   data.map(
                                        (item) => {
                                            checknodoabierto(item);
                                            return (
                                            <div>
                                                <div className= { nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > 
                                                    <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                                    { item.TextoNodo } 
                                                    <Button title={ translate('traducciones.titulos.consultarCampos')}  onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                                        <SearchIcon style={{ fontSize: '16px'}}/>
                                                    </Button>
                                                    { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                        <ExpandLessIcon />
                                                    </Button>
                                                    }
                                                    { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                                    <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                        <ExpandMoreIcon />
                                                    </Button>
                                                    }
                                                </div>
                                                { !item.colapsado &&
                                                <div style={{ marginLeft: '-20px'}}>
                                                    { dibujaNodosHijos(item,classes,viewNode,translate,nodoPadreElemento,colapsar, checknodoabierto) }
                                                </div>
                                                }
                                            </div>
                                        )})
                                    }
                                    </div>
                                    {
                                        nodoPadreElemento != null ? (
                                    <div  className="col-md-7 col-md-custom">
                                        <div>
                                            <div>
                                                {translate('traducciones.titulos.camposResultados')}
                                            </div>
                                            <SimpleForm save={ handleSubmitSearch } toolbar= { <SaveButton label={translate('traducciones.botones.consultar')} icon= { <SearchIcon /> } /> } >
                                                <div className="row">
                                                <TextInput style={{ width: "100%" }} type="text" name={ "campoGeneral" } resettable={ true } label={ "Campo valor general"} source="valorGeneral" value="" defaultValue="" />
                                                </div>   
                                            </SimpleForm>  
                                        </div>    
                                    </div>
                                    ) : null }
                                </div>
                            );
                        }}
                    </Query>
                </div> 
            </div>     

            )}

            <Dialog
                fullWidth
                open={openNoResult}>
                <DialogTitle>{ translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                <DialogContent>
                    <WarningIcon style={{ color: '#c18a2d', fontSize: '60px'}} /> { translate('traducciones.modal.noResults')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClick} >
                        { translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog>   


            <Dialog
                fullWidth
                open={openResult}>
                <DialogTitle>{ translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                <DialogContent>
                    {cargando ? <MyLoading style={{marginTop:'-20px'}}/> : width != "xs" ? 
                    (
                      <TableResultado 
                      dataSearch= { dataSearch } 
                      consultaDocumento = { consultaDocumento } 
                      deleteDocument = { deleteDocument} 
                      cambiaOrden = { cambiaOrden } 
                      order = {order}
                      sort = { sort }
                      checkPermiso = { checkPermiso }
                      perPage= {perPage} 
                      setPage= {changePage} 
                      setPerPage = {ChangePerPage} 
                      page={page}
                      allDocuments={allDocs}
                  />  
                    ) : ( 
                        <div>
                        {
                        dataSearch && dataSearch.data && dataSearch.data.map(elem => {
                                count++;
                                startIndex++;
                                return (  
                                    <CardResultado dataSearch= {dataSearch} i = { startIndex } cambiaOrden= {cambiaOrden} order= {order} sort={sort} elem = {elem} />
                                )                
                            })
                         }
                        <Pagination perPage= {perPage} setPage= {changePage} setPerPage = {ChangePerPage} page={page} total= {dataSearch.total} />
                        </div>)}
                    {/* { width != "xs" ? (
                    <TableResultado 
                        dataSearch= { dataSearch } 
                        consultaDocumento = { null } 
                        deleteDocument = { deleteDocument} 
                        cambiaOrden = { cambiaOrden } 
                        order = {order}
                        sort = { sort }
                        checkPermiso = { checkPermiso }
                        perPage= {perPage} 
                        setPage= {changePage} 
                        setPerPage = {ChangePerPage} 
                        page={page}
                        esBastanteo = { true }
                    />
                    ) : ( 
                        <div>
                        {
                        dataSearch && dataSearch.data && dataSearch.data.map(elem => {
                                count++;
                                return (  
                                    <CardResultado dataSearch= {dataSearch} i = {count } cambiaOrden= {cambiaOrden} order= {order} sort={sort} elem = {elem} />
                                )                
                            })
                         }
                        <Pagination perPage= {perPage} setPage= {changePage} setPerPage = {ChangePerPage} page={page} total= {dataSearch.total} />
                        </div>) }  */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClick} >
                        { translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog>     

                 


            <Dialog
                fullWidth
                    open={openDelete}
                    >
                    <DialogTitle>{ translate('traducciones.modal.eliminarNodo')}</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmitDelete}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            { translate('traducciones.modal.eliminarNodoConfirm')}
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.confirm"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                                icon = { <DeleteIcon /> }
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>   

                <Dialog
                    fullWidth
                    open={openDeleteDocumento}
                    >
                    <DialogTitle>{ translate('traducciones.modal.eliminarDocumento')}</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmitDeleteDocument}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            { translate('traducciones.modal.eliminarDocumentoConfirm')}
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.confirm"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                                icon = { <DeleteIcon /> }
                            />
                            <Button onClick={handleCloseClick2} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>  
     

            </SimpleForm>
        </Create>
)};

export default withWidth()(BusquedaBastanteo);
