import * as React from 'react';
import { useState , useEffect } from 'react';
import {
    FormWithRedirect,
    useDelete,
    useTranslate,
    SimpleForm,  
    Loading, 
    Error, 
    Create,
    Query, 
    SaveButton,
    useDataProvider,
    Pagination,
    useNotify,
    AutocompleteArrayInput
} from 'react-admin';
import Button from '@material-ui/core/Button';
import FolderIcon from '@material-ui/icons/Folder';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SearchIcon from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/Warning';
import withWidth from '@material-ui/core/withWidth';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardResultado from './Responsive/CardResultado';
import ExternalViewer from '../Libs/ExternalViewer/ExternalViewer';
import TableResultado from '../Components/TableResultado';
import MyLoading2 from '../Components/MyLoading2';
import '../Libs/ExternalViewer/Resources/Styles/digitalizarStyles.css'
import BuscadorNodos from '../Components/BuscadorNodos';
import NoAccess from '../Components/NoAccess';
import DownloadButton from '../Components/DownloadButton';
import swal from 'sweetalert';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import Loader from '../Components/Loader';

const useStyles = makeStyles({
    nodo : {
        padding: '6px',
        border: '1px solid #319b4252',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        width: 'max-content'
    },
    nodoSeleccionado :  {
        padding: '6px',
        border: '1px solid black',
        borderRadius: '10px',
        display: 'table',
        marginBottom : '15px',
        fontSize: '14px',
        backgroundColor: "#319b4257",
        color: '#277233'
    }
});

/*
Funcion recursiva que crear la visualizacion y opciones de los nodos hijos
Esta funcion recibe como parametros:
nodo : nodo padre,
clases : objeto de clases css dinamico,
addnode : funcion para seleccionar cada nodo,
translate : funcion de react admin declarada en la clase principal para las traducciones
nodoPadreelemento: nodo seleccionado principal,
colapsar: funcion para colapsar o no el nodo
checknodoabierto: funcion para verificar el estado de colapso del nodo que se esta dibujando
*/
function dibujaNodosHijos(nodo,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto){
    if(nodo.hijos != undefined){
        return (
            <div>
                {nodo.hijos.map(
                (item) => {
                    checknodoabierto(item);
                    return (
                    <div  style={{ marginLeft: '40px', marginTop : '-5px'}}>
                        <div style={{ float: 'left' }}>
                            <SubdirectoryArrowRightIcon style={{ fontSize: '30px' }}/>
                        </div>
                        <div className={ nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo } > <FolderIcon style={{ marginRight: '5px'}} />
                            { item.TextoNodo }
                            <Button title={ translate('traducciones.titulos.consultarCampos')} onClick={() => addNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                <SearchIcon style={{ fontSize: '16px'}}  />
                            </Button>
                            { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                <ExpandLessIcon />
                            </Button>
                            }
                            { item.colapsado && item.hijos && item.hijos.length > 0 &&
                            <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                <ExpandMoreIcon />
                            </Button>
                            }
                        </div>
                        { !item.colapsado &&
                        <div>
                            { dibujaNodosHijos(item,classes,addNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                        </div>
                        }
                    </div>
                )})
                }
            </div>
        )
    }
}

const BusquedaNavegar = ({ width, ...props }) => {  
    
    const permisos = props.permissions ? props.permissions.ConsultadeDocumentos : null;  
    const checkPermiso = (permiso) => {
        if(permisos){
            return permisos[permiso];
        }
        return false;
    }
    const access= JSON.parse(localStorage.getItem('permisos'))
    const inTour = JSON.parse(localStorage.getItem('activeTour'))

    const classes = useStyles();
    const [nodoPadreElemento, setnodoPadreElemento] = useState(null);    
    const [loadinga, setLoadinga] = useState(false);
    const [openNoResult, setOpenNoResult] = useState(false);
    const [openResult, setOpenResult] = useState(false);
    const [openDelete, setopenDelete] = useState(false);
    const [openDocument, setopenDocument] = useState(false);
    const [documentt, setDocument] = useState(null);
    const [deleteOne] = useDelete();
    const translate = useTranslate();
    const [dataSearch, setDataSearch] = useState(null);
    const [visor, setVisor] = useState(null); 
    const notify = useNotify();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [order, setOrder] = useState("");
    const [sort, setSort] = useState(true);
    const [randomUpdate, setRandomUpdate] = useState(null);
    const [datanodosAbiertos, setDatanodosAbiertos] = useState([]);
    const [openDeleteDocumento, setOpenDeleteDocumento] = useState(false);
    const dataProvider = useDataProvider();


    const [dataUsers, setDataUsers] = useState(null);
    const [dataGrupos, setDataGrupos] = useState(null);
    const [openEditUser, setOpenEditUser] = useState(false); 
    const [dataUsersSeleccionados, setDataUsersSeleccionados] = useState(null);
    const [dataGruposSeleccionados, setDataGruposSeleccionados] = useState(null);
    const [cargando,setCargando] = useState (false);
    const [allDocs,setAllDocs] = useState(null);
    const [nodoBuscado,setNodoBuscado] = useState(null);
    const [nodos,setNodos] = useState(null);
    const [openNodosEncontrados,setOpenNodosEncontrados] = useState (false)
    const [resultadoNodos,setResultadoNodos] = useState([])
    const [lastNode,setLastNode] = useState(null);
    const [isCheck, setIsCheck] = useState([]);
    const [todosSeleccionados, setTodosSeleccionados] = useState(false);
    const [allReady, setAllReady] = useState(1);
    const [openEspera, setOpenEspera] = useState(false);

    let introHelp;
    introHelp = introJs('#root');
    introHelp.setOptions({
        disableInteraction: false,
        doneLabel: translate('traducciones.introBotones.doneLabel'),
        nextLabel: translate('traducciones.introBotones.nextLabel'),
        prevLabel: translate('traducciones.introBotones.prevLabel'),
        tooltipPosition:'top',
        steps: [
                {
                intro: translate('traducciones.introHelp.intro1'),
                title: translate('traducciones.introHelp.title1'),
                element: '#help'
                },
        ]                
    });

    const showHelp = () =>{
        const offPermisos = {
            querys: false,
            carga: false,
            admin: false,
            estructura: false,
        }
        if(document.cookie == 'introjs-dontShowAgain=true'){
            document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
                setTimeout(()=>{
                    introHelp.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                       localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).onexit(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                       localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).start();                         
                },500)
        }
    }

    function crearCookie(nombre, valor, diasParaExpirar) {
    var fechaExpiracion = new Date();
    fechaExpiracion.setDate(fechaExpiracion.getDate() + diasParaExpirar);
    var cookie = nombre + '=' + valor + '; expires=' + fechaExpiracion.toUTCString() + '; path=/';
    document.cookie = cookie;
    }

    let introNodos = introJs();
    useEffect(() => {
        if(inTour.querys){
            setTimeout(()=>{
                introNodos = introJs('#search-box');
                introNodos.setOptions({
                    disableInteraction: false,
                    doneLabel: translate('traducciones.introBotones.doneLabel'),
                    nextLabel: translate('traducciones.introBotones.nextLabel'),
                    prevLabel: translate('traducciones.introBotones.prevLabel'),
                    tooltipPosition:'top',
                    steps: [
                        {
                            intro: translate('traducciones.introNavegar.intro1'),
                            title: translate('traducciones.introNavegar.title1')
                        },
                        {
                            intro: translate('traducciones.introNavegar.intro2'),
                            title: translate('traducciones.introNavegar.title2'),
                            element: '#nodo',
                            disableInteraction: true
                        },
                        {
                            intro: translate('traducciones.introNavegar.intro3'),
                            title: translate('traducciones.introNavegar.title3'),
                            element: '#more'
                        },
                        {
                            intro: translate('traducciones.introNavegar.intro4'),
                            title: translate('traducciones.introNavegar.title4'),
                            element: '#btnSearch'
                        },
                    ]                
                  });
        
                introNodos.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).onexit(()=>{showHelp()}).oncomplete(()=>{showHelp()}).start();
            },1000)
        }
      }, []);

    const onToggleItem = (e) => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    }

    const handleSelectAll = () => {
        setTodosSeleccionados(!todosSeleccionados);
        if(dataSearch && dataSearch.data){
            let marcas = dataSearch.data.map(li => li.IdDocument + "");
            setIsCheck(marcas);
        }

        if (todosSeleccionados) {
          setIsCheck([]);
        }
    };

    const handleSubmitEditUsers = async values => {
        setLoadinga(true);
        dataProvider.update('accessDocument', { id: documentt.IdDocument , Users : values.usuarios, Grupos : values.grupos })
        .then(({ data }) => {
            setLoadinga(false);
            setOpenEditUser(false);
        })
        .catch(error => {
            setLoadinga(false);
        });  
    };

    const changePage = (nPage) => {
        if(allReady != 2){
            setDataSearch(null)
            setPage(nPage);
            reloadPages(nodoPadreElemento,nPage,perPage);
        }else{
            setOpenEspera(true)
        }
    }
    const ChangePerPage = (rows) => {
        // setPage(1);
        setPerPage(rows);       
        reloadPages(nodoPadreElemento,page,rows);
    }

    const cambiaOrden = (column) => {
        setOrder(column);
        reloadPages(nodoPadreElemento,null,null,!sort,column);
        setSort(!sort);
    }

    const reloadPages = (nodoPadre,npage,nperpage,nsort,norder) => {  
        const startIndex = (npage - 1) * nperpage;
        const endIndex = startIndex + nperpage;
        if(endIndex > allDocs.data.length){
            setCargando(true)
        
            dataProvider.getList('queryDocument', { 
                    IdNode: nodoPadre != null ? nodoPadre.RutaNodo : "/",
                    PageNumber : 1,
                    DocumentsNumber : endIndex + 100,
                    OrderField : norder ? norder : order,	
                    AscendingOrder : nsort != null ? nsort : sort
                })
                .then((data) => {
                    if(data != null && data.data.length == 0){
                        setCargando(false)
                        setOpenNoResult(true);
                        setDataSearch(null);
                    }else{
                        setAllDocs(data)
                        const dataSlice = {
                            data: data.data.slice(startIndex,endIndex),
                            porcentaje: data.porcentaje,
                            total: data.total
                        }
                        setCargando(false)
                        setDataSearch(dataSlice);
                        setOpenResult(true)
                    }
                })
                .catch(error => {
            });   
        }else{
            const dataSlice = {
                data: allDocs.data.slice(startIndex, endIndex),
                porcentaje: allDocs.porcentaje,
                total: allDocs.total
            }
            setDataSearch(dataSlice)
        }
    }

    const viewNode = (nodoPadre,npage,nperpage,nsort,norder) => {  
        if(allReady == 2){setOpenEspera(true)}
        if(!cargando && allReady != 2){
            introNodos.exit();
            setAllDocs(null)
            setDataSearch(null)
            setCargando(true)
            setPage(1)
            setPerPage(10)
    
            dataProvider.getList('queryDocument', { 
                    IdNode: nodoPadre != null ? nodoPadre.RutaNodo : "/",
                    PageNumber : 1,
                    DocumentsNumber : 100,
                    OrderField : norder ? norder : order,	
                    AscendingOrder : nsort != null ? nsort : sort
                })
                .then((data) => {
                    setnodoPadreElemento(nodoPadre);
                    if(data != null && data.data.length == 0){
                        setCargando(false)
                        setOpenNoResult(true);
                        setDataSearch(null);
                    }else{
                        if(data != null && data.data && data.data.length > 0){
                            setAllDocs(data)
                            const dataSlice = {
                                data: data.data.slice(0,10),
                                porcentaje: data.porcentaje,
                                total: data.total
                            }
                            setCargando(false)
                            setDataSearch(dataSlice);
                            setOpenResult(true)  
                        }
                    }
                })
                .catch(error => {
            });   
        }else if(cargando){
            swal({
                text: 'Espere un momento por favor...',
                icon: 'info'
            })
        }
    }

    const getAllDocuments = (total) => {
        setAllReady(2)
        dataProvider.getList('queryDocument', { 
            IdNode: nodoPadreElemento ? nodoPadreElemento.RutaNodo : "/",
            PageNumber : 1,
            DocumentsNumber : total,
            OrderField : order,	
            AscendingOrder : sort
        })
        .then((data) => {
            setAllDocs(data)
            setAllReady(3)
            setOpenEspera(false)
        })
        .catch(error => {
    });
    }

    const resetAllReady = () =>{
        setAllReady(1)
    }

    const handleCloseClick = () => {
        setOpenNoResult(false);
        setOpenResult(false);
        setopenDelete(false);
        setOpenDeleteDocumento(false);
        setOpenEditUser(false);
        setOpenNodosEncontrados(false)
    }

    const saveAnotations = ()=>{
        var anotations = visor.getAnnotations();
        if(anotations.length > 0){
            dataProvider.update('anotaciones', { DocumentId: documentt.IdDocument,Annotations:anotations})
                .then(({ data }) => {
                    swal({
                        icon:'success',
                        text: translate('traducciones.notificaciones.actualizaAnotacionesOK')
                    })
                })
                .catch(error => {
            });   
        }
    }

    const closeExternal = () =>{
        setopenDocument(false);
    }

    if(loadinga){
        return <Loading />
    }

    const deleteDocument = (doc) =>{
        setDocument(doc);
        setOpenDeleteDocumento(true);
    }

    const handleSubmitDeleteDocument = async values => {
        deleteOne('documento', documentt.IdDocument, documentt,{
            onSuccess: ({ data }) => {
                changePage(page);
                setOpenDeleteDocumento(false);
                setLoadinga(false);
            },
            onFailure: ({ error }) => {
                setLoadinga(false);
            }
        });        
    };

    const consultaDocumento = (elem) => {
         //Obtenemos la url completa y sustituimos el pathName por el pathname del visor con el parametro del id del documento
         const urlFull = window.location.href;
         const pathName = props.location.pathname;
         let urlHost = urlFull.replace(pathName,`/visor?id_document=${elem.IdDocument}`)
         
         /*
         La funcion consulta documento se cambio  por esta linea para abrir una nueva ventana  y mostrar el visor, se obtine el nombre del host y se pasa 
         el id del documento como parametro en la URL para su posterior consulta.
          */
         window.open(urlHost, '_blank', 'fullscreen=yes');
    } 


    const colapsar = (nodo) => {
        setRandomUpdate(Math.random());
        if(nodo.colapsado){
            datanodosAbiertos.push(nodo.RutaNodo)
            setDatanodosAbiertos(datanodosAbiertos);
        }else{
            setDatanodosAbiertos(datanodosAbiertos.filter(function(nodoguardado) { 
                return nodoguardado !== nodo.RutaNodo;
            }));
        }
        nodo.colapsado = !nodo.colapsado;
    }

    const checknodoabierto = (nodo) => {
        if(datanodosAbiertos.includes(nodo.RutaNodo)){
            nodo.colapsado = false;
        }
    }

    var count = 0;

    const desplegarNodos = (nodo) => {
        if (nodo.lvl) {
            let nivel = nodo[`n${nodo.lvl}`]
            const elementos = nivel.RutaNodo.split("/").filter((elemento) => elemento !== "");
            const arrayRuta = elementos.map((_, index) => ({
                RutaNodo: `/${elementos.slice(0, index + 1).join("/")}/`,
                colapsado: true,
            }));
            arrayRuta.map((e) => { colapsar(e) })
            setOpenNodosEncontrados(false)
            setnodoPadreElemento(nivel)
        }
    }

    const handleDataUpdated = (nuevaData) => {
        desplegarNodos(nuevaData)
        setOpenNodosEncontrados(false)

    };
    
    let startIndex = (page - 1) * perPage;
    return (
        <Create {...props} title={ translate('traducciones.titulos.consultaNavegacion')} >
            <SimpleForm {...props} toolbar={ null }  >
                { !access.CONSULTANAVEGAR ? (
                    <NoAccess/>
                ):(
                <div fullWidth style={{minHeight:'600px'}}>
                    <div style={{ padding: '10px'}}>
                        <BuscadorNodos onDataUpdated={handleDataUpdated} data={nodos} nodoPadreElemento={nodoPadreElemento} viewNode={viewNode} colapsar={colapsar} {...props}/> 
                        <Query type="getList" resource="nodos" payload={ nodoPadreElemento }>
                            {({ data, loading, error }) => {
                                if (loading) { return <Loading />; }
                                if (error) { return <Error />; }
                                return (
                                    <div  className="row">
                                        <div  className="col-md-5 col-md-custom"  style={{ overflowX: "auto", padding: "10px"}}>
                                            {setNodos(data)}
                                        {   data.map(
                                            (item) => {
                                            checknodoabierto(item);
                                            return (
                                                <div>
                                                    <div className= { nodoPadreElemento != null && nodoPadreElemento.RutaNodo == item.RutaNodo ? classes.nodoSeleccionado : classes.nodo }  id={item.RutaNodo}> 
                                                        <div id='nodo'>
                                                        <FolderIcon style={{ marginRight: '5px', color : '#5c9d66'}} /> 
                                                        { item.TextoNodo } 
                                                        <Button id='btnSearch' title={ translate('traducciones.titulos.consultarCampos')}  onClick={() => viewNode(item)} style={{ marginLeft: "5px", padding: 4}}>
                                                            <SearchIcon style={{ fontSize: '16px'}}/>
                                                        </Button>
                                                        { !item.colapsado && item.hijos && item.hijos.length > 0  &&
                                                        <Button style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.colapsar')} >
                                                            <ExpandLessIcon />
                                                        </Button>
                                                        }
                                                        { item.colapsado && item.hijos && item.hijos.length > 0 &&
                                                        <Button id='more' style={{ marginLeft: "5px", padding: 0}} onClick={() => colapsar(item) } title={ translate('traducciones.titulos.mostrar')} >
                                                            <ExpandMoreIcon />
                                                        </Button>
                                                        }
                                                        </div>
                                                    </div>
                                                    { !item.colapsado &&
                                                    <div style={{ marginLeft: '-20px'}}>
                                                        { dibujaNodosHijos(item,classes,viewNode,translate,nodoPadreElemento,colapsar,checknodoabierto) }
                                                    </div>
                                                    }
                                                    
                                                </div>
                                            )})
                                        }
                                        </div>
                                        <div  className="col-md-7 col-md-custom">
                                        {cargando ? <MyLoading2 style={{marginTop:'-20px'}}/> : ''}
                                        { dataSearch ? (
                                        <div >
                                            { width != "xs" ? (
                                            <TableResultado 
                                                dataSearch= { dataSearch } 
                                                consultaDocumento = { consultaDocumento } 
                                                deleteDocument = { deleteDocument} 
                                                cambiaOrden = { cambiaOrden } 
                                                order = {order}
                                                sort = { sort }
                                                checkPermiso = { checkPermiso }
                                                perPage= {perPage} 
                                                setPage= {changePage} 
                                                setPerPage = {ChangePerPage} 
                                                page={page}
                                                allDocuments={allDocs}
                                                getAllDocuments={getAllDocuments}
                                                allReady={allReady}
                                                resetAllReady = {resetAllReady}
                                            />
                                            ) : ( 
                                            <div>
                                            {
                                            dataSearch && dataSearch.data && dataSearch.data.map(elem => {
                                                    count++;
                                                    startIndex++;
                                                    return (  
                                                        <CardResultado dataSearch= {dataSearch} i = { startIndex } consultaDocumento= { consultaDocumento } cambiaOrden= {cambiaOrden} order= {order} sort={sort} elem = {elem} onToggleItem={onToggleItem} todosSeleccionados={todosSeleccionados} isCheck={isCheck}/>
                                                    )                
                                                })
                                            }
                                                <div className='row'>
                                                <div className='col-md-6' style={{
                                                    minHeight: '52px',
                                                    paddingRight: '2px',
                                                    paddingTop: '10px',
                                                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                                    fontWeight: '400',
                                                    lineHeight: '1.43',
                                                    letterSpacing: '0.01071em',
                                                    fontSize: '14px !important'
                                                }}>
                                                     { translate('traducciones.titulos.porcentaje')}<strong>{dataSearch.porcentaje}%</strong>
                                                </div>
                                                <div className='col-md-6'>
                                                <input type='checkbox'
                                                    style={{padding: "0px", marginBottom:'10px'}}
                                                    id='checkall'
                                                    name='checkall'
                                                        checked={todosSeleccionados}
                                                        onClick={handleSelectAll}
                                                />
                                                <span style={{marginLeft:'10px', color: 'rgb(92, 157, 102)'}}><b>{translate('traducciones.campos.checkMarcar')}</b></span>
                                                <DownloadButton isCheck={isCheck} dataSearch={dataSearch} allDocuments={allDocs} todosSeleccionados={todosSeleccionados}/>
                                                    <Pagination perPage= {perPage} setPage= {changePage} setPerPage = {ChangePerPage} page={page} total= {dataSearch.total} />
                                                </div>
                                            </div>
                                            </div>) }
                                            
                                        </div>  
                                        ) : null
                                        }
                                        </div>
                                    </div>
                                );
                            }}
                        </Query>
                    </div> 
                </div>
                )}     
                <Dialog
                    open={openNoResult}>
                    <DialogTitle>{ translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                    <DialogContent>
                        <WarningIcon style={{ color: '#c18a2d', fontSize: '60px'}} /> { translate('traducciones.modal.noResults')}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseClick} >
                            { translate('ra.action.close')}
                        </Button>
                    </DialogActions>
                </Dialog>   

                { openDocument ? (     
                <ExternalViewer closeExternal = { closeExternal } saveAnotations= {saveAnotations} />
                ): null }   

                <Dialog
                    fullWidth
                    open={openDeleteDocumento}
                    >
                    <DialogTitle>{ translate('traducciones.modal.eliminarDocumento')}</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmitDeleteDocument}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            { translate('traducciones.modal.eliminarDocumentoConfirm')}
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.confirm"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                                icon = { <DeleteIcon /> }
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog> 

                <Dialog
                    fullWidth
                    open={openEditUser}
                    >
                    <DialogTitle>{ translate('traducciones.modal.editarUsuariosDocumento') }</DialogTitle>
                    <FormWithRedirect
                    resource="nodos"
                    save={handleSubmitEditUsers}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                        <DialogContent>
                            {dataUsers ? (
                            <AutocompleteArrayInput options= { {fullWidth: true } }  optionText="Nombre" defaultValue={ dataUsersSeleccionados } optionValue="Nombre" source="usuarios" choices= { dataUsers ? dataUsers : null } style={{width:'100%'}} />
                            ) : null }
                            {dataUsers ? (
                            <AutocompleteArrayInput options= { {fullWidth: true } }  optionText="GrupoNombre" defaultValue={ dataGruposSeleccionados } optionValue="GrupoNombre" source="grupos" choices= { dataGrupos ? dataGrupos : null } style={{width:'100%'}} />
                            ) : null }
                        </DialogContent>
                        <DialogActions>
                            <SaveButton
                                label="ra.action.save"
                                handleSubmitWithRedirect={
                                    handleSubmitWithRedirect
                                }
                                pristine={pristine}
                                saving={saving}
                            />
                            <Button onClick={handleCloseClick} >
                                { translate('ra.action.cancel')}
                            </Button>
                        </DialogActions>
                        </>
                    )}
                />
                </Dialog>
                <Dialog open={openEspera}>
                    <DialogContent>
                        <div>
                        <Loader/>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setOpenEspera(false)}>
                            { translate('ra.action.close')}
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* <Dialog
                fullWidth
                open={openResult}>
                <DialogTitle>{ translate('traducciones.titulos.resultadoBusqueda')}</DialogTitle>
                <DialogContent>
                {cargando ? <MyLoading2 style={{marginTop:'-20px'}}/> : width != "xs" ? 
                    (
                      <TableResultado 
                      dataSearch= { dataSearch } 
                      consultaDocumento = { consultaDocumento } 
                      deleteDocument = { deleteDocument} 
                      cambiaOrden = { cambiaOrden } 
                      order = {order}
                      sort = { sort }
                      checkPermiso = { checkPermiso }
                      perPage= {perPage} 
                      setPage= {changePage} 
                      setPerPage = {ChangePerPage} 
                      page={page}
                      allDocuments={allDocs}
                  />  
                    ) : ( 
                        <div>
                        {
                        dataSearch && dataSearch.data && dataSearch.data.map(elem => {
                                count++;
                                startIndex++;
                                return (  
                                    <CardResultado dataSearch= {dataSearch} i = {startIndex} cambiaOrden= {cambiaOrden} order= {order} sort={sort} elem = {elem} onToggleItem={onToggleItem} todosSeleccionados={todosSeleccionados} isCheck={isCheck} />
                                )                
                            })
                         }
                        <input type='checkbox'
                            style={{padding: "0px", marginBottom:'10px'}}
                            id='checkall'
                            name='checkall'
                                checked={todosSeleccionados}
                                onClick={handleSelectAll}
                        />
                        <span style={{marginLeft:'10px', color: 'rgb(92, 157, 102)'}}><b>{translate('traducciones.campos.checkMarcar')}</b></span>
                        <DownloadButton isCheck={isCheck} dataSearch={dataSearch} allDocuments={allDocs} todosSeleccionados={todosSeleccionados}/>
                        <Pagination perPage= {perPage} setPage= {changePage} setPerPage = {ChangePerPage} page={page} total= {dataSearch.total} />
                        </div>)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClick} >
                        { translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog> */}
            </SimpleForm>
        </Create>
)};

export default withWidth()(BusquedaNavegar);
