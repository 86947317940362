import * as React from 'react';
import { useState , useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { getPlans } from '../../src/DataProviders/GlobalDataProvider'
import { formatCurrency, formatText } from '../helpers/helpers'
import { Loading } from 'react-admin';


export function Prices({ onClick , setFlag, type, item}) {
  const defaultTheme = createTheme();
  const cardStyle = { cursor: 'pointer'};
  
  const [selectedCard, setSelectedCard] = useState(null);
  const [plans, setPlans] = useState(null);

  useEffect(async () => {
    if(type == 1){
        setPlans({
            DollarPriceMXN: 18.25,
            MaximagePlans: [
                {
                    IdPlan: item.IndustryId,
                    PlanName: 'Plantilla - ' + item.IndustryName,
                    Price: 14.99,
                    Validity: "Unico",
                    Description: 'Creación de plantilla con nodos, subnodos y campos personalizados.',
                    PlanItems: [
                        {
                            ItemId: 1,
                            ItemName: 'Plantilla - '+ item.IndustryName,
                            ItemInformation: item
                        }
                    ]
                },
            ]
        })
    }
    //   try {
    //     const response = await getPlans();
    //       setPlans(response.Information)
    //   } catch (error) {
    //       console.log(error);
    //   }
  }, []);

  function dataExtract(data) {
    onClick({ data : data, dollar : plans.DollarPriceMXN})
    setSelectedCard(data.Price)
  }

  useEffect(() => {
    if (plans) {
      setFlag(true)
    }
  }, [plans])

  if (!plans) {
    return <Loading />
  }

  return (
    <ThemeProvider theme={defaultTheme}>
        <Grid container spacing={5} >
          {plans.MaximagePlans.map((plan) => (
            <Grid item key={plan.PlanName} xs={12} >
              <Card style={cardStyle} onClick={ () => dataExtract(plan)} >
                <CardHeader
                  title={plan.PlanName}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  sx={
                    {
                      backgroundColor:
                      selectedCard === plan.Price ? 'green' : (theme) =>
                      theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
                      color: selectedCard === plan.Price ? 'white' : 'black'
                    }
                  }
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                    >
                    <Typography component="h2" variant="h3" color="text.primary">
                      {formatCurrency(plan.Price * plans.DollarPriceMXN)}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      {/* /mes */}
                    </Typography>
                  </Box>
                  <ul style={{paddingRight:'1em'}}>
                    {formatText(plan.Description).slice(0,6)}
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </ThemeProvider>
  )
}