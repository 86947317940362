import React, { useState } from 'react';
import { Filter, SearchInput, Button } from 'react-admin';

const PostFilter = ({ onFilter }) => {
  const [filterValue, setFilterValue] = useState('');

  const handleFilter = () => {
    onFilter(filterValue);
  };

  return (
    <Filter context="form">
      <SearchInput
        source="q"
        alwaysOn
        style={{ marginLeft: '2px', marginBottom: '10px' }}
        value={filterValue}
        onChange={(e) => setFilterValue(e.target.value)}
      />
      <Button onClick={handleFilter} label="Filtrar" />
    </Filter>
  );
};

export default PostFilter;
