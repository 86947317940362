import React from 'react'
import '../Libs/ExternalViewer/Resources/Styles/Cards.css'
import tecinf from '../images/templates/tecinf.jpg'
import medicina from '../images/templates/salud.jpg'
import finanzas from '../images/templates/finanzas.jpg'
import manufactura from '../images/templates/manufactura.jpg'
import energia from '../images/templates/energy.png'
import retail from '../images/templates/retail.jpeg'
import automocion from '../images/templates/automocion.jpg'
import telecomunicaciones from '../images/templates/telecomunicaciones.jpg'
import alimentos from '../images/templates/alimentos.jpg'
import construccion from '../images/templates/construccion.jpg'
import turismo from '../images/templates/turismo.jpg'
import educacion from '../images/templates/educacion.jpg'
import comunicacion from '../images/templates/comunicacion.jpg'
import agricultura from '../images/templates/agricultura.jpg'
import servicios from '../images/templates/servicios.jpg'
import rh from '../images/templates/rh.jpg'
import areafinanza from '../images/templates/areafinanza.jpg'
import marketing from '../images/templates/marketing.jpg'
import operaciones from '../images/templates/operaciones.jpg'
import logistica from '../images/templates/logistica.jpg'
import ti from '../images/templates/ti.jpg'
import ventas from '../images/templates/ventas.jpg'
import clientes from '../images/templates/clientes.jpg'
import { useEffect, useState } from 'react'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const PlantillaCards = ({dataTemplates, handleOpenDetails}) => {

    const [dataPlantillas, setDataPlantillas] = useState([]);
    const images_Templates = [
        {
          src: medicina,
          alt: 'Salud y Medicina',
          caption: 'Salud y Medicina',
          descriptions: [
            { desc: 'Historias clínicas' },
            { desc: 'Recetas médicas' },
            { desc: 'Informes de laboratorio' },
            { desc: 'Consentimientos informados' },
            { desc: 'Facturas médicas' },
            { desc: 'Informes de alta hospitalaria' },
            { desc: 'Certificados médicos' },
            { desc: 'Pólizas de seguro de salud' },
            { desc: 'Resultados de pruebas diagnósticas' },
            { desc: 'Hojas de registro de pacientes' }
          ]
        },
        {
          src: finanzas,
          alt: 'Finanzas y Banca',
          caption: 'Finanzas y Banca',
          descriptions: [
            { desc: 'Estados financieros C' },
            { desc: 'Contratos de préstamo' },
            { desc: 'Informes de auditoría' },
            { desc: 'Transacciones bancarias' },
            { desc: 'Solicitudes de crédito' },
            { desc: 'Pólizas de seguros' },
            { desc: 'Declaraciones de impuestos' },
            { desc: 'Políticas de cumplimiento' },
            { desc: 'Documentos de inversión' },
            { desc: 'Acuerdos de fusiones y adquisiciones' }
          ]
        },
        {
          src: manufactura,
          alt: 'Manufactura',
          caption: 'Manufactura',
          descriptions: [
            { desc: 'Órdenes de trabajo' },
            { desc: 'Hojas de especificaciones' },
            { desc: 'Certificados de calidad' },
            { desc: 'Facturas de proveedores' },
            { desc: 'Informes de producción' },
            { desc: 'Listas de materiales (BOM)' },
            { desc: 'Informes de inspección' },
            { desc: 'Documentos de garantía' },
            { desc: 'Registros de mantenimiento' }
          ]
        },
        {
          src: energia,
          alt: 'Energía y Recursos Naturales',
          caption: 'Energía y Recursos Naturales',
          descriptions: [
            { desc: 'Contratos de suministro' },
            { desc: 'Informes de impacto ambiental' },
            { desc: 'Licencias y permisos' },
            { desc: 'Facturas de servicios públicos' }
          ]
        },
        {
          src: telecomunicaciones,
          alt: 'Telecomunicaciones',
          caption: 'Telecomunicaciones',
          descriptions: [
            { desc: 'Contratos de servicios' },
            { desc: 'Facturas de telefonía' },
            { desc: 'Informes de incidentes' },
            { desc: 'Licencias de espectro' },
            { desc: 'Acuerdos de nivel de servicio (SLAs)' }
          ]
        },
        {
          src: retail,
          alt: 'Retail',
          caption: 'Retail',
          descriptions: [
            { desc: 'Facturas de ventas' },
            { desc: 'Inventarios' },
            { desc: 'Contratos de proveedores' },
            { desc: 'Listas de precios' },
            { desc: 'Órdenes de compra' },
            { desc: 'Informes de ventas' },
            { desc: 'Estados financieros' },
            { desc: 'Hojas de caja' },
            { desc: 'Acuerdos de distribución' },
            { desc: 'Políticas de devolución y reembolso' }
          ]
        },
        {
          src: automocion,
          alt: 'Automoción',
          caption: 'Automoción',
          descriptions: [
            { desc: 'Contratos de venta de vehículos' },
            { desc: 'Facturas de ventas' },
            { desc: 'Manuales de usuario' },
            { desc: 'Informes de inspección de vehículo' },
            { desc: 'Especificaciones técnicas' },
            { desc: 'Órdenes de producción' },
            { desc: 'Informes de pruebas de seguridad' },
            { desc: 'Documentos de homologación' },
            { desc: 'Hojas de garantía' },
            { desc: 'Registros de mantenimiento' }
          ]
        },
        {
          src: alimentos,
          alt: 'Alimentación y Bebidas',
          caption: 'Alimentación y Bebidas',
          descriptions: [
            { desc: 'Etiquetas de alimentos' },
            { desc: 'Certificados de calidad' },
            { desc: 'Facturas de proveedores' },
            { desc: 'Registros de control de calidad' },
            { desc: 'Fichas técnicas de productos' },
            { desc: 'Certificados de origen' },
            { desc: 'Especificaciones de ingredientes' },
            { desc: 'Registros de trazabilidad' }
          ]
        },
        {
          src: construccion,
          alt: 'Construcción',
          caption: 'Construcción',
          descriptions: [
            { desc: 'Contratos de construcción' },
            { desc: 'Planos arquitectónicos' },
            { desc: 'Especificaciones de construcción' },
            { desc: 'Licencias y permisos' },
            { desc: 'Informes de inspección' },
            { desc: 'Informes de seguridad en el trabajo' },
            { desc: 'Presupuestos' },
            { desc: 'Órdenes de cambio' },
            { desc: 'Contratos de subcontratación' },
            { desc: 'Informes de progreso' }
          ]
        },
        {
          src: turismo,
          alt: 'Turismo y Hospitalidad',
          caption: 'Turismo y Hospitalidad',
          descriptions: [
            { desc: 'Reservas de hotel' },
            { desc: 'Facturas de hospedaje' },
            { desc: 'Itinerarios de viaje' },
            { desc: 'Contratos de servicios turísticos' },
            { desc: 'Políticas de cancelación' },
            { desc: 'Registros de check-in y check-out' },
            { desc: 'Informes de satisfacción del cliente' },
            { desc: 'Hojas de registro de visitantes' }
          ]
        },
        {
          src: educacion,
          alt: 'Educación',
          caption: 'Educación',
          descriptions: [
            { desc: 'Planes de estudio' },
            { desc: 'Certificados y diplomas' },
            { desc: 'Informes de calificaciones' },
            { desc: 'Solicitudes de admisión' },
            { desc: 'Libros de asistencia' },
            { desc: 'Programas de cursos' },
            { desc: 'Políticas y reglamentos estudiantiles' },
            { desc: 'Material didáctico' },
            { desc: 'Registros de asesoramiento académico' },
            { desc: 'Credenciales estudiantiles' }
          ]
        },
        {
          src: comunicacion,
          alt: 'Medios de Comunicación',
          caption: 'Medios de Comunicación',
          descriptions: [
            { desc: 'Contratos de publicidad' },
            { desc: 'Informes de audiencia' },
            { desc: 'Licencias de derechos de autor' },
            { desc: 'Acuerdos de distribución' },
            { desc: 'Contratos de talento' },
            { desc: 'Guías de estilo' },
            { desc: 'Autorizaciones de uso de imagen' },
            { desc: 'Políticas de ética periodística' },
            { desc: 'Informes de cobertura mediática' }
          ]
        },
        {
          src: agricultura,
          alt: 'Agricultura',
          caption: 'Agricultura',
          descriptions: [
            { desc: 'Contratos de venta de productos agrícolas' },
            { desc: 'Informes de cultivo' },
            { desc: 'Registros de pesticidas y fertilizantes' },
            { desc: 'Certificados de calidad de productos agrícolas' },
            { desc: 'Facturas de proveedores' },
            { desc: 'Informes de cosecha' },
            { desc: 'Estudios de suelo' },
            { desc: 'Registros de riego' },
            { desc: 'Documentos de exportación' }
          ]
        },
        {
          src: servicios,
          alt: 'Servicios Profesionales',
          caption: 'Servicios Profesionales',
          descriptions: [
            { desc: 'Contratos de servicios' },
            { desc: 'Facturas de servicios' },
            { desc: 'Informes de proyectos' },
            { desc: 'Propuestas comerciales' },
            { desc: 'Acuerdos de confidencialidad' },
            { desc: 'Documentos de licitación' },
            { desc: 'Cronogramas' },
            { desc: 'Facturación' },
            { desc: 'Políticas de ética profesional' },
            { desc: 'Contratos de consultoría' }
          ]
        },
        {
          src: rh,
          alt: 'Recursos Humanos',
          caption: 'Recursos Humanos',
          descriptions: [
            { desc: "Contratos laborales" },
            { desc: "Nóminas" },
            { desc: "Hojas de vida" },
            { desc: "Formularios de solicitud de empleo" },
            { desc: "Evaluaciones de desempeño" },
            { desc: "Políticas y procedimientos internos" },
            { desc: "Certificados laborales" },
            { desc: "Documentos de capacitación y desarrollo" },
            { desc: "Formularios de permisos y licencias" }
          ]
        },
        {
          src: areafinanza,
          alt: 'Finanzas',
          caption: 'Finanzas',
          descriptions: [
            { desc: "Estados financieros (como el balance general y el estado de resultados)" },
            { desc: "Presupuestos" },
            { desc: "Informes de gastos" },
            { desc: "Facturas y recibos" },
            { desc: "Estados de cuentas bancarias" },
            { desc: "Contratos financieros" },
            { desc: "Informes de auditoría" },
            { desc: "Declaraciones de impuestos" },
            { desc: "Informes de análisis financiero" }
          ]
        },
        {
          src: marketing,
          alt: 'Marketing',
          caption: 'Marketing',
          descriptions: [
            { desc: "Planes de marketing" },
            { desc: "Estrategias de branding" },
            { desc: "Investigaciones de mercado" },
            { desc: "Informes de análisis de mercado" },
            { desc: "Campañas publicitarias" },
            { desc: "Briefings creativos" },
            { desc: "Análisis de competidores" },
            { desc: "Informes de rendimiento de campañas" },
            { desc: "Materiales promocionales" }
          ]
        },
        {
          src: operaciones,
          alt: 'Operaciones',
          caption: 'Operaciones',
          descriptions: [
            { desc: "Órdenes de producción" },
            { desc: "Hojas de ruta" },
            { desc: "Listas de materiales" },
            { desc: "Informes de calidad" },
            { desc: "Informes de producción" },
            { desc: "Programación de la producción" },
            { desc: "Manuales de procedimientos" },
            { desc: "Documentos de seguridad y salud ocupacional" }
          ]
        },
        {
          src: logistica,
          alt: 'Logistica',
          caption: 'Logistica',
          descriptions: [
            { desc: "Órdenes de compra" },
            { desc: "Documentos de transporte (como guías de remisión y facturas de transporte)" },
            { desc: "Informes de seguimiento de inventario" },
            { desc: "Informes de gestión de almacenes" },
            { desc: "Contratos de proveedores" },
            { desc: "Acuerdos de nivel de servicio" },
            { desc: "Informes de costos logísticos" }
          ]
        },
        {
          src: ti,
          alt: 'Tecnología de la Información',
          caption: 'Tecnología de la Información',
          descriptions: [
            { desc: "Políticas de seguridad informática" },
            { desc: "Contratos de licencia de software" },
            { desc: "Informes de gestión de proyectos" },
            { desc: "Registros de incidentes y problemas" },
            { desc: "Documentación técnica" },
            { desc: "Políticas de respaldo y recuperación de datos" },
            { desc: "Políticas de uso aceptable de recursos informáticos" }
          ]
        },
        {
          src: ventas,
          alt: 'Ventas',
          caption: 'Ventas',
          descriptions: [
            { desc: 'Cotizaciones' },
            { desc: 'Órdenes de venta' },
            { desc: 'Facturas' },
            { desc: 'Contratos de venta' },
            { desc: 'Informes de seguimiento de clientes' },
            { desc: 'Registros de ventas' },
            { desc: 'Informes de comisiones de ventas' },
            { desc: 'Informes de análisis de ventas' },
            { desc: 'Documentos de gestión de clientes' }
          ]
        },
         {
          src: clientes,
          alt: 'Clientes',
          caption: 'Clientes',
          descriptions: [
            { desc: 'Cotizaciones' },
            { desc: 'Órdenes de venta' },
            { desc: 'Facturas' },
            { desc: 'Contratos de venta' },
            { desc: 'Informes de seguimiento de clientes' },
            { desc: 'Registros de ventas' },
            { desc: 'Informes de comisiones de ventas' },
            { desc: 'Informes de análisis de ventas' },
            { desc: 'Documentos de gestión de clientes' }
          ]
        }
    ];

    useEffect(()=>{
        const resultado = dataTemplates.map((plantilla) => {
            const match = images_Templates.find((im) => im.caption === plantilla.IndustryName);
            if (match) {
                return {
                    IndustryId: plantilla.IndustryId,
                    IndustryName: plantilla.IndustryName,
                    id: plantilla.id,
                    src: match.src,
                    descriptions: match.descriptions,
                };
            } else {
                // Si no se encuentra una coincidencia, puedes manejarlo como desees.
                // En este caso, simplemente devolveremos una versión parcial del objeto.
                return {
                    IndustryId: plantilla.IndustryId,
                    IndustryName: plantilla.IndustryName,
                    id: plantilla.IndustryId,
                    src: '',
                    descriptions: [{desc:'No se encontró información'}],
                };
            }
        });
        setDataPlantillas(resultado)        
    },[])

  return (
    <div>
    <section class="articles">
        {dataPlantillas != null ? (
            dataPlantillas.map((plan)=>{
                return(
                <article>
                    <div class="article-wrapper">
                        <figure>
                            <img src={plan.src} alt="" />
                        </figure>
                        <div class="article-body">
                            <h4>{plan.IndustryName} {plan.IndustryId > 5 ? <AddShoppingCartIcon style={{color:'blue'}}/> : <CheckCircleOutlineIcon style={{color:'green'}}/> }</h4>
                            {dataPlantillas.length != 1 ? (
                                <a class="read-more" onClick={(e)=>handleOpenDetails(e,plan)}>
                                    Ver detalles de plantilla <span class="sr-only">about this is some title</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 1 20 20" fill="gray">
                                        <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                                    </svg>
                                </a>
                            ) : <span>Precio: $14.99 USD</span>}
                        </div>
                    </div>
                </article>
                )   
            })
        ): null }
    </section>
    </div>
  )
}

export default PlantillaCards