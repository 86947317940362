import React from 'react';
import { useState, useEffect } from 'react';
import { 
    Chart as ChartJS, 
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement, 
    Tooltip, 
    Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  
    Loading, 
    useDataProvider,
    Error,
    useTranslate
} from 'react-admin';

import { 
    faFileWord, 
    faFilePdf, 
    faFileImage, 
    faUser,
    faFile,
    faFileExcel,
    faFileAlt,
    faFileCode
} from '@fortawesome/free-solid-svg-icons'
import '../Libs/ExternalViewer/Resources/Styles/predefinidasStyle.css'
import introJs from 'intro.js';
import 'intro.js/introjs.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement, 
    Tooltip, 
    Legend);

const DashBoard  = () => {

  function getRandomRgb() {
    const red = Math.floor((1 + Math.random()) * 256/2);
    const green = Math.floor((1 + Math.random()) * 256/2);
    const blue = Math.floor((1 + Math.random()) * 256/2);
    return "rgba(" + red + ", " + green + ", " + blue + ", 0.8)";
  }

  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [tiposDocumentos, setTiposDocumentos] = useState([]);
  const [fechaDigitalizacion, setFechaDigitalizacion] = useState([]);
  const [usuarios, setUsuarios] = useState(null);
  const [datosDocumentosPendientesTipo, setDatosDocumentosPendientesTipo] = useState([]);
  const [capturedDocuments, setCapturedDocuments] = useState(null);
  const [totalPagesCapturedDocuments, setTotalPagesCapturedDocuments] = useState(null); 
  const [documentsPendingCapture, setDocumentsPendingCapture] = useState(null);
  const [espacioUtilizado, setespacioUtilizado] = useState(null);
  const [error, setError] = useState();
  const inTour = JSON.parse(localStorage.getItem('activeTour'))

  const translate = useTranslate();
  let introNodos;

  let introHelp;
    introHelp = introJs('#root');
    introHelp.setOptions({
        disableInteraction: false,
        doneLabel: translate('traducciones.introBotones.doneLabel'),
        nextLabel: translate('traducciones.introBotones.nextLabel'),
        prevLabel: translate('traducciones.introBotones.prevLabel'),
        tooltipPosition:'top',
        steps: [
                {
                intro: translate('traducciones.introHelp.intro1'),
                title: translate('traducciones.introHelp.title1'),
                element: '#help'
                },
        ]                
    });

    const showHelp = () =>{
        const offPermisos = {
            querys: false,
            carga: false,
            admin: false,
            estructura: false,
        }
        if(document.cookie == 'introjs-dontShowAgain=true'){
            document.cookie = 'introjs-dontShowAgain =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'
                setTimeout(()=>{
                    introHelp.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).oncomplete(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).onexit(()=>{
                        crearCookie('introjs-dontShowAgain', 'true', 365)
                        localStorage.setItem('activeTour',JSON.stringify(offPermisos))
                    }).start();                         
                },500)
        }
    }

    function crearCookie(nombre, valor, diasParaExpirar) {
    var fechaExpiracion = new Date();
    fechaExpiracion.setDate(fechaExpiracion.getDate() + diasParaExpirar);
    var cookie = nombre + '=' + valor + '; expires=' + fechaExpiracion.toUTCString() + '; path=/';
    document.cookie = cookie;
    }

  useEffect(() => {
    dataProvider.getOne('dashboard', { type: 'getCapturedDocuments' })
            .then(({ data }) => {
                /*
                Description: "Documentos Capturados"
                Total: 169
                */
                setCapturedDocuments(data.data);
                dataProvider.getOne('dashboard', { type: 'getCapturedDocumentsTypes' })
                    .then(({ data }) => {
                        /*
                        Array de 
                        Description: "DOCX"
                        Total: 5
                        */
                        setTiposDocumentos(data.data);

                        if(data.data != null && data.data != undefined && data.data.length > 0) {

                        dataProvider.getOne('dashboard', { type: 'getCapturedDocumentsByDate' })
                            .then(({ data }) => {
                                /*
                                Array de 
                                HorizontalAxisValue: "2022/02/01"
                                VerticalAxisValue: "12"
                                */
                               var labelsDias = [];
                               var datosFechas = [];
                               data.data.forEach(function (record) { 
                                    if(record != null){
                                        labelsDias.push(record.HorizontalAxisValue);
                                        datosFechas.push(record.VerticalAxisValue)
                                    }
                                });

                               setFechaDigitalizacion({
                                    labels: labelsDias,
                                    datasets: [
                                        {
                                            label: translate('traducciones.dash.indexadosXdia'),
                                            data: datosFechas,
                                            backgroundColor: '#3a9b4a',
                                        },
                                    ],
                                });
                                dataProvider.getOne('dashboard', { type: 'getTotalPagesCapturedDocuments' })
                                    .then(({ data }) => {
                                        /*
                                        Description: "Páginas Capturadas"
                                        Total: 885
                                        */
                                       setTotalPagesCapturedDocuments(data.data);
                                        dataProvider.getOne('dashboard', { type: 'getDocumentsPendingCapture' })
                                        .then(({ data }) => {
                                            /*
                                            Description: "Documentos Pendientes Capturar"
                                            Total: 174
                                            */
                                            setDocumentsPendingCapture(data.data);
                                            dataProvider.getOne('dashboard', { type: 'getDocumentTypesPendingCapture' })
                                                .then(({ data }) => {
                                                    /*
                                                    Array de
                                                    Item: "DOCX"
                                                    Percent: "13"
                                                    */
                                                    var labelsd = [];
                                                    var datosd = [];
                                                    var fondos = [];
                                                    data.data.forEach(function (record) { 
                                                            if(record != null){
                                                                labelsd.push(record.Item);
                                                                datosd.push(record.Percent);
                                                                fondos.push(getRandomRgb());
                                                            }
                                                        });    
                                                    setDatosDocumentosPendientesTipo({
                                                        labels: labelsd,
                                                        datasets: [
                                                        {
                                                            label: '%',
                                                            data: datosd,
                                                            borderWidth: 1,
                                                            backgroundColor: fondos
                                                        }
                                                        ],
                                                    });
                                                    
                                                    dataProvider.getOne('dashboard', { type: 'getActiveUsers' })
                                                        .then(({ data }) => {
                                                            /*
                                                            Description: "Usuarios Activos"
                                                            Total: 9
                                                            */
                                                            setUsuarios(data.data);
                                                            dataProvider.getOne('dashboard', { type: 'getUsedSpace' })
                                                            .then(({ data }) => {
                                                                /*
                                                                Maximum: "1000"
                                                                ProgressPercentage: "65"
                                                                */          
                                                                setespacioUtilizado({
                                                                    labels : [''],
                                                                    datasets: [
                                                                        {
                                                                            label: 'Espacio Utilizado ' + data.data.ProgressPercentage + '% de ' + data.data.Maximum + ' GB',
                                                                            data: [data.data.ProgressPercentage],
                                                                            backgroundColor: '#3a9b4a',
                                                                            stacked : "0"
                                                                            
                                                                        },
                                                                        {
                                                                            label: 'Espacio Disponible ' + ((data.data.Maximum * (100 - data.data.ProgressPercentage)) / 100) + " GB",
                                                                            data: [100 - data.data.ProgressPercentage],
                                                                            backgroundColor: 'rgb(223 223 223)',
                                                                            stacked : "0"
                                                                            
                                                                        }
                                                                    ],
                                                                })                                                      
                                                                setLoading(false);
                                                                if(inTour.estructura){
                                                                    setTimeout(()=>{
                                                                        introNodos = introJs('#root');
                                                                        introNodos.setOptions({
                                                                            disableInteraction: false,
                                                                            doneLabel: translate('traducciones.introBotones.doneLabel'),
                                                                            nextLabel: translate('traducciones.introBotones.nextLabel'),
                                                                            prevLabel: translate('traducciones.introBotones.prevLabel'),
                                                                            tooltipPosition:'top',
                                                                            steps: [
                                                                                {
                                                                                    intro: translate('traducciones.introDashboard.intro1'),
                                                                                    title: translate('traducciones.introDashboard.title1')
                                                                                },
                                                                                {
                                                                                    intro: translate('traducciones.introDashboard.intro2'),
                                                                                    title: translate('traducciones.introDashboard.title2'),
                                                                                    element: "#main-content",
                                                                                    scrollTo: "off"
                                                                                },
                                                                                {
                                                                                    intro: translate('traducciones.introDashboard.intro3'),
                                                                                    title: translate('traducciones.introDashboard.title3'),
                                                                                    element: ".MuiDrawer-root",
                                                                                    scrollTo: "off",
                                                                                    position: "right"
                                                                                },
                                                                                {
                                                                                    intro: translate('traducciones.introDashboard.intro4'),
                                                                                    title: translate('traducciones.introDashboard.title4'),
                                                                                    element: "#querys",
                                                                                    scrollTo: "off",
                                                                                    position: "right"
                                                                                },
                                                                                {
                                                                                    intro: translate('traducciones.introDashboard.intro5'),
                                                                                    title: translate('traducciones.introDashboard.title5'),
                                                                                    element: "#digitalizacion",
                                                                                    scrollTo: "#querys",
                                                                                    position: "right"
                                                                                },
                                                                                {
                                                                                    intro: translate('traducciones.introDashboard.intro6'),
                                                                                    title: translate('traducciones.introDashboard.title6'),
                                                                                    element: "#administracion",
                                                                                    scrollTo: "off",
                                                                                    position: "right"
                                                                                },
                                                                                {
                                                                                    intro: translate('traducciones.introDashboard.intro7'),
                                                                                    title: translate('traducciones.introDashboard.title7'),
                                                                                    element: "#estructura",
                                                                                    scrollTo: "off",
                                                                                    position: "right"
                                                                                },
                                                                                {
                                                                                    intro: translate('traducciones.introDashboard.intro8'),
                                                                                    title: translate('traducciones.introDashboard.title8'),
                                                                                    element: "#userMenu",
                                                                                    scrollTo: "off",
                                                                                    position: "right"
                                                                                }
                                                                            ]
                                                                        });
                                                                
                                                                        introNodos.setOption("dontShowAgain", true).setOption("dontShowAgainLabel", translate('traducciones.introBotones.dontShow')).onexit(()=>{showHelp()}).oncomplete(()=>{showHelp()}).start();
                                                                    },800)
                                                                }
                                                                return true;
                                                            })
                                                            .catch(error => {
                                                                setError(error);
                                                                setLoading(false);
                                                            })
                                                        })
                                                        .catch(error => {
                                                            setError(error);
                                                            setLoading(false);
                                                        })
                                                })
                                                .catch(error => {
                                                    //setError(error);
                                                    setLoading(false);
                                                })
                                        })
                                        .catch(error => {
                                            //setError(error);
                                            setLoading(false);
                                        })
                                    })
                                    .catch(error => {
                                        //setError(error);
                                        setLoading(false);
                                    })
                            })
                            .catch(error => {
                                //setError(error);
                                setLoading(false);
                            })

                        }else{
                            setLoading(false);
                        }
                        /////
                    })
                    .catch(error => {
                        //setError(error);
                        setLoading(false);
                    })
            })
            .catch(error => {
                //setError(error);
                setLoading(false);
            })
    }, []);

    if(loading){
        return <Loading />
    }

    if (error) return <Error error={ error } />;

    const getIcon = (type) => {
        switch (type) {
            case "PDF":
                return (<FontAwesomeIcon icon= { faFilePdf } />);    
            case "XLSX":
                return (<FontAwesomeIcon icon= { faFileExcel } />); 
            case "DOCX":
                return (<FontAwesomeIcon icon= { faFileWord } />);  
            case "XLS":
                return (<FontAwesomeIcon icon= { faFileExcel } />); 
            case "DOC":
                return (<FontAwesomeIcon icon= { faFileWord } />); 
            case "JPEG":
                return (<FontAwesomeIcon icon= { faFileImage } />);  
            case "JPG":
                return (<FontAwesomeIcon icon= { faFileImage } />);  
            case "PNG":
                return (<FontAwesomeIcon icon= { faFileImage } />);
            case "XML":
                return (<FontAwesomeIcon icon= { faFileCode } />);
            default:
                return (<FontAwesomeIcon icon= { faFileAlt } />); 
        }
    }
  
  return (

<div className='listaMovil'>
    { tiposDocumentos.length > 0 ? (

    <div style= {{ padding:'15px'}}>
        <div class="row" style={{ marginBottom: '1em', color: 'rgb(118 125 133)', justifyContent: "center" }}>
            { tiposDocumentos.length > 0 && tiposDocumentos.map(
                (item) => {
                    return (
                    <div class="col-md-3" style={{ paddingLeft:'3px', paddingRight: '3px' }}>
                        <div class="small-box">
                            <div class="inner">
                                <h3>{ item.Total }</h3>
                                <p>{ translate('traducciones.dash.documentos') } { item.Description } { translate('traducciones.dash.digitalizados')}</p>
                            </div>                    
                            <div class="icon">
                                { getIcon(item.Description) }           
                            </div>
                        </div>		
                    </div>
                )})
            }     

            
                <div class="col-md-3" style={{ paddingLeft:'3px', paddingRight: '3px' }}>
                    <div class="small-box">
                        <div class="inner">
                            <h3>{ capturedDocuments.Total }</h3>
                            <p>{ capturedDocuments.Description }</p>
                        </div>                    
                        <div class="icon">
                            { getIcon(capturedDocuments.Description) }           
                        </div>
                    </div>		
                </div> 

                <div class="col-md-3" style={{ paddingLeft:'3px', paddingRight: '3px' }}>
                    <div class="small-box">
                        <div class="inner">
                            <h3>{ totalPagesCapturedDocuments.Total }</h3>
                            <p>{ totalPagesCapturedDocuments.Description }</p>
                        </div>                    
                        <div class="icon">
                            { getIcon(totalPagesCapturedDocuments.Description) }           
                        </div>
                    </div>		
                </div> 

        </div>      
        <div className="row">            
            <div className="col-md-3">
                <div class="small-box" style={{ backgroundColor : '#9f9f9f', color: 'white'}}>
                    <div class="inner">
                        <h3>{ usuarios ? usuarios.Total : "--" }</h3>
                        <p>{ usuarios ? usuarios.Description : "--" }</p>
                    </div>                    
                    <div class="icon">
                        <FontAwesomeIcon icon= { faUser } />                     
                    </div>
                </div>

                <Bar data={espacioUtilizado} options= { { 
                    indexAxis: 'y', 
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true,
                        },
                    },
                }} />
            </div>
            <div className="col-md-3" style={{ padding: '3%', paddingTop: '0'}}>
                <Doughnut data={datosDocumentosPendientesTipo} />
            </div>
            <div className="col-md-3">
                <Bar data={fechaDigitalizacion}  />
            </div>
            <div className="col-md-3">
                <div class="small-box" style={{ backgroundColor : '#9f9f9f', color: 'white'}}>
                    <div class="inner">
                        <h3>{ documentsPendingCapture.Total }</h3>
                        <p>{ documentsPendingCapture.Description}</p>
                    </div>                    
                    <div class="icon">
                        <FontAwesomeIcon icon= { faFile } />                     
                    </div>
                </div>
            </div>
        </div>
    </div>
    ) : (

        <div style= {{ textAlign: 'center',   marginTop: '15vh', color: '#979797'}}>
            <h3>MAXIMAGE AIIDARA, { translate('traducciones.generales.dashtitle') }</h3>
            <p>
            <b>{ translate('traducciones.generales.step1') }</b> { translate('traducciones.generales.dashstep1') }
            </p>
            <p>
            <b>{ translate('traducciones.generales.step2') }</b> { translate('traducciones.generales.dashstep2') }
            </p>
            <p>
            <b>{ translate('traducciones.generales.step3') }</b> { translate('traducciones.generales.dashstep3') }  
            </p>
        </div>
    ) 
    
    
    }
    </div>
)
  };

export default DashBoard;

