import React from 'react';
import {
  Card,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import {
  useTranslate,
  EditButton,
  DeleteButton
} from 'react-admin';
import PostFilter from '../../Components/PostFilter';

const useStyles = makeStyles({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0.5rem 0',
    borderRadius : '16px',
    marginBottom: '15px',
    boxShadow: '1px 3px 7px #3b824b'
  },
  cardTitleContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
  },
  cardContentRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0.5rem 0',
  },
  cardHeader : {
    backgroundColor: '#525b59',
    paddingBottom: '10px',
    paddingTop: '10px',
    color: 'white'
  }
});

const MobileCatalogosList = (propsDataGrid) => {
  const { ids, data, checkPermiso } = propsDataGrid;
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <div style={{ margin: '0.5em' }}>
      <div style={{marginLeft:'15%'}}>
        <PostFilter/>
      </div>
      {ids && ids.map(id => (
            <Card key={id} className={classes.card}>
               <CardHeader
                        className={classes.cardHeader }
                        title={
                          <div className={classes.cardTitleContent} alignItems="center">
                                <span style={{ fontSize: 'large' }}>
                                {translate('traducciones.titulos.catalogo')} : { data[id].NombreTabla } 
                                </span>
                            </div>
                        }
                />
                <div style= { { padding: '16px', paddingTop: '5px'} }>
                  <div  align="right" >
                  { checkPermiso('EditarCatalogos') && <EditButton record={ data[id] } label="" className="actionButton" onClick={propsDataGrid.edit}  /> }
                  { checkPermiso('EliminarCatalogos') && <DeleteButton record = { data[id] } label="" className="actionButton" undoable={false} confirmTitle={translate('traducciones.modal.eliminarCatalogo')} confirmContent={translate('traducciones.modal.eliminarCatalogoConfirm')}></DeleteButton> }
                  </div>             
                </div>
            </Card>
          ))
          }
    </div>
  );
};


MobileCatalogosList.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};


export default MobileCatalogosList;