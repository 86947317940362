import React from 'react'
import '../Libs/ExternalViewer/Resources/Styles/Loader.css'
import { useTranslate } from 'react-admin';

const Loader = () => {
    const translate = useTranslate();
  return (
    <div>
        <div class="pl">
        {/* <center><span style={{fontSize:'17px', fontWeight:'bold'}}>Estamos recopilando sus datos.</span></center>
        <center><span style={{fontSize:'17px'}}> Por favor, tenga en cuenta que este proceso podría llevar unos minutos.</span></center>
        <center><span style={{fontSize:'17px'}}> Le agradecemos su paciencia mientras trabajamos en ello.</span></center> */}
            <svg class="pl__rings" viewBox="0 0 128 128" width="128px" height="128px">
                <g fill="none" stroke-linecap="round" stroke-width="4">
                    <g class="pl__ring" transform="rotate(0)">
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.3)" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                    </g>
                    <g class="pl__ring" transform="rotate(0)">
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                    </g>
                    <g class="pl__ring" transform="rotate(0)">
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                    </g>
                    <g class="pl__ring" transform="rotate(0)">
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                    </g>
                    <g class="pl__ring" transform="rotate(180)">
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.3)" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                    </g>
                    <g class="pl__ring" transform="rotate(180)">
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0)" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsla(223,90%,50%,0.5)" stroke-dasharray="50 240" />
                        <ellipse class="pl__orbit" cx="64" cy="64" rx="60" ry="30" stroke="hsl(223,90%,50%)" stroke-dasharray="25 265" />
                    </g>
                    <g class="pl__ring" transform="rotate(0)">
                        <ellipse class="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                        <ellipse class="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                    </g>
                    <g class="pl__ring" transform="rotate(180)">
                        <ellipse class="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                        <ellipse class="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                        <ellipse class="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />
                        <ellipse class="pl__electron" cx="64" cy="64" rx="60" ry="30" stroke="hsl(0,0%,100%)" stroke-dasharray="1 289" stroke-width="8" />	
                    </g>
                </g>
            </svg>
            <div class="pl__nucleus">
                <div class="pl__nucleus-particle"></div>
                <div class="pl__nucleus-particle"></div>
                <div class="pl__nucleus-particle"></div>
                <div class="pl__nucleus-particle"></div>
                <div class="pl__nucleus-particle"></div>
                <div class="pl__nucleus-particle"></div>
                <div class="pl__nucleus-particle"></div>
                <div class="pl__nucleus-particle"></div>
                <div class="pl__nucleus-particle"></div>
                <div class="pl__nucleus-particle"></div>
                <div class="pl__nucleus-particle"></div>
                <div class="pl__nucleus-particle"></div>
                <div class="pl__nucleus-particle"></div>
            </div>
        </div>
        <center><span style={{fontSize:'17px', fontWeight:'bold'}}>{translate('traducciones.loader.texto1')}</span></center>
        <center><span style={{fontSize:'17px'}}> {translate('traducciones.loader.texto2')}</span></center>
        <center><span style={{fontSize:'17px'}}> {translate('traducciones.loader.texto3')}</span></center>
    </div>
  )
}

export default Loader